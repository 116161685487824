import { css } from 'styled-components'

export const wBreakpoints = {
  mobileXS: null,
  mobile: 480,
  tablet: 1024,
  desktop: 1600,
}

export const shortBreakpoint = 1023

const bpFrom = (value, ...args) => css`
  @media screen and (min-width: ${value}px) {
    ${css(...args)}
  }
`

const bpTo = (value, ...args) => css`
  @media not screen and (min-width: ${value}px) {
    ${css(...args)}
  }
`

// Short is for breakpoints below 1024px height
const bpShortFrom = (value, ...args) => css`
  @media screen and (min-width: ${value}px) and (max-height: ${shortBreakpoint}px)  {
    ${css(...args)}
  }
`

const bpShortTo = (value, ...args) => css`
  @media not screen and (min-width: ${value}px) and (max-height: ${shortBreakpoint}px) {
    ${css(...args)}
  }
`
export const bpHeight = (value, ...args) => css`
  @media screen and (max-height: ${value}px) {
    ${css(...args)}
  }
`

// From breakpoint upwards
export const from = {
  mobileXS: (...args) => css(...args),
  mobile: (...args) => bpFrom(wBreakpoints.mobile, ...args),
  tablet: (...args) => bpFrom(wBreakpoints.tablet, ...args),
  desktop: (...args) => bpFrom(wBreakpoints.desktop, ...args),
}

// Up to breakpoint (excluded from next breakpoint)
export const to = {
  mobileXS: (...args) => bpTo(wBreakpoints.mobile, ...args),
  mobile: (...args) => bpTo(wBreakpoints.tablet, ...args),
  tablet: (...args) => bpTo(wBreakpoints.desktop, ...args),
  desktop: (...args) => css(...args),
}

// The "short" variant
// From short breakpoint upwards
export const shortFrom = {
  mobileXS: (...args) => bpHeight(shortBreakpoint, ...args),
  mobile: (...args) => bpShortFrom(wBreakpoints.mobile, ...args),
  tablet: (...args) => bpShortFrom(wBreakpoints.tablet, ...args),
  desktop: (...args) => bpShortFrom(wBreakpoints.desktop, ...args),
}

// Up to breakpoint (excluded from next breakpoint)
export const shortTo = {
  mobileXS: (...args) => bpShortTo(wBreakpoints.mobile, ...args),
  mobile: (...args) => bpShortTo(wBreakpoints.tablet, ...args),
  tablet: (...args) => bpShortTo(wBreakpoints.desktop, ...args),
  desktop: (...args) => bpHeight(shortBreakpoint, ...args),
}