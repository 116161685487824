import { from, to } from './breakpoints'
import { palette } from './palette'
import { components } from './components'
import { ui } from './ui'
import { colors } from './colors'
import { ThemeProvider } from './theme-provider'
import { SubThemeProvider } from './sub-theme-provider'

const theme = {
  ...components,
  site: {
    ui,
    colors,
  }
}

export {
  palette,
  theme,
  // Breakpoints
  from,
  to,
  // Providers
  ThemeProvider,
  SubThemeProvider,
}