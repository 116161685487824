import React, {Component} from 'react';
import {Form} from "semantic-ui-react";
import {connect} from "react-redux";


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
    }

}

class FormSelectState extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            selectedState: props.initialValue,
        }
    }

    render() {
        const {options, disabled, name, onChange} = this.props;
        const {error, selectedState} = this.state;

        return (
            <Form.Field fluid width={6}>
                <Form.Select required
                             label="State"
                             value={selectedState || ''}
                             disabled={disabled}
                             options={options}
                             error={!!error}
                             onChange={(e, {value})=>{
                                 this.setState({
                                     selectedState: value,
                                     error: null,
                                 });
                                 onChange(e, {name, value})
                             }}
                             /*onBlur={()=>{
                                 const isCovered = isCoveredState(selectedState);
                                 let errorMsg = "";
                                 if(!isCovered){
                                     errorMsg = "Brella is not offered in this state";
                                     this.setState({error: errorMsg});
                                 }
                             }}*/
                />
                {!!error && <small style={{color: 'red'}}>{error}</small>}
            </Form.Field>
        );
    }
}

export default connect(mapStateToProps)(FormSelectState);