import React, {Component} from 'react'

class MapMarker extends Component {
    constructor(props) {
        super(props);
        const {...metadata} = this.props;

        this.state = {
            ...metadata,
        };
    }

    handleOnClick() {
        this.setState({show:true});
        const {listing, lat,lng,handleOnClick} = this.props;
        !!handleOnClick && handleOnClick(listing, lat, lng);
    }

    render() {
        const centered = {
            position: 'absolute',
            transform: 'translate(-50%, -100%)',
        };

        return (
            <div>
                <img width={'32'} height={'32'} alt="map marker" style={centered} src={!!this.props.icon ? this.props.icon : 'ClaraFavicon.png'} onClick={this.handleOnClick.bind(this)}/>
            </div>
        );
    }
}

export default MapMarker;