import React from "react";
import {bank, paycheck, paypal, venmo} from "../utils/common";
import {Icon, Image} from "semantic-ui-react";


const PAYOUT_METHODS = Object.freeze({
    venmo:      { key: venmo,       Component: () => <Image style={{width: '130px'}} src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Payment+methods/venmo%402x.png"} />},
    paypal:     { key: paypal,      Component: () => <Image style={{width: '130px'}} src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Payment+methods/paypal%402x.png"} />},
    bank:       { key: bank,        Component: () => <div className={"neutral700Text"}><Icon name={"university"} size={"large"}/><span style={{verticalAlign: 'text-top'}}> <b>Bank Account</b></span></div> },
    paycheck:   { key: paycheck,    Component: () => <span className={"neutral700Text"}><b>Paper check</b></span> }
});

const getElectronicPayouts = () => [PAYOUT_METHODS.venmo, PAYOUT_METHODS.paypal, PAYOUT_METHODS.bank];

export {
    PAYOUT_METHODS,
    getElectronicPayouts,
}