import React, {Component} from 'react'
import {Checkbox, Divider, Loader, Modal, Segment} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {Button, Header} from "../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {formatDate, resolveErrorMessage} from "../../utils/common";
import {setLeftNavigation} from "../../actions/navigationActions";
import {setConsent} from "../../actions/initialDataActions";
import Footer from "../footer";
import PayoutMethods from "../payoutMethods";
import ConsentContent from "../consentContent";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import Mfa from '../../mfa';
import {generateBackupCodes, getMfaSettings} from "../../actions/authActions";
import {ClickableText, CustomGrid, CustomModalHeader} from "../custom-common";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            consented: true,
            showConsentModal: false,
            showConfirmModal: false,
            loadingConsent: false
        }
    }

    async componentDidMount() {
        this.props.dispatch(setLeftNavigation(true))
    }

    handleCheckboxChange = async () => {
        const currentValue = this.props.user.eConsent;
        if (!!currentValue) {
            this.showConfirmModal(true)
        } else {
            this.updateConsent(!currentValue)
        }

    }

    updateConsent = async (value) => {
        try {
            this.setState({loadingConsent: true})
            const payload = {eConsent: value}
            await axios.post("/api/member/v2/updateEConsent", payload, {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(setConsent(value))
            this.setState({loadingConsent: false})
        } catch (e) {
            this.setState({loadingConsent: false, error: resolveErrorMessage(e, 'An unexpected error has occurred')})
        }
    }

    showConsentModal = (value) => {
        this.setState({
            showConsentModal: value
        })
    }

    showConfirmModal = (value) => {
        this.setState({
            showConfirmModal: value
        })
    }

    revoke = () => {
        this.updateConsent(false);
        this.showConfirmModal(false);
    }


    render() {
        const {showConsentModal, showConfirmModal, loadingConsent} = this.state;
        const {userIsCertificateHolder, mfaEnabled} = this.props;
        return (
            <React.Fragment>
                {!!showConsentModal && <Modal onClose={(_) => {
                    this.showConsentModal(false)
                }} open={showConsentModal}>
                    <CustomModalHeader title={"Electronic consent"} onClose={(_) => {this.showConsentModal(false)}} className="customModalHeader" />
                    <Modal.Content>
                        <ConsentContent />
                    </Modal.Content>
                </Modal>}
                {!!showConfirmModal && <Modal size={'tiny'} onClose={(_) => {
                    this.showConfirmModal(false)
                }} open={showConfirmModal}>
                    <CustomModalHeader title={"Confirm removal of electronic consent"} onClose={(_) => {this.showConfirmModal(false)}} className="customModalHeader" />
                    <Modal.Content>
                        <p>Removing electronic consent means all {STRATEGIC_PARTNER.LABEL} communications will happen via mail. Click
                            “Revoke consent” or Cancel.</p>
                        <Divider hidden/>
                        <Segment basic vertical clearing style={{paddingBottom: 0}}>
                            <Button primary floated={"right"} onClick={this.revoke}>Revoke consent</Button>
                            <Button color={"grey"} basic floated={"right"} onClick={(_) => {
                                this.showConfirmModal(false)
                            }}>Cancel</Button>
                        </Segment>
                    </Modal.Content>
                </Modal>}
                <div className="member-main-container">
                    <CustomGrid container stackable columns={1} centered fitted={'horizontally'}>
                        <CustomGrid.Column>
                            <Divider hidden/><Divider hidden/>
                            <CustomGrid columns={2}>
                                {!!userIsCertificateHolder && <CustomGrid.Column style={{paddingRight: '.5em'}}>
                                    <CustomContainer padded>
                                        <Header as={"h4"}>Claim payment settings</Header>
                                        <PayoutMethods/>
                                    </CustomContainer>
                                </CustomGrid.Column>}
                                <CustomGrid.Column style={{paddingLeft: '.5em'}}>
                                    <CustomContainer padded>
                                        <Header as={"h4"}>Electronic Consent</Header>
                                        {loadingConsent ?
                                            <div><Divider hidden/><Divider hidden/><Loader active/></div> :
                                            <React.Fragment>
                                                <p>
                                                    <ClickableText onClick={(_) => {this.showConsentModal(true)}} small>
                                                        View Electronic Consent notice
                                                    </ClickableText>
                                                </p>
                                                <span className={"small"}>
                                                    <b>Consent to conduct business electronically</b>
                                                </span>
                                                    {!!this.props.user &&
                                                    <Checkbox style={{float: 'right'}} toggle
                                                              onChange={this.handleCheckboxChange}
                                                              checked={this.props.user.eConsent}/>}
                                            </React.Fragment>}
                                    </CustomContainer>
                                    <Mfa mfaShowSettings={true} mfaEnabled={mfaEnabled} setLeftNavigation={setLeftNavigation} getMfaSettings={getMfaSettings} formatDate={formatDate} generateBackupCodes={generateBackupCodes}/>
                                </CustomGrid.Column>
                            </CustomGrid>
                        </CustomGrid.Column>
                    </CustomGrid>
                    <Footer showTopFooter={false} showCenterData={true}/>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        user: state.initialData.user,
        userIsCertificateHolder: state.initialData.userIsCertificateHolder,
        mfaEnabled: state.auth.mfaEnabled
    }
};

export default connect(mapStateToProps)(withRouter(Settings));
