import React, {Component} from 'react'
import {Container, List} from "semantic-ui-react";
import {CustomContainer} from '../../custom-common';
import {Button, Header} from "../../base";
import {S3Image} from "../../custom-common";
import Footer from "../../footer";

class NewClaimSuccess extends Component {

    render() {
        const {claimNumber} = this.props.location
        const {history} = this.props
        return (
            <>
                <div className="member-main-container">
                    <Container textAlign="center">
                        <CustomContainer padded="very" compact textAlign="center" style={{margin: '4em auto 1em auto', width: '460px'}}>
                            <S3Image centered width={70} name={"check"}/>
                            <Header as="h3">Thanks for filing your claim</Header>
                            <p>Your claim {!!claimNumber ? `(${claimNumber})` : null} will be reviewed by our team within 24 hours.</p>
                            <CustomContainer basic textAlign="left" className={"pageContent"}>
                                <div className="darkOrangeHeader">Next steps:</div>
                                <List ordered relaxed={"very"} className="newClaimSuccessNextSteps">
                                    <List.Item className="small">If your claim is approved, we’ll pay it in less than 72 hours.</List.Item>
                                    <List.Item className="small">Otherwise, our team will be in touch within 24 hours.</List.Item>
                                </List>
                            </CustomContainer>
                            <Button primary onClick={() => history.push("/claims")}>All done</Button>
                        </CustomContainer>
                    </Container>
                </div>
                <Footer showCenterData={true}/>
            </>
        )
    }
}

export default NewClaimSuccess;
