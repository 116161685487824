import { newMessageActionTypes } from "../actions/actionTypes"

const initialState = {}

export const newMessagesReducer = (state = initialState, action) => {
    const { caseId, text, files, fileIndex } = action.payload || {}

    let message

    function cloneFile(file) {
        const clonedFile = new File([file], file.name, {
            type: file.type,
            lastModified: file.lastModified,
        });

        for (const key in file) {
            if (file.hasOwnProperty(key) && !clonedFile.hasOwnProperty(key)) {
                clonedFile[key] = file[key];
            }
        }

        return clonedFile;
    }

    switch (action.type) {
        case newMessageActionTypes.SET_MESSAGE_TEXT:
            message = state[caseId] || {caseId}
            message.text = text

            return {
                ...state,
                [caseId]: message,
            }
        case newMessageActionTypes.SET_FILES:
            message = state[caseId] || { caseId };
            message.files = files.map(cloneFile);

            return {
                ...state,
                [caseId]: message,
            }
        case newMessageActionTypes.APPEND_FILES:
            message = state[caseId] || {caseId}
            message.files = message.files ? [...message.files, ...files.map(cloneFile)] : files;

            return {
                ...state,
                [caseId]: message,
            }
        case newMessageActionTypes.REMOVE_FILE:
            message = state[caseId]

            if (!message?.files?.length) return state

            message.files = message.files.filter((_, index) => fileIndex !== index)

            return {
                ...state,
                [caseId]: message,
            }
        case newMessageActionTypes.CLEANUP_MESSAGE:
            const {[caseId]: ommited, ...rest} = state

            return {
                ...rest,
            }
        default:
            return state
    }
}