import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Grid, Icon} from "semantic-ui-react";
import {Button, Header} from "../../base";
import "./newClaims.css"
import {connect} from "react-redux";
import Footer from "../../footer";
import NewClaimSteps from "./newClaimSteps";
import {getNumberOfUploadsRequired} from "../../../utils/common";
import DOCUMENT_EXAMPLES from "../documentExamples/documentExamplesConstant";
import DocumentExampleCard from "../documentExamples/documentExampleCard";
import {S3Image} from "../../custom-common";
import ClaimStepsLayout from "../claimStepsLayout";

const mapStateToProps = ({newClaim: {claimant, treatmentInfo}}, ownProps) => {
    return {
        ...ownProps,
        claimant,
        treatmentInfo
    }
};

class UploadInstructions extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/claims/new/treatmentInfo");
    }

    onNext = () => {
        this.props.history.push("/claims/new/uploads")
    }

    render() {
        const button = <Button primary content="Next step" onClick={this.onNext}/>;
        const numberOfUploadsRequired = getNumberOfUploadsRequired(this.props.treatmentInfo)
        return (
            <ClaimStepsLayout className="documents-main-container" style={{justifyContent: "center"}}>
                    <NewClaimMenu onBack={this.onBackHandler} />
                    <NewClaimSteps activeStepIdx={1} />
                    <div style={{marginTop: "56px"}}>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column className={"pageContent uploadInstructions"}>
                                <Header as={"h2"}>Now let's prepare your documents</Header>
                                <p className="neutral700Text instructionText">
                                    On the next step, you'll be asked to upload <b>at least {numberOfUploadsRequired} </b>
                                    supporting documents that help us understand the details of this claim.
                                </p>
                                <div className="subHeader">
                                    Here's the type of information we are looking for—
                                </div>
                                <p className="checkItem neutral700Text">
                                    <Icon enabled="true" name='check' size='small'/>Details on the condition and care received
                                </p>
                                <p className="checkItem neutral700Text">
                                    <Icon enabled="true" name='check' size='small'/>When and where the condition was diagnosed and/or
                                    treated
                                </p>
                                <p className="checkItem neutral700Text">
                                    <Icon enabled="true" name='check' size='small'/>The name of the person who was diagnosed
                                </p>
                                <div className="subHeader">
                                    This information can typically be found in a number of places, look for documents like—
                                </div>
                                <p className="description">
                                    <S3Image name="cameraicon" floated='left' verticalAlign='middle'/> Photos or scans of documents work just fine
                                </p>
                                <div className="cardContainer">
                                    {DOCUMENT_EXAMPLES.map((documentExample, idx) => <DocumentExampleCard key={idx} {...documentExample} />)}
                                </div>
                                    <p>
                                        <Icon enabled="true" name='lightbulb outline' size="large" color="black" inverted/>
                                        <b>Pro tip:</b> <i>If your health care provider has a patient login portal, some of these documents can be found there.</i>
                                    </p>
                            </Grid.Column>
                        </Grid>
                    </div>
                    <div style={{marginTop: "100px"}}>
                        <Footer showTopFooter={true}
                                showCenterData={true}
                                button={button}
                        />
                    </div>
            </ClaimStepsLayout>
        )
    }
}

export default connect(mapStateToProps)(withRouter(UploadInstructions));
