import React from "react";

import {Label} from "semantic-ui-react";

const style = {
    marginLeft: '1em',
    borderRadius: 100,
    background: "#EEEEEE",
    fontWeight: 400,
    paddingLeft: 15,
    paddingRight: 15,
};

const DefaultPill = () => (
    <Label style={style}>
        Default
    </Label>
);

export default DefaultPill;
