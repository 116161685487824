import React from "react";

import {
  MFAGrid,
  MFAHeading,
  MFAText,
  MFAForm,
  MFALabel,
  MFATextError,
  MFAInput,
  MFAIcon,
  MFAContainer,
  MFALink
} from "../../../styled";
import {MAX_CODE_LENGTH} from "../../../constants";


const VerifyRenderItem = ({code, validationErrors, isDisabled, loading, codeHandler, validateMfaCode, validateForm, isCodeEmailVerified, validateMfaCodeError }) => {
  return (
    <>
      <MFAForm.Field
        className="verifyWrapper"
      >
        <MFALabel
          className="verifyLabel"
        >
          6-digit authentication code
        </MFALabel>
        <MFAInput
          type="text"
          name="authenticatorCode"
          id="authenticatorCode"
          value={code}
          onChange={(e) => codeHandler(e)}
          maxLength={MAX_CODE_LENGTH}
          error={validationErrors || validateMfaCodeError}
          required
          disabled={isDisabled}
          onBlur={(e) => validateForm(code)}
        />
      </MFAForm.Field>
      {isCodeEmailVerified &&
      (
        <MFAText className="verified">
          <MFAIcon name="checkmark" size="small" className="verifiedIcon" />
          Verified
        </MFAText>
      )}
    </>
  );
};

const AuthenticatorVerify = ({
  codeHandler,
  validationErrors,
  code,
  validateMfaCode,
  validateMfaCodeError,
  loading,
  mfaEnabled,
  changeMethodSettings,
  isMethodChanged,
  validateForm,
  addAnotherMethod,
  isCodeEmailVerified,
  goBackStepOne
}) => {

  return (
    <>
    <MFAContainer className="sectionWrapper" maxWidth={"497px"}>
      <MFAGrid>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAHeading as="h2">Enter authentication code</MFAHeading>
            <MFAText>
              Enter the 6-digit code generated by your authenticator app to verify setup—
            </MFAText>
          </MFAGrid.Column>
        </MFAGrid.Row>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAForm>
              <MFAForm.Group grouped>
                <MFAGrid padded={false}>
                  <MFAGrid.Row className="noVerticalPadding">
                    <MFAGrid.Column>
                      <VerifyRenderItem
                        code={code}
                        validationErrors={validationErrors}
                        isDisabled={changeMethodSettings ? isMethodChanged : (mfaEnabled && !addAnotherMethod)}
                        loading={loading}
                        codeHandler={codeHandler}
                        validateMfaCode={validateMfaCode}
                        validateForm={validateForm}
                        isCodeEmailVerified={isCodeEmailVerified}
                        validateMfaCodeError={validateMfaCodeError}
                      />

                      {validationErrors && (
                        <MFATextError>Enter a 6-digit authentication code</MFATextError>
                      )}
                      {(validateMfaCodeError && !validationErrors) && (
                        <MFATextError>{validateMfaCodeError}</MFATextError>
                      )}

                      {!isCodeEmailVerified && (
                        <MFAText mt={24}>
                          Having trouble?&nbsp;
                          <MFALink marginTop={24} onClick={goBackStepOne}>
                            Try a different method
                          </MFALink>
                        </MFAText>
                      )}

                    </MFAGrid.Column>
                  </MFAGrid.Row>
                </MFAGrid>
              </MFAForm.Group>
            </MFAForm>
          </MFAGrid.Column>
        </MFAGrid.Row>
        </MFAGrid>
      </MFAContainer>
    </>
  );
};

export default AuthenticatorVerify;
