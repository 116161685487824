import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { MessagesContext } from '../../../contexts/messages'

export class MessagesMenuIcon extends Component {
    static contextType = MessagesContext

    renderDot = () => {
        const { fetching, hasUnread } = this.context;
        const style = {fontSize: 13};

        if (fetching) return <Icon loading name="sync alternate" corner="top right" color="grey" style={style}/>

        if (hasUnread) return <Icon name="circle" corner="top right" color="red" style={style} />

        return null
    }

    render() {
        if (!this.context) return null

        return (<>
            <Link to="/messages" style={{color: '#616161'}}>
                <Icon.Group>
                    <Icon name="mail outline" size="large" />
                    {this.renderDot()}
                </Icon.Group>
            </Link>
        </>

        )
    }
}