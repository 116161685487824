import moment from "moment";
import {showCancelAOEModal} from "../actions/anualOpenEnrollmentActions";
import {PHONE_NUMBER_REGEX} from "./constants";

export function formatMoney(value) {
    if (value === null || value === undefined || value === 0) {
        return "--"
    }

    value = value.toFixed(2)
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/\.00$/, "")}`
}

export function strFormatMoney(value) {
    if (value === null || value === undefined || value === 0) {
        return "--"
    }
    return `$${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}

export function formatPlanIncrementList(increments) {
    return increments.map(value => {
        return formatMoney(value)
    }).join(" / ")
}

export const calculatePremiumCost = (increment, rate) => {
    return Math.round(increment * rate / 1000)
}

export const getStepProps = (activeStepIdx) => {
    const stepProps = [
        {
            active: false,
            completed: false,
            disabled: true,
            label: "Sign up",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Employer info",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Eligibility",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Review",
        },
        {
            active: false,
            completed: false,
            disabled: true,
            label: "Sign and submit",
        }
    ];

    for (let i = 0; i < activeStepIdx; i++) {
        stepProps[i].disabled = false;
        stepProps[i].completed = true;
    }
    stepProps[activeStepIdx].active = true;
    stepProps[activeStepIdx].disabled = false;
    return stepProps;
}

export function resolveErrorMessage(e, defaultError) {
    if (e.response?.data?.errorMessage) {
        return e.response.data.errorMessage
    } else if (e.response?.data?.paymentErrorMsg) {
        return e.response.data.paymentErrorMsg
    } else {
        return defaultError
    }
}

export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function resolveTier(value) {
    switch (value) {
        case "employee":
            return "Employee only"
        case "spouse":
            return "Employee+Spouse"
        case "child":
            return "Employee+Child(ren)"
        case "family":
            return "Family"
        default:
            return "--"
    }
}

export function dowloadBase64(fileName, contentType, data) {
    const element = document.createElement("a")
    element.href = `data:${contentType};base64,${data}`;
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element)
}

export function dowloadBlob(fileName, contentType, data) {
    const element = document.createElement("a")
    element.href = window.URL.createObjectURL(new Blob([data], {endings: 'native', type: `${contentType};charset=utf-8`}))
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element)
}

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const isValidEmail = email => {
    if (!email || typeof email !== 'string' || /^\s|\s$/.test(email)) {
        return false;
    }
    return emailPattern.test(email);
};

export const buildFullName = ({firstName, middleName, lastName, suffix, relation}) => {
    let fullName = []
    if (firstName) {
        fullName.push(firstName)
    }
    if (middleName) {
        fullName.push(middleName)
    }
    if (lastName) {
        fullName.push(lastName)
    }
    if (suffix) {
        fullName.push(suffix)
    }
    if (relation) {
        fullName.push(`(${relation})`)
    }

    return fullName.join(" ")
}

export function formatDate(date, defaultValue = "") {
    if (!date) {
        return defaultValue
    }
    return moment.utc(date).format("MM/DD/YYYY")
}

export const errorStyle = {color: '#D82000'};

export const validateFloat = (value, acceptPartial) => {
    if (/^(\d*|\d+.\d{1,2})$/.test(value) === false) {
        // validate partial / intermediate
        return acceptPartial ? /^\d+\.$/.test(value) : false;
    }

    return true;
}

export function getPayrollFrecuencyLabel(frecuency, short) {
    switch (frecuency) {
        case "Monthly":
            return short ? "mo" : "month"
        case "Quarterly":
            return "quarter"
        default:
            return "paycheck"
    }
}

export function cancelAOEFlow(props) {
    return (
        props.dispatch(showCancelAOEModal(true))
    )
}

export function onBackHandler(props) {
    return props.history.push(props.prevRoute);
}

export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export function resolveTierShort(value) {
    switch (value) {
        case "employee":
            return "EE"
        case "spouse":
            return "EE+SP"
        case "child":
            return "EE+CH"
        case "family":
            return "EE+FAM"
        default:
            return "-"
    }
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const isValidPhoneNumber = value => {
    if (!value) {
        return false;
    }
    return PHONE_NUMBER_REGEX.test(value.replace(/ /g, ""))
};
