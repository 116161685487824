import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Loader, Menu, Message} from "semantic-ui-react";
import {connect} from "react-redux";
import Overview from "./overview";
import PaymentSettings from "./paymentSettings";
import BillingAlert from "./billingAlert";
import Footer from "../footer";
import FailedPaymentAlert from "./failedPaymentAlert";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        lastPaymentFailed: state.billing.lastPaymentFailed
    }
};

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: "overview",
            loading: false,
            error: null,
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.activeTab) {
            if (this.props.location.state.activeTab === 'billingSettings') {
                this.switchToBillingSettings()
            } else {
                this.switchToOverview()
            }
        }
    }

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
    }

    switchToBillingSettings = () => {
        this.setState({activeItem: "billingSettings"})
    }

    switchToOverview = () => {
        this.setState({activeItem: "overview"})
    }

    render() {
        const {activeItem, loading, error} = this.state;
        const {lastPaymentFailed} = this.props;
        const marginTop = lastPaymentFailed ? 38 : -16;
        return (
            <div className="member-main-container">
                <div className={"alertContainer"}>
                    {lastPaymentFailed && <FailedPaymentAlert switchToSettings={this.switchToBillingSettings}/>}
                    <BillingAlert switchToOverview={this.switchToOverview}/>
                </div>
                <Divider hidden/>
                {loading ? <div style={{height: 'calc(100vh - 250px)'}}><Loader active/></div> :
                    <Grid container stackable columns={1} style={{marginTop: `${marginTop}px`}}>
                        <Grid.Column className={"pageContent"}>
                            <Divider hidden/>
                            {!!error ? <Message error>{error}</Message> :
                                <React.Fragment>
                                    <Menu text size={"small"} className={"memberSectionMenu"}>
                                        <Menu.Item
                                            style={{fontWeight: 'bold'}}
                                            name='overview'
                                            active={activeItem === 'overview'}
                                            onClick={this.handleItemClick}
                                        />
                                        <Menu.Item
                                            style={{fontWeight: 'bold'}}
                                            name='billingSettings'
                                            active={activeItem === 'billingSettings'}
                                            onClick={this.handleItemClick}
                                        >Billing settings</Menu.Item>
                                    </Menu>

                                    {lastPaymentFailed && <React.Fragment>
                                    <Divider hidden/>
                                    <Message error><b>Issue with payment.</b> Please retry or use a different payment method.</Message>
                                    </React.Fragment>}

                                    {activeItem === 'overview' &&
                                    <Overview switchToBillingSettings={this.switchToBillingSettings}/>}
                                    {activeItem === 'billingSettings' &&
                                    <PaymentSettings />}
                                </React.Fragment>
                            }

                        </Grid.Column>
                    </Grid>}
                <Footer showTopFooter={false} showCenterData={true}/>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Billing));