import React, {useEffect, useState} from 'react'
import moment from "moment"
import {Checkbox, Form} from "semantic-ui-react"

const FLORIDA_ACKNOWLEDGEMENT_AGE_THRESHOLD = 30;

const ChildElegibilityAgreement = ({strings, isEditting, birthDate, childMaxEligibilityAge, curDependent, isChild, setUserAgrees, employer}) => {

    const {
        displayChildEligibilityAgreement
    } = curDependent

    const [agrees, setAgrees] = useState(false)
    const [agreesFL, setAgreesFL] = useState(false)

    useEffect(() => {
        const newValue = curDependent
        if (agrees && isEmployerStateFL()) {
            newValue.userAgrees = agrees
        } else {
            newValue.userAgrees = agrees || agreesFL
        }
        setUserAgrees(newValue)
    }, [agrees, agreesFL])

    const isEmployerStateFL = () => employer?.address?.state === "FL";

    const showFloridaAcknowledgement = () => isEmployerStateFL() && moment().diff(birthDate, 'years') < FLORIDA_ACKNOWLEDGEMENT_AGE_THRESHOLD

    const renderHeader = () => {
        return (
            <b style={{lineHeight: "21px"}}>
                {showFloridaAcknowledgement() ? strings.inOrderToDetermin : strings.inOrderForChildToQualify}
            </b>
        )
    }

    const getCheckboxLabel = () => {
        return showFloridaAcknowledgement() ? strings.iAckTheCriteriaAppliesToMyChild : strings.iAgree
    }

    const renderFooter = () => (showFloridaAcknowledgement() ? <p>{strings.pleaseNoteAboveCriteria}</p> : null)

    return (
        <>
            {(!!displayChildEligibilityAgreement || (isEditting && moment().diff(birthDate, 'years') >= childMaxEligibilityAge)) && !!isChild &&
                <Form.Field>
                    <div className="childEligibilityAgreementBox">
                        {renderHeader()}
                        {showFloridaAcknowledgement() &&
                            <>
                                <ul>
                                    <li>{strings.cIsUnmarried}</li>
                                    <li>{strings.cIsFLResident}</li>
                                </ul>
                                <Checkbox
                                    label={getCheckboxLabel()}
                                    checked={agreesFL}
                                    onClick={() => {
                                        setAgreesFL(!agreesFL)
                                    }}
                                    className="childEligibilityAgreementCheckbox"
                                />
                            </>
                        }
                        <ul>
                            <li>{strings.cIsIncapable}</li>
                            <li>{strings.cIsChiefly}</li>
                            <li>{strings.cProofIs}</li>
                        </ul>
                        <Checkbox
                            label={getCheckboxLabel()}
                            checked={agrees}
                            onClick={() => {
                                setAgrees(!agrees)
                            }}
                            className="childEligibilityAgreementCheckbox"
                        />
                        {renderFooter()}
                    </div>
                </Form.Field>
            }
        </>
    )
}

export default ChildElegibilityAgreement

