import React, {Component} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import BorderedSegment from "../borderedSegment";
import {creditCard} from "../../utils/common";
import RemovePaymentMethodModal from "./removePaymentMethodModal";
import ChangeDefaultPaymentMethodModal from "./changeDefaultPaymentMethodModal";
import {hoursSinceDate} from "../../utils/date";
import {CustomContainer} from '../custom-common';

class PaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRemovePMModal: false,
            showChangeDefaultModal: false,
        }
    }

    setDefault = () => {
        const {paymentMethod, autoPay} = this.props;
        const {stripeId} = paymentMethod;
        if (autoPay) {
            this.setState({
                showChangeDefaultModal: true
            })
        } else {
            this.props.setDefault(stripeId)
        }
    }

    renderContent = () => {
        const {paymentMethod, autoPay, editCreditCard} = this.props;
        const {type, isDefault, last4, brand, lastPaymentWithError} = paymentMethod;
        const {metadata} = paymentMethod;
        const errorMessage = autoPay ? <><b>Issue with payment method.</b></> : <><b>Payment failed.</b> Try again or use a different payment method.</>
        const showVerificationStatus = () => {
            if (metadata?.verificationStatus && metadata.verificationStatus === "manually_verified") {
                return hoursSinceDate(metadata.verifiedAt) <= 72
            }
            return false
        }

        return <Grid columns={2}>
            <Grid.Column verticalAlign={'top'} width={10} stretched={false} className={"paymentMethod"}>
                <div style={{float: 'left', marginRight: '10px'}} className="neutral600Text">
                    <Icon name={type === creditCard ? 'credit card outline' : 'university'} style={{fontSize: 20}}/>
                </div>
                <div style={{display:"flex", alignItems:"left"}}>
                    <span className="neutral600Text" style={{marginRight:"4px"}}><b>{type === creditCard ? 'Credit card' : brand}</b></span>
                    { isDefault &&
                        <Label horizontal className={"label-rounded"}><p>Default</p></Label>
                    }
                    {lastPaymentWithError &&
                        <><Icon color={"red"} style={{marginLeft: '2em'}} name='exclamation circle'/><span className={"smaller"}>{errorMessage}</span></>
                    }
                    { showVerificationStatus() &&
                        <p className={"vibranBlueText"} style={{fontSize: "14px!important", marginLeft:"4px"}}>
                            <Icon name="checkmark"/> <span style={{fontWeight: "bold"}}>Verified</span>
                        </p>
                    }
                </div>
                <div style={{display: 'block', width:'100%', float:'left', marginLeft:'40px'}}>
                    { type === creditCard ?
                        <span className="smaller">Ending with {last4}</span> :
                        <span className="smaller">{'******'}{last4}</span>
                    }
                </div>
            </Grid.Column>
            <Grid.Column textAlign={"right"} width={2}>
                <Dropdown icon={"ellipsis horizontal"}>
                    <Dropdown.Menu>
                        {!isDefault &&
                          <Dropdown.Item text='Set as Default' onClick={this.setDefault}/>
                        }
                        {type === creditCard && <Dropdown.Item text={'Edit'} onClick={editCreditCard}/>}
                        <Dropdown.Item text='Delete' onClick={()=>{this.setState({showRemovePMModal: true})}}/>
                    </Dropdown.Menu>
                </Dropdown>
            </Grid.Column>
        </Grid>
    }

    render() {
        const {paymentMethod, remove, autoPay, pmListLength} = this.props;
        const {showRemovePMModal, showChangeDefaultModal} = this.state;
        return <>
            {!!showRemovePMModal &&
            <RemovePaymentMethodModal show={showRemovePMModal}
                                      close={()=>{this.setState({showRemovePMModal: false})}}
                                      remove={remove}
                                      paymentMethod={paymentMethod}
                                      autoPay={autoPay}
                                      pmListLength={pmListLength}
            />
            }
            {!!showChangeDefaultModal &&
            <ChangeDefaultPaymentMethodModal show={showChangeDefaultModal}
                                      close={()=>{this.setState({showChangeDefaultModal: false})}}
                                      setDefault={this.props.setDefault}
                                      paymentMethod={paymentMethod}
            />
            }
        {paymentMethod.lastPaymentWithError ? <BorderedSegment borderColor={"#DF4C32"}>
            {this.renderContent()}
        </BorderedSegment>: paymentMethod.isDefault ? <BorderedSegment borderColor={"#43806C"}>
                {this.renderContent()}
            </BorderedSegment>
        : <CustomContainer>
                <div style={{paddingLeft: '8px'}}>{this.renderContent()}</div>
          </CustomContainer>
        }
        </>
    }
}

export default withRouter(connect()(PaymentMethod));
