
export const processMessages = rawData => {
  const data = [...rawData].sort((a, b) => (a.submittedDate > b.submittedDate) ? 1 : -1)
  const threadsByCaseId = {}
  const lastUpdatedCases = {}

  data.forEach(({
    id,
    caseId,
    isConcierge,
    submittedDate,
    status,
    message,
    subject,
    attachments
  }) => {
    const parsedMessage = {
      id: id || 'root',
      caseId,
      isConcierge,
      submittedDate,
      message,
      isUnread: status === 'Unread',
      attachments,
    }

    if (!threadsByCaseId[caseId]) {
      threadsByCaseId[caseId] = {
        caseId,
        subject,
        messages: [parsedMessage]
      }
    } else {
      threadsByCaseId[caseId].messages.push(parsedMessage)
    }
    lastUpdatedCases[caseId] = submittedDate
  })

  const mapping = Object.entries(lastUpdatedCases).sort(([,a],[,b]) => a < b ? 1 : -1)
  const threads = mapping.map(([caseId]) => threadsByCaseId[caseId])

  return threads
}
