import React, {Component} from "react"
import axios from "axios";
import {Checkbox, Divider, Form, FormGroup, Grid, Message, Modal, Icon} from "semantic-ui-react";
import {Button, Header} from "../base";
import {withRouter} from "react-router-dom";
import {getLocalization, interpolateString} from "../../utils/localization";
import {connect} from "react-redux";
import {
    AOE_CONFIRM_PLAN_CHANGES_ROUTE,
    convertServerDateFormatToDisplay,
    DOMESTIC_PARTNER,
    getTranslatedGenderText,
    getTranslatedRelationshipText,
    handleSaveUserProgress,
    SPOUSE,
    getTranslatedStudentStatusText
} from "../../utils/common";
import {removeAOEDependent, removeDependent, setAOEDependent, setAOEFamilyChanged, setDependent} from "../../actions/anualOpenEnrollmentActions";
import Footer from "../footer";
import moment from "moment";
import FormSelectState from "../formSelectState";
import ErrorInput from "../errorInput"
import {cancelAOEFlow} from "../../utils/utils";
import AOESteps from "./aOESteps";
import {setPrevRoute} from "../../actions/navigationActions";
import CancelLink from "./cancelLink";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {ClickableText, CustomContainer, CustomModalHeader, DOBInput, SSNInput} from "../custom-common";
import ChildElegibilityAgreement from "./childElegibilityAgreement";

const DEFAULT_COUNTRY = "USA";

const mapStateToProps = (state, ownProps) => {
    const {user, initialData, anualOpenEnrollment} = state;
    const {generalInitialData, aOEDependents, dependents, keepCoverage, city, zipCode, selectedState, streetAddress} = anualOpenEnrollment;
    const {phoneTypeOptions, nameSuffixOptions, stateOptions, genderOptions, contract, eligibility, studentStatusOptions} = generalInitialData;
    const includesDomesticPartnerships = !!eligibility.domesticPartnership;
    const relationshipOptions = generalInitialData.relationshipOptions.filter((opt) => {
        if (!includesDomesticPartnerships) {
            return opt.value !== DOMESTIC_PARTNER
        } else {
            return true;
        }
    })
    const userAddress = {
        street: streetAddress,
        zipCode,
        city,
        state: selectedState,
        country: DEFAULT_COUNTRY
    };
    return {
        ...ownProps,
        user,
        initialData,
        childMaxEligibilityAge: contract.childMaxEligibilityAge,
        lang: user.lang,
        stateOptions,
        genderOptions,
        relationshipOptions,
        nameSuffixOptions,
        phoneTypeOptions,
        dependents,
        aOEDependents: aOEDependents.map(dependent => {
            const keys = Object.keys(dependent.address);
            let addressSameAsEmployee = dependent.addressSameAsEmployee || keys.every(key => {
                if (key === 'state') {
                    return dependent.address[key].toLowerCase() === userAddress.state?.toLowerCase();
                } else if (key === 'zipCode' || key === 'city' || key === 'street') {
                    return dependent.address[key] === userAddress[key]
                } else {
                    return true
                }
            });
            return {
                ...dependent,
                addressSameAsEmployee
            }
        }),
        includesDomesticPartnerships,
        anualOpenEnrollment,
        keepCoverage,
        studentStatusOptions,
    }
};

class ReviewFamily extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitPlanLoading: false,
            openModal: false,
            modalEditMode: false,
            editDependentObjIdx: null,
            openDeleteDependentModal: false,
            validationErrorsMap: new Map(),
            invalidZipCode: false,
            invalidDepZipCode: false,
            curDependent: {
                showSpouseWarning: false,
                displayChildEligibilityAgreement: false,
                selectedSpouseButNotAvailable: false,
                selectedRelationship: '',
                firstName: '',
                middleName: '',
                suffix: '',
                lastName: '',
                birthDate: '',
                gender: '',
                addressSameAsEmployee: !this.isAddressComplete(),
                ssn: '',
                ssnError: null,
                relToEmployee: '',
                streetAddress: '',
                appartment: '',
                zipCode: '',
                city: '',
                state: '',
                userAgrees: false,
                dobError: null,
                studentStatus: '',
            },
            showSubmitErrorMessage: false,
            showDependentsSubmitErrorMessage: false,
            editAOEDependent: false,
            curDependentCopy: null,
            dependentUpsertError: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    openModal = (editDependentObj, editDependentObjIdx, isAOE) => {
        let curDependent = {
            firstName: '',
            middleName: '',
            suffix: '',
            lastName: '',
            relToEmployee: '',
            gender: '',
            ssn: '',
            ssnError: null,
            birthDate: '',
            addressSameAsEmployee: !this.isAddressComplete(),
            streetAddress: '',
            appartment: '',
            zipCode: '',
            city: '',
            state: '',
            userAgrees: false,
            showSpouseWarning: false,
            displayChildEligibilityAgreement: false,
            selectedSpouseButNotAvailable: false,
            selectedRelationship: '',
            dobError: null,
        }
        if (!!editDependentObj) {
            const {address, ...rest} = editDependentObj;
            curDependent = {...rest, ...address};
        }
        this.setState({
            modalEditMode: !!editDependentObj,
            editDependentObjIdx: editDependentObjIdx,
            openModal: true,
            curDependent: curDependent,
            showDependentsSubmitErrorMessage: false,
            editAOEDependent: !!isAOE,
            curDependentCopy: curDependent,
            dependentUpsertError: ''
        })
    }

    closeModal = () => {
        this.setState({
            openModal: false,
            editDependentObj: {},
            editDependentObjIdx: null,
            curDependent: {},
        })
    }

    isAddressComplete = () => {
        const {user: {city, zipCode, street, state}} = this.props.initialData;
        return !street || !zipCode || !city || !state;
    };

    renderDependents = (strings) => {
        let {aOEDependents, dependents} = this.props;

        const addDependent = <Button primary basic onClick={() => this.openModal()}>+ {strings.addDependent}</Button>

        return <React.Fragment>
            {(!!dependents && dependents.length > 0) &&
            <React.Fragment><p><b>Current family members</b></p>
                {dependents.map(((dependent, index) => {
                    const mNow = moment(Date.now());
                    const mDate = moment(dependent.birthDate)
                    const age = mNow.diff(mDate, "years")
                    return <CustomContainer key={index}>
                        <Grid verticalAlign={'middle'}>
                            <Grid.Column width={8}>
                                <b>{dependent.firstName} {dependent.middleName ? dependent.middleName + ' ' : null}{dependent.lastName}</b><br/>
                                <span className={'small neutral700Text'}>{dependent.relToEmployee}, Age {age}</span>
                            </Grid.Column>
                        </Grid>
                    </CustomContainer>
                }))}
                <Divider hidden/>
            </React.Fragment>}
            {(!!aOEDependents && aOEDependents.length > 0) && <React.Fragment>
                <p><b>Added family members</b></p>
                {aOEDependents.map(((dependent, index) => {
                    const mNow = moment(Date.now());
                    const mDate = moment(dependent.birthDate)
                    const age = mNow.diff(mDate, "years")
                    return <CustomContainer key={index}>
                        <Grid verticalAlign={'middle'}>
                            <Grid.Column width={8}>
                                <b>{dependent.firstName} {dependent.middleName ? dependent.middleName + ' ' : null}{dependent.lastName}</b><br/>
                                <span className={'small neutral700Text'}>{dependent.relToEmployee}, Age {age}</span>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign={'right'}>
                                <ClickableText onClick={() => this.openModal(dependent, index, true)} small>Edit</ClickableText>
                            </Grid.Column>
                        </Grid>
                    </CustomContainer>
                }))}
            </React.Fragment>}
            <Divider hidden/>
            {addDependent}
        </React.Fragment>
    }

    handleDependentFormChange = (event, {value, name}) => {
        if (name === "zipCode") {
            const valNum = parseInt(value, 10);
            if (isNaN(valNum) && value !== "") {
                return;
            }
        }
        if (name === "birthDate" && value) {
            const {childMaxEligibilityAge} = this.props;
            const displayChildEligibilityAgreement = moment(value, 'MM/DD/YYYY', true).isValid() && moment().diff(value, 'years') >= childMaxEligibilityAge;
            this.setState(
                {
                    curDependent: {
                        ...this.state.curDependent,
                        displayChildEligibilityAgreement,
                        dobError: null,
                        [name]: value
                    }
                })
        } else {
            this.setState(
                {
                    curDependent: {
                        ...this.state.curDependent,
                        [name]: value
                    }
                })
        }
    }

    displayDeleteDependentModal = () => {
        this.setState({
            openModal: false,
            openDeleteDependentModal: true,
        })
    }

    closeDeleteDependentModal = (cancelAction) => {
        const {modalEditMode, editDependentObjIdx, editAOEDependent} = this.state;
        if (!cancelAction) {
            if (modalEditMode) {
                if (editAOEDependent) {
                    this.props.dispatch(removeAOEDependent(editDependentObjIdx));
                } else {
                    this.props.dispatch(removeDependent(editDependentObjIdx));
                }
            }
            this.closeModal();
            this.props.dispatch(setAOEFamilyChanged())
            this.setState({
                openDeleteDependentModal: false,
            });
        } else {
            this.setState({
                openDeleteDependentModal: false,
                openModal: true,
            })
        }
    }

    renderDeleteDependentModal = () => {
        const {curDependent, openDeleteDependentModal, loadingSpinner} = this.state;
        const localization = getLocalization(this.props.lang);
        const strings = localization.reviewFamily;
        const firstName = !!curDependent.firstName ? curDependent.firstName : "Unnamed";
        return <Modal size={'tiny'} style={{maxWidth: '440px'}} onClose={() => this.closeDeleteDependentModal(true)} open={openDeleteDependentModal}>
            {loadingSpinner ?
                <div style={{textAlign: "center", width: '440px', height: '193px'}}>
                    <Icon style={{top: '40%', left: '40%', position: 'absolute'}} name="huge spinner loading icon" disabled/>
                </div> :
                <>
                    <CustomModalHeader title={strings.confirmDelete} onClose={() => this.closeDeleteDependentModal(true)} className="customModalHeader"/>
                    <Modal.Content className={"small"}>
                        {strings.dependent} <b>{firstName}</b> {strings.willBeRemoved}
                        <div style={{paddingTop: '2em'}}>
                            <Button primary style={{backgroundColor: '#dc191d'}} floated={"right"} onClick={() => this.closeDeleteDependentModal(false)}>
                                {strings.delete}
                            </Button>
                            <Button basic floated={"right"} color={"grey"} onClick={() => this.closeDeleteDependentModal(true)}>
                                {strings.cancel}
                            </Button>
                        </div>
                    </Modal.Content>
                    <Divider hidden/>
                </>
            }
        </Modal>
    }

    setChildElegibilityUserAgreement = (newValue) => {
        this.setState({
            curDependent: {...newValue},
        });
    }

    renderDependentForm = () => {
        const {curDependent, editDependentObjIdx, modalEditMode, invalidDepZipCode, loadingSpinner} = this.state;
        const {
            dependents,
            lang,
            stateOptions,
            genderOptions,
            relationshipOptions,
            childMaxEligibilityAge,
            nameSuffixOptions,
            aOEDependents,
            initialData: {employer},
            studentStatusOptions
        } = this.props;
        const {
            relToEmployee, firstName, lastName, birthDate, gender, addressSameAsEmployee, ssn, streetAddress, appartment, city, state, zipCode, userAgrees,
            displayChildEligibilityAgreement, selectedSpouseButNotAvailable, middleName, suffix, studentStatus
        } = curDependent;
        const totalDependents = dependents.concat(aOEDependents);
        const spouseDependent = totalDependents.filter(d => d.relToEmployee === SPOUSE || d.relToEmployee === DOMESTIC_PARTNER);
        const hasSpouseAlready = spouseDependent.length > 0;
        const localization = getLocalization(lang);
        const strings = localization.reviewFamily;
        const error = this.validAddForm();

        const isChild = relToEmployee === "Child";
        const editingSpouse = relToEmployee === SPOUSE || relToEmployee === DOMESTIC_PARTNER;
        let filteredRelationshipOptions = relationshipOptions;
        if (hasSpouseAlready && !editingSpouse) {
            filteredRelationshipOptions = relationshipOptions.filter((r) => r.text !== SPOUSE && r.text !== DOMESTIC_PARTNER);
        }
        const disableFields = selectedSpouseButNotAvailable;
        const isEditting = !!editDependentObjIdx || editDependentObjIdx === 0;

        const handleDOBError = (msg) => {
            if (msg !== curDependent.dobError) {
                this.setState({
                    curDependent: {
                        ...curDependent,
                        dobError: msg,
                    }
                });
            }
        };

        return (
            <>
                {loadingSpinner ?
                    <div style={{textAlign: "center", width: '540px', height: '630px'}}>
                        <Icon style={{top: '40%', left: '45%', position: 'absolute'}} name="huge spinner loading icon" disabled/>
                    </div>
                    :
                    <Form>
                        <Header as="h4">{strings.role}</Header>
                        <Form.Field>
                            <Form.Select value={relToEmployee} fluid width={12}
                                         options={filteredRelationshipOptions.map((o) => {
                                             return {
                                                 key: o.key, value: o.value, text: getTranslatedRelationshipText(o.text, getLocalization(lang))
                                             }
                                         })}
                                         name={"relToEmployee"}
                                         required label={strings.relationship}
                                         onFocus={() => {
                                             this.setState({
                                                 curDependent: {
                                                     ...this.state.curDependent,
                                                     selectedRelationship: '',
                                                 }
                                             });
                                         }}
                                         onChange={this.handleDependentFormChange}
                                         onBlur={() => {
                                             this.setState({
                                                 curDependent: {
                                                     ...this.state.curDependent,
                                                     selectedRelationship: relToEmployee,
                                                 }
                                             });
                                         }}/>
                        </Form.Field>
                        <Header as="h4">{strings.details}</Header>
                        <Form.Group widths="equal">
                            <ErrorInput fluid required={true}
                                        label={strings.firstName}
                                        name='firstName'
                                        disabled={disableFields}
                                        value={!firstName ? '' : firstName}
                                        onChange={this.handleDependentFormChange}/>

                            <ErrorInput fluid
                                        label={strings.middleName}
                                        name='middleName'
                                        disabled={disableFields}
                                        value={!middleName ? '' : middleName}
                                        onChange={this.handleDependentFormChange}/>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <ErrorInput fluid required={true}
                                        label={strings.lastName}
                                        name='lastName'
                                        disabled={disableFields}
                                        value={!lastName ? '' : lastName}
                                        onChange={this.handleDependentFormChange}/>

                            <Form.Select value={!suffix ? '' : suffix}
                                         fluid
                                         options={nameSuffixOptions} name={"suffix"}
                                         label={strings.suffix}
                                         onChange={this.handleDependentFormChange}/>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Field required>
                                <DOBInput value={!!birthDate ? birthDate.length === 10 ? convertServerDateFormatToDisplay(birthDate) : birthDate : ''} fluid
                                          name={"birthDate"}
                                          label={strings.dob}
                                          setFormError={handleDOBError}
                                          onChange={(event, {value}) => this.handleDependentFormChange(event, {value, name: 'birthDate'})}
                                          required
                                />
                            </Form.Field>

                            <Form.Select fluid required label={strings.gender} disabled={disableFields}
                                         value={gender}
                                         name={"gender"}
                                         options={genderOptions.map((o) => {
                                             return {key: o.key, value: o.value, text: getTranslatedGenderText(o.text, getLocalization(this.props.lang))}
                                         })}
                                         onChange={this.handleDependentFormChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <SSNInput
                                disabled={disableFields}
                                label={<label>{strings.ssn} <span style={{fontWeight: 'normal'}}>({strings.optional})</span></label>}
                                name={"ssn"}
                                onChange={(e, value) => this.handleDependentFormChange(e, {name: "ssn", value})}
                                required={false}
                                setSSNError={(hasError) => {
                                    const dep = curDependent;
                                    dep.ssnError = hasError;
                                    this.setState({
                                        curDependent: {...dep},
                                    })
                                }}
                                value={ssn}
                                width={6}
                            />
                            {isChild && employer.address.state === "FL" &&
                                <Form.Select label={<label>{strings.studentStatus}</label>}
                                             width={6} value={studentStatus} name={"studentStatus"}
                                             disabled={disableFields}
                                             options={studentStatusOptions.map(({value, text, key}) => {
                                                 return {key, value, text: getTranslatedStudentStatusText(text, getLocalization(this.props.lang))}
                                             })}
                                             onChange={this.handleDependentFormChange}
                                />
                            }
                        </Form.Group>
                        <Header as="h4">{strings.address}</Header>
                        <Form.Group>
                            <Form.Field width={6}>
                                <Checkbox label={strings.sameAsEmployee}
                                          name={"addressSameAsEmployee"}
                                          checked={addressSameAsEmployee}
                                          onChange={(e) => {
                                              this.handleDependentFormChange(e, {
                                                  name: "addressSameAsEmployee",
                                                  value: !addressSameAsEmployee
                                              })
                                          }}
                                />
                            </Form.Field>
                        </Form.Group>
                        {!addressSameAsEmployee &&
                            <React.Fragment>
                                <FormGroup widths={'equal'}>
                                    <ErrorInput fluid required={true}
                                                label={strings.streetAddress}
                                                name='streetAddress'
                                                disabled={disableFields}
                                                value={!streetAddress ? '' : streetAddress}
                                                onChange={this.handleDependentFormChange}/>

                                    <Form.Input label={strings.aptSuiteOther} disabled={disableFields}
                                                value={!appartment ? '' : appartment}
                                                name={"appartment"} onChange={this.handleDependentFormChange}/>
                                </FormGroup>
                                <FormGroup widths={'equal'}>
                                    <Form.Field>
                                        <Form.Input label={strings.zipCode} required
                                                    value={!zipCode ? '' : zipCode}
                                                    error={invalidDepZipCode}
                                                    name={"zipCode"}
                                                    onFocus={() => {
                                                        this.setState({
                                                            invalidDepZipCode: false,
                                                        });
                                                    }}
                                                    onChange={this.handleDependentFormChange}
                                                    onBlur={() => {
                                                        const isError = !zipCode || zipCode.length === 0 || ((zipCode.length > 5 && zipCode.length < 9) || zipCode.length < 5 || zipCode.length > 9);
                                                        this.setState({
                                                            invalidDepZipCode: isError || isNaN(zipCode * 1),
                                                        })
                                                    }}/>
                                        {!!invalidDepZipCode && <small style={{color: 'red'}}>{strings.invalidZipCode}</small>}
                                    </Form.Field>

                                    <ErrorInput fluid required={true}
                                                label={strings.city}
                                                name='city'
                                                disabled={disableFields}
                                                value={!city ? '' : city}
                                                onChange={this.handleDependentFormChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <FormSelectState name={"state"}
                                                     initialValue={state}
                                                     options={stateOptions}
                                                     disabled={disableFields}
                                                     onChange={this.handleDependentFormChange}/>
                                </FormGroup>
                            </React.Fragment>
                        }
                        <ChildElegibilityAgreement
                            strings={strings}
                            isEditting={isEditting}
                            childMaxEligibilityAge={childMaxEligibilityAge}
                            birthDate={birthDate}
                            isChild={isChild}
                            curDependent={this.state.curDependent}
                            setUserAgrees={this.setChildElegibilityUserAgreement}
                            employer={employer}
                        />
                        <CustomContainer basic vertical clearing style={{paddingBottom: 0}}>
                            {isEditting &&
                                <Button basic floated={"left"}
                                        style={{boxShadow: '0px 0px 0px 1px transparent inset', paddingLeft: 0}}
                                        onClick={() => {
                                            this.displayDeleteDependentModal(modalEditMode);
                                        }}>
                                    <span className={"warningRedText"}><b>{strings.delete}</b></span>
                                </Button>}

                            <Button primary
                                    disabled={!!error || invalidDepZipCode || !!curDependent.dobError || !!curDependent.ssnError ||
                                        (!!isChild && moment().diff(birthDate, 'years') >= childMaxEligibilityAge && !userAgrees) ||
                                        (!!displayChildEligibilityAgreement && !!isChild && !userAgrees) || !!selectedSpouseButNotAvailable
                                    }
                                    floated={"right"}
                                    onClick={() => {
                                        if (modalEditMode) {
                                            this.editDependent(curDependent, editDependentObjIdx);
                                        } else {
                                            this.submitNewDependent();
                                        }
                                    }}>
                                {strings.save}
                            </Button>
                            <Button color='grey' basic floated={"right"} onClick={this.closeModal}>{strings.cancel}</Button>
                        </CustomContainer>
                        {!!this.state.dependentUpsertError &&
                            <Grid.Column><Divider hidden/>
                                <Message negative>
                                    {this.state.dependentUpsertError}
                                </Message>
                            </Grid.Column>}
                    </Form>
                }
            </>
        )
    }

    validAddForm = () => {
        const {curDependent} = this.state;
        const required = ['firstName', 'lastName', 'birthDate', 'gender', 'relToEmployee'];
        let error = false;

        for (let i = 0; i < required.length; i++) {
            let item = required[i];
            if (!curDependent[item] || curDependent[item].length === 0) {
                error = true;
                break;
            }
        }

        if (!curDependent.addressSameAsEmployee) {
            const requiredIfAddress = ['streetAddress', 'zipCode', 'city', 'state'];
            for (let i = 0; i < requiredIfAddress.length; i++) {
                let item = requiredIfAddress[i];
                if (!curDependent[item] || curDependent[item].length === 0) {
                    error = true;
                    break;
                }
            }
        }

        return error;
    }

    sanitizeDependent = (dependent) => {
        const d = {
            ...dependent,
            birthDate: moment(dependent.birthDate).format('YYYY-MM-DD'),
            id: dependent.id ? dependent.id : null,
            socialSecurityNumber: dependent.ssn,
        }
        delete d['addressSameAsEmployee']
        delete d['isQualifiedForCoverage']
        delete d['dobError']

        return d;
    }

    upsertDependent = async (dependent) => {
        const localization = getLocalization(this.props.lang);
        const strings = localization.reviewFamily;
        const checkFieldsConstraints = dependent.id ? this.checkFieldsConstraints(dependent, this.state.curDependentCopy) : null;
        const duplicateDependent = this.checkDupDependent(dependent);

        if (!!checkFieldsConstraints || duplicateDependent) {
            this.setState({
                loadingSpinner: false,
                dependentUpsertError: checkFieldsConstraints || strings.duplicateDependent
            });
            return;
        }
        try {
            this.setState({
                loadingSpinner: true,
            })
            const payload = {
                token: this.props.initialData.token,
                dependent: this.sanitizeDependent(dependent)
            }
            const {data} = await axios.post("/api/member/v2/upsertDependent", payload);
            this.setState({
                loadingSpinner: false,
            })

            return data;
        } catch (e) {
            this.setState({
                loadingSpinner: false,
                dependentUpsertError: strings.requestError
            })
        }
    }

    getAddress = ({state, city, zipCode, streetAddress, addressSameAsEmployee}) => {
        const {anualOpenEnrollment} = this.props;
        return {
            street: addressSameAsEmployee ? anualOpenEnrollment.streetAddress : streetAddress,
            zipCode: addressSameAsEmployee ? anualOpenEnrollment.zipCode : zipCode,
            city: addressSameAsEmployee ? anualOpenEnrollment.city : city,
            state: addressSameAsEmployee ? anualOpenEnrollment.selectedState : state,
            country: DEFAULT_COUNTRY
        };
    };

    // TODO: The logic between this function and editDependent is very similar. Consider merging both functions to avoid duplication.
    submitNewDependent = async () => {
        const {curDependent} = this.state;
        const {aOEDependents} = this.props;
        let dependentIdx = aOEDependents ? aOEDependents.length : 0;
        const dependent = {
            ...curDependent,
            address: this.getAddress(curDependent),
            isQualifiedForCoverage: curDependent.userAgrees,
        }
        const res = await this.upsertDependent(dependent);
        if (!res) {
            return
        }
        dependent.id = res.rosterFamilyMemberId;
        this.props.dispatch(setAOEDependent(dependent, dependentIdx));
        this.closeModal();
        this.props.dispatch(setAOEFamilyChanged())
    }

    editDependent = async (dependentObj, dependentIdx) => {
        const dependent = {
            ...dependentObj,
            address: this.getAddress(dependentObj),
            isQualifiedForCoverage: !!dependentObj.userAgrees ? dependentObj.userAgrees : false,
        }
        if (this.state.editAOEDependent) {
            const res = await this.upsertDependent(dependent);
            if (!res) {
                return;
            }
            this.props.dispatch(setAOEDependent(dependent, dependentIdx));
            this.props.dispatch(setAOEFamilyChanged())
        } else {
            this.props.dispatch(setDependent(dependent, dependentIdx));
        }
        this.closeModal();
    }

    checkFieldsConstraints(curDependent, curDependentCopy) {
        const localization = getLocalization(this.props.lang);
        const strings = localization.reviewFamily;
        if (curDependentCopy.ssn) {
            if (curDependent.ssn !== curDependentCopy.ssn) {
                return strings.ssnUpdateError;
            } else if (curDependent.firstName !== curDependentCopy.firstName) {
                return strings.firstNameUpdateError;
            } else if (curDependent.lastName !== curDependentCopy.lastName) {
                return strings.lastNameUpdateError;
            } else if (curDependent.birthDate !== curDependentCopy.birthDate) {
                return strings.birthdateUpdateError;
            } else if (curDependent.relToEmployee !== curDependentCopy.relToEmployee) {
                return strings.roleUpdateError;
            }
        }

        return null;
    }

    checkDupDependent = (dependent) => {
        const {aOEDependents, dependents} = this.props;
        return aOEDependents.some(dp =>
                dp.id !== dependent.id &&
                ((dp.ssn && dp.ssn === dependent.ssn) ||
                    (!dp.ssn && dp.firstName === dependent.firstName && dp.lastName === dependent.lastName &&
                        dp.birthDate === dependent.birthDate && dp.relToEmployee === dependent.relToEmployee))
            ) ||
            dependents.some(dp =>
                dp.id !== dependent.id &&
                ((dp.ssn && dp.ssn === dependent.ssn) ||
                    (!dp.ssn && dp.firstName === dependent.firstName && dp.lastName === dependent.lastName &&
                        dp.birthDate === dependent.birthDate && dp.relToEmployee === dependent.relToEmployee))
            );
    }

    next = () => {
        const {invalidDepZipCode, validationErrorsMap} = this.state;
        const hasErrors = invalidDepZipCode || validationErrorsMap.length > 0;
        if (!hasErrors) {
            const {anualOpenEnrollment} = this.props;
            handleSaveUserProgress(this.props.anualOpenEnrollment).then();
            if(anualOpenEnrollment.changedFamilyMembers){
                this.props.history.push(this.props.nextRoute)
            } else {
                if (this.props.keepCoverage) {
                    this.props.history.push(AOE_CONFIRM_PLAN_CHANGES_ROUTE)
                } else {
                    this.props.history.push(this.props.nextRoute)
                }
            }
        } else {
            if (hasErrors) {
                this.setState({
                    showSubmitErrorMessage: true
                })
            }
        }
    }

    render() {
        const {dependents, anualOpenEnrollment} = this.props;
        const {openModal, openDeleteDependentModal} = this.state;

        const localization = getLocalization(this.props.lang);
        const strings = localization.reviewFamily;

        let displayAgeReduction = false;
        const {ageReduction, ageReductionAge, ageReductionPercentage} = anualOpenEnrollment.generalInitialData.ratedClass.contractTerms;
        const {policyEffectiveDate} = anualOpenEnrollment.generalInitialData.contract;
        if(ageReduction){
            const oldDependents = dependents.filter(dependent => moment(policyEffectiveDate).diff(moment(dependent.birthDate), "years") >= ageReductionAge);
            if(oldDependents.length > 0){
                displayAgeReduction = true;
            }
        }

        const button = <Button primary content={localization.next} onClick={this.next} />;
        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;

        return (
            <React.Fragment>
                {!!openModal && <Modal size={'tiny'} onClose={this.closeModal} open={openModal}>
                    <CustomModalHeader title={strings.addDependent} onClose={this.closeModal} className="customModalHeader" />
                    <Modal.Content className={"small"}>
                        {this.renderDependentForm()}
                    </Modal.Content>
                </Modal>}
                {!!openDeleteDependentModal && this.renderDeleteDependentModal()}
                <div className="member-main-container" style={{background: 'white'}}>
                    <AOESteps activeStepIdx={0}/>
                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Grid stackable columns={1}>
                                <Grid.Column textAlign={'left'}>
                                    <Header as='h2'>{strings.title}</Header>
                                    <p>
                                        You’re eligible to make coverage changes including adding additional family members. Review your family details below, and add any
                                        additional members you’d like to add to your {STRATEGIC_PARTNER.LABEL} coverage—
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    {!!displayAgeReduction && <React.Fragment>
                                        <CustomContainer basic className={"bkgLinen small"}>
                                            <b>{strings.important}</b>: {interpolateString(strings.basedOnDependentAge, [ageReductionPercentage])}
                                        </CustomContainer>
                                        <Divider hidden/>
                                    </React.Fragment>}
                                    {this.renderDependents(strings)}
                                </Grid.Column>

                                {this.state.showSubmitErrorMessage &&
                                    <Grid.Column><Divider hidden/><Message negative>
                                        {strings.genericErr}
                                    </Message>
                                    </Grid.Column>}
                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter showCenterData button={button} link={cancel}/>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ReviewFamily));
