import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Icon, Menu} from 'semantic-ui-react'
import UserMenu from './userMenu'
import {isMobile} from "../utils/utils";
import {BrandedLogo} from "./base";
import { MFA_ROUTE } from "../utils/common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        loginInfo: state.auth.loginInfo,
        user: state.auth.user,
        mfaNotEnabledGracePeriodEnded: state.auth.mfaNotEnabledGracePeriodEnded
    }
};
class FullScreenPageMenu extends Component {
    render() {
        const {onBack, center, user, useMobileStyles, showArrow, hideSettings} = this.props;
        const centerStyles = (useMobileStyles && isMobile())
            ? { width: '75%', textAlign: 'center', margin:'auto'}
            : { width: '34%', textAlign: 'center', lineHeight: '44px', fontWeight: 'bold'};

        const mfaFlow = this.props.location.pathname === MFA_ROUTE;
        const showLogo = mfaFlow && !showArrow;

        return (
            <React.Fragment>
                <Menu fixed='top' secondary pointing className="member-menuContainer">
                    <div style={{ width: '33%'}}>
                        {showLogo && <BrandedLogo className={"member-headerLogo"}/>}
                        {onBack && (
	                        ((showArrow && mfaFlow) || !mfaFlow) && (
                            <Icon
                                link
                                name="left arrow"
                                onClick={onBack}
                                style={{ padding: '10px 18px' }}
                            />
                        ))}
                    </div>
                    {center && (
                        <div style={centerStyles}>
                            {center}
                        </div>
                    )}
                    <UserMenu user={user} hideSettings={hideSettings} mfaFlow={mfaFlow}/>
                </Menu>
                <div className={"member-header-shadow"}/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(FullScreenPageMenu));
