const key = "member-fsl";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(key)
        return JSON.parse(serializedState) || undefined
    } catch (e) {
        console.warn(e)
        return undefined
    }
}

export const persistState = async (state) => {
    try {
        const { mobile, ...persistedState } = state
        localStorage.setItem(key, JSON.stringify(persistedState))
    } catch (e) {
        console.warn(e)
    }
}

export const purgeState = () => {
    localStorage.removeItem(key)
}