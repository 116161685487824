import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isMobile} from '../../utils/utils'
import MobileAppDialog from './mobileAppDialog'

const mapStateToProps = (state, ownProps) => {
    const {useWeb} = state.mobile

    return {
        useWeb,
        ...ownProps
    }
}

class MobileDetector extends Component {
    status = {isMobile: isMobile()}

    render() {
        const {children, useWeb} = this.props
        const {isMobile} = this.status
        const allowedPaths = ["/register", "/member/register", "/verifyEmail", "/verify", "/member/verifyEmail",
            "/member/verify", "/member/login", "/login", "/member/resetPassword/", "/member/forgotPassword"]
        if (allowedPaths.includes(window.location.pathname)) {
            return children
        }

        if (isMobile && !useWeb) return <MobileAppDialog />

        return children
    }
}

export default connect(mapStateToProps)(MobileDetector)
