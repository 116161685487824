import React from 'react';
import {Button, Divider, Form, Message, Segment} from "semantic-ui-react";
import {isValidEmail} from "../../utils/utils";
import {CustomEmailInput} from '../custom-common';

const RenderPaypalConfigForm = ({ username = '', confirmUsername = '', loading, submitError, setError, getError, handleChange, configurePaypal, closeModal }) => {

    const onFocus = (e) => {
        const { name } = e.target;
        setError(name, '');
    }

    const onChange = (e) => {
        const { name, value: newValue = "" } = e.target;
        const value = newValue.trim();
        setError(name, '');

        handleChange(e, { name, value });
    };

    const validateFields = (e) => {
        const { name, value: newValue = "" } = e.target;
        const value = newValue.trim();

        let error = null;
        if (isValidEmail(username)) {
            if (username !== value) {
                error = "Your emails do not match. Please try again.";
                setError("confirmUsername", error);
            }
        } else {
            !isValidEmail(value)
            ? setError(name, "Invalid email format")
            : setError(name, error);
        }


    };

    const disabled = !username || !confirmUsername || !!getError('username') || !!getError('confirmUsername') || loading || username !== confirmUsername;

    return (
        <>
            <Form>
                <Form.Group>
                    <CustomEmailInput
                        width={12}
                        label={"Email"}
                        value={username}
                        name={"username"}
                        onChange={onChange}
                        onBlur={(e) => {validateFields(e)}}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <CustomEmailInput
                        width={12}
                        label={"Confirm Email"}
                        value={confirmUsername}
                        name={"confirmUsername"}
                        onChange={onChange}
                        onBlur={(e) => {validateFields(e)}}
                        customError={getError("confirmUsername")}
                        onFocus={onFocus}
                        required
                    />
                </Form.Group>
            </Form>
            {!!submitError && <Message negative>{submitError}</Message>}
            <Divider hidden/>
            <Segment basic vertical clearing style={{paddingBottom: 0}}>
                <Button primary floated={"right"} loading={loading} disabled={disabled}
                        onClick={configurePaypal}>Submit</Button>
                <Button color={"grey"} basic floated={"right"}
                        onClick={closeModal}>Cancel</Button>
            </Segment>
        </>
    )
}

export default RenderPaypalConfigForm;