import React from 'react';
import {
    Button,
    Divider,
    Form,
    Message,
    Segment
} from "semantic-ui-react";
import NumberFormat from "react-number-format";
import ErrorInput from "../errorInput";
import {BankAccountInput} from "../custom-common";

const RenderBankConfigForm = ({routingNumber, accountNumber, submitError, loading, validationErrorsMap, setError, getError, handleChange, configureBank, closeModal, validateBankForm, loadingConnectedAccountError}) => {
    const error = validateBankForm();
    const validationError = validationErrorsMap.size === 0;
    const disableButton = !validationError;
    const ROUTING_NUMBER_DIGITS = 9;

    const renderRoutingNumberField = () => <Form.Field width={6}>
        <NumberFormat format="#########" placeholder="XXXXXXXXX"
                      name={"routingNumber"}
                      error={!!getError('routingNumber')}
                      onFocus={() => {
                          if (!!getError('routingNumber')) {
                              setError('routingNumber', null);
                          }
                      }}
                      required={true} fluid
                      label={"Routing number"}
                      customInput={Form.Input}
                      onChange={(e) => {
                          const value = e.target.value;
                          handleChange(e, {
                              name: 'routingNumber',
                              value
                          })
                      }
                      }
                      value={routingNumber || ''}
                      onBlur={(e) => {
                          const value = e.target.value;
                          if (value.trim().length < ROUTING_NUMBER_DIGITS) {
                              setError('routingNumber', 'Routing number must have 9 digits');
                          }
                      }}/>
        {!!getError('routingNumber') && <small
            className={"warningRedText"}>{getError('routingNumber')}</small>}
    </Form.Field>;

    return (
        <>
            <Form>
                <p className={"small"}>Please make sure that the information entered below is associated to the bank account you are adding.</p>
                <Form.Group widths={"equal"}>
                    <ErrorInput required label={"Account holder first name"}
                                name={"firstName"}
                                onChange={handleChange}/>
                    <ErrorInput required label={"Account holder last name"}
                                name={"lastName"}
                                onChange={handleChange}/>
                </Form.Group>
                <Form.Group>
                    {renderRoutingNumberField()}
                    <BankAccountInput
                        label={"Account number"}
                        name={"accountNumber"}
                        onChange={(e, value) => handleChange(e, {value, name: "accountNumber"})}
                        value={accountNumber || ''}
                        width={6}
                    />
                </Form.Group>
            </Form>
            <Divider hidden/>
            {!!submitError && <Message negative>{submitError}</Message>}
            {loadingConnectedAccountError && <Message negative>{loadingConnectedAccountError}</Message>}
            <Segment basic vertical clearing style={{paddingBottom: 0}}>
                <Button primary floated={"right"} loading={loading}
                        disabled={!!error || loading || disableButton} onClick={configureBank}>Submit</Button>
                <Button color={"grey"} basic floated={"right"}
                        onClick={closeModal}>Cancel</Button>
            </Segment>
        </>
    )
}

export default RenderBankConfigForm;
