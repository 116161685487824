import React, {Component} from "react";
import {Form} from "semantic-ui-react";

class ErrorInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localValue: "",
            hasError: null,
        };
    }

    componentDidMount() {
        this.setState({localValue: this.props.value});
    }

    render() {
        const {hasError, localValue} = this.state;
        const {onChange, onFocus, label, name, value, required, width, error, errorMsg, lang, ...otherFormInputProps} = this.props;

        const warning = ((!!hasError && required) || !!error) ? <small className={"warningRedText"}>{errorMsg ? errorMsg : 'Required'}</small> : null;

        return (<Form.Field width={width}>
                <Form.Input name={name}
                            fluid required={required} label={label} error={(hasError && required) || !!error}
                            value={localValue}
                            onChange={(event, {value, name}) => {
                                this.setState({localValue: value});
                                onChange(event, {value, name});
                            }}
                            onFocus={() => {
                                this.setState({hasError: false});
                                if (onFocus) {
                                    onFocus();
                                }
                            }}
                            onBlur={(e) => {
                                const val = e.target.value;
                                if (required && (!val || val.length === 0)) {
                                    this.setState({hasError: true});
                                } else {
                                    this.setState({hasError: false});
                                }
                            }}

                            {...otherFormInputProps}/>
                {warning}
            </Form.Field>

        );
    }
}

export default ErrorInput;
