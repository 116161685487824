import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Menu, Grid, Responsive, Segment} from "semantic-ui-react";
import CancelModal from "./anualOpenEnrollment/cancelModal";
import {currentYear} from "../utils/date";

const mapStateToProps = (state, ownProps) => {
    return {
        authToken: state.auth?.loginInfo?.authToken,
        disclaimers: state.initialData.disclaimers,
        showCancelAOEFlowModal: state.anualOpenEnrollment.showCancelAOEFlowModal,
        ...ownProps
    }
};
class Footer extends Component {
    render() {
        const {button, showTopFooter, link, showCenterData, disclaimers, showCancelAOEFlowModal, largeButton} = this.props;
        const isLoggedin = this.props.authToken || false;
        const footerDisclaimer = disclaimers[disclaimers.length - 1]?.title;

        return (
            <React.Fragment>
                {showCancelAOEFlowModal && <CancelModal/>}
            <div className="footer" style={{left: 0, zIndex: 103}}>
                {showTopFooter &&
                <div className={"buttonContainer"}>
                    <Container>
                        <Grid columns={3} verticalAlign={"middle"}>
                            <Grid.Column width={!!largeButton? 4 : 5}/>
                            <Grid.Column width={ !!largeButton ? 4 : 2}>
                                {button}
                            </Grid.Column>
                            <Grid.Column width={!!largeButton? 4 : 5} style={{paddingLeft: '0px'}} textAlign={"left"}>
                                <div>{!!link && link}</div>
                            </Grid.Column>
                        </Grid>
                    </Container>
                </div>
                }
                <Responsive as={Container} fluid style={{padding: "0 20px"}}
                            minWidth={Responsive.onlyComputer.minWidth}>
                    <Grid columns={3}>
                        <Grid.Column width={9}>
                            <Menu text>
                                <Menu.Item>©{currentYear()} Ansel Health, Inc. All Rights Reserved.</Menu.Item>
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Menu text>
                                <Menu.Menu position="right">
                                    <Menu.Item as="a" href="https://www.joinansel.com/privacy-policy" target="_blank">
                                        Privacy
                                    </Menu.Item>
                                    <Menu.Item as="a" href="https://www.joinansel.com/terms" target="_blank">
                                        Terms
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                        </Grid.Column>
                    </Grid>
                    {showCenterData && isLoggedin && footerDisclaimer && (
                      <>
                        <p className={"smaller neutral700Text"} style={{
                          marginTop: "-15px",
                          marginBottom: "5px",
                          marginLeft: "6px",
                          textAlign: "left",
                          whiteSpace: "pre-line",
                          lineHeight: "15px",
                        }}>{footerDisclaimer}</p>
                      </>
                    )}
                </Responsive>
                <Responsive as={Segment} basic maxWidth={Responsive.onlyComputer.minWidth - 1}>
                        {showCenterData && isLoggedin && footerDisclaimer && <>
                            <p className={"smaller neutral700Text"}>
                                {footerDisclaimer}
                            </p>
                        </>}
                        <p className={"smaller neutral700Text"}>©{currentYear()} Ansel Health, Inc. All Rights Reserved.</p>
                    <p>
                        <a style={{paddingRight: '1em'}} className={"smaller neutral500Text"} href="https://www.joinansel.com/privacy-policy" rel="noopener noreferrer" target="_blank">
                            <b>Privacy</b>
                        </a>
                        <a className={"smaller neutral500Text"}  href="https://www.joinansel.com/terms" target="_blank" rel="noopener noreferrer">
                            <b>Terms</b>
                        </a>
                    </p>
                </Responsive>
            </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(Footer);
