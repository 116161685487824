import React from 'react'
import {Divider} from "semantic-ui-react";


const ConsentContent = () => (<>
        <p style={{textAlign: "left"}}>
            <h3><b>ReliaStar Life Insurance Company</b></h3>
                Home Office: Minneapolis, MN
                <br/>Administrative Office: [PO Box 122, Minneapolis, MN 55440-0122]
        </p>
        <Divider hidden/>
        <Divider hidden/>
        <p><b>CONSUMER CONSENT TO THE ELECTRONIC DELIVERY OF RECORDS & DOCUMENTS</b><br/>
            ("Agreement")
        </p>
        <Divider hidden/>
        <p>
            By using the Services and agreeing to these Terms, you are signing this Terms of Service agreement
            electronically. You agree your electronic signature is the legal equivalent of your manual signature on
            this Terms of Service agreement, and authorizes electronic delivery of Correspondence:

        </p>
        <Divider hidden/>
        <p>
            You acknowledge and agree to the electronic delivery of communications, including notices, disclosures,
            and/or other documents (collectively, “Correspondence”) that we, ReliaStar Life Insurance Company
            (“ReliaStar”), are required by law to provide to you. Enrolling in electronic delivery is strictly
            voluntary. To receive Correspondence electronically, you understand that you need, and agree that you have:
            (1) internet access; (2) an e-mail address where you can receive e-mail from ReliaStar; (3) the ability to
            download and view communications in a Portable Document Format (PDF), using software such as Adobe Acrobat
            Reader; and (4) access to a printer, if you wish to print any Correspondence. At any time, you have the
            right to receive Correspondence by paper delivery at no charge. A change in your communication preferences
            will take effect within four (4) business days after receipt of the change request and will not apply to any
            Correspondence provided prior to the effective date of the change request.
        </p>
        <Divider hidden/>
        <p>
            You understand that for regulatory, technical or any other reason, you may be provided Correspondence via
            paper delivery instead of electronic delivery. You may opt out of electronic delivery and request paper
            delivery or update your e-mail address by contacting the ReliaStar Customer Service Department toll free
            at [888-290-1153].
        </p>
    </>
);

export default ConsentContent;
