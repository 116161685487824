import {anualOpenEnrollmentActionTypes} from "../actions/actionTypes";
import {DOMESTIC_PARTNER, SPOUSE} from "../utils/common";

const initialState = {
    generalInitialData: {
        stateOptions: [],
        genderOptions: [],
        relationshipOptions: [],
        nameSuffixOptions: [],
        phoneTypeOptions: [],
        highDeductibleOptions: [],
        coPayOptions: [],
        disposableIncomeOptions: [],
        coverageOptions: [],
        payrollFrequencySelected: null,
        payrollFrequencyMonthly: null,
        employerContributions: [],
        quoteInfo: null,
        presets: null,
        ratedClass: null,
        limitations: null,
        increments: null,
        frauds: null,
        eligibility: null,
        contract: null,
        employer: null,
        planComparison: {
            upcomingPlan: {
                cost: null,
                employerContribution: null,
                coveredFamilyMembers: [],
                benefitAmounts: {
                    moderate: null,
                    severe: null,
                    catastrophic: null,
                    chronic: null,
                    mental: null,
                },
                payrollFrequency: null,
            },
            currentPlan: {
                cost: null,
                employerContribution: null,
                coveredFamilyMembers: [],
                benefitAmounts: {
                    moderate: null,
                    severe: null,
                    catastrophic: null,
                    chronic: null,
                    mental: null,
                },
                payrollFrequency: null,
            }
        },
        upcomingPolicyEffectiveDate: null,
        currentSelectPlan: null,
        studentStatusOptions: [],
    },
    aOEDependents: [],
    dependents: [],
    firstName: null,
    lastName: null,
    middleName: null,
    gender: null,
    suffix: null,
    daytimePhoneNumber: null,
    phoneType: null,
    selectedState: null,
    city: null,
    zipCode: null,
    streetAddress: null,
    appartment: null,
    birthDate: null,
    ssn: null,
    email: null,
    typicallyPayInfo: null,
    highDeductibleInfo: null,
    fetchPlanRecommendation: true,
    coverageOption: null,
    costPerOption: null,
    selectedTierBrellaSelect: {
        value: false,
        premier: false,
        enhanced: false,
    },
    moderateCoverageSelected: null,
    severeCoverageSelected: null,
    catastrophicCoverageSelected: null,
    moderateCoverageRecommended: null,
    severeCoverageRecommended: null,
    catastrophicCoverageRecommended: null,
    costValue: null,
    costEnhanced: null,
    costPremier: null,
    contribValue: null,
    contribEnhanced: null,
    contribPremier: null,
    leftoverMoney: null,
    lastVisitedSavePoint: {
        route: null,
        step: null,
    },
    employerContribution: null,
    keepCoverage: null,
    showCancelAOEFlowModal: null,
    cost: null,
    hasModifiedDependents: null,
    coveredDependents: [],
    status: null, // Can be: 'Pending', 'InProgress', 'Completed', 'Declined' or null
    previousUrl: null,
    changedFamilyMembers: null,
    skipRecommendation: null
};

export const anualOpenEnrollmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case anualOpenEnrollmentActionTypes.SET_AOE_USER_INITIAL_DATA:
            const {user} = action.payload;
            return {
                ...state,
                firstName: user.firstName,
                lastName: user.lastName,
                middleName: user.middleName,
                gender: user.gender,
                suffix: user.suffix,
                daytimePhoneNumber: user.certificateHolderPhone,
                phoneType: user.certificateHolderPhoneType,
                selectedState: user.address.state,
                city: user.address.city,
                zipCode: user.address.zipCode,
                streetAddress: user.address.street,
                //appartment: user.address.appartment,
                birthDate: user.birthDate,
                ssn: user.socialSecurityNumber,
                email: action.payload.email
            };
        case anualOpenEnrollmentActionTypes.SET_AOE_INITIAL_DATA:
            return {
                ...state,
                generalInitialData: action.payload.initialData
            }
        case anualOpenEnrollmentActionTypes.SET_AOE_INITIAL_DEPENDENTS:
            return {
                ...state,
                dependents: action.payload
            }
        case anualOpenEnrollmentActionTypes.SET_AOE_INITIAL_COVERED_DEPENDENTS:
            return {
                ...state,
                coveredDependents: action.payload
            }
        case anualOpenEnrollmentActionTypes.SET_AOE_DEPENDENT:
            const {aOEDependent, aOEDependentIdx} = action.payload;
            let {aOEDependents, ...restOfState} = state;
            if(aOEDependents.length === 0){
                aOEDependents = [];
            }
            aOEDependents[aOEDependentIdx] = aOEDependent;
            return {
                ...restOfState,
                aOEDependents: [...aOEDependents]
            };
        case anualOpenEnrollmentActionTypes.REMOVE_AOE_DEPENDENT:{
            const { idx } = action.payload;
            let {aOEDependents, ...rest} = state;
            aOEDependents.splice(idx, 1);
            return {
                ...rest,
                aOEDependents: [...aOEDependents]
            };
        }
        case anualOpenEnrollmentActionTypes.SET_DEPENDENT:
            const {dependent, idx} = action.payload;
            let {dependents, ...rest} = state;
            if(dependents.length === 0){
                dependents = [];
            }
            dependents[idx] = dependent;
            return {
                ...rest,
                dependents: [...dependents],
                hasModifiedDependents: true
            };
        case anualOpenEnrollmentActionTypes.REMOVE_DEPENDENT:{
            const { idx } = action.payload;
            let {dependents, ...rest} = state;
            dependents.splice(idx, 1);
            return {
                ...rest,
                dependents: [...dependents],
                hasModifiedDependents: true
            };
        }
        case anualOpenEnrollmentActionTypes.SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_LAST_NAME:
            return {
                ...state,
                lastName: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_MIDDLE_NAME:
            return {
                ...state,
                middleName: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_SUFFIX:
            return {
                ...state,
                suffix: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_DAYTIME_PHONE:
            return {
                ...state,
                daytimePhoneNumber: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_PHONE_TYPE:
            return {
                ...state,
                phoneType: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_SELECTED_STATE:
            return {
                ...state,
                selectedState: action.payload,
            };

        case anualOpenEnrollmentActionTypes.SET_STREET_ADDRESS:
            return {
                ...state,
                streetAddress: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_APPARTMENT:
            return {
                ...state,
                appartment: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_ZIP_CODE:
            return {
                ...state,
                zipCode: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_CITY:
            return {
                ...state,
                city: action.payload,
            };
        case anualOpenEnrollmentActionTypes.SET_HEALTH_PLAN_DETAILS:
            return {
                ...state,
                typicallyPayInfo: action.payload.typicallyPayInfo,
                highDeductibleInfo: action.payload.highDeductibleInfo,
            };
        case anualOpenEnrollmentActionTypes.SET_FETCH_PLAN_RECOMMENDATION: {
            const {fetchPlanRecommendation} = action.payload;
            return {
                ...state,
                fetchPlanRecommendation,
            }
        }
        case anualOpenEnrollmentActionTypes.SET_COVERAGE_OPTION:
            const {selectedCoverageOption, deleteChildren, deleteSpouse} = action.payload;
            let deps = state.dependents.concat(state.aOEDependents) || [];
            if(!!deps && deps.length > 0){
                if(deleteChildren){
                    deps = deps.filter((d)=>d.relToEmployee !== "Child");
                }
                if(deleteSpouse){
                    deps = deps.filter((d)=>d.relToEmployee !== SPOUSE && d.relToEmployee !== DOMESTIC_PARTNER);
                }
            }
            return {
                ...state,
                coveredDependents: [...deps],
                coverageOption: selectedCoverageOption,
            };
        case anualOpenEnrollmentActionTypes.SET_COST_PER_OPTION:
            return {
                ...state,
                costPerOption: action.payload
            };

        case anualOpenEnrollmentActionTypes.SET_MODERATE_COVERAGE:
            return {
                ...state,
                moderateCoverageSelected: action.payload
            };
        case anualOpenEnrollmentActionTypes.SET_SEVERE_COVERAGE:
            return {
                ...state,
                severeCoverageSelected: action.payload
            };
        case anualOpenEnrollmentActionTypes.SET_CATASTROPHIC_COVERAGE:
            return {
                ...state,
                catastrophicCoverageSelected: action.payload
            };
        case anualOpenEnrollmentActionTypes.SET_SELECTED_AND_RECOMMENDED_COVERAGE:
            return {
                ...state,
                moderateCoverageRecommended: action.payload.moderate,
                severeCoverageRecommended: action.payload.severe,
                catastrophicCoverageRecommended: action.payload.catastrophic,
                moderateCoverageSelected: action.payload.moderateSelected,
                severeCoverageSelected: action.payload.severeSelected,
                catastrophicCoverageSelected: action.payload.catastrophicSelected,
            };
        case anualOpenEnrollmentActionTypes.SET_SELECT_COST:
            return {
                ...state,
                costValue: action.payload.costValue,
                costEnhanced: action.payload.costEnhanced,
                costPremier: action.payload.costPremier,
                contribValue: action.payload.contribValue,
                contribEnhanced: action.payload.contribEnhanced,
                contribPremier: action.payload.contribPremier,
            };

        case anualOpenEnrollmentActionTypes.SET_BRELLA_SELECT_TIER_SELECTED: {
            const {value, premier, enhanced} = action.payload;
            return {
                ...state,
                selectedTierBrellaSelect: {value, premier, enhanced},
            }
        }
        case anualOpenEnrollmentActionTypes.SET_LEFTOVER_MONEY:
            return {
                ...state,
                leftoverMoney: action.payload
            };
        case anualOpenEnrollmentActionTypes.CLEAR_AOE_DATA:
            return {
                ...initialState,
            }
        case anualOpenEnrollmentActionTypes.SET_PROGRESS_DATA: {
                const {
                    dependents, typicallyPayInfo, highDeductibleInfo, leftoverMoney, aOEDependents, keepCoverage, changedFamilyMembers,
                    coverageOption, costPerOption, moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected,
                    moderateCoverageRecommended, severeCoverageRecommended, catastrophicCoverageRecommended, costValue, costEnhanced,
                    costPremier, selectedState, streetAddress, appartment, zipCode, city, birthDate, ssn, middleName, suffix, skipRecommendation,
                    daytimePhoneNumber, phoneType, employerContribution, cost, firstName, lastName, lastVisitedSavePoint, selectedTierBrellaSelect, fetchPlanRecommendation, gender
                } = action.payload;
                return {
                    ...state,
                    dependents,
                    aOEDependents,
                    typicallyPayInfo,
                    highDeductibleInfo,
                    leftoverMoney,
                    coverageOption,
                    costPerOption,
                    moderateCoverageSelected,
                    severeCoverageSelected,
                    catastrophicCoverageSelected,
                    moderateCoverageRecommended,
                    severeCoverageRecommended,
                    catastrophicCoverageRecommended,
                    costValue,
                    costEnhanced,
                    costPremier,
                    selectedState,
                    streetAddress,
                    appartment,
                    zipCode,
                    city,
                    birthDate,
                    ssn,
                    middleName,
                    suffix,
                    daytimePhoneNumber,
                    phoneType,
                    employerContribution,
                    cost,
                    firstName,
                    lastName,
                    lastVisitedSavePoint,
                    selectedTierBrellaSelect,
                    fetchPlanRecommendation,
                    gender,
                    keepCoverage,
                    changedFamilyMembers,
                    skipRecommendation
                }
            }
        case anualOpenEnrollmentActionTypes.SET_LAST_VISITED_SAVEPOINT_ROUTE:
            const {route, step} = action.payload;
            return {
                ...state,
                lastVisitedSavePoint: {
                    route,
                    step
                },
            };
            case anualOpenEnrollmentActionTypes.SET_PLAN_CONTRIB_COST:
                return {
                    ...state,
                    employerContribution: action.payload.employerContribution,
                    cost: action.payload.cost,
                };
        case anualOpenEnrollmentActionTypes.SET_KEEP_COVERAGE: {
            return {
                ...state,
                keepCoverage: action.payload
            }
        }
        case anualOpenEnrollmentActionTypes.SHOW_CANCEL_AOE_MODAL: {
            return {
                ...state,
                showCancelAOEFlowModal: action.payload
            }
        }
        case anualOpenEnrollmentActionTypes.SET_PREVIOUS_URL: {
            return {
                ...state,
                previousUrl: action.payload || null,
            }
        }
        case anualOpenEnrollmentActionTypes.SET_STATUS: {
            return {
                ...state,
                status: action.payload || null,
            }
        }
        case anualOpenEnrollmentActionTypes.SET_FAMILY_CHANGED:
            return {
                ...state,
                changedFamilyMembers: true
            }
        case anualOpenEnrollmentActionTypes.SET_SKIP_RECOMMENDATION:
            return {
                ...state,
                skipRecommendation: action.payload
            };
        default:
            return state
    }
};
