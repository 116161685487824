import React from "react";

import {Icon} from "semantic-ui-react";


const PDFIcon = () => (
    <Icon
        name="file pdf outline"
        color="red"
        size="large"
        style={{ position: 'absolute', left: '1.5rem', top: '2rem' }}
    />
);

export default PDFIcon;
