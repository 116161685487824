import React, {Component} from "react"
import {Checkbox, Divider, Form, Grid, Header as SHeader, Icon, Modal, Popup, Radio, Select} from "semantic-ui-react";
import {CustomContainer, CustomGrid, S3Image, CustomModalHeader, ClickableText} from '../custom-common';
import {Button, Header, Link} from "../base";
import {withRouter} from "react-router-dom";
import {getLocalization, interpolateString, languages, parseMultilineString, parseSingleUnderlinedWordInString} from "../../utils/localization";
import {connect} from "react-redux";
import {setPrevRoute} from "../../actions/navigationActions";
import {AOE_END_COVERAGE, AOE_HEALTH_PLAN_DETAILS_ROUTE, AOE_INCOME_ROUTE, AOE_REVIEW_FAMILY_ROUTE, CHILD, DOMESTIC_PARTNER, findEmployerContribution, formatMoneyAmount, handleSaveUserProgress, SPOUSE, truncTwoDec,} from "../../utils/common";
import {
    setBrellaSelectTierSelected,
    setCatastrophicCoverage,
    setCostPerOption,
    setCoverageOption,
    setFetchPlanRecommendation,
    setModerateCoverage,
    setPlanContribAndCost,
    setPlanCoverageSelectedAndRecommendedSelect,
    setSevereCoverage,
} from "../../actions/anualOpenEnrollmentActions";
import axios from "axios";
import Footer from "../footer";
import moment from "moment";
import 'moment-timezone';
import {cancelAOEFlow} from "../../utils/utils";
import AOESteps from "./aOESteps";
import CancelLink from "./cancelLink";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import IMAGE from "../../constants/images";


const mapStateToProps = (state, ownProps) => {
    const {initialData, anualOpenEnrollment} = state;
    const {ratedClass, contractInfo, contract, coverageOptions, costPerOptions, eligibility} = anualOpenEnrollment.generalInitialData;
    const defaultCostPerOptionId = costPerOptions.filter(cpo => cpo.value === "Month")[0]?.id;
    const {moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected} = anualOpenEnrollment;
    const moderateCS = !!moderateCoverageSelected ? moderateCoverageSelected : null;
    const severeCS = !!severeCoverageSelected ? severeCoverageSelected : null;
    const catastrophicCS = !!catastrophicCoverageSelected ? catastrophicCoverageSelected : null;
    let additionalChronic = 0;
    let additionalMental = 0;
    if(ratedClass && ratedClass.contractTerms && ratedClass.contractTerms.chronicBrokerRequested){
        additionalChronic = ratedClass.contractTerms.chronicCoverageLimit;
    }
    if(ratedClass && ratedClass.contractTerms && ratedClass.contractTerms.mentalBrokerRequested){
        additionalMental = ratedClass.contractTerms.mentalCoverageLimit;
    }
    const includesDomesticPartnerships = !!eligibility.domesticPartnership;
    const {enrollmentStartDate, enrollmentEndDate} = contract;
    const start = moment(enrollmentStartDate);
    const end = moment(enrollmentEndDate);
    const now = moment().tz('America/Chicago');
    let isOpenEnrollment = false;
    if(moment(now.format("YYYY-MM-DD")).isBetween(start, end, 'days', '[]')){
        isOpenEnrollment = true;
    }

    return {
        ...ownProps,
        contractInfo: contractInfo,
        additionalChronic,
        additionalMental,
        isOpenEnrollment,
        includesDomesticPartnerships,
        openEnrollmentStarts: start.format("MMMM, Do YYYY"),
        coverageOptions,
        costPerOptions,
        defaultCostPerOptionId,
        costPerOption: anualOpenEnrollment.costPerOption,
        coverageOption: anualOpenEnrollment.coverageOption,
        moderateCoverageSelected: moderateCS,
        severeCoverageSelected: severeCS,
        catastrophicCoverageSelected: catastrophicCS,
        moderateCoverageRecommended: anualOpenEnrollment.moderateCoverageRecommended,
        severeCoverageRecommended: anualOpenEnrollment.severeCoverageRecommended,
        catastrophicCoverageRecommended: anualOpenEnrollment.catastrophicCoverageRecommended,
        user: initialData.user,
        initialData,
        lang: initialData.user.lang,
        cost: !!anualOpenEnrollment.cost ? anualOpenEnrollment.cost : "-",
        anualOpenEnrollment,
        skipRecommendation: anualOpenEnrollment.skipRecommendation,
        riderLabels: initialData.riderLabels,
    }
};

const PLAN_TITLES = Object.freeze({
    VALUE: {name: 'value'},
    ENHANCED: {name: 'enhanced'},
    PREMIER: {name: 'premier'},
});

class PlanSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepProps: null,
            loading: false,
            loadingPersonalize: false,
            submitPlanLoading: false,
            skipRecommendation: false,
            displayInformationAboutCategoriesModal: false,
            displaySeeWhyWeChoseThisAmountsModal: false,
            displayCaptureEmailModal: false,
            capturedEmail: '',
            recommendationEmailScheduledSuccessfully: null,
            emailValidationError: null,
            recommendedPlan: "",
            displayDontWantCoverageModal: false,
            tierSelected: {
                value: false,
                enhanced: false,
                premier: false,
            },
            userModifiedData: false,
        };
    }

    async componentDidMount() {
        const {anualOpenEnrollment, skipRecommendation, defaultCostPerOptionId} = this.props;
        const selectedCostPerOptionId = anualOpenEnrollment.costPerOption || defaultCostPerOptionId;
        this.props.dispatch(setCostPerOption(selectedCostPerOptionId));
        if(anualOpenEnrollment.fetchPlanRecommendation) {
            if (skipRecommendation) {
                const {generalInitialData} = anualOpenEnrollment;
                const tier = generalInitialData.ratedClass.contractTerms.familyTier;
                const deletes = this.alterDependentInfoBasedOnCoverage(tier);
                this.props.dispatch(setCoverageOption(tier, deletes.deleteChildren, deletes.deleteSpouse));
                await this.personalizePlan(tier, selectedCostPerOptionId);
                this.setState({
                    tierSelected: {value: generalInitialData.currentSelectPlan === "Value", premier: generalInitialData.currentSelectPlan === "Premier", enhanced: generalInitialData.currentSelectPlan === "Enhanced"},
                    skipRecommendation: skipRecommendation,
                    loading: false,
                });
            } else {
                this.setState({skipRecommendation: false})
                await this.submitInitialPlanDetails();
                await this.personalizePlan(null, selectedCostPerOptionId);
            }
        } else {
            const {selectedTierBrellaSelect: {value, premier, enhanced}} = this.props.anualOpenEnrollment;
            const ts = {value, premier, enhanced}
            this.setState({
                tierSelected: {...ts},
            });
            await this.submitInitialPlanDetails(true);
            await this.personalizePlan(null, selectedCostPerOptionId, true);
        }

        let prevRoute = AOE_INCOME_ROUTE;
        if (skipRecommendation) {
            prevRoute = AOE_HEALTH_PLAN_DETAILS_ROUTE;
        }
        this.props.dispatch(setPrevRoute(prevRoute));
    }

    endCoverage = () => {
        this.props.history.push(AOE_END_COVERAGE)
    }

    alterDependentInfoBasedOnCoverage = (coverageOptionId) => {
        const {coverageOptions} = this.props;
        const option = coverageOptions.filter((c)=>c.value === coverageOptionId)[0];
        let deleteChildren;
        let deleteSpouse;
        switch(option.value){
            case 'employee':
                deleteChildren = true;
                deleteSpouse = true;
                break;
            case 'spouse':
                deleteChildren = true;
                deleteSpouse = false;
                break;
            case 'child':
                deleteChildren = false;
                deleteSpouse = true;
                break;
            default:
                deleteChildren = false;
                deleteSpouse = false;
        }
        return {deleteChildren, deleteSpouse};
    }

    personalizePlan = async (forcedCoverageOption, forcedCostPerOptId, useSelectedPlanInsteadOfRecommended) => {
        this.setState({loadingPersonalize: true, recommendationEmailScheduledSuccessfully: null});
        try {
            const {additionalChronic, additionalMental, coverageOption, costPerOption, costPerOptions, moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected, initialData, anualOpenEnrollment} = this.props;
            const {userModifiedData, tierSelected} = this.state;
            const selectedOptionId = costPerOption || forcedCostPerOptId;
            const selectedCostPerOpt = costPerOptions?.find(({ id }) => id === selectedOptionId)?.value;
            const payrollFreq = selectedCostPerOpt === 'Month' ? anualOpenEnrollment.generalInitialData.payrollFrequencyMonthly : anualOpenEnrollment.generalInitialData.payrollFrequencySelected;
            let coverageOpt = coverageOption;
            if (!!forcedCoverageOption) {
                coverageOpt = forcedCoverageOption;
            }

            let selectedPlan = "VALUE";
            if (tierSelected.premier) {
                selectedPlan = "PREMIER";
            } else if (tierSelected.enhanced) {
                selectedPlan = "ENHANCED";
            }

            let result = await axios.post("/api/member/v2/personalizePlan", {
                familyTierValue: coverageOpt,
                moderateBenefit: !!moderateCoverageSelected ? moderateCoverageSelected : 0,
                severeBenefit: !!severeCoverageSelected ? severeCoverageSelected : 0,
                catastrophicBenefit: !!catastrophicCoverageSelected ? catastrophicCoverageSelected : 0,
                quoteInfo: anualOpenEnrollment.generalInitialData.quoteInfo,
                mentalBenefit: additionalMental,
                chronicBenefit: additionalChronic,
                employer: anualOpenEnrollment.generalInitialData.employer,
                birthDate: initialData.user.birthDate,
                policyEffectiveDate: anualOpenEnrollment.generalInitialData.upcomingPolicyEffectiveDate,
                employerContributions: anualOpenEnrollment.generalInitialData.employerContributions,
                presets: anualOpenEnrollment.generalInitialData.presets,
                payrollFrequencySelected: payrollFreq,
                userModifiedData,
                planOffering: anualOpenEnrollment.generalInitialData.ratedClass.contractTerms.planOffering,
                userActionData: {
                    brellaSelectPlan: selectedPlan,
                },
                ratedClassRates: anualOpenEnrollment.generalInitialData.ratedClass.ratedClassRates,
            });
            if (!!result && !!result.data) {
                const data = result.data;
                this.props.dispatch(setPlanCoverageSelectedAndRecommendedSelect(data.costValue, data.costEnhanced, data.costPremier,
                    data.contribValue, data.contribEnhanced, data.contribPremier));

                const {recommendedPlan} = this.state;
                let plan = recommendedPlan;
                if(!!useSelectedPlanInsteadOfRecommended){
                    const {tierSelected} = this.state;
                    plan = "value"
                    if(tierSelected.enhanced){
                        plan = "enhanced"
                    } else if(tierSelected.premier){
                        plan = "premier"
                    }
                }

                let cost = data.costValue;
                let contrib = data.contribValue;
                let presets = initialData.presets;
                let selectedPreset = presets.value;
                if(plan === 'enhanced'){
                    cost = data.costEnhanced;
                    contrib = data.contribEnhanced;
                    selectedPreset = presets.enhanced;
                } else if (plan === 'premier'){
                    cost = data.costPremier;
                    contrib = data.contribPremier;
                    selectedPreset = presets.premier;
                }
                const ec = contrib ? contrib : 0.0;
                this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)));
                const d = selectedPreset;
                this.props.dispatch(setModerateCoverage(d.moderate));
                this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                this.props.dispatch(setSevereCoverage(d.severe));
            }
            this.setState({loadingPersonalize: false});
        } catch (e) {
            console.log(e);
            this.setState({loadingPersonalize: false})
        }
    }

    submitInitialPlanDetails = async (dontChangeUserElections) => {
        this.setState({loading: true});
        try {
            const {anualOpenEnrollment, initialData, coverageOptions, skipRecommendation} = this.props;
            const {
                generalInitialData,
                leftoverMoney,
                aOEDependents,
                dependents,
                typicallyPayInfo,
                payrollFrequencySelected,
                payrollFrequencyMonthly,
                highDeductibleInfo
            } = anualOpenEnrollment;
            if(highDeductibleInfo === null || leftoverMoney === null){
                this.setState({loading: false});
                return;
            }
            let ageAtPolicyEffectiveDate = initialData.certificate.ageAtPolicyEffectiveDate;
            const sanitizedDependents = aOEDependents.map(d => ({...d, birthDate: moment(d.birthDate).format('YYYY-MM-DD')}));
            const totalDependents = dependents.concat(sanitizedDependents);
            const payload = {
                dependents: totalDependents,
                copayId: typicallyPayInfo,
                highDeductibleInfoId: highDeductibleInfo,
                leftoverMoneyId: leftoverMoney,
                ageAtPolicyEffectiveDate: ageAtPolicyEffectiveDate,
                payrollFrequencySelected: generalInitialData.payrollFrequencySelected,
                planOffering: generalInitialData.ratedClass.contractTerms.planOffering,
                quoteInfo: generalInitialData.quoteInfo,
                presets: generalInitialData.presets,
                ratedClassRates: generalInitialData.ratedClass.ratedClassRates,
            }

            let result = await axios.post("/api/member/v2/submitPlanDetails", payload, {headers: {'Content-Type': 'application/json'}});
            if (!!result && !!result.data && !!result.data.select) {
                const data = result.data;
                const dataSelect = result.data.select;
                const recommendedPreset = !skipRecommendation && dataSelect.selectPreset;
                const recommendedOption = data.familyTierValue;
                const deletes = this.alterDependentInfoBasedOnCoverage(recommendedOption);
                if (!dontChangeUserElections) {
                    this.props.dispatch(setCoverageOption(recommendedOption, deletes.deleteChildren, deletes.deleteSpouse));
                }

                const ec = findEmployerContribution(recommendedOption, coverageOptions, initialData.employerContributions);
                if (!!recommendedPreset) {
                    const ts = this.state.tierSelected;
                    const recommendedPresetLC = recommendedPreset.toLowerCase();
                    if(!dontChangeUserElections){
                        ts[recommendedPresetLC] = true;
                    }
                    this.setState({
                        recommendedPlan: recommendedPresetLC,
                        tierSelected: {...ts},
                        loading: false,
                    });

                    const {anualOpenEnrollment: {costValue, costEnhanced, costPremier}} = this.props;
                    let cost = costValue;
                    if(recommendedPresetLC === 'enhanced'){
                        cost = costEnhanced;
                    } else if (recommendedPresetLC === 'premier'){
                        cost = costPremier;
                    }

                    let presets = initialData.presets;
                    if (!presets) {
                        presets = {
                            value: {severe: 0, moderate: 0, catastrophic: 0},
                            premier: {severe: 0, moderate: 0, catastrophic: 0},
                            enhanced: {severe: 0, moderate: 0, catastrophic: 0}
                        }
                    }
                    const d = presets[recommendedPreset.toLowerCase()];
                    this.props.dispatch(setModerateCoverage(d.moderate));
                    this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                    this.props.dispatch(setSevereCoverage(d.severe));
                    this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)))
                } else {
                    this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), 0))
                    this.setState({loading: false});
                }
            } else {
                this.setState({loading: false});
            }
        } catch (e) {
            console.error(e);
            this.setState({loading: false})
        }
    }

    renderSeeWhyWeChoseThisAmountsModal = (strings) => {
        const {displaySeeWhyWeChoseThisAmountsModal} = this.state;
        const onModalClose = () => this.setState({displaySeeWhyWeChoseThisAmountsModal: false});
        return <Modal open={displaySeeWhyWeChoseThisAmountsModal} size={'tiny'} onClose={onModalClose}>
            <CustomModalHeader title={strings.seeWhyWeChoseTitle} onClose={onModalClose} className="customModalHeader" />
            <Modal.Content style={{maxHeight:'513px', overflowY: 'auto'}} className={"small"}>
                <p>{strings.modalP1}</p>
                <p><b>{strings.modalP2Title}</b><br/>
                    {strings.modalP2}
                </p>

                <p><b>{strings.modalP3Title}</b><br/>
                    {strings.modalP3}
                </p>

                <p><b>{strings.modalP4Title}</b><br/>
                    {strings.modalP4}
                </p>

                <p><b>{strings.modalP5Title}</b><br/>
                    {strings.modalP5}
                </p>
            </Modal.Content>
        </Modal>
    }


    selectPlan = (title, dispatchSelection) => {
        return async () => {
            const selectedPlan = title.toLowerCase();
            const tierSelected = {
                value: selectedPlan === PLAN_TITLES.VALUE.name,
                premier: selectedPlan === PLAN_TITLES.PREMIER.name,
                enhanced: selectedPlan === PLAN_TITLES.ENHANCED.name,
            };
            this.setState({
                tierSelected,
                userModifiedData: true,
            });
            const {value, premier, enhanced} = tierSelected;
            this.props.dispatch(setBrellaSelectTierSelected(value, premier, enhanced));
            await this.personalizePlan(null, null, true);
            dispatchSelection();
        }
    }

    renderRecommendationTier = (strings, title, moderateVal, severeVal, catastrophicVal, employerContr, cost, dispatchSelection) => {
        const {costPerOptions, costPerOption} = this.props;
        const {tierSelected, recommendedPlan, loadingPersonalize} = this.state;
        const isRecommended = title.toLowerCase() === recommendedPlan.toLowerCase();
        let isSelectedTier = tierSelected[title.toLowerCase()]
        const noSelectedTier = !tierSelected || !(tierSelected.enhanced || tierSelected.premier || tierSelected.value);
                if (noSelectedTier) {
            isSelectedTier = title.toLowerCase() === recommendedPlan.toLowerCase();
        }

        const {PRIMARY, SECONDARY} = STRATEGIC_PARTNER.PALETTE;
        const titleBkgColor = isSelectedTier ? SECONDARY : '#F2F2F2';
        const titleColor = isSelectedTier ? 'white' : PRIMARY;
        const borderColor = isSelectedTier ? SECONDARY : '#E0E0E0';
        const boxShadow = isSelectedTier ? '0px 4px 4px rgba(0, 0, 0, 0.15)' : 'none';
        let costPerOptionLabel = (!!costPerOptions && !!costPerOption && costPerOptions.filter(cpo => cpo.id === costPerOption)[0].value.toLowerCase()) || '';
        if(costPerOptionLabel !== ''){
            if(costPerOptionLabel === "month"){
                costPerOptionLabel = strings.monthly;
            } else {
                costPerOptionLabel = strings.paycheck;
            }
        }

        return (
            <CustomGrid.Column>
                <CustomContainer basic loading={loadingPersonalize} className="recommendationTier">
                    <CustomGrid columns={1} style={{margin: 0}}>
                        <CustomGrid.Column className={"planSelectTable"} style={{borderColor: borderColor, boxShadow: boxShadow}}>
                            <div style={{backgroundColor: titleBkgColor, padding: '0.8em', textAlign: 'center', height: '56px'}}>
                                <Checkbox radio style={{display: 'block', float: 'left', marginTop: '7px'}}
                                          checked={isSelectedTier}
                                          onClick={isSelectedTier ? null : this.selectPlan(title, dispatchSelection)}/>
                            <span style={{marginLeft: '-16px'}}>
                                <SHeader as={'h4'} style={{color: titleColor, display: 'inline'}}>{title}</SHeader>
                            </span>
                            </div>
                            <div style={{paddingLeft: '1em', paddingRight: '1em'}}>
                                <div className={"smaller"} style={{marginTop: '1.5em', marginBottom: '0.6em'}}>
                                    <p><b>{strings.payouts}</b></p>
                                </div>

                                <CustomGrid>
                                    <CustomGrid.Row columns={3} className={'nonprintable'}>
                                        <CustomGrid.Column>
                                            <div className={"smaller"}>{strings.moderate}</div>
                                            <b>${formatMoneyAmount(moderateVal)}</b>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <div className={"smaller"}>{strings.severe}</div>
                                            <b>${formatMoneyAmount(severeVal)}</b>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <div className={"smaller"}>{strings.catastrophic}</div>
                                            <b>${formatMoneyAmount(catastrophicVal)}</b>
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                </CustomGrid>

                                <Divider hidden/>

                                {!!employerContr && employerContr > 0 &&
                                    <div style={{marginBottom: '0.6em'}}>
                                        <div className={"smaller"}>{strings.employerContribution}</div>
                                        <b>${!!employerContr ? truncTwoDec(employerContr) : employerContr}</b>/<small>{costPerOptionLabel}</small>
                                    </div>
                                }

                                <Divider/>
                                <div style={{paddingLeft: '1em', paddingRight: '1em'}}>
                                    <CustomGrid textAlign={'center'}>
                                        <CustomGrid.Column>
                                        <Header as={"h3"} style={{margin: 0}}>${!!cost ? truncTwoDec(cost) : cost}</Header>
                                        <small>{strings.costPer}{' '}{costPerOptionLabel}</small><br/>
                                        {((isRecommended && strings.recommendedForYou) && <b style={{fontSize: '0.9rem', color: STRATEGIC_PARTNER.PALETTE.SECONDARY}}>{(isRecommended && strings.recommendedForYou)}</b>) || <div>&nbsp;</div>}
                                    </CustomGrid.Column>
                                    </CustomGrid>
                                </div>
                                <Divider hidden/>
                            </div>
                        </CustomGrid.Column>
                    </CustomGrid>
                </CustomContainer>
            </CustomGrid.Column>
        );
    }

    getRecommendedPlanLabelForDescription = (strings) => {
        const {recommendedPlan} = this.state;
        if (recommendedPlan.toLowerCase() === PLAN_TITLES.VALUE.name) {
            return strings.valuePlan;
        }
        if (recommendedPlan.toLowerCase() === PLAN_TITLES.ENHANCED.name) {
            return strings.enhancedPlan;
        }
        if (recommendedPlan.toLowerCase() === PLAN_TITLES.PREMIER.name) {
            return strings.premierPlan;
        }
    }

    renderPayFrequencySelect = () => {
        const {costPerOptions = [], anualOpenEnrollment, lang} = this.props;
        const {loadingPersonalize} = this.state;

        const payrollFrequencySelected = anualOpenEnrollment?.generalInitialData?.payrollFrequencySelected;
        const payrollFrequencyMonthly = anualOpenEnrollment?.generalInitialData?.payrollFrequencyMonthly;

        if (payrollFrequencyMonthly && payrollFrequencyMonthly === payrollFrequencySelected) return null;

        const {plan: strings} = getLocalization(lang) || {};

        const optionsMap = {
            Paycheck: strings?.paycheck ? {key: 'Paycheck', text: strings.paycheck.toLowerCase()} : null,
            Month: strings?.monthly ? {key: 'Month', text: strings.monthly.toLowerCase()} : null,
        };

        const options = costPerOptions
            .map(({id, value}) => (
                optionsMap[value] ? {key: id, value: id, text: `${strings.costPer} ${optionsMap[value].text}`} : null
            ))
            .filter(option => option !== null);

        return options.length > 0 && (
            <Select
                options={options}
                style={{minWidth: 0}}
                fluid
                disabled={loadingPersonalize}
                value={this.props.costPerOption}
                onChange={async (a, {value}) => {
                    try {
                        this.setState({userModifiedData: true});
                        await this.props.dispatch(setCostPerOption(value));
                        await this.personalizePlan(null, null, true);
                    } catch (error) {
                        console.error('Error in handling pay frequency select change:', error);
                    }
                }}
            />
        );
    }

    getFilteredCoverOptions = () => {
        const {coverageOptions, lang, includesDomesticPartnerships,anualOpenEnrollment} = this.props;
        const localization = getLocalization(lang);
        const localizationEng = getLocalization(languages.ENGLISH);
        const engStrings = localizationEng.plan;
        const strings = localization.plan;

        const coverOpts = coverageOptions.map(c => {
            let text = c.text;
            if(c.text === engStrings.coverOpt1){
                text = strings.coverOpt1;
            } else if(c.text === engStrings.coverOpt2){
                if(!!includesDomesticPartnerships){
                    text = parseSingleUnderlinedWordInString(strings.coverOpt2Under);
                } else {
                    text = strings.coverOpt2;
                }
            } else if(c.text === engStrings.coverOpt3){
                text = parseSingleUnderlinedWordInString(strings.coverOpt3Under);
            } else if(c.text === engStrings.coverOpt4){
                text = strings.coverOpt4;
            }
            return {key: c.key, text: <label>{text}</label>, value: c.value}
        });

        const totalDependents = anualOpenEnrollment.dependents.concat(anualOpenEnrollment.aOEDependents)

        const isMarried = totalDependents.filter(dep => dep.relToEmployee === SPOUSE || (!!includesDomesticPartnerships && dep.relToEmployee === DOMESTIC_PARTNER)).length > 0;
        const hasChildren = totalDependents.filter(dep => dep.relToEmployee === CHILD).length > 0;

        let filteredCoverOptions;
        if (isMarried) {
            if (!hasChildren) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('child'))
            } else {
                filteredCoverOptions = coverOpts;
            }
        } else {
            if (hasChildren) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse'))
            } else {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse') && !opts.value.includes('child'))
            }
        }

        return filteredCoverOptions;
    }

    handleCoverageChange = async (e, {value}) => {
        this.setState({userModifiedData: true});
        const {deleteChildren, deleteSpouse} = this.alterDependentInfoBasedOnCoverage(value);
        await this.props.dispatch(setCoverageOption(value, deleteChildren, deleteSpouse));
        await this.personalizePlan(null, null, true);
        this.props.dispatch(setFetchPlanRecommendation(false));
    }

    render() {
        const {loading, skipRecommendation} = this.state;

        const {
            lang, additionalChronic, additionalMental, initialData,
            anualOpenEnrollment: {costValue, costEnhanced, costPremier, contribValue, contribEnhanced, contribPremier},
            coverageOption, coverageOptions, anualOpenEnrollment, includesDomesticPartnerships, riderLabels,
        } = this.props;
        let presets = initialData.presets;
        if (!presets) {
            presets = {
                value: {severe: 0, moderate: 0, catastrophic: 0},
                premier: {severe: 0, moderate: 0, catastrophic: 0},
                enhanced: {severe: 0, moderate: 0, catastrophic: 0}
            }
        }
        const localization = getLocalization(lang);
        const strings = localization.plan;
        const stringsAlsoIncludes = localization.yourBrellaPlanAlsoIncludes;

        let title = strings.title;
        let descriptionGreen = <ClickableText onClick={() => this.setState({displaySeeWhyWeChoseThisAmountsModal: true})}>
            <b>{strings.basedOnModal}</b>
        </ClickableText>;
        let description = <span className={'printableText'}>
            {strings.basedOnAlternate}
            <span><b>{' '}{this.getRecommendedPlanLabelForDescription(strings)}.{' '}</b></span>
            <span className={"secondaryBlueText"}><b>{' '}{descriptionGreen}</b>.{' '}</span>
            {strings.basedOnAlternate2}
        </span>

        if (skipRecommendation) {
            title = strings.titleSkipped;
            descriptionGreen = <Link to={AOE_HEALTH_PLAN_DETAILS_ROUTE}><b>{strings.descriptionGreenSkipped}</b></Link>;
            description = <React.Fragment>{strings.descriptionSkipped}{' '}<span className={"secondaryBlueText"}>{descriptionGreen}{'\u2192'}</span></React.Fragment>
        }

        const filteredCoverOptions = this.getFilteredCoverOptions();

        let displayAgeReduction = false;
        const {ageReduction, ageReductionAge, ageReductionPercentage} = initialData.certificate.ratedClass.contractTerms;
        let selectedCoverage = {};
        coverageOptions.forEach((c)=>{
            if(c.id === coverageOption){
                selectedCoverage = c;
            }
        })
        if(ageReduction && selectedCoverage.value === "employee"){
            const age = initialData.certificate.ageAtPolicyEffectiveDate;
            if(age >= ageReductionAge){
                displayAgeReduction = true;
            }
        }

        const button = (<Button primary content={localization.next}
                                onClick={() => {
                                        handleSaveUserProgress(anualOpenEnrollment).then();
                                        this.props.history.push(this.props.nextRoute);
                                    }
                                }
                                disabled={!this.props.coverageOption || !this.props.costPerOption || this.state.loadingPersonalize}
                                loading={this.state.submitPlanLoading}
        />)

        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;

        if (loading) {
            return <React.Fragment>
                <div className="member-main-container" style={{background: 'white'}}>
                    <Grid container stackable columns={1} textAlign={'center'} style={{margin: '5em'}}>
                        <Grid.Row>
                            <Grid.Column>
                                <S3Image {...IMAGE.LEVERS}/>
                                <Header as={'h4'}>{strings.justAMoment}</Header>
                                <p>{strings.jamDescr}</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </React.Fragment>;
        } else {
            return (
                <React.Fragment>
                    {this.renderSeeWhyWeChoseThisAmountsModal(strings)}

                    <div className="member-main-container" style={{background: 'white'}}>
                        <AOESteps activeStepIdx={1}/>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={12} className={"pageContent"}>
                                <Grid stackable container>
                                    <Grid.Row columns={1} centered>
                                        <Grid.Column textAlign={'left'} width={12}>
                                            <Header as='h2' className={'printableTitle'}>{title}</Header>
                                            <p>{description}</p>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={1}>
                                        {!!displayAgeReduction && <React.Fragment>
                                            <Divider hidden/>
                                            <CustomContainer basic className={"bkgLinen small"}>
                                                <b>{strings.important}</b>: {interpolateString(strings.basedOnBenefit, [ageReductionPercentage])}
                                            </CustomContainer>
                                            <Divider hidden/>
                                        </React.Fragment>}
                                    </Grid.Row>

                                    <Grid.Row style={{marginBottom: 0, paddingBottom: 0,}}>
                                        <Grid.Column width={8}>
                                            <Header as={'h4'}>{strings.iWantToCover}</Header>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row style={{marginTop: 0, paddingTop: 0}}>
                                        <Grid.Column width={8}>
                                            <Form>
                                                <Form.Field>
                                                    {filteredCoverOptions.map((opt, idx) => {
                                                        const selected = coverageOption === opt.value;
                                                        let content = <Radio checked={selected} key={idx} label={opt.text}
                                                                             name='radioGroup' onClick={selected ? null : this.handleCoverageChange}
                                                                             style={{marginRight: '0.4em'}} value={opt.value}/>
                                                        if (opt.value === "child") {
                                                            content = <Popup key={idx} content={<span>{parseMultilineString(strings.childrenAreElegible)}</span>}
                                                                             style={{fontSize: '12px'}}
                                                                             trigger={content}/>
                                                        } else if (opt.value === "spouse" && !!includesDomesticPartnerships) {
                                                            content = <Popup key={idx} content={<span>{strings.includesDomestic}</span>}
                                                                             style={{fontSize: '12px'}}
                                                                             trigger={content}/>
                                                        }
                                                        return content;
                                                    })}
                                                </Form.Field>
                                            </Form>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Grid.Row>
                                                {this.renderPayFrequencySelect()}
                                            </Grid.Row>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row style={{padding: 0}}>
                                        <Grid.Column>
                                            <div style={{fontSize: "14px"}}>
                                                {parseMultilineString(strings.dontSeeCoverageTierSelect)}{' '}
                                                <Link to={AOE_REVIEW_FAMILY_ROUTE}>{strings.editYourFamily}</Link>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={3}>

                                        {this.renderRecommendationTier(strings, "Value",
                                            presets.value.moderate, presets.value.severe, presets.value.catastrophic, contribValue, costValue, ()=>{
                                                const d = presets.value;
                                                this.props.dispatch(setModerateCoverage(d.moderate));
                                                this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                                                this.props.dispatch(setSevereCoverage(d.severe));
                                                this.props.dispatch(setPlanContribAndCost(truncTwoDec(contribValue), truncTwoDec(costValue)));
                                                this.props.dispatch(setFetchPlanRecommendation(false));
                                            })}

                                        {this.renderRecommendationTier(strings, "Enhanced",
                                            presets.enhanced.moderate, presets.enhanced.severe, presets.enhanced.catastrophic, contribEnhanced, costEnhanced, ()=>{
                                                const d = presets.enhanced;
                                                this.props.dispatch(setModerateCoverage(d.moderate));
                                                this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                                                this.props.dispatch(setSevereCoverage(d.severe));
                                                this.props.dispatch(setPlanContribAndCost(truncTwoDec(contribEnhanced), truncTwoDec(costEnhanced)));
                                                this.props.dispatch(setFetchPlanRecommendation(false));
                                            })}

                                        {this.renderRecommendationTier(strings, "Premier",
                                            presets.premier.moderate, presets.premier.severe, presets.premier.catastrophic, contribPremier, costPremier, ()=>{
                                                const d = presets.premier;
                                                this.props.dispatch(setModerateCoverage(d.moderate));
                                                this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                                                this.props.dispatch(setSevereCoverage(d.severe));
                                                this.props.dispatch(setPlanContribAndCost(truncTwoDec(contribPremier), truncTwoDec(costPremier)));
                                                this.props.dispatch(setFetchPlanRecommendation(false));
                                            })}

                                    </Grid.Row>

                                    {(!!additionalChronic || !!additionalMental) &&
                                        <React.Fragment>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Header as={'h3'}>{stringsAlsoIncludes.title}</Header>
                                                    <p>{stringsAlsoIncludes.yourBrellaPlan}{stringsAlsoIncludes.payoutListed}</p>
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={!!additionalChronic && !!additionalMental ? 2 : 1} stretched>
                                                {!!additionalChronic &&
                                                    <Grid.Column >
                                                        <div className={'bkgLightGray'} style={{padding: '2em'}}>
                                                            <div style={{marginBottom: '0.3em'}}>
                                                                <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                                    <Header as={'h4'} style={{display: 'inline'}}>{riderLabels.chronic}{' '}</Header>
                                                                </span>
                                                                ${additionalChronic}
                                                            </div>
                                                            <p>{stringsAlsoIncludes.chronicDetails1}<b>4,100</b>{stringsAlsoIncludes.chronicDetails2.replace('$riderLabel', riderLabels.chronic)}</p>
                                                        </div>
                                                    </Grid.Column>
                                                }
                                                {!!additionalMental &&
                                                    <Grid.Column style={{margin: 0}}>
                                                        <div className={'bkgLightGray'} style={{padding: '2em'}}>
                                                            <div style={{marginBottom: '0.3em'}}>
                                                                <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                                    <Header as={'h4'} style={{display: 'inline'}}>{riderLabels.mental}{' '}</Header>
                                                                </span>
                                                                ${additionalMental}
                                                            </div>
                                                            <p>{stringsAlsoIncludes.mentalDetails1}<b>600</b>{stringsAlsoIncludes.mentalDetails2.replace('$riderLabel', riderLabels.mental)}</p>
                                                        </div>
                                                    </Grid.Column>
                                                }
                                            </Grid.Row>
                                        </React.Fragment>
                                    }

                                    <Divider hidden/>
                                    <Grid.Row columns={1} className={'planEndYourCoverage'}>
                                        <Grid.Column>
                                            <span className={'planEndYourCoverageLinkText'} onClick={this.endCoverage}>
                                                <Icon name={"close"}/><b>End your {STRATEGIC_PARTNER.LABEL} coverage</b>
                                            </span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </div>

                    <Footer link={cancel} showTopFooter showCenterData button={button}/>
                    )

                </React.Fragment>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps)(PlanSelect));
