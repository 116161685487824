import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';
import {VisibilityIcon} from "./visibilityIcon";

export const DOBDisplay = ({dob, label = 'Date of birth', displayInline = false, name, visibilityIconVisible = true}) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(prevIsVisible => !prevIsVisible);

    const maskDob = () => isVisible ? dob : dob.replace(/\d{4}$/, '••••');

    const labelContent = <b>{label} {(dob && visibilityIconVisible) && <VisibilityIcon ariaLabel={"date of birth"} isVisible={isVisible} onClick={toggleVisibility}/>}</b>;

    return displayInline ? (
        <Grid columns={2}>
            <Grid.Column width={4}>
                {labelContent}
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">{`${name} (${maskDob(dob)})`}</Grid.Column>
        </Grid>
    ) : (
        <>
            {labelContent}
            <div>{maskDob(dob)}</div>
        </>
    );
};

DOBDisplay.propTypes = {
    label: PropTypes.string,
    displayInline: PropTypes.bool,
    dob: PropTypes.string.isRequired,
    name: function (props, propName) {
        if (props.displayInline && (!props[propName] || typeof (props[propName]) !== 'string')) {
            return new Error(`Prop ${propName} is required and it should be a string when displayInline is true`);
        }
    },
    visibilityIconVisible: PropTypes.bool,
};
