import React, {useState} from "react";
import {Checkbox, Confirm, Image} from "semantic-ui-react";
import {Button, Header} from "../base";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";

const AutoPayOnTtile = ({strings}) => (
    <>
        <strong>{strings.paymentRequired.autoPayIsOn}</strong><br/>
        {strings.paymentRequired.weWillNotifyYou}
    </>
);

const AutoPayOnContent = ({strings}) => {
    return (
        <div className="content">
            {strings.paymentRequired.areYouSureYouWantToTurnOff}
        </div>
    );
};

const AutoPayOffTtile = ({strings}) => (
    <>
        <strong>{strings.paymentRequired.setItAndForgetIt}</strong><br />
        {strings.paymentRequired.enableAutoPayToSave}
    </>
);

const AutoPayOffContent = ({checked, onChange, strings, inSettings}) => {
    return (
        <div className="content">
            {!inSettings &&
                <>
                    <Checkbox style={{zIndex: 0}} label={strings.paymentRequired.savePaymentMethod} onChange={onChange} checked={checked} />
                    <br />
                </>
            }
            <p>{strings.paymentRequired.iAuthorizeBrellaServices}</p>
        </div>
    );
};

const AutoPayBoxContent = ({strings, autoPayBtn, onClick, inSettings}) => {
    if (inSettings) {
        return (
            <div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <Image style={{marginBottom: "auto"}} src={process.env.PUBLIC_URL + "/images/refresh_dollar.svg"} />
                    <div style={{margin: "0 15px"}}>
                        <Header style={{marginBottom: 0}} as="h4">{strings.paymentRequired.autoPay}</Header>
                        <p>{autoPayBtn ? <AutoPayOnTtile strings={strings}/> : <AutoPayOffTtile strings={strings}/>}</p>
                    </div>
                    <Checkbox style={{zIndex: 0}} checked={autoPayBtn} onClick={onClick} toggle />
                </div>
            </div>
        )
    }

    return (
        <div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div>
                    <Image src={process.env.PUBLIC_URL + "/images/dollar.svg"} color="green" avatar/>
                    <Header as="span" size="small"><strong>{strings.paymentRequired.autoPay}</strong></Header>
                </div>
                <Checkbox style={{zIndex: 0}} checked={autoPayBtn} onClick={onClick} toggle />
            </div>
            <p style={{marginTop: "1em"}}>
                {autoPayBtn ? <AutoPayOnTtile strings={strings} /> : <AutoPayOffTtile strings={strings} />}
            </p>
        </div>
    )
}

const AutoPayBox = ({strings, onConfirmAutoPay, inSettings = false}) => {
    const {autoPayBtn, toggleAutoPay} = PaymentDetailsContext.useContainer();
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const confirmBtnDisabled = !inSettings && !autoPayBtn && !checked;

    const onCancel = () => {
        setChecked(false);
        setOpen(false);
    };

    const onConfirm = () => {
        toggleAutoPay();
        !autoPayBtn && setChecked(false);
        setOpen(false);
        !!onConfirmAutoPay && onConfirmAutoPay(!autoPayBtn);
    };

    const onClick = () => {
        setOpen(true);
    };

    const onChange = (_, { checked: value }) => {
        setChecked(value);
    };

    return (
        <>
            <AutoPayBoxContent strings={strings} autoPayBtn={autoPayBtn} onClick={onClick} inSettings={inSettings} />
            <Confirm open={open}
                     header={autoPayBtn ? strings.paymentRequired.turnOffAutoPay : strings.paymentRequired.enableAutoPay}
                     cancelButton={<Button basic primary content={strings.paymentRequired.cancel} onClick={onCancel} />}
                     confirmButton={<Button content={strings.paymentRequired.confirm} disabled={confirmBtnDisabled} onClick={onConfirm} />}
                     size="mini"
                     content={autoPayBtn ?
                         <AutoPayOnContent strings={strings} /> :
                         <AutoPayOffContent strings={strings} checked={checked} onChange={onChange} inSettings={inSettings} />} />
        </>
    );
}

export default AutoPayBox;
