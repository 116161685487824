import {EMPTY_FILE_SIZE, MAX_FILE_SIZE} from "./constants";


export const isValidFileByTypes = (file, validTypes) =>
    validTypes.includes(file.type);

export const isValidFileBySize = (file) =>
    file.size <= MAX_FILE_SIZE && file.size > EMPTY_FILE_SIZE;    

export const isValidClaimFileType = (file) =>
    isValidFileByTypes(file, ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']);

export const isValidClaimFileSize = (file) =>
    isValidFileBySize(file)