
import moment from 'moment'

export const displayDateFormat = 'MM/DD/YYYY';

export const isEvenNumber = (num) => num & 2 === 0;

export const isValidPartialDate = (partialDateStr, prevValue) => {
    const reg = /^\d*$/;
    const parts = partialDateStr.split('/');
    if (parts.length > 3) {
        return null;
    }
    const mm = parts[0];
    const dd = parts[1];
    const yyyy = parts[2];
    if (mm !== undefined && (!reg.test(mm) || mm.length > 2 || parseInt(mm) > 12)) {
        return null;
    }
    if (dd !== undefined) {
        if (!reg.test(dd) || dd.length > 2 || parseInt(dd) > 31) {
            return null;
        }
        const digitMm = parseInt(mm);
        const digitDd = parseInt(dd);
        const isEvenMonth = isEvenNumber(digitMm);

        if (digitMm === 2 && digitDd > 29) {
            return null;
        }
        if (isEvenMonth) {
            if (digitDd > 30) {
                return null;
            }
        } else {
            if (digitDd > 31) {
                return null;
            }
        }
    }
    if (yyyy !== undefined && (!reg.test(yyyy) || yyyy.length > 4)) {
        return null;
    }

    let response = partialDateStr;
    const userIsAddingCharacters = partialDateStr.length > prevValue.length;
    if (userIsAddingCharacters) {
        if (partialDateStr.length === 2) {
            response = partialDateStr + '/';
        } else if (partialDateStr.length === 5) {
            response = partialDateStr + '/';
        }
    } else {
        if (partialDateStr.length === 2) {
            response = partialDateStr.substring(0, 1)
        } else if (partialDateStr.length === 5) {
            response = partialDateStr.substring(0, 4)
        }
    }

    return response
}

export function addWorkDays(date, days) {
    date = moment(date);
    while (days > 0) {
        date = date.add(1, 'days');
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
            days -= 1;
        }
    }
    return date;
}

export function parseDate(string) {
    if (!string) return ''

    const date = moment(string)

    return date.isValid() ? date.format(displayDateFormat) : ''
}

export function calculateDaysBetween(start, end) {
    start = moment(start, displayDateFormat);
    end = moment(end, displayDateFormat);

    if (!start.isValid() || !end.isValid()) return null;

    const diff = end.diff(start, 'days') + 1;

    return diff >= 0 ? diff : 0;
}

export function addDays(date, days) {
    return moment(date).add(days, 'days');
}

function setSpanishFormatDate() {
    moment.locale('es', {
            months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
            monthsShort: 'Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Sep._Oct._Nov._Dic.'.split('_'),
            weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
            weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
            weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
        }
    );
}

function setEnglishFormatDate() {
    moment.locale('en');
}

export function setLanguageFormatDate(language) {
    switch (language) {
        case "es":
            setSpanishFormatDate();
            break;
        default:
            setEnglishFormatDate();
    }
}

export function hoursSinceDate (date)  {
    return moment().diff(date, 'hours')
}

export function currentYear () {
    return new Date().getFullYear()
}


const ONE_HOUR_IN_SECONDS = 3600;
const ONE_HOUR = 1;

export const segToHours = (seconds) => {
    const hours = Math.ceil(seconds / ONE_HOUR_IN_SECONDS);
    if (hours < ONE_HOUR) {
        return (seconds / ONE_HOUR_IN_SECONDS).toFixed(1)
    }
    return hours
}

/**
 * Validates a date string against a specified format.
 *
 * @param {Object} options - The options for date validation.
 * @param {string} options.value - The date string to validate.
 * @param {string} [options.format=displayDateFormat] - The format against which to validate the date string.
 * @returns {boolean} True if the date string is valid according to the given format, otherwise false.
 */
export const isValidDate = ({value, format = displayDateFormat}) => {
    if (typeof value !== 'string') {
        return false;
    }
    return moment(value, format, true).isValid();
};
