import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    ${props => props.popupLink ?
            `display: inline-block;`: `display: contents;`
    }
`;

export const ClickableText = ({onClick, children, small = false, popupLink}) => (
    <StyledButton className={`linkText ${small ? "small" : ""}`} onClick={onClick} popupLink={popupLink}>
        {children}
    </StyledButton>
);

ClickableText.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    small: PropTypes.bool,
    popupLink: PropTypes.bool
};
