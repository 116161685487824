import React from 'react'
import styled, {css} from 'styled-components'
import LoginForm from './loginForm'
import {from, shortFrom, to, wBreakpoints} from '../../../design-system/breakpoints'
import {palette} from '../../../design-system';
import {Button} from '../../base';

const scope = 'Login'

const mobileFrame = css`
  width: 100%;
  max-width: 394px;
  margin-left: auto;
  margin-right: auto;
  `

const contentFrame = css`
  width: 100%;
  max-width: 394px;
  margin: auto;
`

export const Layout = styled(({ loading, ...props }) => <div {...props} />)`
  display: flex;

  ${({ loading }) => loading && css`
    position: relative;
    pointer-events: none;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(255, 255, 255, .5);
    }
  `}

  ${to.mobile`
    flex-direction: column;
  `}

  ${from.tablet`
    width: 100%;
    height: 100vh;
    overflow: hidden;
  `}
`

Layout.displayName = `${scope}/Layout`

export const LayoutInfoPanel = styled.div`
  color: ${({ theme }) => theme.panel.foreground};
  height: 100%;
  overflow: auto;

  ${to.mobile`
    ${mobileFrame}
    padding-top: 124px;
  `}

  ${from.tablet`
    padding-top: max(7.5vh, 20px);
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 20px;
    width: 50%;
    max-width: 630px;
    background: ${({ theme }) => `url(${theme.panel.backgroundImage}) no-repeat center bottom ${theme.panel.background}`};
    background-size: 100% auto;
    color: ${({ theme }) => theme.panel.foreground};
  `}

  ${from.desktop`
    max-width: 730px;
  `}

  ${shortFrom.tablet`
    padding-top: 60px;
    max-width: 500px;
  `}

  // These are raw because the breakpoints are too complicated
  @media screen and (min-width: ${wBreakpoints.tablet}px) and (max-height: 750px) {
    background: ${({ theme }) => theme.panel.background};
  }

  @media screen and (width: 1440px) and (height: 800px) {
    padding-top: 40px;
}
`

LayoutInfoPanel.displayName = `${scope}/Layout.InfoPanel`
Layout.InfoPanel = LayoutInfoPanel

export const LayoutInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 0;

  ${from.tablet`
    padding-top: 32px;
    padding-bottom: 180px;
  `}

  ${shortFrom.tablet`
    padding-top: 24px;
  `}

@media screen and (width: 1440px) and (height: 800px) {
  padding-top: 32px;
}
`

LayoutInfo.displayName = `${scope}/Layout.Info`
Layout.Info = LayoutInfo

export const LayoutContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${to.mobile`
    flex: 1 1 auto;
  `}

  ${from.tablet`
    flex: 1;
    height: 100vh;
  `}
`
LayoutContentPanel.displayName = `${scope}/Layout.ContentPanel`
Layout.ContentPanel = LayoutContentPanel

export const Content = styled.div`
  ${contentFrame}
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;

  ${to.mobile`
    ${mobileFrame}
    order: 1;
    padding: 40px 0;
  `}
`
Content.displayName = `${scope}/Content`

export const Footer = styled.div`
  ${contentFrame}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  font-size: 12px;
  color: ${({ theme }) => theme.footer.color};

  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ui.menu {
    font-size: 12px;
    line-height: 1.5;

    .item:not(a) {
      font-size: 14px;
    }

    a.item {
      font-weight: bold;
    }
  }

  ${to.mobile`
    ${mobileFrame}
    margin-top: 90px;
    order: 3;
  `}

  ${to.mobileXS`
    flex-direction: column;
  `}
`
Footer.displayName = `${scope}/Footer`

export const FooterCopy = styled.div`
`
FooterCopy.displayName = `${scope}/Footer.Copy`
Footer.Copy = FooterCopy

export const FooterLinks = styled.div`
  display: flex;
`
FooterLinks.displayName = `${scope}/Footer.Links`
Footer.Links = FooterLinks

export const FooterLink = styled.a`
  margin: 0 6px;
  color: ${({ theme }) => theme.footer.links.idle};
  font-weight: bold;
  &:hover {
    color: ${({ theme }) => theme.footer.links.hover};
  }
`
FooterLink.displayName = `${scope}/Footer.Link`
Footer.Link = FooterLink

export const Brand = styled.div`
  width: 100%;
  display: block;
  max-width: ${({ theme }) => theme.brand.width};
`
Brand.displayName = `${scope}/Brand`

export const BrandDesktop = styled.img.attrs(({ theme }) => ({
  src: theme.brand.logo,
  alt: theme.brand.alt,
}))`
  height: 48px;
  ${to.mobile`
    display: none;
  `}
`
BrandDesktop.displayName = `${scope}/Brand.Desktop`
Brand.Desktop = BrandDesktop

export const BrandMobile = styled.img.attrs(({ theme }) => ({
  src: theme.brand.logo,
  alt: theme.brand.alt,
}))`
  height: 48px;
  ${from.tablet`
    display: none;
  `}
`
BrandMobile.displayName = `${scope}/Brand.Mobile`
Brand.Mobile = BrandMobile

export const Slogan = styled.h1`
  position: relative;
  max-width: 485px;
  font-size: 40px;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.slogan.fontFamily};
  color: ${({ theme }) => theme.slogan.color};

  ${to.mobile`
    max-width: 400px;
    padding: 40px 0;
    font-size: 32px;
  `}

  ${to.mobileXS`
    font-size: 20px;
    padding-top: 30px;
  `}

  ${shortFrom.tablet`
    font-size: 32px;
  `}
`
Slogan.displayName = `${scope}/Slogan`

export const Welcome = styled.div`
  margin-top: 16px;
  font-size: 28px;
  line-height: 1.2;

  ${to.mobile`
    display: none;
  `}
`
Welcome.displayName = `${scope}/Welcome`

export const Title = styled.div`
  font-size: 24px;
  line-height: 1.5;
  font-family: 'EuclidCircularA-SemiBold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: ${({ theme }) => theme.title.color};
  margin-bottom: 8px;

  ${to.mobileXS`
    font-size: 20px;
  `}
`
Title.displayName = `${scope}/Title`

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.5;

  ${to.mobileXS`
    font-size: 12px;
    margin-bottom: 40px;
  `}

  > span {
    white-space: nowrap;
  }
`
Subtitle.displayName = `${scope}/Subtitle`
export const Form = styled(LoginForm)`
  margin-top: 50px;
`
LoginForm.displayName = `${scope}/Form`

export const Separator = styled.div`
  display: none;
  height: 7px;
  width: 145px;
  background-color: ${({ theme }) => theme.title.color};
  border-radius: 21px;

  ${to.mobile`
    display: block;
  `}
`
Separator.displayName = `${scope}/Form`

export const RegisterText = styled.span`
  font-size: 12px;
  color: ${palette.neutral900};
  margin-right: 16px;
`

RegisterText.displayName = `${scope}/RegisterText`

export const RegisterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${from.tablet`
    padding: 16px;
  `}

  ${to.mobile`
    order: 2;
    max-width: 394px;
    display: inline-block;
    width: 100%;
    margin: auto;
  `}
`

RegisterWrapper.displayName = `${scope}/RegisterWrapper`

export const RegisterButton = styled(Button).attrs({
  basic: true
})`
  &&& {
    color: ${({theme}) => theme.registerButton.idle} !important;
    border: 1px solid ${({theme}) => theme.registerButton.idle};
    &:hover {
      color: ${({theme}) => theme.registerButton.hover} !important;
      border: 1px solid ${({theme}) => theme.registerButton.hover} !important;
    }
  }
`

RegisterButton.displayName = `${scope}/RegisterButton`
