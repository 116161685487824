import React, {useCallback} from 'react';
import {usePlaidLink} from 'react-plaid-link';
import {PaymentDetailsContext} from "./PaymentDetailsContainer";

const PlaidMicroDeposits = (props) => {
    const {
        setBankAccountVerificationStatus,
        bankAccountVerificationStatus,
    } = PaymentDetailsContext.useContainer();

    const onSuccess = useCallback((_, data) => {
        setBankAccountVerificationStatus("Verified")
        data.metadata = {"verificationStatus": "manually_verified"}
        props.setPlaidData(data)
    }, [props, setBankAccountVerificationStatus])

    const onExit = useCallback((error, data) => {
        if(error?.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS'){
            setBankAccountVerificationStatus("Unable to verify")
        }
    }, [setBankAccountVerificationStatus]);

    const config = {
        token: props.plaidLinkToken,
        env: props.plaidEnviroment,
        onSuccess,
        onExit,
    }

    const {open, ready, loading} = usePlaidLink(config)

    if (bankAccountVerificationStatus === 'Pending verification') {
        return (<>
            <a disabled={!ready || loading}
               onClick={() => open()}
               style={{lineHeight: '21px', float:'right', color:'#43806C'}}
               className={"small bold"}
               href
            >Verify account
            </a>
        </>)
    }
    return null
}

export default PlaidMicroDeposits