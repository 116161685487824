import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from "moment";
import {toggle as toggleGetHelpModal} from "../../actions/getHelpActions";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        historicalInvoices: state.billing.historicalInvoices,
        pastDue: state.billing.pastDue,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleGetHelpModal: state => dispatch(toggleGetHelpModal(state)),
    }
};


class BillingAlert extends Component {

    renderAlert = () => {
        const {pastDue, historicalInvoices, toggleGetHelpModal} = this.props;
        const dueInvoices = historicalInvoices.filter((e) => e.paymentStatus === "Due");
        if (!!pastDue && pastDue !== "0.00") {
            const firstPastDueInvoice = historicalInvoices.find((e) => e.paymentStatus === "Past due" || e.paymentStatus === "Partially paid");

            if(firstPastDueInvoice) {
                const month = moment('' + firstPastDueInvoice.billingPeriod.month).format("MMMM");
                const year = moment('' + firstPastDueInvoice.billingPeriod.year).format("YYYY");
                return <React.Fragment>
                    <div className="billingAlert past-due">
                        {/* TODO: Contact us: open popup */}
                        <span className={"smaller"}>Your bill for {month} {year} coverage is past due. To pay, go to your <span
                            onClick={this.props.switchToOverview} className={"clickable"}><b>account summary</b></span>. Questions? <span
                            className={"clickable"}
                            onClick={() => toggleGetHelpModal(true)}><b>Contact us</b></span>.</span>
                    </div>
                </React.Fragment>
            }
        } else if (dueInvoices?.length > 0) {
            const invoice = dueInvoices[dueInvoices.length - 1];
            const month = moment('' + invoice.billingPeriod.month).format("MMMM");
            const year = moment('' + invoice.billingPeriod.year).format("YYYY");
            return <React.Fragment>
                <div className="billingAlert due">
                    <span className={"smaller"}>Your bill for {month} {year} coverage is due. To pay, please go to your <span
                        onClick={this.props.switchToOverview}
                        className={"clickable"}><b>account summary</b></span>.</span>
                </div>
                </React.Fragment>
        } else {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderAlert()}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BillingAlert));
