import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Icon} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {Button, Header} from "../../../base";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {
    CHANGE_ADDRESS_FORM_ROUTE,
    convertDateToLongFormat,
    convertDisplayDateFormatToServer,
    displayDateFormat,
    isCoveredState,
    resolveErrorMessage,
    SUCCESS_ADDRESS_CHANGE_ROUTE
} from "../../../../utils/common";
import moment from "moment";
import axios from "axios";
import ChangeAddressSteps from "./changeAddressSteps";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        planChangeDependents: state.planChange.planChangeDependents,
        changePrimaryAddress: state.planChange.changePrimaryAddress,
        newAddress: state.planChange.newAddress,
        certificate: state.initialData.certificate,
        certificateHolder: state.initialData.certificateHolder,
        qleReason: state.planChange.qleReason,
        qleDate: state.planChange.qleDate,
        benefits: state.initialData.certificate.assetCoverages,
        dependents: state.initialData.dependents
    }
};

class ConfirmAddressChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitError: null,
            submitting: false
        }
    }

    onBackHandler = () => {
        this.props.history.push(CHANGE_ADDRESS_FORM_ROUTE);
    }

    onSubmit = async () => {
        const {
            qleReason,
            qleDate,
            dependents,
            certificate,
            newAddress,
            benefits,
            planChangeDependents,
            changePrimaryAddress
        } = this.props;
        let finalDependents = dependents.map(a => ({...a}));
        finalDependents.forEach(finalDep => planChangeDependents.forEach(planDep => {
            if (planDep.memberId === finalDep.memberId) {
                if (!!changePrimaryAddress) {
                    finalDep.address = null
                } else {
                    finalDep.address = newAddress
                }
                finalDep.isQLE = true
            }
        }))

        try {
            this.setState({
                submitError: null,
                submitting: true
            })
            await axios.post("/api/member/v2/submitPlanChanges", {
                qleReason: qleReason,
                qleDate: convertDisplayDateFormatToServer(qleDate),
                benefitModerate: benefits.moderate,
                benefitSevere: benefits.severe,
                benefitCatastrophic: benefits.catastrophic,
                newAddress: !!changePrimaryAddress ? newAddress : null,
                dependents: finalDependents,
                familyTier: certificate.familyTier
            });
            this.props.history.push(SUCCESS_ADDRESS_CHANGE_ROUTE)
        } catch (e) {
            this.setState({
                    submitError: resolveErrorMessage(e, "There has been a problem submitting your plan update."),
                    submitting: false
                }
            )
        }
    }

    onCancel = () => {
        this.props.history.push("/plan")
    }

    render() {
        const {
            planChangeDependents,
            changePrimaryAddress,
            newAddress,
            certificateHolder,
            submitting,
            qleDate,
            dependents
        } = this.props;
        const button = <Button primary content="Submit" disabled={submitting} loading={submitting}
                               onClick={this.onSubmit}/>;
        const dependentNames = (!!dependents && dependents.length > 0) && <React.Fragment>{' '}
            {dependents.map((dependent, index) => <span
                key={index}><b>{`${index ? ', ' : ''} ${dependent.firstName} ${dependent.lastName}`}</b></span>)}
        </React.Fragment>


        const link = <span className={"linkText"} onClick={this.onCancel}>or Cancel</span>;
        const mNow = moment(Date.now());
        const mDate = moment(certificateHolder.birthDate)
        const primaryAge = mNow.diff(mDate, "years")
        const mQLEDate = moment(qleDate, displayDateFormat);
        const monthNextToQleDate = moment(mQLEDate).add(1, 'months');
        const firstOfmonthNextToQleDate = moment(monthNextToQleDate).startOf('month');
        const isCovered = isCoveredState(newAddress.state)

        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeAddressSteps activeStepIdx={2}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as={"h2"}>Confirm your address change</Header>
                                <p className={"neutral700Text"}>Please review carefully before submitting this change to
                                    Brella —</p>

                                {!isCovered && <CustomContainer basic className={"bkgLinen"}>
                                    <p><Icon name={"warning"}/><b>Invalid change of address</b></p>
                                    <p>Unfortunately, Brella is not available in {newAddress.stateObject.text}. Brella
                                        coverage will end
                                        on {convertDateToLongFormat(firstOfmonthNextToQleDate)} for {dependentNames}<b>{!!dependentNames ? ' and ' : ''}you</b>.
                                    </p>
                                </CustomContainer>}

                                <Divider hidden/>

                                <Grid columns={2}>
                                    <Grid.Column>
                                        <p><b>Address</b><br/>{newAddress.street}</p>
                                        <p><b>City</b><br/>{newAddress.city}</p>
                                        <p><b>State</b><br/>{newAddress.stateObject.text}</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <p><b>Appartment</b><br/>{newAddress.appartment || "--"}</p>
                                        <p><b>Zip code</b><br/>{newAddress.zipCode}</p>
                                    </Grid.Column>
                                </Grid>

                                <Divider hidden/>

                                <p><b>Address change applies to:</b></p>
                                {!!changePrimaryAddress && <CustomContainer>
                                    <b>{certificateHolder.firstName} {certificateHolder.middleName ? certificateHolder.middleName + ' ' : null}{certificateHolder.lastName}</b><br/>
                                    Age {primaryAge}
                                </CustomContainer>}

                                {(!!planChangeDependents && planChangeDependents.length > 0) && planChangeDependents.map(((dependent, i) => {
                                    const mNow = moment(Date.now());
                                    const mDate = moment(dependent.birthDate)
                                    const age = mNow.diff(mDate, "years")
                                    return <CustomContainer key={i}>
                                        <b>{dependent.firstName} {dependent.middleName ? dependent.middleName + ' ' : null}{dependent.lastName}</b><br/>
                                        {dependent.relToEmployee}, Age {age}
                                    </CustomContainer>
                                }))}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button} link={link}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ConfirmAddressChange));
