import React, {useState, useEffect, useRef} from 'react';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {VisibilityIcon} from "./visibilityIcon";

const MAX_SSN_LENGTH = 9;
const HYPHEN_REGEX = /-/g;
const SSN_FORMAT_REGEX = /^...-..-....$/;

export const SSNInput = ({
                             disabled = false,
                             note,
                             onChange,
                             required,
                             setSSNError = () => {},
                             value,
                             visibilityIconVisible = true,
                             width,
                             ...otherProps
                         }) => {
    const [rawSSN, setRawSSN] = useState("");
    const [displaySSN, setDisplaySSN] = useState("");
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState(null);
    const timeoutRef = useRef(null);
    const [lastClickedElement, setLastClickedElement] = useState(null);

    useEffect(() => {
        if (value) {
            const sanitizedValue = value.replace(HYPHEN_REGEX, "");
            setRawSSN(sanitizedValue)
            setDisplaySSN(mask(value))
        }
    }, []);

    useEffect(() => {
        if (!visible && rawSSN?.length > 0) {
            const timer = setTimeout(() => {
                setDisplaySSN(mask(format(rawSSN)))
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [rawSSN]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            setLastClickedElement(prevLastClickedElement => {
                if (prevLastClickedElement && prevLastClickedElement.id === "eye" && e.target.id !== "eye") {
                    onBlur()
                }
                return e.target
            });
        };
        if (!disabled) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [rawSSN]);

    const toggleVisibility = () => {
        setVisible(prevVisible => {
            setDisplaySSN(mask(format(rawSSN)))
            return !prevVisible
        });
    };

    const setSelectionRangeTimeout = (e, selectionStart) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            e.target.setSelectionRange(selectionStart, selectionStart);
        }, visible ? 0 : 1010);
    };

    const handleSmallerInput = ({e, value, selectionStart, sanitizedValue}) => {
        let newSSN;
        if (visible) {
            newSSN = sanitizedValue;
        } else {
            const match = sanitizedValue.match(/\d+/);
            if (match) {
                const startIndex = match.index;
                const enteredDigit = match[0];
                const endIndex = startIndex + enteredDigit.length;

                const modifiedStart = `${startIndex === 0 ? enteredDigit : ''}${rawSSN.slice(0, startIndex)}`;
                const middle = `${startIndex !== 0 ? enteredDigit : ''}`;
                const modifiedEnd = `${rawSSN.slice(rawSSN.length - (value.length - endIndex))}`;

                newSSN = `${modifiedStart}${middle}${modifiedEnd}`;
            } else {
                const idx = selectionStart > 7 ? selectionStart - 2 : selectionStart > 3 ? selectionStart - 1 : selectionStart;
                if (sanitizedValue.length === idx) {
                    newSSN = `${rawSSN.slice(0, idx)}`;
                } else {
                    newSSN = `${rawSSN.slice(0, idx)}${rawSSN.slice(idx + (rawSSN.length - sanitizedValue.length))}`;
                }

            }
        }
        setRawSSN(newSSN);
        validatePartial(newSSN);
        const display = visible ? newSSN : value;
        setDisplaySSN(format(display));
        onChange(e, newSSN);
        setSelectionRangeTimeout(e, selectionStart);
    };

    const handleLongerInput = ({e, value, selectionStart, sanitizedValue}) => {
        let newSSN;
        if (visible) {
            newSSN = sanitizedValue;
        } else {
            const match = sanitizedValue.match(/\d+/);
            const startIndex = match.index;
            const enteredDigit = match[0];
            const endIndex = startIndex + enteredDigit.length;

            const modifiedStart = `${startIndex === 0 ? enteredDigit : ''}${rawSSN.slice(0, startIndex)}`;
            const middle = `${startIndex !== 0 ? enteredDigit : ''}`;
            const modifiedEnd = `${endIndex === MAX_SSN_LENGTH ? '' : rawSSN === enteredDigit ? '' : rawSSN.slice(startIndex + enteredDigit.length - 1)}`;

            newSSN = `${modifiedStart}${middle}${modifiedEnd}`;
        }
        setRawSSN(newSSN);
        validatePartial(newSSN);
        const display = visible ? newSSN : value;
        setDisplaySSN(format(display));
        onChange(e, newSSN);
        if (selectionStart !== MAX_SSN_LENGTH) {
            const start = selectionStart === 4 || selectionStart === 7 || selectionStart === 10 ? selectionStart + 1 : selectionStart;
            setSelectionRangeTimeout(e, start);
        }
    };

    const shouldRejectChange = ({e, value, numDigits, selectionStart}) => {
        // Edition is restricted when ssn is not visible
        if (!visible) {
            // The user can only delete full SSN (partial deletes are not allowed)
            if (!value.length) return false;

            // Partial deletes are not allowed
            if (numDigits < rawSSN.length) return true;

            // The user can't add a value at the beginning if input already has a value
            if (selectionStart < value.length) return true;

            // The user can paste only complete ssn
            if (e.nativeEvent.inputType === "insertFromPaste" && numDigits !== MAX_SSN_LENGTH) return true;

            // The user can't add hyphens in the wrong places
            for (let i = 0; i < value.length; i++) {
                if (value[i] === "-" && ![3,6].includes(i)) return true;
            }
        }

        const reg = /^(?=.*\d)[•\-\d]*$/;
        if (!reg.test(value)) {
            if (!numDigits) return false;
            setSelectionRangeTimeout(e, selectionStart);
            return true;
        }

        if (numDigits === 10 && selectionStart < MAX_SSN_LENGTH) {
            setSelectionRangeTimeout(e, selectionStart - 1);
            return true;
        }
        if (numDigits > MAX_SSN_LENGTH || (selectionStart > 11 && numDigits === rawSSN.length)) {
            setSelectionRangeTimeout(e, selectionStart);
            return true;
        }
        return false;
    };

    const handleChange = (e) => {
        let {value, selectionStart} = e.target;
        const sanitizedValue = value.replace(HYPHEN_REGEX, "");
        const numDigits = sanitizedValue.length;
        if (shouldRejectChange({e, value, numDigits, selectionStart})) {
            return;
        }
        if (value.length < format(rawSSN).length) {
            handleSmallerInput({e, value, selectionStart, sanitizedValue});
        } else {
            handleLongerInput({e, value, selectionStart, sanitizedValue});
        }
    };

    const format = (v) => {
        if (SSN_FORMAT_REGEX.test(v)) {
            return v;
        }
        v = v.slice(0, 11).replace(HYPHEN_REGEX, "");
        if (v.length <= 3) {
            return v;
        }
        if (v.length > 3 && v.length <= 5) {
            return `${v.slice(0, 3)}-${v.slice(3)}`;
        }
        if (v.length > 5) {
            return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
        }
    };

    const mask = (v) => {
        return v.replace(/[0-9]/g, "•");
    };

    const setErrorState = (error, hasError) => {
        setError(error);
        setSSNError(hasError);
    }

    const validatePartial = (value) => {
        if (!value || !value.length) {
            setErrorState("", false);
            return;
        }
        const firstPart = value.substring(0, 3);
        const secondPart = value.substring(3, 5);
        if (firstPart === "000" || firstPart === "666" || Number(firstPart) >= 900 || secondPart === "00") {
            setErrorState("Invalid Social Security Number", true);
        }
    };

    const onBlur = () => {
        if (lastClickedElement && lastClickedElement.id === "eye") {
            return;
        }

        if (!rawSSN && !required) return;

        if (!rawSSN) {
            setErrorState("Required", true);
            return;
        }
        validatePartial(rawSSN);

        const thirdPart = rawSSN.substring(5);
        if (thirdPart === "0000") {
            setErrorState("Invalid Social Security Number", true);
        }

        const dummySSNs = ["078051120", "111111111", "333333333", "123456789", "219099999", "999999999"];
        if (dummySSNs.includes(rawSSN)) {
            setErrorState("Invalid Social Security Number", true);
        }

        if (rawSSN.trim().length !== MAX_SSN_LENGTH) {
            setErrorState("Invalid Social Security Number", true);
        }
    };

    return (
        <Form.Field width={width}>
            {disabled ?
                <Form.Input
                    className="disabled-input"
                    fluid
                    icon={(value && visibilityIconVisible) && <VisibilityIcon ariaLabel={"SSN"} isVisible={visible} onClick={toggleVisibility}/>}
                    readOnly
                    required={required}
                    value={value ? (visible ? format(value) : mask(format(value))) : '-'}
                    {...otherProps}
                />
                : <>
                    <Form.Input
                        fluid
                        icon={visibilityIconVisible && <VisibilityIcon ariaLabel={"SSN"} isVisible={visible} onClick={toggleVisibility}/>}
                        value={visible ? format(rawSSN) : displaySSN}
                        onChange={handleChange}
                        error={!!error}
                        onBlur={onBlur}
                        onFocus={() => setError(null)}
                        placeholder={"XXX-XX-XXXX"}
                        required={required}
                        {...otherProps}
                    />
                    {(!!note && !!error) && <div><small className="warningRedText">{error}</small></div>}
                    {(!note && !!error) && <small className="warningRedText">{error}</small>}
                </>}
            {!!note && <span className={"smaller"}><i>{note}</i></span>}
        </Form.Field>
    );
};

SSNInput.propTypes = {
    disabled: PropTypes.bool,
    note: PropTypes.string,
    onChange: PropTypes.func,
    setSSNError: PropTypes.func,
    value: PropTypes.string,
    visibilityIconVisible: PropTypes.bool,
    width: PropTypes.number,
};
