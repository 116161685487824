import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import CustomStep from "./customStep";

const localization = {
  steps: {
    step0: "Select",
    step1: "Configure",
    step2: "Verify",
    step3: "Backup",
  },
};

const localizationChangeMethod = {
  steps: {
    step0: "Configure",
    step1: "Verify",
    step2: "Backup",
  },
};

const getStepProps = (localizedStrings, activeStepIdx, steps) => {
  let stepProps = [];
  const maxLength = steps.length - 1;

  for (let index = 0; index < maxLength; index++) {
    stepProps.push({
      active: false,
      completed: false,
      disabled: true,
      label: localizedStrings.steps[`step${index}`],
    });
  }

  for (let i = 0; i < activeStepIdx; i++) {
    stepProps[i].disabled = false;
    stepProps[i].completed = true;
  }

  if (maxLength === activeStepIdx) return;

  stepProps[activeStepIdx].active = true;
  stepProps[activeStepIdx].disabled = false;

  return stepProps;
};

const getStepPropsMethodChange = (localizedStrings, activeStepIdx, steps) => {
  let stepProps = [];
  const maxLength = steps.length - 1;

  for (let index = 0; index < maxLength; index++) {
    stepProps.push({
      active: false,
      completed: false,
      disabled: true,
      label: localizedStrings.steps[`step${index}`],
    });
  }
  for (let i = 0; i < activeStepIdx ; i++) {
    stepProps[i].disabled = false;
    stepProps[i].completed = true;
  }

  if (maxLength === activeStepIdx ) return;

  stepProps[activeStepIdx ].active = true;
  stepProps[activeStepIdx ].disabled = false;

  return stepProps;
};

class Stepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepProps: [],
    };
  }

  buildSteps = () => {
    this.setState({
      stepProps: this.props.changeMethodSettings ?  getStepPropsMethodChange(localizationChangeMethod, this.props.step, this.props.steps) : getStepProps(localization, this.props.step, this.props.steps),
    });
  };

  componentDidMount() {
    this.buildSteps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.step !== prevProps.step) {
      this.buildSteps();
    }
  }

  render() {
    const { stepProps } = this.state;
    if(!stepProps) return null;
    const stepsCount = stepProps.length;
    const steps = stepProps.map((s, idx) => {
      return (
        <Grid.Column key={idx} style={{ padding: 0 }}>
          <CustomStep
            completed={!!s.completed}
            stepNumber={idx + 1}
            active={!!s.active}
            disabled={!!s.disabled}
            numberLabel={s.numberLabel}
            lastStep={stepsCount}
          />
        </Grid.Column>
      );
    });

    const stepLabels = stepProps.map((s, idx) => {
      return (
        <Grid.Column key={idx}>
          <div
            style={{ width: "130px", textAlign: "center" }}
            className={s.disabled ? "disabledStep" : ""}
          >
            <p className="smaller">{s.label}</p>
          </div>
        </Grid.Column>
      );
    });

    let stepsStyle = {
      marginLeft: "-210px",
      maxWidth: "450px",
      textAlign: "center",
      marginBottom: "1em",
    };

    return (
      <div
        className={"nonprintable"}
        style={{ marginLeft: "50%", paddingTop: "51px" }}
      >
        <div className="steps" style={stepsStyle}>
          <Grid
            style={{ marginLeft: "57px", marginRight: "0px" }}
            columns={steps.length || 4}
          >
            {steps}
          </Grid>
          <Grid
            style={{ marginLeft: "0px", marginRight: "60px", marginTop: "0px" }}
            columns={steps.length || 4}
          >
            <Grid.Row>{stepLabels}</Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Stepper;
