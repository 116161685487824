import React from "react";

import {Icon} from "semantic-ui-react";


const DocumentExamplesProTip = () => (
    <p className="neutral900Text">
        <b><Icon name={'lightbulb outline'}/> Pro tip: </b>
        <i>If your health care provider has a patient login portal, some of these documents can be found there.</i>
    </p>

);

export default DocumentExamplesProTip;