import React from 'react'
import { Header, Image, Divider } from 'semantic-ui-react';
import {CustomContainer} from '../../custom-common';

export const NoContent = ({
    title = 'You have no messages',
    details = "Have a question? Send us a note and we'll get back to you shortly."
}) => (
    <CustomContainer textAlign="center" padded={"very"}>
        <Divider hidden/>
        <Image
            centered
            style={{width: "110px"}}
            src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Claims-Flow/EmptyState_greyscale.png"}
        />
        <Header as="h4" className="neutral700Text">{title}</Header>
        <p className={"neutral700Text"}>{details}</p>
        <Divider hidden/>
    </CustomContainer>
)

NoContent.displayName = 'NoContent'