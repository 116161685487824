import React, {Component} from "react"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PlanChoice from "./planChoice";
import PlanSelect from "./planSelect";

const mapStateToProps = (state, ownProps) => {
    const {anualOpenEnrollment} = state;
    const isBrellaChoice = !!anualOpenEnrollment.generalInitialData.ratedClass.contractTerms && anualOpenEnrollment.generalInitialData.ratedClass.contractTerms.planOffering === "Brella Choice";
    return {
        ...ownProps,
        isBrellaChoice,
    }
};

class SelectPlan extends Component {
    render() {
        const {isBrellaChoice, ...rest} = this.props;
        return isBrellaChoice ? <PlanChoice {...rest}/> : <PlanSelect {...rest}/>
    }
}

export default withRouter(connect(mapStateToProps)(SelectPlan));
