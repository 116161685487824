import React from "react"
import connect from "react-redux/es/connect/connect"
import {Redirect, Route} from "react-router-dom"


const mapStateToProps = (state, ownProps) => {
    return {
        authToken: state.auth.loginInfo ? state.auth.loginInfo.authToken : null,
        ...ownProps
    }
};

const AnualOpenEnrollmentRoute = ({ component: Component, authToken, prevRoute, nextRoute, ...rest}) => (
    <Route {...rest} render={(props) => (
        authToken
            ? <Component {...props} prevRoute={prevRoute} nextRoute={nextRoute}/>
            : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
    )} />
);

export default connect(mapStateToProps)(AnualOpenEnrollmentRoute);