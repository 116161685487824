import React, {Component} from 'react';
import {Label} from "semantic-ui-react";

class CustomStep extends Component {
    render() {
        const {stepNumber, completed, disabled, lastStep} = this.props;
        return (
            <div className='step'>
                <Label circular className={disabled ? 'grey' : 'custom'}
                       emtpy={'semantic ui expects a string here, not boolean true'}/>

                {stepNumber !== lastStep ?
                    <div className='lineContainer'>
                        <div className={completed ? 'completed' : 'grey'}/>
                    </div> : null
                }
            </div>
        )
    }
}

export default CustomStep;
