import {
  MFAButton,
  MFASegment,
  MFAText
} from "../styled";
import {MFA_TYPE, MINIMUM_ATTEMPTS} from "../constants";
import {BackupCodeButton} from "./backupCodeButton";
import React, {useState} from "react";
import {sendCode} from "../../actions/authActions";
import {segToHours} from "../../utils/date";

export const PreviewEmailToVerified = ({ mfaToken, userName, mfaEmailSetUpped, handleCheckPreview, handleUseMethod }) => {

  const [hasReachedMaxCodeRequest, setHasReachedMaxCodeRequest] = useState(false)
  const [remainingLockedOutTimeWindowSeconds, setRemainingLockedOutTimeWindowSeconds] = useState(null);

  const sendCodeByEmailHandler = async () => {
    try {
      const {data} = await sendCode(userName, MFA_TYPE.EMAIL, mfaToken)
      const hasReachedMaxCode = data.remainingSendCodeAttempts === MINIMUM_ATTEMPTS
      setHasReachedMaxCodeRequest(hasReachedMaxCode)
      setRemainingLockedOutTimeWindowSeconds(data.remainingLockedOutTimeWindowSeconds)
      if (!hasReachedMaxCode) handleCheckPreview()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <MFAText className={"alignLeft"}>
        You provided {mfaEmailSetUpped} email address for two-factor authentication.
        Click Send code to receive an email containing a 6-digit code—
      </MFAText>

      <MFAButton
        mt={16}
        className="submitMethod"
        onClick={sendCodeByEmailHandler}
        disabled={hasReachedMaxCodeRequest}
      >
        Send code
      </MFAButton>

      {hasReachedMaxCodeRequest && (
        <MFASegment padded mt={12} className={"mfaSettingEmailBox mfaErrorBox mfaSettingText"}>
          <strong>You have reached the maximum number of code requests. </strong>
          Please try again in {segToHours(remainingLockedOutTimeWindowSeconds)} hours or try a different authentication method.
        </MFASegment>
      )}

      <BackupCodeButton handleUseMethod={() => handleUseMethod(MFA_TYPE.BACKUP_CODES)} />
    </>
  )
}
