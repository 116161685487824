import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Checkbox, Divider, Form, Grid, Image, Modal, Popup, Radio, Select} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {Button, Header, Link} from "../../../base";
import Footer from "../../../footer";
import {connect} from "react-redux";
import {setLeftNavigation, setPrevRoute} from "../../../../actions/navigationActions";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {ADD_OTHER_DEPENDENT_ROUTE, convertDisplayDateFormatToServer, findEmployerContribution, formatMoneyAmount, HEALTH_PLAN_DETAILS_ROUTE, INCOME_ROUTE, truncTwoDec, UPDATE_COVERAGE_ROUTE} from "../../../../utils/common";
import {
    setBrellaSelectTierSelected,
    setCatastrophicCoverage,
    setCostPerOption,
    setCoverageOption,
    setFetchPlanRecommendation,
    setModerateCoverage,
    setPlanContribAndCost,
    setPlanCoverageSelectedAndRecommendedSelect,
    setSevereCoverage
} from "../../../../actions/planChangeActions";
import moment from "moment";
import axios from 'axios';
import ChangeSteps from "../changeSteps";
import {ClickableText, CustomModalHeader} from "../../../custom-common";
import STRATEGIC_PARTNER from "../../../../constants/strategicPartners";

const mapStateToProps = (state, ownProps) => {
    const {contract, certificate, user, benefits, presets} = state.initialData;
    const {coverageOption, costPerOption, costPerOptions, moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected, maritalStatus, totalDependents, familyTierOptions, selectedTierBrellaSelect} = state.planChange
    let additionalChronic = 0;
    let additionalMental = 0;
    if (!!contract && !!contract.riderSelection && contract.riderSelection.length > 0) {
        contract.riderSelection.forEach((ci) => {
            if (ci.selected) {
                if (ci.name === "chronic") {
                    additionalChronic = ci.coverage;
                } else if (ci.name === "mental") {
                    additionalMental = ci.coverage;
                }
            }
        });
    }
    const includesDomesticPartnerships = !!state.initialData.eligibility.domesticPartnership;
    return {
        ...ownProps,
        additionalChronic,
        additionalMental,
        includesDomesticPartnerships,
        familyTierOptions,
        totalDependents,
        coverageOption,
        costPerOption,
        costPerOptions,
        moderateCoverageSelected,
        severeCoverageSelected,
        catastrophicCoverageSelected,
        maritalStatus,
        planChange: state.planChange,
        initialData: state.initialData,
        selectedTierBrellaSelect,
        certificate,
        user,
        prevRoute: state.navigation.prevRoute,
        benefits,
        presets
    }
};

class PlanSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepProps: null,
            loading: false,
            loadingPersonalize: false,
            submitPlanLoading: false,
            skipRecommendation: false,
            displayInformationAboutCategoriesModal: false,
            displaySeeWhyWeChoseThisAmountsModal: false,
            recommendedPlan: "",
            tierSelected: {
                value: false,
                enhanced: false,
                premier: false,
            },
            userModifiedData: false,
            currentCoverage: {
                value: false,
                enhanced: false,
                premier: false,
            }
        }
    }

    async componentDidMount() {
        this.props.dispatch(setLeftNavigation(false))
        let skipRecommendation = new URLSearchParams(this.props.location.search).get("skipRecommendation")
        skipRecommendation = skipRecommendation !== null;
        if(this.props.planChange.fetchPlanRecommendation) {
            if (skipRecommendation) {
                const {initialData, certificate} = this.props;

                const costPerOption = initialData.payrollFrequency === "Monthly" ? 3 : 1
                this.props.dispatch(setCostPerOption(costPerOption));

                const deletes = this.alterDependentInfoBasedOnCoverage(certificate.familyTier);
                this.props.dispatch(setCoverageOption(certificate.familyTier, deletes.deleteChildren, deletes.deleteSpouse));

                await this.personalizePlan(certificate.familyTier, costPerOption).then();
                const plan = this.getCurrentPlan();
                await this.setState({
                    tierSelected: {value: plan.value, premier: plan.premier, enhanced: plan.enhanced},
                    skipRecommendation: skipRecommendation,
                    loading: false,
                    currentCoverage: {value: plan.value, premier: plan.premier, enhanced: plan.enhanced}
                });
                this.props.dispatch(setBrellaSelectTierSelected(plan.value, plan.premier, plan.enhanced));
            } else {
                const plan = this.getCurrentPlan();
                this.setState({
                    skipRecommendation: false,
                    currentCoverage: {value: plan.value, premier: plan.premier, enhanced: plan.enhanced}
                })
                await this.submitInitialPlanDetails();
                await this.personalizePlan();
            }
        } else {
            const {selectedTierBrellaSelect: {value, premier, enhanced}} = this.props;
            const ts = {value, premier, enhanced}
            const plan = this.getCurrentPlan();
            await this.setState({
                tierSelected: {...ts},
                skipRecommendation: skipRecommendation,
                currentCoverage: {value: plan.value, premier: plan.premier, enhanced: plan.enhanced}
            });
            //await this.submitInitialPlanDetails(true);
            //await this.personalizePlan(null, null,true);
        }

        let prevRoute;
        if(skipRecommendation){
            prevRoute = UPDATE_COVERAGE_ROUTE;
        } else {
            if (!!this.props.planChange.leftoverMoney) {
                prevRoute = INCOME_ROUTE;
            } else {
                prevRoute = HEALTH_PLAN_DETAILS_ROUTE;
            }
        }
        this.props.dispatch(setPrevRoute(prevRoute))
    }

    getCurrentPlan = () => {
        const {benefits, presets} = this.props;
        const isValue = benefits.moderate === presets.value.moderate && benefits.severe === presets.value.severe && benefits.catastrophic === presets.value.catastrophic;
        const isEnhanced = benefits.moderate === presets.enhanced.moderate && benefits.severe === presets.enhanced.severe && benefits.catastrophic === presets.enhanced.catastrophic;
        const isPremier = benefits.moderate === presets.premier.moderate && benefits.severe === presets.premier.severe && benefits.catastrophic === presets.premier.catastrophic;
        return (
            {
                value: isValue,
                enhanced: isEnhanced,
                premier: isPremier
            }
        )
    }

    personalizePlan = async (forcedCoverageOption, forcedCostPerOpt, useSelectedPlanInsteadOfRecommended) => {
        this.setState({loadingPersonalize: true, recommendationEmailScheduledSuccessfully: null});
        try {
            const {additionalChronic, additionalMental, coverageOption, costPerOption, costPerOptions, moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected, initialData} = this.props;
            let selectedCostPerOpt;
            if (!!forcedCostPerOpt) {
                selectedCostPerOpt = forcedCostPerOpt;
            } else {
                selectedCostPerOpt = costPerOptions.filter(cpo => cpo.id === costPerOption)[0].value;
            }
            const payrollFreq = selectedCostPerOpt === 'Month' ? "Month" : initialData.payrollFrequency;
            let coverageOpt = coverageOption;
            if (!!forcedCoverageOption) {
                coverageOpt = forcedCoverageOption;
            }

            let result = await axios.post("/api/member/v2/personalizePlan", {
                familyTierValue: coverageOpt,
                moderateBenefit: !!moderateCoverageSelected ? moderateCoverageSelected : 0,
                severeBenefit: !!severeCoverageSelected ? severeCoverageSelected : 0,
                catastrophicBenefit: !!catastrophicCoverageSelected ? catastrophicCoverageSelected : 0,
                quoteInfo: initialData.quoteInfo,
                mentalBenefit: additionalMental,
                chronicBenefit: additionalChronic,
                employerContributions: initialData.employerContributions,
                presets: initialData.presets,
                payrollFrequencySelected: payrollFreq,
                ageAtPolicyEffectiveDate: initialData.certificate.ageAtPolicyEffectiveDate,
                ratedClassRates: initialData.ratedClass.ratedClassRates,
            });
            if (!!result && !!result.data) {
                const data = result.data;
                this.props.dispatch(setPlanCoverageSelectedAndRecommendedSelect(data.costValue, data.costEnhanced, data.costPremier,
                    data.contribValue, data.contribEnhanced, data.contribPremier));

                const {recommendedPlan} = this.state;
                let plan = recommendedPlan;
                if(!!useSelectedPlanInsteadOfRecommended){
                    const {tierSelected} = this.state;
                    plan = "value"
                    if(tierSelected.enhanced){
                        plan = "enhanced"
                    } else if(tierSelected.premier){
                        plan = "premier"
                    }
                }

                let cost = data.costValue;
                let contrib = data.contribValue;
                let presets = initialData.presets;
                let selectedPreset = presets.value;
                if(plan === 'enhanced'){
                    cost = data.costEnhanced;
                    contrib = data.contribEnhanced;
                    selectedPreset = presets.enhanced;
                } else if (plan === 'premier'){
                    cost = data.costPremier;
                    contrib = data.contribPremier;
                    selectedPreset = presets.premier;
                }
                const ec = contrib ? contrib : 0.0;
                this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)));
                const d = selectedPreset;
                this.props.dispatch(setModerateCoverage(d.moderate));
                this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                this.props.dispatch(setSevereCoverage(d.severe));
            }
            this.setState({loadingPersonalize: false});
        } catch (e) {
            console.log(e);
            this.setState({loadingPersonalize: false})
        }
    }

    submitInitialPlanDetails = async (dontChangeUserElections) => {
        this.setState({loading: true});
        try {
            const {user, planChange, initialData, familyTierOptions, costPerOptions} = this.props;
            if(planChange.highDeductibleInfo === null || planChange.leftoverMoney === null){
                this.setState({loading: false});
                return;
            }
            let result = await axios.post("/api/member/v2/submitPlanDetails", {
                planOffering: initialData.contract.planOffering,
                ageAtPolicyEffectiveDate: initialData.certificate.ageAtPolicyEffectiveDate,
                birthDate: user.birthDate,
                presets: initialData.presets,
                quoteInfo: initialData.quoteInfo,
                maritalStatusId: planChange.maritalStatus,
                dependentsId: planChange.dependentsId,
                copayId: planChange.typicallyPayInfo,
                highDeductibleInfoId: planChange.highDeductibleInfo,
                leftoverMoneyId: planChange.leftoverMoney,
                ratedClassRates: initialData.ratedClass.ratedClassRates,
            });
            if (!!result && !!result.data && !!result.data.select) {
                const data = result.data;
                const dataSelect = result.data.select;
                const recommendedPreset = dataSelect.selectPreset;
                const recommendedOption = data.familyTierValue;
                const deletes = this.alterDependentInfoBasedOnCoverage(recommendedOption);
                if (!dontChangeUserElections) {
                    this.props.dispatch(setCoverageOption(recommendedOption, deletes.deleteChildren, deletes.deleteSpouse));
                }

                const isMonthly = initialData.payrollFrequencySelected === initialData.payrollFrequencyMonthly;
                let costPerOption = costPerOptions.filter(cpo => cpo.value === 'Paycheck')[0].id
                if (isMonthly) {
                    costPerOption = costPerOptions.filter(cpo => cpo.value === 'Month')[0].id
                }
                if (!dontChangeUserElections) {
                    this.props.dispatch(setCostPerOption(costPerOption));
                }

                const ec = findEmployerContribution(recommendedOption, familyTierOptions, initialData.employerContributions);
                if (!!recommendedPreset) {
                    const ts = this.state.tierSelected;
                    const recommendedPresetLC = recommendedPreset.toLowerCase();
                    if(!dontChangeUserElections){
                        ts[recommendedPresetLC] = true;
                    }
                    this.setState({
                        recommendedPlan: recommendedPresetLC,
                        tierSelected: {...ts},
                        loading: false,
                    });

                    const {planChange: {costValue, costEnhanced, costPremier}} = this.props;
                    let cost = costValue;
                    if(recommendedPresetLC === 'enhanced'){
                        cost = costEnhanced;
                    } else if (recommendedPresetLC === 'premier'){
                        cost = costPremier;
                    }

                    let presets = initialData.presets;
                    if (!presets) {
                        presets = {
                            value: {severe: 0, moderate: 0, catastrophic: 0},
                            premier: {severe: 0, moderate: 0, catastrophic: 0},
                            enhanced: {severe: 0, moderate: 0, catastrophic: 0}
                        }
                    }
                    const d = presets[recommendedPreset.toLowerCase()];
                    this.props.dispatch(setModerateCoverage(d.moderate));
                    this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                    this.props.dispatch(setSevereCoverage(d.severe));
                    this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)))
                } else {
                    this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), 0))
                    this.setState({loading: false});
                }
            } else {
                this.setState({loading: false});
            }
        } catch (e) {
            console.error(e);
            this.setState({loading: false})
        }
    }

    selectPlan = (title, checked) => {
        const {tierSelected} = this.state;
        const ts = tierSelected;
        if (checked) {
            ts.value = false;
            ts.premier = false;
            ts.enhanced = false;
        }
        ts[title.toLowerCase()] = checked
        this.setState({
            tierSelected: {...ts},
            userModifiedData: true,
        });
        this.props.dispatch(setBrellaSelectTierSelected(ts.value, ts.premier, ts.enhanced));
        this.personalizePlan(null, null, true).then();
    }

    getRecommendedPlanLabelForDescription = () => {
        const {recommendedPlan} = this.state;
        if (recommendedPlan.toLowerCase() === 'value') {
            return "Value Plan";
        }
        if (recommendedPlan.toLowerCase() === 'enhanced') {
            return "Enhanced Plan";
        }
        if (recommendedPlan.toLowerCase() === 'premier') {
            return "Premier Plan";
        }
    }

    renderPayFrequencySelect = () => {
        const {costPerOptions, initialData} = this.props;
        const paycheck = costPerOptions.filter(cpo => cpo.value === 'Paycheck')[0];
        const monthly = costPerOptions.filter(cpo => cpo.value === 'Month')[0];
        const opts = [];
        let result = null;
        if (initialData.payrollFrequency !== "Monthly") {
            opts.push({key: paycheck.id, value: paycheck.id, text: "Cost per paycheck"});
            opts.push({key: monthly.id, value: monthly.id, text: "Cost per month"});
            result = <Select options={opts} style={{minWidth: 0}} fluid
                             value={this.props.costPerOption}
                             onChange={async (a, {value}) => {
                                 await this.setState({userModifiedData: true});
                                 const newValue = value === 3 ? 3 : 1
                                 await this.props.dispatch(setCostPerOption(newValue));
                                 await this.personalizePlan(null, null, true);
                             }}/>
        }
        return result;
    }

    getFilteredCoverOptions = () => {
        const {familyTierOptions, includesDomesticPartnerships, totalDependents, maritalStatus} = this.props;

        const coverOpts = familyTierOptions.map(c => {
            let text = c.text;
            if (c.text === "Myself") {
                text = "Myself";
            } else if (c.text === "My spouse and me") {
                if (!!includesDomesticPartnerships) {
                    text = <React.Fragment>My <u>spouse</u> and me</React.Fragment>;
                } else {
                    text = "My spouse and me";
                }
            } else if (c.text === "My child(ren) and me") {
                text = <React.Fragment>My <u>child(ren)</u> and me</React.Fragment>;
            } else if (c.text === "My family and me") {
                text = "My family and me";
            }
            return {key: c.key, text: <label>{text}</label>, value: c.value}
        });

        const married = maritalStatus === 1
        const hasKids = totalDependents.filter(dependent => dependent.relToEmployee === "Child").length > 0

        let filteredCoverOptions;
        if (!!married) {
            if (!hasKids) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('child'))
            } else {
                filteredCoverOptions = coverOpts;
            }
        } else {
            if (hasKids) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse'))
            } else {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse') && !opts.value.includes('child'))
            }
        }
        return filteredCoverOptions;
    }

    onBackHandler = () => {
        this.props.history.push(this.props.prevRoute);
    }

    onNext = () => {
        const skipRecommendation = new URLSearchParams(this.props.location.search).get("skipRecommendation")
        const skip = skipRecommendation !== null
        const route = skip ? "/change/update/select?skipRecommendation" : "/change/update/select"
            this.props.dispatch(setPrevRoute(route))
            this.props.history.push("/change/update/confirm")
    }

    renderSeeWhyWeChoseThisAmountsModal = () => {
        const {displaySeeWhyWeChoseThisAmountsModal} = this.state;
        const onModalClose = () => this.setState({displaySeeWhyWeChoseThisAmountsModal: false});
        return <Modal open={displaySeeWhyWeChoseThisAmountsModal} size={'tiny'} onClose={onModalClose}>
            <CustomModalHeader title={"Recommendation overview"} onClose={onModalClose} className="customModalHeader" />
            <Modal.Content style={{maxHeight:'513px', overflowY: 'auto'}} className={"small"}>
                <p>Your needs are unique to you. That’s why we made {STRATEGIC_PARTNER.LABEL}’s plans customizable—so you can choose the payout amounts that are right for you. We assess a number of different factors to make a personalized recommendation. Here’s how each factor comes into play.</p>
                <p><b>Age</b><br/>
                    The types and severity of health issues we face changes over time. As we get older, the risk of serious and dangerous medical issues increases, so we generally recommend increasing payout amounts for the Severe and Catastrophic benefit categories with age. On the other hand, when we are younger and tend to be more active, {STRATEGIC_PARTNER.LABEL} recommends higher payout amounts in the Moderate benefit category.
                </p>

                <p><b>Family status</b><br/>
                    Your family makeup helps us understand who is eligible for coverage and make some assumptions about their health risks. Given their zest for life, children generally tend to have less serious but more frequent injuries and illnesses, so if you have children we recommend higher Moderate payout amounts.
                </p>

                <p><b>Medical expenses</b><br/>
                    How you feel about the affordability of your health plan’s deductible and your share of costs give us a sense of your financial exposure in the event of an unexpected health issue. If you have concerns about your deductible and / or health cost responsibilities we recommend higher payouts across all three categories.
                </p>

                <p><b>Your budget</b><br/>
                    Your discretionary income helps us recommend a plan with premiums that work for your budget. If you don’t have much left over at the end of the month, we might recommend a {STRATEGIC_PARTNER.LABEL} plan with a more affordable premium. As your disposable income increases, we may recommend more coverage in line with other factors like your age and family status.
                </p>
            </Modal.Content>
        </Modal>
    }

    alterDependentInfoBasedOnCoverage = (coverageOptionId) => {
        const {familyTierOptions} = this.props;
        const option = familyTierOptions.filter((c)=>c.value === coverageOptionId)[0];
        let deleteChildren;
        let deleteSpouse;
        switch(option.value){
            case 'employee':
                deleteChildren = true;
                deleteSpouse = true;
                break;
            case 'spouse':
                deleteChildren = true;
                deleteSpouse = false;
                break;
            case 'child':
                deleteChildren = false;
                deleteSpouse = true;
                break;
            default:
                deleteChildren = false;
                deleteSpouse = false;
        }
        return {deleteChildren, deleteSpouse};
    }

    renderRecommendationTier = (title, moderateVal, severeVal, catastrophicVal, employerContr, cost, dispatchSelection) => {
        const {costPerOptions, costPerOption} = this.props;
        const {tierSelected, recommendedPlan, currentCoverage} = this.state;
        const isRecommended = title.toLowerCase() === recommendedPlan.toLowerCase();
        const isCurrent = !!currentCoverage[title.toLowerCase()];
        let isSelectedTier = tierSelected[title.toLowerCase()]
        const noSelectedTier = !tierSelected || !(tierSelected.enhanced || tierSelected.premier || tierSelected.value);
        if (noSelectedTier) {
            isSelectedTier = title.toLowerCase() === recommendedPlan.toLowerCase();
        }

        const titleBkgColor = isSelectedTier ? '#437F6C' : '#E7F6F3';
        const titleColor = isSelectedTier ? 'white' : '#244F43';
        const borderColor = isSelectedTier ? '#437F6C' : '#E0E0E0';
        const boxShadow = isSelectedTier ? '0px 4px 4px rgba(0, 0, 0, 0.15)' : 'none';
        let costPerOptionLabel = (!!costPerOptions && costPerOptions.length > 0 && !!costPerOption && costPerOptions.filter(cpo => cpo.id === costPerOption)[0].value.toLowerCase()) || '';

        return (
            <Grid.Column>
                <Grid columns={1} style={{margin: 0}}>
                    <Grid.Column className={"planSelectTable"} style={{borderColor: borderColor, boxShadow: boxShadow}}>
                        <div style={{backgroundColor: titleBkgColor, padding: '0.8em', textAlign: 'center', height: '56px'}}>
                            <Checkbox radio style={{display: 'block', float: 'left', marginTop: '7px'}}
                                      checked={isSelectedTier}
                                      onClick={(e, {checked}) => {
                                          this.selectPlan(title, checked);
                                          dispatchSelection()
                                      }}/>
                            <span style={{marginLeft: '-16px'}}>
                                <Header as={'h4'} style={{color: titleColor, display:'inline'}}>{title}</Header>
                            </span>
                        </div>
                        <div style={{paddingLeft: '1em', paddingRight: '1em'}}>
                            <div className={"smaller"} style={{marginTop: '1.5em', marginBottom: '0.6em'}}>
                                <p><b>Payouts</b></p>
                            </div>

                            <Grid>
                                <Grid.Row columns={3} className={'nonprintable'}>
                                    <Grid.Column>
                                        <div className={"smaller"}>Moderate</div>
                                        <b>${formatMoneyAmount(moderateVal)}</b>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className={"smaller"}>Severe</div>
                                        <b>${formatMoneyAmount(severeVal)}</b>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className={"smaller"}>Catastrophic</div>
                                        <b>${formatMoneyAmount(catastrophicVal)}</b>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider hidden/>

                            {!!employerContr && employerContr > 0 &&
                            <div style={{marginBottom: '0.6em'}}>
                                <div className={"smaller"}>Employer contribution</div>
                                <b>${!!employerContr ? truncTwoDec(employerContr) : employerContr}</b>/<small>{costPerOptionLabel}</small>
                            </div>
                            }

                            <Divider/>
                            <div style={{paddingLeft: '1em', paddingRight: '1em', paddingBottom: '1em'}}>
                                <Grid textAlign={'center'}>
                                    <Grid.Column>
                                        <Header as={"h3"} style={{margin: 0}}>${!!cost ? truncTwoDec(cost) : cost}</Header>
                                        <span className={"small"}>Your cost per{' '}{costPerOptionLabel}</span><br/>
                                        {(!!isRecommended) ? <b className={"small"} style={{color: STRATEGIC_PARTNER.PALETTE.SECONDARY}}>-Recommended-</b>
                                         : (!!isCurrent) ? <b className={"neutral600Text small"}>Current coverage</b>
                                        : <div>&nbsp;</div>
                                        }
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        );
    }

    render() {
        const {loading, skipRecommendation} = this.state;
        const {costPerOptions, planChange} = this.props;
        const button = <Button primary content="Next step" onClick={this.onNext}/>;
        const {
            initialData, additionalChronic, additionalMental,
            planChange: {costValue, costEnhanced, costPremier, contribValue, contribEnhanced, contribPremier},
            coverageOption, familyTierOptions, user, includesDomesticPartnerships,
        } = this.props;

        let presets = initialData.presets;
        if (!presets) {
            presets = {
                value: {severe: 0, moderate: 0, catastrophic: 0},
                premier: {severe: 0, moderate: 0, catastrophic: 0},
                enhanced: {severe: 0, moderate: 0, catastrophic: 0}
            }
        }

        let descriptionGreen = <ClickableText onClick={() => this.setState({displaySeeWhyWeChoseThisAmountsModal: true})}>
            See why we recommended this plan.
        </ClickableText>;
        let description = <span>
            Based on the information you provided, we recommend the Enhanced plan.
            <span><b>{' '}{this.getRecommendedPlanLabelForDescription()}.{' '}</b></span>
            <span>{' '}{descriptionGreen}.{' '}</span>You can choose or adjust your plan and who’s covered below—
        </span>
        let title = "Our recommendation for you";
        if (skipRecommendation) {
            title = "Select your Brella benefits";
            descriptionGreen = <Link to={HEALTH_PLAN_DETAILS_ROUTE}><b>personalized recommendation</b></Link>;
            description = <React.Fragment>Need some help? Answer 3 questions to get a{' '}<span
                className={"secondaryBlueText"}>{descriptionGreen}{'\u2192'}</span></React.Fragment>
        }

        const filteredCoverOptions = this.getFilteredCoverOptions();

        let displayAgeReduction = false;
        const {ageReduction, ageReductionAge, ageReductionPercentage} = initialData.certificate.ratedClass.contractTerms;
        let selectedCoverage = {};
        familyTierOptions.forEach((c) => {
            if (c.id === coverageOption) {
                selectedCoverage = c;
            }
        })
        if (ageReduction && selectedCoverage.value === "employee") {
            const age = moment().diff(convertDisplayDateFormatToServer(user.birthDate), 'years');
            if (age >= ageReductionAge) {
                displayAgeReduction = true;
            }
        }

        const hasAddedDependentsOutsideQle = planChange.planChangeDependents.filter(dependent => dependent.isQle === false).length > 0;

        if (loading) {
            return <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                    <div className="member-main-container"
                         style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                        <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                            <Grid.Column width={8} className={"pageContent"} textAlign={"center"}>
                                <Image centered style={{width: '150px'}}
                                       src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Loading.png"}/>
                                <Header as={'h4'}>Just a moment...</Header>
                                <p>We're personalizing your plan</p>
                            </Grid.Column>
                        </Grid>
                </div>
            </React.Fragment>;
        } else {
            return (
                <React.Fragment>
                    <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>

                    {this.renderSeeWhyWeChoseThisAmountsModal()}
                    <div className="member-main-container" style={{background: 'white'}}>
                        <ChangeSteps activeStepIdx={2}/>
                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={12} className={"pageContent"}>
                            <Grid stackable container>
                                <Grid.Row columns={1} centered>
                                    <Grid.Column textAlign={'left'} width={12}>
                                        <Header as='h2'>{title}</Header>
                                        <p>{description}</p>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={1}>
                                    {!!displayAgeReduction && <React.Fragment>
                                        <Divider hidden/>
                                        <CustomContainer basic className={"bkgLinen small"}>
                                            <b>IMPORTANT</b>: Based on your age, you will receive {ageReductionPercentage}% of any benefit you select here.
                                        </CustomContainer>
                                        <Divider hidden/>
                                    </React.Fragment>}
                                </Grid.Row>

                                <Grid.Row style={{marginBottom: 0, paddingBottom: 0}}>
                                    <Grid.Column width={8}>
                                        <Header as={'h4'}>I want to cover</Header>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{marginTop: 0, paddingTop: 0}}>
                                    <Grid.Column width={8}>
                                        <Form>
                                            <Form.Field>
                                                {filteredCoverOptions.map((opt, idx) => {
                                                    let content = <Radio key={idx} label={opt.text} name='radioGroup'
                                                                         style={{marginRight: '0.4em'}}
                                                                         checked={coverageOption === opt.value}
                                                                         value={opt.value}
                                                                         onClick={async (e, {value}) => {
                                                                             await this.setState({userModifiedData: true});
                                                                             const deletes = this.alterDependentInfoBasedOnCoverage(value);
                                                                             await this.props.dispatch(setCoverageOption(value, deletes.deleteChildren, deletes.deleteSpouse));
                                                                             await this.personalizePlan(null, null, true);
                                                                             this.props.dispatch(setFetchPlanRecommendation(false));
                                                                         }}/>
                                                    if (opt.value === "child") {
                                                        content = <Popup key={idx} content={
                                                            <span>Children are eligible up to age {initialData.contract.childMaxEligibilityAge}.</span>}
                                                                         style={{fontSize: '12px'}}
                                                                         trigger={content}/>
                                                    } else if (opt.value === "spouse" && !!includesDomesticPartnerships) {
                                                        content = <Popup key={idx} content={
                                                            <span>Includes domestic partnerships and civil unions.</span>}
                                                                         style={{fontSize: '12px'}}
                                                                         trigger={content}/>
                                                    }
                                                    return content;
                                                })}
                                            </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Grid.Row>
                                            {(!!costPerOptions && costPerOptions.length > 0) && this.renderPayFrequencySelect()}
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{padding: 0}}>
                                    <Grid.Column>
                                        <div style={{fontSize: "14px"}}>
                                            Don't see a coverage tier?
                                            {hasAddedDependentsOutsideQle ? <p>Go back and{' '} <Link style={{color: '#437F6C'}}
                                                                                                         to={ADD_OTHER_DEPENDENT_ROUTE}>edit your family details.</Link></p> :
                                                <p>You'll be able to add family members outside of this QLE at the end of this flow.</p>
                                            }
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={3}>

                                    {this.renderRecommendationTier( "Value",
                                        presets.value.moderate, presets.value.severe, presets.value.catastrophic, contribValue, costValue, () => {
                                            const d = presets.value;
                                            this.props.dispatch(setModerateCoverage(d.moderate));
                                            this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                                            this.props.dispatch(setSevereCoverage(d.severe));
                                            this.props.dispatch(setPlanContribAndCost(truncTwoDec(contribValue), truncTwoDec(costValue)));
                                            this.props.dispatch(setFetchPlanRecommendation(false));
                                        })}

                                    {this.renderRecommendationTier( "Enhanced",
                                        presets.enhanced.moderate, presets.enhanced.severe, presets.enhanced.catastrophic, contribEnhanced, costEnhanced, () => {
                                            const d = presets.enhanced;
                                            this.props.dispatch(setModerateCoverage(d.moderate));
                                            this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                                            this.props.dispatch(setSevereCoverage(d.severe));
                                            this.props.dispatch(setPlanContribAndCost(truncTwoDec(contribEnhanced), truncTwoDec(costEnhanced)));
                                            this.props.dispatch(setFetchPlanRecommendation(false));
                                        })}

                                    {this.renderRecommendationTier( "Premier",
                                        presets.premier.moderate, presets.premier.severe, presets.premier.catastrophic, contribPremier, costPremier, () => {
                                            const d = presets.premier;
                                            this.props.dispatch(setModerateCoverage(d.moderate));
                                            this.props.dispatch(setCatastrophicCoverage(d.catastrophic));
                                            this.props.dispatch(setSevereCoverage(d.severe));
                                            this.props.dispatch(setPlanContribAndCost(truncTwoDec(contribPremier), truncTwoDec(costPremier)));
                                            this.props.dispatch(setFetchPlanRecommendation(false));
                                        })}

                                </Grid.Row>

                                {(!!additionalChronic || !!additionalMental) && <React.Fragment>
                                    <Grid.Row columns={1}>
                                        <Grid.Column textAlign={'left'}>
                                            <Header as={'h3'}>Optional riders</Header>
                                            <p>Your plan also covers these conditions—the payout for these conditions is listed.</p>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={!!additionalChronic && !!additionalMental ? 2 : 1} stretched>
                                        {!!additionalChronic &&
                                        <Grid.Column textAlign={'left'}>
                                            <div className={'bkgLightGray'} style={{padding: '2em'}}>
                                                <div style={{marginBottom: '0.3em'}}>
                                                        <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                            <Header as={'h4'} style={{display: 'inline'}}>Chronic{' '}</Header>
                                                        </span>
                                                    ${additionalChronic}
                                                </div>
                                                <p>Receive coverage for over <b>4,100</b> additional chronic conditions spanning asthma, diabetes, epilepsy, and many more.
                                                </p>
                                            </div>
                                        </Grid.Column>
                                        }
                                        {!!additionalMental &&
                                        <Grid.Column style={{margin: 0}} textAlign={'left'}>
                                            <div className={'bkgLightGray'} style={{padding: '2em'}}>
                                                <div style={{marginBottom: '0.3em'}}>
                                                        <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                            <Header as={'h4'} style={{display: 'inline'}}>Mental{' '}</Header>
                                                        </span>
                                                    ${additionalMental}
                                                </div>
                                                <p>Additional coverage for <b>600</b> different mental health disorders like anxiety, bipolar, and depression.</p>
                                            </div>
                                        </Grid.Column>}
                                    </Grid.Row>
                                </React.Fragment>}
                            </Grid>
                        </Grid.Column>
                    </Grid>
                        <Footer button={button} showTopFooter showCenterData disabled={!this.props.coverageOption || !this.props.costPerOption}/>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default connect(mapStateToProps)(withRouter(PlanSelect));
