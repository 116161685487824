import React, {Component} from 'react'
import {Button, Card, Divider, Dropdown, Grid, Header as SHeader, Icon, Image, Label, Menu, Message, Segment} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {withRouter} from "react-router-dom";
import {cleanupNewClaim, setClaimant} from "../../actions/newClaimActions";
import {connect} from "react-redux";
import moment from "moment";
import {setLeftNavigation} from "../../actions/navigationActions";
import {convertServerDateFormatToDisplay, getClaimStatusLabel, isValidClaimStatus, memberName} from "../../utils/common";
import {buildFullName} from '../../utils/utils';
import CoveredModal from "../coveredModal";
import Footer from "../footer";
import Alert from "../alert";
import {ClaimPayment} from './claimPayment';
import BillingAlert from "../billing/billingAlert";
import FailedPaymentAlert from "../billing/failedPaymentAlert";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {Header} from "../base";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        dependents: state.initialData.dependents,
        userIsCertificateHolder: state.initialData.userIsCertificateHolder,
        certificateHolder: state.initialData.certificateHolder,
        user: state.initialData.user,
        claims: state.claims.claims,
        NIGOClaims: state.claims.NIGOClaims,
        lastPaymentFailed: state.billing.lastPaymentFailed
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLeftNavigation: state => dispatch(setLeftNavigation(state)),
        cleanupNewClaim: () => dispatch(cleanupNewClaim()),
        setClaimant: claimant => dispatch(setClaimant(claimant)),
    }
};

class Claims extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayCoveredModal: false,
            activeItem: "open",
            filteredClaims: []
        }
    }

    componentDidMount() {
        const {setLeftNavigation, cleanupNewClaim} = this.props

        setLeftNavigation(true)
        cleanupNewClaim()
        if (this.props.userIsCertificateHolder) {
            this.setState({
                filteredClaims: this.claims.filter(claim => claim.status !== "Approved" && claim.status !== "Denied")
            })
        } else {
            this.setState({
                filteredClaims: this.claims.filter(
                    claim => claim.status !== "Approved" && claim.status !== "Denied" &&
                             this.props.user.memberId === claim.claimant.memberId
                )
            })
        }
    }

    handleChange = (claimant) => {
        const {setClaimant} = this.props

        setClaimant(claimant)
        this.props.history.push("/claims/new")
    }

    viewClaimDetail = (claimId) => {
        this.props.history.push({pathname: "/claims/details", state: {claimId: claimId}})
    }

    doDisplayCoveredModal = (status) => {
        let {displayTipsModal} = this.state;
        if (status) {
            displayTipsModal = false;
        }
        this.setState({
            displayTipsModal,
            displayCoveredModal: status,
        });
    }

    handleItemClick = (e, {name}) => {
        let filteredClaims = [];
        switch (name) {
            case "closed":
                filteredClaims = this.claims.filter(claim => claim.status === "Approved" || claim.status === "Denied")
                break
            case "open":
                filteredClaims = this.claims.filter(claim => claim.status !== "Approved" && claim.status !== "Denied")
                break
            default:
                return
        }
        this.setState({
            filteredClaims: filteredClaims,
            activeItem: name
        })
    }

    get claims() {
        const {claims} = this.props

        return (claims && claims.filter(c => isValidClaimStatus(c.status))) || []
    }

    getClaimPayments = (claim) => {
        if (!claim.paymentApplications || !claim.paymentApplications.length) {
            return [{
                method: claim.selectedPayoutMethod.method,
                status: 'Pending',
                amount: 'Pending',
                issuedDate: null
            }]
        }

        return claim.paymentApplications.map(({payment: {type: method, status, amount, processedDate: issuedDate}}) => ({
            method,
            status,
            amount,
            issuedDate
        }))
    }

    render() {
        const {error, displayCoveredModal, activeItem, filteredClaims} = this.state;
        const {dependents, certificateHolder, NIGOClaims, lastPaymentFailed} = this.props;
        const claims = this.claims
        const trigger = (
            <Button data-qa-button_new_claim primary compact>File new claim <Icon name={"dropdown"}
                                                                                             style={{margin: 0}}/></Button>)

        const alerts = [(!!NIGOClaims && NIGOClaims.length > 0), lastPaymentFailed];
        let count = 0;
        alerts.forEach(alert => {
                if (!!alert) {
                    count = count + 1
                }
            }
        )
        const marginTop = count !== 0 ? count * 38 : -16;
        const {firstName, lastName, suffix} = certificateHolder;
        return (
            <div className="member-main-container">
                <CoveredModal displayCoveredModal={displayCoveredModal}
                              onModalClose={() => this.doDisplayCoveredModal(false)}
                />

                <div className={"alertContainer"}>
                    {(!!NIGOClaims && NIGOClaims.length > 0) &&
                    <Alert style={{width: "calc(100% - 220px)"}} type={"warning"}>
                        {NIGOClaims.length > 1 ? <span>We need some additional information from you to
                        process some of your claims.
                        </span> : <span>We need some additional information from you to
                        process your claim. <span onClick={() => {
                                this.viewClaimDetail(NIGOClaims[0].id)
                            }} className={"link"}><b>View claim→</b></span>
                        </span>
                        }
                    </Alert>}
                    {lastPaymentFailed && <FailedPaymentAlert switchToSettings={()=>{this.props.history.push({pathname: "/billing", state: {activeTab: 'billingSettings'}})}}/>}
                    <BillingAlert switchToOverview={() => {
                        this.props.history.push('/billing')
                    }}/>
                </div>
                <Divider hidden/>


                <Grid container stackable columns={1} centered style={{marginTop: `${marginTop}px`}}>
                    <Grid.Column className={"pageContent"}>
                        <Divider hidden/>
                        {(!!claims && claims.length > 0) && (
                            <Menu text size={"small"} className={"memberSectionMenu"}>
                                <Menu.Item
                                    name='open'
                                    active={activeItem === 'open'}
                                    onClick={this.handleItemClick}
                                ><b>Open</b></Menu.Item>
                                <Menu.Item
                                    name='closed'
                                    active={activeItem === 'closed'}
                                    onClick={this.handleItemClick}
                                ><b>Closed</b>
                                </Menu.Item>
                            </Menu>
                        )}

                        <CustomContainer padded>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={4} style={{paddingRight: 0}}>
                                        <Header as="h4">{activeItem === "closed" ? "Closed " : "Current "}claims</Header>
                                    </Grid.Column>
                                    <Grid.Column width={8} style={{paddingLeft: 0}} textAlign="right">
                                        <Button data-qa-button_condition primary basic compact onClick={() => {
                                            this.setState({displayCoveredModal: true})
                                        }}>Conditions lookup</Button>
                                        {(!error) && <React.Fragment>
                                            {!!dependents && dependents.length > 0 ?
                                                <Dropdown trigger={trigger} icon={null} direction={"left"}>
                                                    <Dropdown.Menu direction={"left"}
                                                                   style={{minWidth: '200px'}}>
                                                        <Dropdown.Header content='Filing for:'/>
                                                        <Dropdown.Item onClick={(_) => {
                                                            this.handleChange(certificateHolder)
                                                        }} key={certificateHolder.id}
                                                                       text={buildFullName({firstName, lastName, suffix, relation: "Primary"})}
                                                                       value={certificateHolder.id}/>
                                                        {!!dependents && dependents.map(item => {
                                                            const {firstName, lastName, suffix, relToEmployee:relation, id} = item;
                                                            return <Dropdown.Item onClick={(_) => {
                                                                this.handleChange(item)
                                                            }} key={id}
                                                                                  text={buildFullName({firstName, lastName, suffix, relation})}
                                                                                  value={id}/>
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown> : <Button data-qa-button_new_claim primary compact onClick={(_) => {
                                                    this.handleChange(certificateHolder)
                                                }} style={{marginRight: 0, marginLeft: '8px'}}>File new
                                                    claim</Button>}
                                        </React.Fragment>
                                        }
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <p>Questions? Call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.</p>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    {!!error ?
                                        <Grid.Column width={12}><Message
                                            negative>{error}</Message></Grid.Column> :
                                        <Grid.Column>
                                            {(!claims || claims.length === 0) ? (
                                                <CustomContainer textAlign="center" basic padded={"very"}>
                                                    <Image style={{width: "110px"}} centered
                                                           src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Claims-Flow/EmptyState_greyscale.png"}
                                                    />
                                                    <SHeader as="h4" className="neutral700Text">
                                                        You have no claims
                                                    </SHeader>
                                                    <p className={"neutral700Text"}>You don't have any claims
                                                        yet
                                                        because you've had a healthy year.<br/>If
                                                        anything comes up, though, let us know by filing a
                                                        claim.
                                                    </p>
                                                    <Divider hidden/>
                                                    <Divider hidden/>
                                                    <Divider hidden/>
                                                </CustomContainer>
                                            ) : (!filteredClaims || filteredClaims.length === 0) ? (
                                                <CustomContainer textAlign="center" basic padded={"very"}>
                                                    <Image style={{width: "110px"}} centered
                                                           src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Claims-Flow/EmptyState_greyscale.png"}
                                                    />
                                                    <SHeader as="h4" className="neutral700Text">
                                                        You have
                                                        no {activeItem === "closed" ? "closed " : "opened "} claims
                                                    </SHeader>
                                                    <Divider hidden/>
                                                    <Divider hidden/>
                                                    <Divider hidden/>
                                                </CustomContainer>
                                            ) : ((!!filteredClaims && filteredClaims.length > 0) && filteredClaims.map(claim => {
                                                    const showPaymentInfo = !!claim.status && (claim.status === "Approved")
                                                    const claimStatus = getClaimStatusLabel(claim.status)
                                                    const payments = this.getClaimPayments(claim)
                                                    return (
                                                        <Card key={claim.id} fluid>
                                                            <Card.Content>
                                                                <Grid columns={2} verticalAlign={"middle"}>
                                                                    <Grid.Column textAlign={"left"}>
                                                                        <b>Claim {claim.claimNumber}</b><br/>Filing
                                                                        for {memberName(claim.claimant)}
                                                                    </Grid.Column>
                                                                    <Grid.Column textAlign={"right"}>
                                                                        <p className="small neutral600Text">Last
                                                                            updated
                                                                            on {convertServerDateFormatToDisplay(claim.lastUpdated)}
                                                                        </p>
                                                                    </Grid.Column>

                                                                </Grid>
                                                                <Grid columns={3} verticalAlign={"middle"}>
                                                                    <Grid.Column>
                                                                        <Label className={claimStatus.className}><Icon
                                                                            name={"circle"}/>{claimStatus.text}</Label>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <p className="small neutral600Text"><b>Submitted
                                                                            on</b><br/>{moment(claim.submissionDate).format('MMMM DD, YYYY [at] h:ma')}
                                                                        </p>
                                                                    </Grid.Column>
                                                                    <Grid.Column textAlign={"right"}>
                                                                        <Button primary compact basic onClick={() => {
                                                                            this.viewClaimDetail(claim.id, claim.claimNumber)
                                                                        }}>View details →</Button>
                                                                    </Grid.Column>
                                                                </Grid>
                                                                {!!showPaymentInfo &&
                                                                <CustomContainer basic className={"bkgLightGray"}
                                                                         style={{borderRadius: '8px'}}>
                                                                    {payments.map((payment, key) => (
                                                                        <ClaimPayment key={key} {...payment} />
                                                                    ))}
                                                                </CustomContainer>
                                                                }
                                                            </Card.Content>
                                                        </Card>
                                                    )
                                                })
                                            )}
                                        </Grid.Column>}
                                </Grid.Row>
                            </Grid>
                        </CustomContainer>
                    </Grid.Column>
                    <Footer showTopFooter={false} showCenterData={true}/>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Claims));
