import { useState, useEffect } from "react";
import {
    MFAContainer,
    MFAGrid,
    MFAText,
    MFAHeading,
    MFAInput,
    MFATextError,
    MFAForm,
    MFALabel,
    MFAButton
} from "../styled";

const ValidateChangeMFA = ({removeMethod, validateMfaCodeError, loading}) => {
    const [password, setPassword] = useState(undefined);
    const [error, setError] = useState(validateMfaCodeError)
    const [disableBtn, setDisableBtn] = useState(true);

    const handleChange = (e) => {
        if (e.target.value.length > 0) {
          setDisableBtn(false);
          setError(null)
        } else {
          setDisableBtn(true)
        };
        setPassword(e.target.value)
    }

    useEffect(() => {
      setError(validateMfaCodeError);
    }, [validateMfaCodeError])

    return (
      <MFAContainer
        maxWidth={"456px"}
        className="methodChange"
      >
        <MFAHeading as="h2" textAlign="center">
          Enter password
        </MFAHeading>
        <MFAGrid>
          <MFAGrid.Row columns={2} className={"spacingTop"}>
            <MFAGrid.Column width={12}>
              <MFAContainer className="verifyMethodChange" maxWidth={"308px"}>
                <MFAText>
                  Enter your password to change your two-factor authentication method—
                </MFAText>
                <MFAForm.Field className={"contentLeft"}>
                  <MFALabel className={"labelVerify"}>Password</MFALabel>
                  <MFAContainer className="changeMethodField">
                    <MFAInput
                      type="password"
                      name={"changeMFAMethod"}
                      id={"changeMFAMethod"}
                      onChange={(e) => handleChange(e)}
                      value={password}
                      required
                      disabled={loading}
                      className={"verifyInput"}
                    />
                    {error && <MFATextError> {error}</MFATextError>}
                  </MFAContainer>
                </MFAForm.Field>
                <MFAButton
                    className={'submitPassword'}
                    loading={loading}
                    disabled={loading || disableBtn}
                    onClick={() => {
                        removeMethod(password)
                    }}
                    >
                    Submit
                    </MFAButton>
              </MFAContainer>
            </MFAGrid.Column>
          </MFAGrid.Row>
        </MFAGrid>
      </MFAContainer>
    );
}

export default ValidateChangeMFA
