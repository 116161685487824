import React, {Component} from 'react';
import {Form, Header, Search} from "semantic-ui-react";
import {CustomContainer} from './custom-common';
import axios from "axios";
import { debounce } from 'throttle-debounce';
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import ClaimsSidebar from "./claims/claimsSidebar";

class Lookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            loading: false,
            error: null,
            suggestions: [],
            matches: null,
            availableCodes: [{
                id: 1,
                value: "ICD9",
                code: "ICD9",
            }, {
                id: 2,
                value: "ICD10",
                code: "ICD10CM",
            }, {
                id: 3,
                value: "SNOMED",
                code: "SNOMED",
            }],
            userSelectedCodeIdxFlags: [
                false,
                true,
                false,
            ],
            searchOpen: false,
            result: null
        };
    }

    fetchSuggestions = async (searchText) => {
        try {
            this.setState({
                loading: true,
                error: null,
            });
            const result = await axios.post(
                "/api/member/v2/searchDiagnosis",
                {
                    searchTerm: searchText,
                },
                {
                    headers: {'Content-Type': 'application/json'},
                }
            );
            let suggestions = [];
            result.data.hints.forEach(hint => {
                suggestions = suggestions.concat({
                    title: hint,
                })
            });
            this.setState({
                loading: false,
                suggestions: suggestions,
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: "No suggestions found",
            })
        }
    };

    fetchMatches = async (searchText) => {
        try {
            this.setState({
                loading: true,
                error: null,
                matches: null,
            });
            const response = await axios.post(
                "/api/member/v2/searchDiagnosis",
                {
                    searchTerm: searchText,
                },
                {
                    headers: {'Content-Type': 'application/json'},
                }
            );
            let result = null
            if (response.data.found) {
                result = {label: response.data.hints[0], category: response.data.category}
            }

            this.setState({
                loading: false,
                result: result,
            })


        } catch (e) {
            this.setState({
                loading: false,
                error: "No matches found",
            })
        }
    };

    handleSearchTermChange = async (event, {value}) => {
        const searchText = value.trim();
        this.setState({searchText: value, searchOpen: true, suggestions: searchText===""? []: this.state.suggestions, matches: []});
        if (searchText === "") {
            return
        }
        this.fetchSuggestions(searchText)
    };

    handleResultSelect = async (e, {result}) => {
        const searchText = result.title.trim();
        this.setState({searchText: result.title, searchOpen: false, matches: []});
        if (searchText === "") {
            return
        }
        this.fetchMatches(searchText)
    };

    handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            let {searchText} = this.state;
            searchText = searchText.trim();
            if (searchText === "") {
                return
            }
            this.setState({searchOpen: false});
            this.fetchMatches(searchText)
        }
    };

    handleCheckboxChange = (e, {id}) => {
        let {userSelectedCodeIdxFlags} = this.state;
        userSelectedCodeIdxFlags[id] = !userSelectedCodeIdxFlags[id];
        this.setState({userSelectedCodeIdxFlags});
    };

    filterResultsByCode = () => {
        const {matches, availableCodes, userSelectedCodeIdxFlags} = this.state;
        let result = matches;

        const checkedCodes = [];
        userSelectedCodeIdxFlags.forEach((c, idx) => {
            if(c){
                const ac = availableCodes.filter(ac => ac.id === idx + 1);
                if(ac.length > 0){
                    checkedCodes.push(ac[0].code);
                }
            }
        });

        if(!!matches){
            result = matches.filter((f)=> {
                for(let i=0; i<checkedCodes.length; i++){
                    if(f.codeSystem === checkedCodes[i]){
                        return true;
                    }
                }
                return false;
            });
        }
        return result;
    }

    render() {
        const {searchText, loading, suggestions, result, searchOpen} = this.state;
        //const {searchText, loading, suggestions, availableCodes, userSelectedCodeIdxFlags, searchOpen} = this.state;
        //const matches = this.filterResultsByCode();
        return (
            <ClaimsSidebar>
                <div className="member-main-container">
                    <Container>
                        <Form>
                            <Header as='h3' textAlign='left'>
                                What were you diagnosed with?
                            </Header>
                            <p> What were you treated for? Use simple keywords or phrases such as
                                “asthma”, “broken arm”, “sprained wrist”, etc
                            </p>
                            <Search
                                style={{maxWidth: '600px'}}
                                input={{fluid: true, icon: 'search', iconPosition: 'left'}}
                                loading={loading}
                                onFocus={()=>this.setState({searchOpen: true})}
                                onBlur={()=>this.setState({searchOpen: false})}
                                open={searchOpen}
                                onResultSelect={this.handleResultSelect}
                                onKeyDown={this.handleKeyDown}
                                onSearchChange={debounce(500, true, this.handleSearchTermChange)}
                                results={suggestions}
                                value={searchText}

                            />

                            {result &&
                                <CustomContainer basic>
                                    <b>{result.label}: </b>{result.category}</CustomContainer>}
                            {/*!matches ? null : (
                                <Table basic>
                                    <Responsive as={Table.Header} minWidth={Responsive.onlyTablet.minWidth}>
                                        <Table.Row>
                                            <Table.HeaderCell>Text</Table.HeaderCell>
                                            <Table.HeaderCell>CodeSystem</Table.HeaderCell>
                                            <Table.HeaderCell>Code</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                            <Table.HeaderCell>Score</Table.HeaderCell>
                                            <Table.HeaderCell>Billable</Table.HeaderCell>
                                        </Table.Row>
                                    </Responsive>
                                    <Table.Body>
                                        {matches.map((item, index) => {
                                                return (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>{item.text}</Table.Cell>
                                                        <Table.Cell>{item.codeSystem}</Table.Cell>
                                                        <Table.Cell>{item.code}</Table.Cell>
                                                        <Table.Cell>{item.description}</Table.Cell>
                                                        <Table.Cell>{item.score}</Table.Cell>
                                                        <Table.Cell>{item.billable}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                        )}
                                    </Table.Body>
                                </Table>
                            )*/}
                        </Form>
                    </Container>
                </div>
            </ClaimsSidebar>
        );
    }
}

export default Lookup;
