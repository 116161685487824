import React from 'react';
import {Message} from "semantic-ui-react";

const ERROR_MESSAGE = {
  card_number: "The credit card number entered is invalid. Please double check the entered number and try again.",
  undefined: "There was a problem processing your credit card information. Please verify that the following were entered correctly."
};

const ERROR_CODES = {
  incorrect_number: 'card_number',
  incomplete_number: 'card_number',
  invalid_number: 'card_number',
}

const CreditCardErrors = ({ errors = [] }) => {
    const listOfErrors = errors
        .filter(error => !!error && !!error?.code)
        .map(({ code }) => {
            const title = code.replace(/_/g, " ");
            const error_code = ERROR_CODES[code];
            const error_message = ERROR_MESSAGE[error_code];
            return <p key={code}><strong>{title.charAt(0).toUpperCase() + title.slice(1)}.</strong>&nbsp;{error_message}</p>;
        });

    return <Message style={{border: "none", borderRadius: 0}}
                    visible={!!listOfErrors.length}
                    list={listOfErrors}
                    error/>;
}

export default CreditCardErrors;
