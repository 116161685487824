import React from 'react'
import {Segment} from "semantic-ui-react";
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { getKeyOrValueAndKey, mergeStrings} from './utils/utils';

const CustomSegment =  styled(Segment)`
    position: ${props => (`${props.position ? `${props.position} !important` : 'relative'}`)};
    top: ${props => (`${props.top ? `${props.top}px` : 'auto'}`)};
    left: ${props => (`${props.left ? `${props.left}px` : 'auto'}`)};
`;


export const CustomContainer = ({
    className,
    left,
    padded,
    position,
    top,
    ...otherProps
  }) => {
    const paddedClass = getKeyOrValueAndKey(padded, 'padded');
    const classes = mergeStrings(paddedClass, className);
    return <CustomSegment className={classes} left={left} position={position} top={top} {...otherProps}></CustomSegment>
}

CustomContainer.propTypes = {
    className: PropTypes.string,
    left: PropTypes.number,
    padded: PropTypes.string,
    position: PropTypes.string,
    top: PropTypes.number,
};

export default CustomContainer;