import React, {Component} from "react"
import {Divider, Grid, Message, Segment} from "semantic-ui-react";
import {CustomContainer} from './custom-common';
import {Header, Link} from "./base";
import {S3Image, ClickableText} from "./custom-common";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {resolveErrorMessage} from "../utils/utils";
import MainMenu from "./MainMenu";
import STRATEGIC_PARTNER from "../constants/strategicPartners";

class PasswordResetRequestSent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            success: false
        };
    }

    resendLink = async () => {
        try {
            const email = this.props.location.state.email
            this.setState({loading: true, error: null, success: false});
            await axios.post("/api/member/v2/requestPasswordReset", {email: email});
            this.setState({loading: false, success: true})
        } catch (e) {
            console.warn(e)
            const error = resolveErrorMessage(e, "Unable to send reset password request")
            this.setState({loading: false, error: error})
        }
    }

    render() {
        const {loading, error, success} = this.state
        return (
            <React.Fragment>
                <MainMenu/>
                <div className="employer-portal-container">
                    <Grid container stackable centered>
                        <Grid.Column style={{marginTop: '8em'}} width={6} className={"pageContent"}>
                            <Segment style={{padding: '2em 4em 2em 4em'}} loading={loading}>
                                <S3Image name={"check"} centered width={81}/>
                                <Header as={"h3"} textAlign={"center"}>Your request was sent</Header>

                                <p className={"small"}>Check your email. If we have an account associated with that
                                    email, you’ll receive a
                                    link to reset your password.</p>

                                <Divider hidden/>
                                <CustomContainer>
                                    <p className={"smaller"}>
                                        <span className={"neutral900Text"}><b>Didn't receive an email? </b></span><br/>
                                        Either <ClickableText onClick={this.resendLink}>re-send the link</ClickableText> or <Link to="/forgotPassword">try a different
                                        email</Link>
                                    </p>
                                    {error && <Message negative size="mini">{error}</Message>}
                                    {success && <Message positive size="mini">Email re-sent successfully</Message>}
                                </CustomContainer>
                            </Segment>
                            <Segment basic style={{padding: '1em 4em'}}>
                                <p className={"smaller"}>Still need help? Reach out to our team <span
                                    style={{whiteSpace: "nowrap"}}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}</span> or <a
                                    href={`mailto:${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}</a></p>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(PasswordResetRequestSent);
