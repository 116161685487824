import React, {useState} from 'react';
import {Form, Input, Modal} from "semantic-ui-react";
import {Button} from "../base";
import CreditCardErrors from "../paymentDetails/creditCardErrors";
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from "react-stripe-elements";
import {PaymentDetailsContext} from "../paymentDetails/PaymentDetailsContainer";
import NumberFormat from "react-number-format";
import {CustomModalHeader} from '../custom-common';

const STRIPE_BASE_INPUT_STYLES = {
    fontSize: "14px",
    flex: "auto",
    color: "#212121",
    border: "1px solid #9E9E9E",
    borderRadius: "7px",
    fontFamily: "'Raleway', 'Helvetica Neue', Arial, Helvetica, sans-serif",
    padding: "8px 8px",
    outline: "none",
    margin: "0em",
    boxShadow: "0em 0em 0em 0em transparent inset",
    transition: "color 0.1s ease, border-color 0.1s ease",
};

const CreditCardModal = (props) => {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [newExpiry, setNewExpiry] = useState(null)

    const {show, close, strings, save, isEdit, current} = props;

    const handleSubmit = () => {
        setProcessing(true);
        setError(null);
        if (isEdit) {
            save(current.stripeId, newExpiry)
            close()
        } else {
            props.stripe
                .createPaymentMethod('card')
                .then((result) => {
                    setProcessing(false)
                    if (result.error) {
                        setError(result.error);
                        console.log(result.error);
                    } else {
                        setError(null);
                        save(result.paymentMethod.id)
                        close()
                        console.log('Received Stripe PaymentMethod:', result.paymentMethod);
                    }
                });
        }
    };

    const headerTitleAction = `${isEdit ? 'Edit' : 'Add'} credit card`;

    return (
        <PaymentDetailsContext.Provider initialState={{}}>
        <Modal size={'mini'} onClose={close} open={show} style={{maxWidth: '562px'}}>
            <CustomModalHeader title={headerTitleAction} onClose={close} className="customModalHeader" />
            <Modal.Content style={{overflowY: 'auto'}}>
                <Form>
                    <CreditCardErrors errors={[error]}/>

                    <Form.Field error={!!error?.message}>
                        <label>Card number</label>
                        {isEdit ? <Input disabled value={'********' + current.last4}/>  : <div style={STRIPE_BASE_INPUT_STYLES}>
                            <CardNumberElement/>
                        </div>}
                    </Form.Field>

                    <Form.Group widths="equal" style={{alignItems: "end"}}>
                        <Form.Field error={!!error?.message}>
                            <label>Expiry</label>
                            {isEdit ? <NumberFormat onChange={(e)=>{setNewExpiry(e.target.value)}} value={newExpiry} format="##/##" placeholder="MM/YY" mask={['M', 'M', 'Y', 'Y']} />
                                : <div style={STRIPE_BASE_INPUT_STYLES}>
                                <CardExpiryElement/>
                            </div>}
                        </Form.Field>
                        {!isEdit && <Form.Field error={!!error?.message}>
                            <label>CVC</label>
                            <div style={STRIPE_BASE_INPUT_STYLES}>
                                <CardCVCElement/>
                            </div>
                        </Form.Field>}
                    </Form.Group>
                    <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button color="grey" basic onClick={close}>{strings.paymentRequired.cancel}</Button>
                        <Button primary type="submit" disabled={processing} onClick={handleSubmit}>Save</Button>
                    </div>
                </Form>
            </Modal.Content>
        </Modal>
        </PaymentDetailsContext.Provider>
    )
}

export default injectStripe(CreditCardModal);
