import {MFALink} from "../styled";
import React from "react";

export const BackupCodeButton = ({ handleUseMethod }) => {

  return (
    <MFALink
      onClick={handleUseMethod}
      className={"left"}
      style={{ textAlign: "left", fontSize: "14px", marginBottom: '10px' }}
    >
      Or, use a backup code
    </MFALink>
  )
}
