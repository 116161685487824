import React, {Component} from "react"
import {Divider, Form, Grid, Icon} from "semantic-ui-react";
import {Button, Header} from "../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setFetchPlanRecommendation, setHealthPlanDetails, setSkipRecommendation} from "../../actions/anualOpenEnrollmentActions";
import {getLocalization, languages, parseMultilineString} from "../../utils/localization";
import Footer from "../footer";
import {setPrevRoute} from "../../actions/navigationActions";
import {AOE_SELECT_PLAN_ROUTE, handleSaveUserProgress} from "../../utils/common";
import {cancelAOEFlow} from "../../utils/utils";
import AOESteps from "./aOESteps";
import CancelLink from "./cancelLink";

const mapStateToProps = (state, ownProps) => {
    const {anualOpenEnrollment, user} = state;
    return {
        highDeductibleOptions: anualOpenEnrollment.generalInitialData.highDeductibleOptions,
        coPayOptions: anualOpenEnrollment.generalInitialData.coPayOptions,
        storedTypicallyPayInfo: anualOpenEnrollment.typicallyPayInfo,
        storedHighDeductibleInfo: anualOpenEnrollment.highDeductibleInfo,
        lang: user.lang,
        anualOpenEnrollment,
        ...ownProps
    }
};

class AOEHealthPlanDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            needsUserProgressUpdate: false,
            highDeductibleSelected: props.storedHighDeductibleInfo,
            typicallyPaySelected: props.storedTypicallyPayInfo,
        };
    }

    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    handleHighDeductibleChange = (selectedKey) => {
        let {typicallyPaySelected} = this.state;
        const clearWhenVisitingADoctor = this.showVisitingTheDoctorQuestion(selectedKey);
        if(clearWhenVisitingADoctor){
            typicallyPaySelected = null;
        }
        this.setState({highDeductibleSelected: selectedKey, typicallyPaySelected, needsUserProgressUpdate: true});
    };

    handleTypicallyPayChange = (selectedKey) => {
        this.setState({typicallyPaySelected: selectedKey, needsUserProgressUpdate: true});
    };

    showVisitingTheDoctorQuestion = (highDeductibleSelected) => {
        const NOT_COVERED_ID = 3;
        return highDeductibleSelected !== NOT_COVERED_ID;
    }

    next = () => {
        const {dispatch, anualOpenEnrollment} = this.props;
        const {highDeductibleSelected, typicallyPaySelected, needsUserProgressUpdate} = this.state;
        dispatch(setHealthPlanDetails(typicallyPaySelected, highDeductibleSelected));
        dispatch(setSkipRecommendation(false))
        dispatch(setFetchPlanRecommendation(true));
        const aOE = {...anualOpenEnrollment}
        if(needsUserProgressUpdate){
            aOE.typicallyPayInfo = typicallyPaySelected;
            aOE.highDeductibleInfo = highDeductibleSelected;
        }
        handleSaveUserProgress(aOE).then();
        this.props.history.push(this.props.nextRoute);
    }

    skip = () => {
        this.props.dispatch(setSkipRecommendation(true))
        this.props.history.push(AOE_SELECT_PLAN_ROUTE)
    }

    render() {
        const {highDeductibleSelected, typicallyPaySelected} = this.state;

        const localization = getLocalization(this.props.lang);
        const strings = localization.healthPlanDetails;

        const {highDeductibleOptions, coPayOptions} = this.props;
        const highDeductibleOpts = highDeductibleOptions.map((o) => {
            return {key: o.id, value: o.id, text: o.value};
        });
        const coPayOpts = coPayOptions.map((o) => {
            return {key: o.id, value: o.id, text: o.value};
        });
        const showCoverageQuestion = this.showVisitingTheDoctorQuestion(highDeductibleSelected);

        const button = <Button primary content={localization.next}
                               disabled={highDeductibleSelected === null || (showCoverageQuestion && typicallyPaySelected === null)}
                               onClick={this.next}/>;
        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;
        return (
            <React.Fragment>
                <div className="member-main-container" style={{background: 'white'}}>
                    <AOESteps activeStepIdx={1}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Grid stackable columns={1}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Header as="h2">{strings.title}</Header>
                                        <p className={"neutral700Text"}>{parseMultilineString(strings.subTitle)}</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form>
                                            <p className={"requiredField"}><b>{strings.doesYourHealth}</b></p>
                                            <Form.Field>
                                                <Form.Group grouped>
                                                    {highDeductibleOpts.map((r) => {
                                                        let text = r.text;
                                                        const localizationEng = getLocalization(languages.ENGLISH);
                                                        const engStrings = localizationEng.healthPlanDetails;
                                                        if(text === engStrings.yes){
                                                            text = strings.yes;
                                                        } else if(text === engStrings.no){
                                                            text = strings.no;
                                                        } else if(text === engStrings.imNot){
                                                            text = strings.imNot;
                                                        }

                                                        return <React.Fragment key={r.text}>
                                                            <Form.Radio className={'radioNormalFontSize'}
                                                                onChange={() => this.handleHighDeductibleChange(r.key)}
                                                                checked={highDeductibleSelected === r.key}
                                                                label={text}/>
                                                        </React.Fragment>
                                                    })}
                                                </Form.Group>
                                            </Form.Field>
                                            <Divider hidden/>

                                            {showCoverageQuestion && <React.Fragment>
                                                <p className={"requiredField"}><b>{strings.whenVisitingDoctor}</b></p>
                                                <Form.Field>
                                                    <Form.Group grouped>
                                                        {coPayOpts.map((r) => {
                                                            let text = r.text;
                                                            const localizationEng = getLocalization(languages.ENGLISH);
                                                            const engStrings = localizationEng.healthPlanDetails;
                                                            if(text === engStrings.iPay){
                                                                text = strings.iPay;
                                                            } else if(text.trim() === engStrings.theFullCost){
                                                                text = strings.theFullCost;
                                                            }
                                                            return <React.Fragment key={r.text}>
                                                                <Form.Radio key={r.id} className={'radioNormalFontSize'}
                                                                            onChange={() => this.handleTypicallyPayChange(r.key)}
                                                                            checked={typicallyPaySelected === r.key}
                                                                            label={text}/>
                                                            </React.Fragment>
                                                        })}
                                                    </Form.Group>
                                                </Form.Field>
                                            </React.Fragment>
                                            }
                                        </Form>
                                        <Divider hidden/>
                                        {strings.iDontWant}
                                        <span>{' '}
                                                <span className={"linkText"} onClick={this.skip}>{strings.skip}<Icon name="long arrow alternate right"/></span>
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter showCenterData button={button} link={cancel} />
                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(AOEHealthPlanDetails));
