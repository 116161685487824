import React, {Component} from "react"
import {Container, Divider, Form, Grid, Message} from "semantic-ui-react";
import {CustomContainer, CustomEmailInput} from './custom-common';
import {Button, Header} from "./base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {resolveErrorMessage} from "../utils/common";
import STRATEGIC_PARTNER from "../constants/strategicPartners";

const mapStateToProps = (state, ownProps) => {
    const {initialData} = state;
    return {
        ...ownProps,
        token: initialData.token,
    }
};

class ExpiredToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            email: null,
            message: null,
        };
    }

    componentDidMount() {
        window.onbeforeunload = null;
    }

    handleSubmit = async () => {
        const handleError = (message) => {
            this.setState({
                loading: false,
                error: true,
                message
            });
        }

        try {
            this.setState({loading: true, error: false});
            const {email} = this.state;
            const {token} = this.props;
            let result = await axios.post("/api/member/v2/resetToken", {email, token});
            if (result.status === 200) {
                // this.props.dispatch(setResetTokenEmail(email));
                this.setState({loading: false});
                window.onbeforeunload = null;
                this.props.history.push("/requestSent");
            } else {
                handleError("Unexpected error");
            }
        } catch (e) {
            handleError(resolveErrorMessage(e, "Unexpected error"));
        }
    }

    render() {
        const {email, error, loading, message} = this.state;
        const strings = {
            title: "Link has expired",
            descr: `Enter your email address and we’ll send you a new link to access ${STRATEGIC_PARTNER.LABEL}—`,
            email: "Email address",
            sendLink: "Send link",
            error: "Unexpected error",
        }
        return (
            <React.Fragment>
                <Container className={'contentContainer'}>
                    <Grid container stackable centered>
                        <Grid.Column style={{marginTop: '3em'}} width={5}>
                            <Header as={"h2"} textAlign={"center"}>{strings.title}</Header>
                            <CustomContainer padded>
                                <p>{strings.descr}</p>

                                <Form>
                                    <Form.Group>
                                        <CustomEmailInput
                                                width={12}
                                                placeholder={strings.email}
                                                value={email || ''}
                                                onChange={(e, {value}) => {
                                                    this.setState({email: value, error: false});
                                                }}
                                            />
                                    </Form.Group>
                                    <Button primary
                                            loading={loading}
                                            onClick={this.handleSubmit}
                                            disabled={email === null || email.length === 0 || loading}>
                                        {strings.sendLink}
                                    </Button>
                                    {error && <Message negative>{message}</Message>}
                                    <Divider hidden/>
                                </Form>
                            </CustomContainer>
                        </Grid.Column>
                    </Grid>
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ExpiredToken));
