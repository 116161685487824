import {navigationActionTypes} from "./actionTypes"


export function setLeftNavigation(value) {
    return {
        type: navigationActionTypes.SET_LEFT_NAV,
        payload: value
    }
}

export function setPrevRoute(value) {
    return {
        type: navigationActionTypes.SET_PREV_ROUTE,
        payload: value
    }
}

export function setLastRoute(value) {
    return {
        type: navigationActionTypes.SET_LAST_ROUTE,
        payload: value
    }
}