import React from "react";
import {Loader} from "semantic-ui-react";


const style = {
    height: "100vh",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 999,
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const PaymentProcessing = ({loading}) => (
    <>
        {loading &&
        <div style={style}>
            <Loader style={{paddingBottom: "230px"}} active={true} size='massive'/>
            <div style={{textAlign: "center", marginTop: "30px"}}>
                <h4 className={"vibranBlueHeader"} style={{marginBottom: "10px"}}>
                    Payment processing...
                </h4>
                <p>Please do not refresh or close your window, <br/>this process may take up to a minute.</p>
            </div>
        </div>
        }
    </>
);

export default PaymentProcessing;
