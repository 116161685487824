import React from 'react';
import {Container, Grid} from 'semantic-ui-react';
import CoverageDetails from "./coverageDetails";
import Footer from "./footer";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getLocalization} from "../utils/localization";
import moment from "moment";
import {usePreventBackNavigation} from "../hooks";

const mapStateToProps = ({initialData}, ownProps) => {
    const strings = getLocalization(initialData.user.lang);

    const effectiveDate = moment(initialData.certificate.effectiveDate, "YYYY-MM-DD")
        .format("MMM DD, YYYY");

    return {
        ...ownProps,
        effectiveDate,
        strings,
    }
};

const SuccessfulPayment = ({effectiveDate, strings}) => {
    usePreventBackNavigation();

    return (
        <>
            <Container className={'stopScreens'}>
                <h1 className={'vibranBlueHeader'} style={{fontSize: '32px', marginBottom: '5px'}}>
                    { strings.paymentSuccessful.youAreAllSet }
                </h1>
                <p className={'neutral700Text'}>
                    { strings.paymentSuccessful.yourBrellaCoverage }
                </p>
                <Grid style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{margin: '15px 0px'}}>
                        <p className={'neutral600Text'} style={{marginBottom: 0}}><b>
                            { strings.paymentSuccessful.coverageEffectiveDate }:
                        </b></p>
                        <h4 className={'neutral900Text'} style={{marginTop: '0'}}><b>{ effectiveDate }</b></h4>
                    </div>
                    <img style={{margin: '0 auto'}}
                         width={316}
                         alt={'successful payment'}
                         src={'https://brella-platform-assets.s3.us-east-1.amazonaws.com/common/Illustrations/Success-mobile_revision.png'}
                    />
                </Grid>
                <CoverageDetails showReachOut={true} />
            </Container>
            <Footer />
        </>
    );
}

export default withRouter(connect(mapStateToProps)(SuccessfulPayment));
