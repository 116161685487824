import axios from "axios"

const BREXUS_LOCAL= 'http://localhost:8083'

export const axiosInit = () => {
  if (window.location.host !== 'localhost:3000') return

  const brx = process.env.REACT_APP_API || BREXUS_LOCAL

  axios.interceptors.request.use(({ url, ...config }) => ({
    ...config,
    url: `${brx}${url}`,
  }))
}
