import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {isMobile, resolveErrorMessage} from '../../../../utils/utils'
import { setLogin, setAuthMeta, setMfaType, setRememberDays, setMfaTypesConfigured, setMfaEmailSetUpped } from '../../../../actions/authActions'
import { setLeftNavigation } from "../../../../actions/navigationActions"
import {
  Wrapper,
  Form,
  Input,
  Message,
  SubmitButton,
  Actions,
  Action,
  Link,
  HelpMessage,
} from './styled'
import SupportEmailLink from "../../../base/support-email-link";
import STRATEGIC_PARTNER from "../../../../constants/strategicPartners";
import {
  MFA_ROUTE
} from "../../../../utils/common";
import {MFA_TYPE} from "../../../../mfa/constants";
import {CustomEmailInput} from '../../../custom-common';

const LokedOutMessage = () => {
  const { EMAIL, NUMBER } = STRATEGIC_PARTNER.CONTACT_SUPPORT;

  return (
    <>
      <p>
        Your account has been locked for security reasons. For help, contact us
        at{" "}
        <a href={`mailto:${EMAIL}`} className={"textLink"}>
          {EMAIL}
        </a>{" "}
        or call{" "}
        <a href={`tel:${NUMBER}`} className="textLink">
          {NUMBER}
        </a>
        .
      </p>
    </>
  );
};

export const LoginForm = ({ className }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const isResetPasswordFlow = useSelector(state => state.mobile.isResetPasswordFlow);

  useEffect(() => {
    if(history?.location?.isLocked){
      setError(<LokedOutMessage />);
    }
  }, [dispatch])


  const handleChange = e => {
    const { name, value } = e.target

    setForm(form => ({ ...form, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (loading) return

    setError(null)

    const { email, password } = form

    if (!email) return setError('Please enter your email address')
    if (!password) return setError('Please enter your password')

    setLoading(true)

    try {
      const { data } = await axios.post('/api/member/v2/login', {
        email: email.trim(),
        password: password
      })

      if(data.isLocked){
        setError(<LokedOutMessage />);
        setLoading(false)
      } else {
        postLogin(email.trim(), data)
      }
    } catch (e) {
      setError(resolveErrorMessage(e, 'An unexpected error has occurred.'))
      setLoading(false)
    }
  }

  const resolveMfaTypesConfigured = (multiFactoredMethods) => {
    const mfaTypes = []
    if(multiFactoredMethods.totpKey) {
      mfaTypes.push(MFA_TYPE.TOTP)
    }
    if(multiFactoredMethods.email) {
      dispatch(setMfaEmailSetUpped(multiFactoredMethods.email.email))
      mfaTypes.push(MFA_TYPE.EMAIL)
    }
    return mfaTypes
  }

  const postLogin = (email, loginResponse) => {
    const {
      employerName : username,
      redirectAuthenticated,
      step,
      ...auth
    } = loginResponse

    if (redirectAuthenticated) return window.location = redirectAuthenticated;

    const loginData = {
        ...auth,
        username
    }

    const mfaTypesConfigured = resolveMfaTypesConfigured(auth.multiFactoredMethods)

    dispatch(setLogin(loginData));
    dispatch(setAuthMeta(email, auth.mfaEnabled, auth.mfaNotEnabledGracePeriodEnded));
    dispatch(setMfaType(auth.type ? auth.type : MFA_TYPE.TOTP))
    dispatch(setMfaTypesConfigured(mfaTypesConfigured))
    dispatch(setRememberDays(loginResponse.rememberDeviceDays ? loginResponse.rememberDeviceDays : undefined))
    if (isResetPasswordFlow && isMobile()) {
      history.push('/betterOnTheApp');
    }
    else if (auth.mfaEnabled && auth.authToken ) {
      history.push({ pathname: '/plan' })
    } else {
      dispatch(setLeftNavigation(false));
      history.push({ pathname: MFA_ROUTE });
    }
  }

  return (
    <Wrapper className={className} loading={loading}>
      {error && <Message negative>{error}</Message>}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
            <CustomEmailInput
              width={12}
              type='email'
              name='email'
              value={form.email || ''}
              onChange={handleChange}
              disabled={loading}
            />
        </Form.Group>
        <Form.Field>
          <Input.Label>Password</Input.Label>
          <Input
            type='password'
            name='password'
            value={form.password || ''}
            onChange={handleChange}
            disabled={loading}
          />
        </Form.Field>
        <Form.Actions>
          <SubmitButton disabled={loading}>Sign in</SubmitButton>
        </Form.Actions>
      </Form>
      <Actions>
        <Action large>
          <Link to='/forgotPassword'>Forgot password?</Link>
        </Action>
      </Actions>
      {error &&
          <HelpMessage>Still need help? Reach out to our technical support team at <br/>
            {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER} or <SupportEmailLink/> </HelpMessage>}
    </Wrapper>
  )
}

export default LoginForm
