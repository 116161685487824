import React, {Component} from 'react'
import GoogleMapReact from "google-map-react";

const googleMapsApiKey = "AIzaSyBepJVzqJwvuSo7H9iWHkIheBK1EPCHMd8";

class Map extends Component {
    getMapOptions = (maps, streetViewControl) => {
        return {
            streetViewControl: !!streetViewControl || false,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.BOTTOM_CENTER,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            }
        }
    };

    render() {
        const {streetViewControl, children, ...all} = this.props;
        return <GoogleMapReact
                options={(maps) => this.getMapOptions(maps, streetViewControl)}
                bootstrapURLKeys={{key: googleMapsApiKey}}
                {...all}>
            {children}
        </GoogleMapReact>
    }
}

export default Map;