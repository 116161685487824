import React from "react";
import CheckoutForm from "./checkoutForm";
import {Grid} from "semantic-ui-react";
import AutoPayBox from "./autoPayBox";

const CreditCardPm = ({ strings }) => (
        <Grid.Row columns={2}>
            <Grid.Column style={{paddingLeft: 0}}>
                <CheckoutForm strings={strings} />
            </Grid.Column>
            <Grid.Column  style={{paddingRight: 0}}>
                <AutoPayBox strings={strings} />
            </Grid.Column>
        </Grid.Row>
    );

export default CreditCardPm;
