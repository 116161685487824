import {claimsActionTypes} from "../actions/actionTypes";

const initialState = {
    claims: null,
    selectedClaim: null,
    NIGOClaims: null
};

export const claimsReducer = (state = initialState, action) => {
    switch (action.type) {
        case claimsActionTypes.SET_CLAIM_LIST:
            return {
                ...state,
                claims: action.payload,
            }
        case claimsActionTypes.SET_SELECTED_CLAIM:
            return {
                ...state,
                selectedClaim: action.payload,
            }
        case claimsActionTypes.CLEAR_CLAIMS:
            return {
                claims: null,
                selectedClaim: null,
                NIGOClaims: null
            }
        case claimsActionTypes.SET_NIGO_CLAIMS:
            return {
                ...state,
                NIGOClaims: action.payload
            }
        case claimsActionTypes.UPDATE_CLAIM_LIST:
            return {
                ...state,
                claims: action.payload
            }
        default:
            return state
    }
};