import React from 'react';
import { Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { getKeyOnly, mergeStrings} from './utils/utils';


const getClasses = ({ small, medium, large}) => {
    return mergeStrings(
        getKeyOnly(small, 'small'),
        getKeyOnly(medium, 'medium'),
        getKeyOnly(large, 'large'),
    )
};

export const CustomDivider = ({ small, medium, large, ...rest }) => {
    const classes = getClasses({ small, medium, large });
    return <Divider {...rest} className={classes} />;
};


CustomDivider.propTypes = {
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
};