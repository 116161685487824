import {
  MFAButton,
  MFAForm,
  MFAGrid,
  MFALabel,
  MFAText,
} from "../styled";
import React from "react";
import {Input} from "semantic-ui-react";
import {MFA_TYPE} from "../constants";
import {BackupCodeButton} from "./backupCodeButton";


export const SelectMethodToVerified = ({ usingMethod, handleUseMethod, submit }) => {

  return (
    <>
      <MFAText className={"alignLeft"}>
        Choose one of the two-factor authentication methods below—
      </MFAText>

      <MFAGrid>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAForm className={'methodsList'}>
              <MFAForm.Group grouped>
                <MFAGrid padded={false}>
                  <MFAGrid.Row className="noVerticalPadding">

                    <MFAGrid.Column className="mt-16">
                      <MFAForm.Field inline className="methodField methodFieldLabel" onClick={() => handleUseMethod(MFA_TYPE.TOTP)}>
                        <Input
                          control='input'
                          type='radio'
                          name='method'
                          id="authenticator"
                          checked={usingMethod[MFA_TYPE.TOTP]}
                        />
                        <MFALabel htmlFor="authenticator">
                          Authenticator App
                        </MFALabel>
                      </MFAForm.Field>
                    </MFAGrid.Column>

                  </MFAGrid.Row>


                  <MFAGrid.Row className="noVerticalPadding">
                    <MFAGrid.Column className="mb-16">
                      <MFAForm.Field inline className="methodField methodFielEmail" onClick={() => handleUseMethod(MFA_TYPE.EMAIL)}>
                        <Input
                          control='input'
                          type='radio'
                          name='method'
                          id="authenticator"
                          checked={usingMethod[MFA_TYPE.EMAIL]}
                        />
                        <MFALabel htmlFor="authenticator">
                          Email
                        </MFALabel>
                      </MFAForm.Field>
                    </MFAGrid.Column>
                  </MFAGrid.Row>


                </MFAGrid>
              </MFAForm.Group>
            </MFAForm>
          </MFAGrid.Column>
        </MFAGrid.Row>
      </MFAGrid>

      <MFAButton
        mt={16}
        className="submitMethod"
        onClick={submit}
        disabled={(!usingMethod[MFA_TYPE.EMAIL] && !usingMethod[MFA_TYPE.TOTP])}
      >
        Continue
      </MFAButton>

      <BackupCodeButton handleUseMethod={() => handleUseMethod(MFA_TYPE.BACKUP_CODES)} />
    </>
  );
}
