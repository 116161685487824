import React, {Component} from 'react'
import {Icon, List} from 'semantic-ui-react';
import FileUpload from '../../fileUploads';
import {isValidFileByTypes, isValidFileBySize} from "../../../utils/file";
import {MAX_FILE_SIZE_MB} from "../../../utils/constants";

export class UploadFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            analyzing: false,
            errorWithTypes: null,
            errorWithSizes: null,
        }
    }

    onFilesLoad = (newFiles) => {
        const {files = [], validFileTypes, onAddingFiles, onFilesUpdate, onError} = this.props

        this.setState({errorWithTypes: null, errorWithSizes: null})

        let added
        let invalidType, invalidSize  = [];

        if (validFileTypes) {
            added = newFiles.filter((file) => isValidFileByTypes(file, validFileTypes) && isValidFileBySize(file));
            invalidType = newFiles.filter((file) => !isValidFileByTypes(file, validFileTypes));                       
            invalidSize = newFiles.filter((file) => !isValidFileBySize(file)); 
        } else {
            added = newFiles
        }

        if (invalidType?.length) {
            let error

            if (invalidType.length > 1) {
                const list = invalidType.map(f => f.name).join(', ')                
                error = `Following files have an unsupported file type: ${list}.`
            } else {
                const file = invalidType[0]
                error = `${file.name} unsupported file type.`
            }
            this.setState({errorWithTypes: error}, () => onError && onError({message: error, files: invalidType}))
        }

        if (invalidSize?.length) {
            let error

            if (invalidSize.length > 1) {
                const list = invalidSize.map(f => f.name).join(', ')                
                error = `Following files exceeds ${MAX_FILE_SIZE_MB}MB: ${list}.`
            } else {
                const file = invalidSize[0]
                error = `${file.name} The file exceeds ${MAX_FILE_SIZE_MB}MB.`
            }
            this.setState({errorWithSizes: error}, () => onError && onError({message: error, files: invalidSize}))
        }

        const res = onAddingFiles && onAddingFiles(added)
        if (res === false) return

        const newFileList = [...files, ...added]

        onFilesUpdate(newFileList, {added})
    }

    removeFile = (index) => {
        const {files, onFilesUpdate, onRemovingFiles} = this.props

        const removed = files[index]

        const res = onRemovingFiles && onRemovingFiles(removed)
        if (res === false) return

        const newFileList = [...files]
        newFileList.splice(index, 1)

        onFilesUpdate(newFileList, {removed})
    }

    render() {
        const {
            style,
            files,
            hideAttachmentArea = false,
            disabled
        } = this.props
        const {analyzing, errorWithTypes, errorWithSizes} = this.state
        const styles = {
            ...style,
            height: 'auto',
            minHeight: 0
        }

        return (<>
            {!!files?.length && (
                <List clasname="neutral900Text" color='red'>
                    {files.map((file, index) => {
                        return (
                            <List.Item key={index}>
                                <Icon name='file'/>
                                <List.Content>
                                    <b>{file.name}</b>
                                    &nbsp;
                                    {!disabled && (
                                        <Icon
                                            link
                                            color='grey'
                                            onClick={() => {
                                                this.removeFile(index)
                                            }}
                                            name='trash alternate outline'
                                        />
                                    )}
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
            )}
            {!hideAttachmentArea && (
                <FileUpload small style={styles} onFilesLoad={this.onFilesLoad} disabled={analyzing || disabled}>
                    <div>
                        <p style={{marginBottom: 0}}><Icon color="grey" name="download"/></p>
                        <p style={{marginBottom: 0}} className="small neutral700Text">Drag and drop or <strong>browse your computer</strong></p>
                        <p className="small neutral700Text">File types accepted are JPGs, PNGs, and PDFs</p>
                        <p className={"small neutral700Text"}>Max file size {MAX_FILE_SIZE_MB}MB</p>
                        {errorWithTypes && (
                                <p className="small">
                                    <Icon style={{color: '#D82000'}} name='exclamation circle'/>
                                    <span style={{color: '#D82000'}}>{errorWithTypes}</span>
                                </p>
                            )}
                        {errorWithSizes && (
                                <p className="small">
                                    <Icon style={{color: '#D82000'}} name='exclamation circle'/>
                                    <span style={{color: '#D82000'}}>{errorWithSizes}</span>
                                </p>
                        )}
                    </div>
                </FileUpload>
            )}
        </>)
    }
}
