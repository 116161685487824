import React, {Component} from "react"
import {Divider, Form, FormGroup, Grid, Message, Select} from "semantic-ui-react";
import {CustomContainer, DOBInput, SSNInput} from '../custom-common';
import {Button, Header} from "../base";
import {withRouter} from "react-router-dom";
import {getLocalization, languages} from "../../utils/localization";
import {connect} from "react-redux";
import {
    getTranslatedGenderText,
    getTranslatedPhoneTypeText,
    aOEStatus,
    convertServerDateFormatToDisplay,
} from "../../utils/common";
import {setAOEStatus, setUserInfo} from "../../actions/anualOpenEnrollmentActions";
import Footer from "../footer";
import NumberFormat from "react-number-format";
import ErrorInput from "../errorInput"
import FormSelectState from "../formSelectState";
import {cancelAOEFlow} from "../../utils/utils";
import AOESteps from "./aOESteps";
import {handleSaveUserProgress} from "../../utils/common";
import {setPrevRoute} from "../../actions/navigationActions";
import CancelLink from "./cancelLink";

const mapStateToProps = (state, ownProps) => {
    const {initialData, anualOpenEnrollment, user} = state;

    return {
        ...ownProps,
        initialData,
        anualOpenEnrollment,
        lang: user.lang,
        stateOptions: anualOpenEnrollment.generalInitialData.stateOptions,
        genderOptions: anualOpenEnrollment.generalInitialData.genderOptions,
        nameSuffixOptions: anualOpenEnrollment.generalInitialData.nameSuffixOptions,
        phoneTypeOptions: anualOpenEnrollment.generalInitialData.phoneTypeOptions,
        streetAddress: anualOpenEnrollment.streetAddress,
        appartment: anualOpenEnrollment.appartment, //no
        zipCode: anualOpenEnrollment.zipCode,
        city: anualOpenEnrollment.city,
        selectedState: anualOpenEnrollment.selectedState,
        birthDate: anualOpenEnrollment.birthDate,
        ssn: anualOpenEnrollment.ssn,
        middleName: anualOpenEnrollment.middleName,
        suffix: anualOpenEnrollment.suffix,
        daytimePhoneNumber: anualOpenEnrollment.daytimePhoneNumber,
        phoneType: anualOpenEnrollment.phoneType,
        firstName: anualOpenEnrollment.firstName,
        lastName: anualOpenEnrollment.lastName,
        gender: anualOpenEnrollment.gender,
    }
};

class ReviewPersonalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitPlanLoading: false,
            validationErrorsMap: new Map(),
            invalidZipCode: false,
            invalidDepZipCode: false,
            showSubmitErrorMessage: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    handleChange = (event, {name, value}) => {
        if (name === "SET_ZIP_CODE") {
            const valNum = parseInt(value, 10);
            if (isNaN(valNum) && value !== "") {
                return;
            }
        }
        this.props.dispatch(setUserInfo(name, value));
        this.setState({error: '', showSubmitErrorMessage: false});
        this.setError(name, null)
    };

    validate = () => {
        const required = ['firstName', 'lastName', 'birthDate', 'ssn', 'streetAddress', 'city', 'zipCode', 'selectedState', 'gender'];
        let error = false;

        for (let i = 0; i < required.length; i++) {
            let item = required[i];
            if (!this.props.anualOpenEnrollment[item] || this.props.anualOpenEnrollment[item].length === 0) {
                error = true;
                break;
            }
        }
        let newMap = new Map();
        newMap["firstName"] = "SET_FIRST_NAME"
        newMap["lastName"] = "SET_LAST_NAME"
        newMap["daytimePhoneNumber"] = "SET_DAYTIME_PHONE"
        newMap["phoneType"] = "SET_PHONE_TYPE"
        newMap["streetAddress"] = "SET_STREET_ADDRESS"
        newMap["city"] = "SET_CITY"
        newMap["zipCode"] = "SET_ZIP_CODE"
        newMap["selectedState"] = "SET_SELECTED_STATE"
        newMap["gender"] = "SET_GENDER"

        for (let i = 0; i < required.length; i++) {
            let item = required[i];
            if (!this.props.anualOpenEnrollment[item] || this.props.anualOpenEnrollment[item] === null || this.props.anualOpenEnrollment[item].length === 0) {
                this.setError(newMap[item], this.props.lang === languages.ENGLISH ? "Required" : "Requerido");
            }
        }
        return error;
    }

    getError = (key) => {
        const vem = this.state.validationErrorsMap;
        if (!!vem) {
            return vem.get(key);
        }
        return null;
    }

    setError = (key, msg) => {
        const {validationErrorsMap} = this.state;
        validationErrorsMap.set(key, msg)
        this.setState({
            validationErrorsMap,
        })
    }

    next = () => {
        const {invalidZipCode, invalidDepZipCode, validationErrorsMap} = this.state;
        const error = this.validate();
        const hasErrors = invalidZipCode || invalidDepZipCode || error || validationErrorsMap.length > 0;

        if (!hasErrors) {
            handleSaveUserProgress(this.props.anualOpenEnrollment).then();
            this.props.history.push(this.props.nextRoute)
            const {status} = this.props.anualOpenEnrollment;
            if(status === aOEStatus.pending){
                this.props.dispatch(setAOEStatus(aOEStatus.inProgress));
            }
        } else {
            if (hasErrors) {
                this.setState({
                    showSubmitErrorMessage: true
                })
            }
        }
    }

    render() {
        const {
            selectedState, streetAddress, appartment, zipCode, city, birthDate, ssn, middleName, genderOptions,
            suffix, daytimePhoneNumber, phoneType, stateOptions, firstName, lastName, phoneTypeOptions, nameSuffixOptions, gender
        } = this.props;
        const {invalidZipCode} = this.state;

        const localization = getLocalization(this.props.lang);
        const strings = localization.reviewPersonalInfo;

        const button = <Button primary content={localization.next} onClick={this.next} />;
        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;

        return (
            <React.Fragment>
                <div className="member-main-container" style={{background: 'white'}}>
                    <AOESteps activeStepIdx={0}/>
                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Grid stackable>
                                <Grid.Row columns={1}>
                                    <Grid.Column textAlign={'left'}>
                                        <Header as='h2'>{strings.title}</Header>
                                        <CustomContainer basic className={"bkgLinen small"}>
                                            <p>{strings.theGreyedOut}</p>
                                        </CustomContainer>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Header as={'h4'}>{strings.yourDetails}</Header>
                                        <Form>
                                            <FormGroup widths={'equal'}>
                                                <ErrorInput fluid required={true}
                                                            error={!!this.getError('SET_FIRST_NAME')}
                                                            label={strings.firstName}
                                                            name='SET_FIRST_NAME'
                                                            value={!firstName ? '' : firstName}
                                                            onChange={this.handleChange}/>

                                                <Form.Input label={strings.middleName}
                                                            value={!middleName ? '' : middleName}
                                                            name={"SET_MIDDLE_NAME"} onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                            <FormGroup widths={'equal'}>
                                                <ErrorInput fluid required={true}
                                                                          label={strings.lastName}
                                                                          error={!!this.getError('SET_LAST_NAME')}
                                                                          name='SET_LAST_NAME'
                                                                          value={!lastName ? '' : lastName}
                                                                          onChange={this.handleChange}/>
                                                <Form.Select value={!suffix ? '' : suffix}
                                                             fluid
                                                             options={nameSuffixOptions} name={"SET_SUFFIX"}
                                                             label={strings.suffix}
                                                             onChange={this.handleChange}/>
                                            </FormGroup>
                                            <FormGroup widths={'equal'}>
                                                <DOBInput value={!!birthDate ? birthDate.length === 10 ? convertServerDateFormatToDisplay(birthDate): birthDate : ''} label={strings.dob} isReadonly required fluid/>
                                                <Form.Field required error={!!this.getError('SET_GENDER')}>
                                                    <label>{strings.gender }</label>
                                                    <Select value={gender}
                                                            name={"SET_GENDER"}
                                                            options={genderOptions.map((o)=>{
                                                                return {key: o.key, value: o.value, text: getTranslatedGenderText(o.text, getLocalization(this.props.lang))}
                                                            })}
                                                            onChange={this.handleChange}
                                                    />
                                                    {!!this.getError('SET_GENDER') && <small
                                                        className={"warningRedText"}>{this.getError('SET_GENDER')}</small>}
                                                </Form.Field>
                                            </FormGroup>
                                            <FormGroup widths={'equal'}>
                                                <Form.Field>
                                                    <NumberFormat format="(###) ###-####" placeholder="(XXX) XXX-XXXX"
                                                                  name={"SET_DAYTIME_PHONE"}
                                                                  error={!!this.getError('SET_DAYTIME_PHONE')}
                                                                  onFocus={() => {
                                                                      if (!!this.getError('SET_DAYTIME_PHONE')) {
                                                                          this.setError('SET_DAYTIME_PHONE', null);
                                                                      }
                                                                  }}
                                                                  fluid
                                                                  label={strings.daytimePhone}
                                                                  customInput={Form.Input}
                                                                  value={!daytimePhoneNumber ? '' : daytimePhoneNumber}
                                                                  onBlur={(e) => {
                                                                      const value = e.target.value;
                                                                      if (!value || value.trim().length < "(###) ###-####".length) {
                                                                          this.setError('SET_DAYTIME_PHONE', strings.invalidPhoneNumber);
                                                                      }
                                                                      this.handleChange(e, {
                                                                          name: 'SET_DAYTIME_PHONE',
                                                                          value
                                                                      })
                                                                  }}/>
                                                    {!!this.getError('SET_DAYTIME_PHONE') && <small
                                                        className={"warningRedText"}>{this.getError('SET_DAYTIME_PHONE')}</small>}
                                                </Form.Field>
                                                <Form.Field error={!!this.getError('SET_PHONE_TYPE')}>
                                                    <label>{strings.phoneType}</label>
                                                    <Select value={!phoneType ? '' : phoneType}
                                                            options={phoneTypeOptions.map((o)=>{
                                                                return {key: o.key, value: o.value, text: getTranslatedPhoneTypeText(o.text, getLocalization(this.props.lang))
                                                                }})}
                                                            name={"SET_PHONE_TYPE"}
                                                            onChange={this.handleChange}/>
                                                    {!!this.getError('SET_PHONE_TYPE') && <small
                                                        className={"warningRedText"}>{this.getError('SET_PHONE_TYPE')}</small>}
                                                </Form.Field>
                                            </FormGroup>
                                            <Form.Group>
                                                <SSNInput
                                                    disabled
                                                    label={strings.ssn}
                                                    required
                                                    value={ssn}
                                                    width={6}
                                                />
                                            </Form.Group>
                                        </Form>
                                        <Divider hidden/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Header as={'h4'}>{strings.homeAddress}</Header>
                                        <Form>
                                            <FormGroup widths={'equal'}>
                                                <ErrorInput required={true}
                                                            error={!!this.getError('SET_STREET_ADDRESS')}
                                                            label={strings.streetAddress}
                                                            name='SET_STREET_ADDRESS'
                                                            value={!streetAddress ? '' : streetAddress}
                                                            onChange={this.handleChange}/>

                                                <Form.Input label={strings.aptSuiteOther}
                                                            value={!appartment ? '' : appartment}
                                                            name={"SET_APPARTMENT"} onChange={this.handleChange}/>
                                            </FormGroup>
                                            <FormGroup widths={'equal'}>
                                                <Form.Field>
                                                    <Form.Input label={strings.zipCode} required
                                                                value={!zipCode ? '' : zipCode}
                                                                error={invalidZipCode || !!this.getError('SET_ZIP_CODE')}
                                                                name={"SET_ZIP_CODE"}
                                                                onFocus={() => {
                                                                    this.setState({
                                                                        invalidZipCode: false,
                                                                    });
                                                                }}
                                                                onChange={this.handleChange}
                                                                onBlur={() => {
                                                                    const isError = !zipCode || zipCode.length === 0 || ((zipCode.length > 5 && zipCode.length < 9) || zipCode.length < 5 || zipCode.length > 9);
                                                                    this.setState({
                                                                        invalidZipCode: isError || isNaN(zipCode * 1),
                                                                    })
                                                                }}/>
                                                    {!!invalidZipCode &&
                                                    <small style={{color: 'red'}}>{strings.invalidZipCode}</small>}
                                                    {!!this.getError('SET_ZIP_CODE') && <small
                                                        className={"warningRedText"}>{this.getError('SET_ZIP_CODE')}</small>}
                                                </Form.Field>


                                                <ErrorInput required={true}
                                                                          error={!!this.getError('SET_CITY')}
                                                                          label={strings.city}
                                                                          name='SET_CITY'
                                                                          value={!city ? '' : city}
                                                                          onChange={this.handleChange}/>
                                            </FormGroup>
                                            <FormGroup>
                                                    <FormSelectState value={selectedState || ''}
                                                                     initialValue={selectedState || ''}
                                                                     options={stateOptions}
                                                                fluid name={"SET_SELECTED_STATE"}
                                                                onChange={this.handleChange}/>
                                            </FormGroup>
                                        </Form>
                                        <Divider hidden/><Divider hidden/><Divider hidden/><Divider hidden/>
                                    </Grid.Column>

                                    {this.state.showSubmitErrorMessage &&
                                    <Grid.Column><Divider hidden/><Message negative>
                                        <b>{strings.genericErrTitle}</b> {strings.genericErr}</Message>
                                    </Grid.Column>}
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter showCenterData button={button} link={cancel}/>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ReviewPersonalInfo));
