import {navigationActionTypes} from "../actions/actionTypes";

const initialState = {
    showLeftNavigation: null,
    prevRoute: null,
    lastRoute: null
};

export const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case navigationActionTypes.SET_LEFT_NAV:
            return {
                ...state,
                showLeftNavigation: action.payload,
            }
        case navigationActionTypes.SET_PREV_ROUTE:
            return {
                ...state,
                prevRoute: action.payload
            }
        case navigationActionTypes.SET_LAST_ROUTE:
            return {
                ...state,
                lastRoute: action.payload
            }
        default:
            return state
    }
};