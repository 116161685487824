import React, {Component} from 'react';
import {Container, Divider, Form, Grid, Input, Loader, Message} from "semantic-ui-react";
import {Button, Header} from "./base";
import {withRouter} from "react-router-dom";
import axios from "axios";
import Footer from "./footer";
import {resolveErrorMessage} from "../utils/common";
import {connect} from "react-redux";
import MainMenu from "./MainMenu";
import {S3Image} from "./custom-common";
import { isMobile } from '../utils/utils';

const mapStateToProps = (state, ownProps) => {
    const { useWeb } = state.mobile
    return {
        ...ownProps,
        useWeb,
        verifyToken: state.auth.verifyToken
    }
};

class CreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            agreeToTerms: false,
            loading: false,
            requesting: false,
            submitError: null,
            tokenError: null,
            validationErrors: {
                password: null,
                confirmPassword: null,
                agreeToTerms: null
            }
        };
    }

    status = { isMobile: isMobile() }
    componentDidMount() {
    }

    handleChange = (e, {name, value}) => {
        const {validationErrors} = this.state
        validationErrors[name] = null
        this.setState({[name]: value, validationErrors: validationErrors})
    }

    handleAcceptTermsChange = () => {
        const {agreeToTerms, validationErrors} = this.state;
        validationErrors.agreeToTerms = null
        this.setState({agreeToTerms: !agreeToTerms, validationErrors: validationErrors})
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validate();
        if (errors){
            return;
        }

        try {
            const token = this.props.verifyToken;
            let payload = {token: token, password: this.state.password}
            this.setState({requesting: true, submitError: null});
            const response = await axios.post("/api/member/v2/createMemberAccount", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({requesting: false});
            const nextRoute = !!response.data.isPrimary ? "/login" : "/welcome"
            if(this.status.isMobile && !this.props.useWeb){
                this.props.history.push({pathname: '/betterOnTheApp'});
            }else{
                this.props.history.replace(nextRoute);
            }
        } catch (e) {
            this.setState({
                requesting: false,
                submitError: resolveErrorMessage(e, "Unable to create password")
            });
        }
    }

    validate = () => {
        const {validationErrors, password, confirmPassword, agreeToTerms} = this.state
        let error = !this.validatePassword()
        if (password !== confirmPassword) {
            validationErrors.confirmPassword = "Passwords do not match"
            error = false
        }
        if (!agreeToTerms) {
            validationErrors.agreeToTerms = "Must agree to terms"
            error = false
        }
        this.setState({validationErrors: validationErrors})
        return error
    }

    validatePassword = () => {
        const {password, validationErrors} = this.state;
        const invalidPassword = (password.length < 10 || password.search(/[A-Z]/) < 0 || password.search(/[0-9]/) < 0);
        if (invalidPassword) {
            validationErrors.password = "Passwords must contain at least 10 characters, one uppercase letter, and one number."
            this.setState({validationErrors: validationErrors})
            return false
        }
        return true;
    }

    validateConfirmationPassword = () => {
        const {confirmPassword, password, validationErrors} = this.state;
        const invalidPassword = (password !== confirmPassword);
        if (invalidPassword) {
            validationErrors.confirmPassword = "Passwords do not match."
            this.setState({validationErrors: validationErrors})
            return false
        }
        return true;
    }

    render() {
        const {password, confirmPassword, agreeToTerms, validationErrors, loading, requesting, submitError} = this.state;
        if (loading) {
            return <Container><Loader active={true}/></Container>
        }
        const disableSubmit = !agreeToTerms || password.length < 10 || validationErrors.password || confirmPassword !== password || requesting

        return (
            <div className="member-main-container" style={{background: 'white'}}>
                <MainMenu center={"Create account"}/>
                <Divider hidden/><Divider hidden/>
                <Grid container stackable columns={1} centered>
                    <Grid.Column width={6}>
                        <S3Image name={"welcome"} common centered height={155}/>
                        <div className={'divider1_5'}></div>
                        <Header as='h1' textAlign='center'>Create password</Header>
                        <p style={{textAlign: 'center'}}>Let’s create your account by choosing a password below—<br/>
                            passwords must contain at least 10 characters,
                            one uppercase letter, and one number.</p>
                        <Divider hidden/>
                        <Grid container stackable centered>
                            <Grid.Column width={9} textAlign='left' className={"pageContent"}>
                                <Form>
                                    <Form.Field error={!!validationErrors.password} required>
                                        <label style={{fontSize: '14px', fontWeight: '700', lineHeight: '150%'}}>Enter password</label>
                                        <Input
                                            fluid type="password"
                                            name='password'
                                            value={password}
                                            onChange={this.handleChange}
                                            onFocus={() => {
                                                this.setState({passwordError: null});
                                            }}
                                            onBlur={(e) => {
                                                const val = e.target.value;
                                                this.validatePassword(val);
                                            }}
                                        />
                                        {validationErrors.password && <span className={"warningRedText smaller"}>{validationErrors.password}</span>}
                                    </Form.Field>

                                    <Form.Field error={!!validationErrors.confirmPassword} required>
                                        <label style={{fontSize: '14px', fontWeight: '700', lineHeight: '150%'}}>Re-enter password</label>
                                        <Input
                                            fluid type="password"
                                            name='confirmPassword'
                                            value={confirmPassword}
                                            onChange={this.handleChange}
                                            onBlur={(e) => {
                                                const val = e.target.value;
                                                this.validateConfirmationPassword(val);
                                            }}
                                        />
                                        {!!validationErrors.confirmPassword && <span className={"warningRedText smaller"}>{validationErrors.confirmPassword}</span>}
                                    </Form.Field>
                                    <Form.Checkbox name="agreeToTerms" checked={agreeToTerms} onChange={this.handleAcceptTermsChange}
                                                   label={<label>I accept the&nbsp;
                                                       <a style={{fontWeight: 700}} href="https://www.joinansel.com/terms" target="_blank" rel="noopener noreferrer">Terms</a>
                                                       &nbsp;and&nbsp;
                                                       <a style={{fontWeight: 700}} href="https://www.joinansel.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy
                                                           Policy</a>
                                                   </label>}
                                    />
                                    {validationErrors.agreeToTerms && <span className={"warningRedText smaller"}>{validationErrors.agreeToTerms}</span>}

                                    {!!submitError && <Form.Field><Message negative>{submitError}</Message></Form.Field>}
                                    <Form.Field>
                                        <Button onClick={this.handleSubmit} primary style={{width: '101px', height: '45px', padding: '12px 20px 12px 20px'}}
                                                disabled={disableSubmit} loading={requesting}>
                                            Continue
                                        </Button>
                                    </Form.Field>
                                </Form>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                </Grid>
                <Footer/>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(CreatePassword));
