import React from 'react';
import {GridRow} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {getKeyOrValueAndKey, mergeStrings} from './utils/utils';

export const CustomRow = ({ fitted, compact,className,...rest }) => {
    const fittedClass = getKeyOrValueAndKey(fitted, 'fitted')
    const compactClass = getKeyOrValueAndKey(compact, 'compact')
    const classes = mergeStrings(className, fittedClass, compactClass);
    return <GridRow {...rest} className={classes}/>

};

CustomRow.propTypes = {
    fitted: PropTypes.oneOfType([
        PropTypes.bool, PropTypes.oneOf(['horizontally', 'vertically'])
    ]),
    compact: PropTypes.oneOfType([
        PropTypes.bool, PropTypes.oneOf(['horizontally', 'vertically'])
    ]),
};