import {anualOpenEnrollmentActionTypes} from "./actionTypes";

export function setAOEDependent(dependent, idx) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_AOE_DEPENDENT,
        payload: {aOEDependent: dependent, aOEDependentIdx: idx}
    }
}

export function setAOEDependents(dependents) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_AOE_DEPENDENTS,
        payload: {dependents}
    }
}

export function removeAOEDependent(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.REMOVE_AOE_DEPENDENT,
        payload: {idx: payload}
    }
}

export function setDependent(dependent, idx) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_DEPENDENT,
        payload: {dependent: dependent, idx: idx}
    }
}

export function removeDependent(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.REMOVE_DEPENDENT,
        payload: {idx: payload}
    }
}

export function setAOEInitialData(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_AOE_INITIAL_DATA,
        payload: payload
    }
}

export function setAOEUserInitialData(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_AOE_USER_INITIAL_DATA,
        payload: payload
    }
}

export function setAOEInitialDependents(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_AOE_INITIAL_DEPENDENTS,
        payload: payload
    }
}

export function setUserInfo(actionName, payload) {
    return {
        type: anualOpenEnrollmentActionTypes[actionName],
        payload: payload
    }
}

export function clearAOEData(){
    return {
        type: anualOpenEnrollmentActionTypes.CLEAR_AOE_DATA,
        payload: {}
    }
}

export function setHealthPlanDetails(typicallyPayInfo, highDeductibleInfo) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_HEALTH_PLAN_DETAILS,
        payload: {typicallyPayInfo, highDeductibleInfo}
    }
}

export function setFetchPlanRecommendation(fetchPlanRecommendation) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_FETCH_PLAN_RECOMMENDATION,
        payload: {fetchPlanRecommendation}
    }
}

export function setLeftoverMoney(leftoverMoney) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_LEFTOVER_MONEY,
        payload: leftoverMoney
    }
}

export function setCoverageOption(opt, deleteChildren, deleteSpouse) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_COVERAGE_OPTION,
        payload: {selectedCoverageOption: opt, deleteChildren, deleteSpouse }
    }
}

export function setCostPerOption(opt) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_COST_PER_OPTION,
        payload: opt
    }
}

export function setModerateCoverage(opt) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_MODERATE_COVERAGE,
        payload: opt
    }
}

export function setSevereCoverage(opt) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_SEVERE_COVERAGE,
        payload: opt
    }
}

export function setCatastrophicCoverage(opt) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_CATASTROPHIC_COVERAGE,
        payload: opt
    }
}

export function setPlanCoverageSelectedAndRecommended(moderate, moderateSelected, severe, severeSelected, catastrophic, catastrophicSelected) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_SELECTED_AND_RECOMMENDED_COVERAGE,
        payload: {moderate, moderateSelected, severe, severeSelected, catastrophic, catastrophicSelected}
    }
}

export function setPlanCoverageSelectedAndRecommendedSelect(costValue, costEnhanced, costPremier, contribValue, contribEnhanced, contribPremier) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_SELECT_COST,
        payload: {costValue, costEnhanced, costPremier, contribValue, contribEnhanced, contribPremier}
    }
}

export function setPlanContribAndCost(contrib, cost) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_PLAN_CONTRIB_COST,
        payload: {employerContribution: contrib, cost}
    }
}

export function setBrellaSelectTierSelected(value, premier, enhanced) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_BRELLA_SELECT_TIER_SELECTED,
        payload: {value, premier, enhanced}
    }
}

export function setProgressData(data) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_PROGRESS_DATA,
        payload: data
    }
}

export function setLastVisitedSavePoint(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_LAST_VISITED_SAVEPOINT_ROUTE,
        payload: payload
    }
}

export function setKeepCoverage(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_KEEP_COVERAGE,
        payload: payload
    }
}

export function showCancelAOEModal(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SHOW_CANCEL_AOE_MODAL,
        payload: payload
    }
}

export function setAOEStatus(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_STATUS,
        payload: payload
    }
}

export function setAOEPreviousUrl(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_PREVIOUS_URL,
        payload: payload
    }
}

export function setAOEFamilyChanged() {
    return {
        type: anualOpenEnrollmentActionTypes.SET_FAMILY_CHANGED
    }
}

export function setSkipRecommendation(payload) {
    return {
        type: anualOpenEnrollmentActionTypes.SET_SKIP_RECOMMENDATION,
        payload: payload
    }
}
