import React, {Component} from 'react'
import {Dropdown, Menu, Responsive} from 'semantic-ui-react'
import {NavLink} from "react-router-dom"

/*const mapStateToProps = (state, ownProps) => {
    return {
        authToken: state.auth.authToken,
        ...ownProps
    }
};*/

class MainMenu extends Component {
    logout = () => {
    };

    render() {
        return (
            <React.Fragment>
                <Menu fixed='top' secondary pointing className="member-menuContainer" style={{background: '#fff'}}>
                    <Responsive as={Menu.Menu} position='right' minWidth={810}>
                        <Menu.Item as={NavLink} activeClassName="active" exact to="/lookup">Lookup</Menu.Item>
                        <Menu.Item as={NavLink} activeClassName="active" exact to="/transcribe">Transcribe</Menu.Item>
                        <Menu.Item as={NavLink} activeClassName="active" exact to="/places">Places</Menu.Item>
                    </Responsive>
                    <Responsive as={Menu.Menu} position='right' maxWidth={811}>
                        <Dropdown item text='Modules'>
                            <Dropdown.Menu>
                                <Dropdown.Item as={NavLink} activeClassName="active" exact
                                               to="/lookup">Lookup</Dropdown.Item>
                                <Dropdown.Item as={NavLink} activeClassName="active" exact
                                               to="/transcribe">Transcribe</Dropdown.Item>
                                <Dropdown.Item as={NavLink} activeClassName="active" exact
                                               to="/places">Places</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Responsive>
                </Menu>
            </React.Fragment>
        )
    }
}

export default MainMenu;