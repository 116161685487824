import React, {Component} from "react"
import {Divider, Grid, Loader, Message} from "semantic-ui-react";
import {Button, Header} from "../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setPrevRoute} from "../../actions/navigationActions";
import Footer from "../footer";
import moment from "moment";
import axios from "axios";
import {aOEStatus, resolveErrorMessage} from "../../utils/common";
import {setAOEStatus} from "../../actions/anualOpenEnrollmentActions";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import IMAGE from "../../constants/images";
import {S3Image} from "../custom-common";

const mapStateToProps = (state, ownProps) => {
    const {initialData} = state;
    return {
        ...ownProps,
        initialData,
    }
};

class EndCoverage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
    }

    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    submit = async() => {
        this.setState({loading: true})
        try {
            await axios.post("/api/member/v2/endMyCoverage", {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(setAOEStatus(aOEStatus.declined));
            this.props.history.push('/plan')
        } catch(e) {
            this.setState({loading: false, error: resolveErrorMessage(e, "An unexpected error ocurred")})
        }
    }

    render() {
        const button = (<Button primary content={"End my coverage"} onClick={this.submit}/>)
        if (!!this.state.loading) {
            return <Loader active/>
        } else {
            return (
                <React.Fragment>
                    <div className="member-main-container" style={{background: 'white'}}>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={8} className={"pageContent"}>
                                <Grid stackable container style={{marginTop: '5em'}} textAlign={'left'}>
                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <S3Image {...IMAGE.ICON_MAP} />
                                            <Divider hidden/><Divider hidden/>
                                        </Grid.Column>
                                        <Grid.Column textAlign={"center"}>
                                            <Header as='h1'>Are you sure?</Header>
                                            <Divider hidden/>
                                            <p>
                                                We’re sorry to see you go. Once your {STRATEGIC_PARTNER.LABEL} coverage ends,<br/>you’ll no longer be eligible to receive claim payouts for<br/>covered conditions.
                                            </p>
                                        <Divider hidden/>
                                            <p>
                                                <b>Your {STRATEGIC_PARTNER.LABEL} coverage will end on {moment(this.props.initialData.certificate.expirationDate).format("MMM DD, YYYY")}</b>
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider hidden/><Divider hidden/>

                                {!!this.state.error && <Message negative>{this.state.error}</Message>}
                            </Grid.Column>
                        </Grid>
                    </div>
                    <Footer showTopFooter showCenterData button={button} largeButton />
                </React.Fragment>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps)(EndCoverage));
