import React from "react";
import PaperCheckModal from "./paperCheckModal";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";

const PaperCheckInstructionsModal = ({strings}) => {
    const {paperCheckInstructionsVisibility, hidePaperCheckInstructions} = PaymentDetailsContext.useContainer();
    return <PaperCheckModal strings={strings} size="tiny" onClose={hidePaperCheckInstructions} open={paperCheckInstructionsVisibility} />;
}

export default PaperCheckInstructionsModal;
