import React from 'react';
import {Table} from "semantic-ui-react";
import NumberFormat from "react-number-format";


const ItemsDetailsTable = ({ title, items }) => (
    <Table className={'itemsDetailsTable'}>
        <Table.Header>
            <Table.Row style={{padding: 0}}>
                <Table.Cell className={'neutral600Text'}>
                    { title }
                </Table.Cell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {
                items.map(item => (item.value &&
                         <Table.Row key={`item-${item.name.toLowerCase()}`} className={'neutral900Text'}>
                            <Table.Cell><b>{ item.name }</b></Table.Cell>
                            <Table.Cell>
                                <NumberFormat
                                    value={item.value}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )
                )
            }
        </Table.Body>
    </Table>
);

export default ItemsDetailsTable;