import React, {useState, useEffect} from "react";
import {
  MFAContainer,
  MFAForm,
  MFAGrid,
  MFAHeading,
  MFAIcon,
  MFAInput,
  MFALabel,
  MFALink,
  MFAMethodValidatorContainer,
  MFAMethodValidatorMsg,
  MFASegment,
  MFAText,
  MFATextError,
} from "../../../styled";
import Toast from "../../../../components/billing/toast";
import {MAX_CODE_LENGTH} from "../../../constants";
import {segToHours} from "../../../../utils/date";

const VerifyRenderItem = ({
  code,
  isDisabled,
  codeHandler,
  validateMfaCodeError,
  isCodeEmailVerified,
  validateForm,
  validationErrors,
  hasReachedMaxCodeRequests
}) => {
  return (
    <>
      <MFAForm.Field
        className="verifyWrapper"
      >
        <MFALabel
          className="verifyLabel"
        >
          6-digit authentication code
        </MFALabel>
        <MFAInput
          type="text"
          name="emailCode"
          id="emailCode"
          value={code}
          onChange={(e) => codeHandler(e)}
          maxLength={MAX_CODE_LENGTH}
          error={validateMfaCodeError || validationErrors}
          required
          disabled={isDisabled || isCodeEmailVerified || hasReachedMaxCodeRequests}
          onBlur={(e) => validateForm(code)}
        />
        <MFAMethodValidatorContainer>
          <MFAMethodValidatorMsg>
            {(validateMfaCodeError && !validationErrors) &&
              <MFATextError className={'verifyError'}>
                {validateMfaCodeError}
              </MFATextError>
            }

            {validationErrors && (
              <MFATextError>
                Enter a 6-digit authentication code
              </MFATextError>
            )}
          </MFAMethodValidatorMsg>
        </MFAMethodValidatorContainer>

      </MFAForm.Field>

      {isCodeEmailVerified &&
        (
          <MFAText className="verified">
            <MFAIcon name="checkmark" size="small" className="verifiedIcon" />
            Verified
          </MFAText>
        )}
    </>
  );
};

const EmailVerify = ({
   codeHandler,
   validationErrors,
   code,
   validateMfaCodeError,
   loading,
   mfaEnabled,
   changeMethodSettings,
   validateForm,
   sendCodeByEmailHandler,
   goBackStepOne,
   hasReachedMaxCodeRequests,
   isCodeEmailVerified,
   remainingCodeResendWindowSeconds,
   remainingLockedOutTimeWindowSeconds,
   mfaEmailSetUpped
 }) => {
  const [counterSendEmail, setCounterSendEmail] = useState(remainingCodeResendWindowSeconds);
  const [runTime, setRunTime] = useState(false);
  const [toastEnabled, setToastEnabled] = useState(false)
  const toggleToast = () => setToastEnabled(!toastEnabled);


  const wrappSendCodeByEmailHandler = async () => {
    if (runTime || isCodeEmailVerified || hasReachedMaxCodeRequests) return

    await sendCodeByEmailHandler()
    toggleToast()
    setRunTime(true)
  }

  useEffect(() => {
    if(runTime) {
      setCounterSendEmail(remainingCodeResendWindowSeconds);
      setCounterSendEmail((time) => time - 1 );

      const interval = setInterval(() => {
        setCounterSendEmail((time) => time - 1 );
      }, 1000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [runTime])

  useEffect(() => {
    if(counterSendEmail === 0) {
      setRunTime(false)
    }
  }, [counterSendEmail]);

  useEffect(() => {
    setRunTime(true)
  }, []);

    return (
        <>
          <MFAContainer className="sectionWrapper" maxWidth={"497px"}>
            <MFAGrid>
              <MFAGrid.Row>
                <MFAGrid.Column>
                  <MFAHeading as="h2">Enter authentication code</MFAHeading>
                  <MFAText>
                    Please enter the 6-digit code we sent you to {mfaEmailSetUpped} to verify setup below—
                  </MFAText>
                </MFAGrid.Column>
              </MFAGrid.Row>
              <MFAGrid.Row>
                <MFAGrid.Column>
                  <MFAForm>
                    <MFAForm.Group grouped>
                      <MFAGrid padded={false}>
                        <MFAGrid.Row className="noVerticalPadding">
                          <MFAGrid.Column>
                            {!changeMethodSettings && (
                              <VerifyRenderItem
                                code={code}
                                validationErrors={validationErrors}
                                isDisabled={mfaEnabled}
                                loading={loading}
                                codeHandler={codeHandler}
                                validateForm={validateForm}
                                validateMfaCodeError={validateMfaCodeError}
                                isCodeEmailVerified={isCodeEmailVerified}
                                hasReachedMaxCodeRequests={hasReachedMaxCodeRequests}
                              />
                            )}

                            {hasReachedMaxCodeRequests && (
                              <MFASegment padded mt={12} className={"mfaSettingEmailBox mfaErrorBox mfaSettingText"}>
                                <strong>You have reached the maximum number of code requests.</strong> <br/>
                                Please try again in {segToHours(remainingLockedOutTimeWindowSeconds)} hours or try a different authentication method.
                              </MFASegment>
                            )}

                            <MFAText mt={12} className={"settingsItalic"}>
                              If you haven’t received the email after a few minutes,
                              please check your junk mail folder as it may have been
                              mistaken for spam.
                            </MFAText>

                            <MFAText mt={24}>
                              Didn’t receive the code?&nbsp;
                              <MFALink
                                className={`${(runTime || isCodeEmailVerified || hasReachedMaxCodeRequests) && "disabled"}`}
                                onClick={wrappSendCodeByEmailHandler}
                              >
                                Resend {(runTime) && `in ${counterSendEmail}s`}
                              </MFALink>
                            </MFAText>

                            {!isCodeEmailVerified && (
                              <MFAText mt={24}>
                                Having trouble?&nbsp;
                                <MFALink marginTop={24} onClick={goBackStepOne}>
                                  Try a different method
                                </MFALink>
                              </MFAText>
                            )}

                          </MFAGrid.Column>
                        </MFAGrid.Row>
                      </MFAGrid>
                    </MFAForm.Group>
                  </MFAForm>
                </MFAGrid.Column>
              </MFAGrid.Row>
            </MFAGrid>

            {toastEnabled && (
              <Toast
                success
                title={"Email sent successfully"}
                text={"A 6-digit code was sent for two-factor authentication."}
                onClose={toggleToast}
                autodismiss={5000}
              />
            )}

          </MFAContainer>
        </>
    )
}

export default EmailVerify
