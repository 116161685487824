
import React from 'react';

import {MFAGrid} from '../../styled';
import Stepper from './stepper';
import MethodSelector from '../methods/methodSelector';

//Authenticator
import AuthenticatorConfig from '../methods/authenticator/config';
import AuthenticatorVerify from '../methods/authenticator/verify'

//Email
import EmailConfig from '../methods/email/config';
import EmailVerify from '../methods/email/verify'

//SMS
import SmsConfig from '../methods/sms/config';
import SmsVerify from '../methods/sms/verify'

//Backup Codes
import BackupCodes from '../backup'

//Success Page
import SuccessMFASetup from '../success'
import {MFA_TYPE} from "../../constants";

const Steps = ({
   methodType,
   currentStep,
   selectMethodHandler,
   codeHandler,
   validationErrors,
   mfaSetupParams,
   code,
   setMfaSavedCodes,
   mfaCodeAreSaved,
   mfaEnabled,
   validateMfaCode,
   validateMfaCodeError,
   loading,
   backupCodes,
   continueToPortal,
   mfaSetup,
   STEPS_CONFIG_INIT,
   changeMethodSettings,
   isMethodChanged,
   validateForm,
   sendCodeByEmailHandler,
   goBackStepOne,
   mfaConfigured,
   addAnotherMethod,
   hasReachedMaxCodeRequests,
   isCodeEmailVerified,
   remainingCodeResendWindowSeconds,
   remainingLockedOutTimeWindowSeconds,
   toggleFooterActionDisabled,
   setEmailSetUpToMFA,
   mfaEmailSetUpped
}) => {

    return (

        <>
            <MFAGrid centered>
                    {mfaSetup && (
                    <MFAGrid.Row>
                        <MFAGrid.Column>
                            {mfaSetup && (<Stepper step={currentStep} steps={STEPS_CONFIG_INIT} changeMethodSettings={changeMethodSettings}/>)}
                        </MFAGrid.Column>
                    </MFAGrid.Row>
                    )}
                    <MFAGrid.Row>
                        <MFAGrid.Column>
                        {((!mfaEnabled || addAnotherMethod) && currentStep === 0) && (
                          <MethodSelector
                            methodType={methodType}
                            selectMethodHandler={selectMethodHandler}
                            isEmailConfigured={mfaConfigured.includes(MFA_TYPE.EMAIL)}
                            isTotpConfigured={mfaConfigured.includes(MFA_TYPE.TOTP)}
                            isSmsConfigured={mfaConfigured.includes(MFA_TYPE.SMS)}
                          />
                        )}
                        {(((!mfaEnabled || addAnotherMethod) && currentStep === 1) || (changeMethodSettings && currentStep === 0)) && (
                                <>
                                    {methodType === MFA_TYPE.TOTP && <AuthenticatorConfig mfaSetupParams={mfaSetupParams} />}
                                    {methodType === MFA_TYPE.EMAIL && (<EmailConfig toggleFooterActionDisabled={toggleFooterActionDisabled} setEmailSetUpToMFA={setEmailSetUpToMFA}  />)}
                                    {methodType === MFA_TYPE.SMS && (<SmsConfig />)}
                                </>
                            )

                        }
                        {((currentStep === 2 && !changeMethodSettings) || (changeMethodSettings && currentStep === 1)) &&
                         (
                            <>
                                {methodType === MFA_TYPE.TOTP &&
                                  <AuthenticatorVerify
                                    goBackStepOne={goBackStepOne}
                                    codeHandler={codeHandler}
                                    validationErrors={validationErrors}
                                    code={code}
                                    validateMfaCodeError={validateMfaCodeError}
                                    validateMfaCode={validateMfaCode}
                                    loading={loading}
                                    mfaEnabled={mfaEnabled}
                                    validateForm={validateForm}
                                    changeMethodSettings={changeMethodSettings}
                                    isMethodChanged={isMethodChanged}
                                    addAnotherMethod={addAnotherMethod}
                                    isCodeEmailVerified={isCodeEmailVerified}
                                  />
                                }
                                {methodType === MFA_TYPE.EMAIL &&
                                  <EmailVerify
                                    validateMfaCodeError={validateMfaCodeError}
                                    codeHandler={codeHandler}
                                    validationErrors={validationErrors}
                                    code={code}
                                    sendCodeByEmailHandler={sendCodeByEmailHandler}
                                    validateForm={validateForm}
                                    goBackStepOne={goBackStepOne}
                                    hasReachedMaxCodeRequests={hasReachedMaxCodeRequests}
                                    isCodeEmailVerified={isCodeEmailVerified}
                                    remainingCodeResendWindowSeconds={remainingCodeResendWindowSeconds}
                                    remainingLockedOutTimeWindowSeconds={remainingLockedOutTimeWindowSeconds}
                                    mfaEmailSetUpped={mfaEmailSetUpped}
                                  />
                                }
                                {methodType === MFA_TYPE.SMS &&
                                  <SmsVerify
                                    codeHandler={codeHandler}
                                    validationErrors={validationErrors}
                                  />
                                }
                            </>
                         )
                        }
                        {((currentStep === 3 && !changeMethodSettings && !isMethodChanged && !addAnotherMethod) || (changeMethodSettings && currentStep === 2 && !addAnotherMethod)) && (
                          <BackupCodes
                            savedCodes={mfaCodeAreSaved}
                            setMfaSavedCodes={setMfaSavedCodes}
                            backupCodes={backupCodes}
                          />
                        )}
                        {((mfaEnabled && currentStep === 4) || (isMethodChanged && currentStep === 3 ) || (addAnotherMethod && currentStep === 3)) && (<SuccessMFASetup continueToPortal={continueToPortal} />)}
                        </MFAGrid.Column>
                    </MFAGrid.Row>
            </MFAGrid>
        </>
    )
}

export default Steps;
