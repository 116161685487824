import React from 'react';
import {Container, Image} from 'semantic-ui-react';
import Footer from "./footer";
import {connect, useDispatch} from "react-redux";
import {getLocalization} from "../utils/localization";
import {toggle as toggleGetHelpModal} from "../actions/getHelpActions";
import {usePreventBackNavigation} from "../hooks";

const mapStateToProps = ({initialData}, ownProps) => {
    const strings = getLocalization(initialData.user.lang);

    return {
        ...ownProps,
        strings,
    }
};

const style = {
    height: "200px",
    width: "200px",
    backgroundColor: "#FFF7EC",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

const EnrollmentEnded = (props) => {
    const {strings} = props;
    const dispatch = useDispatch();
    usePreventBackNavigation();

    return (
        <>
            <Container className={'stopScreens'} style={{texdAling: "center"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={style}>
                        <Image style={{minWidth: 180, maxWidth: 180, paddingLeft: "5px", paddingBottom: "12px"}}
                               centered src={process.env.PUBLIC_URL + '/images/icon_map.png'}/>
                    </div>
                </div>
                <h1 className={'vibranBlueHeader'} style={{fontSize: '32px', marginBottom: '5px', textAlign: "center"}}>
                    {strings.enrollmentEnded.looksLike}<br/>
                    {strings.enrollmentEnded.brellaAnymore}
                </h1>
                <br/>
                <p className={'neutral700Text'} style={{textAlign: "center"}}>
                    {strings.enrollmentEnded.weHave}
                    <br/>
                    <br/>
                    {strings.enrollmentEnded.ifYouDecide}
                    <a href onClick={() => dispatch(toggleGetHelpModal(true))}>
                        {strings.enrollmentEnded.contactOur}
                    </a>
                    {strings.enrollmentEnded.forMore}
                </p>
            </Container>
            <Footer showTopFooter={false} showCenterData={false}/>
        </>
    );
}

export default connect(mapStateToProps)(EnrollmentEnded);
