import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Form, Grid, Icon, Loader, Message, Table} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {BrandedLogo, Header, SupportEmailLink} from "../base";
import {connect} from "react-redux";
import {buildFullName, dowloadBase64, formatMoney, resolveTierShort, strFormatMoney} from "../../utils/utils";
import moment from "moment";
import axios from "axios";
import PaymentAuthorizationCard from "./paymentAuthorizationCard";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import InvoiceDetailModalHeader from "./invoiceDetailModalHeader";
import {setLeftNavigation} from "../../actions/navigationActions";
import Footer from "../footer";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        employer: state.initialData.employer,
        historicalInvoices: state.billing.historicalInvoices,
        user: state.initialData.user,
        contract: state.initialData.contract,

    }
};

class InvoiceDetails extends Component {
    constructor(props) {
        super(props);
        const id = this.props.match.params.id;
        this.state = {
            loading: false,
            error: null,
            adjDirection: "ascending",
            adjColumn: null,
            invoice: null,
            adjSearch: '',
            adjFilteredData: [],
            showPaymentSuccess: false,
            noInvoiceError: null,
            invoiceId: id,
            employeeTransactions: [],
        }
    }

    async componentDidMount() {
        this.props.dispatch(setLeftNavigation(false))
        await this.callRefreshInvoice()
    }

    filterAdjustments = (event, {value}) => {
        const {invoice} = this.state;
        const filteredData = invoice.adjustments.filter(item => {
            if (value === '') {
                return true
            }
            const searchValue = value.toLowerCase()
            return item.type.toLowerCase().includes(searchValue)
        })
        this.setState({adjFilteredData: filteredData, adjSearch: value})
    }

    sortAdj = (column) => {
        const {adjDirection, adjFilteredData} = this.state;
        const sortableItems = adjFilteredData;
        sortableItems.sort((a, b) => {
            let v1, v2
            v1 = a[column]
            v2 = b[column]
            if (v1 < v2) {
                return adjDirection === 'ascending' ? -1 : 1;
            }
            if (v1 > v2) {
                return adjDirection === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        this.setState({
            adjDirection: adjDirection === "ascending" ? "descending" : "ascending",
            adjColumn: column,
            adjFilteredData: sortableItems,
            selectedRowIndex: null
        });
    }

    renderInvoiceInfo = () => {
        const {employeeTransactions} = this.state;

        //if(!contact) return null;
        return (
            <CustomContainer basic style={{
                background: '#fafafa',
            }}>
                <Header as={"h5"}><b>Premium details</b></Header>

                {employeeTransactions.map((item, index) => {
                    return <CustomContainer basic key={index}
                                    className={!item.completePeriod || !item.currentPeriod ? "bkgLinen" : ""}>
                        <Grid columns={4}>
                            <Grid.Column><span
                                className={"vibranBlueText"}><b>{item.currentPeriod === true ? "This month" : "Previous coverage"}</b></span></Grid.Column>
                            <Grid.Column>{item.startDate ? moment(item.startDate).format('MMM Do, YYYY') : "-"}</Grid.Column>
                            <Grid.Column>{item.endDate ? moment(item.endDate).format('MMM Do, YYYY') : "-"}</Grid.Column>
                            <Grid.Column>{strFormatMoney(item.amount)} {(!item.completePeriod || !item.currentPeriod) &&
                            <Icon name={"flag"} className={"peach"}/>}  </Grid.Column>
                        </Grid>
                    </CustomContainer>
                })}


                <Divider hidden/>
                <Header as={"h5"}><b>Coverage details</b></Header>
                {employeeTransactions.map((item, index) => {
                    return <CustomContainer basic key={index}>
                        <Grid columns={6}>
                            <Grid.Column width={12} style={{padding: 0}}>
                                            <span
                                                className={"smaller"}>Effective Date <b>{moment(item.startDate).format('MMM Do, YYYY')} - {moment(item.endDate).format('MMM Do, YYYY')}</b></span>
                            </Grid.Column>

                            <Grid.Column className={"bkgNeutral100"}>
                                <div className={"neutral700Text smaller"}><b>Moderate</b></div>
                                <span className={"vibranBlueText"}>
                                            <b>{formatMoney(item.benefits?.moderate)}</b>
                                        </span>
                            </Grid.Column>

                            <Grid.Column className={"bkgNeutral100"}>
                                <div className={"neutral700Text smaller"}><b>Severe</b></div>
                                <span className={"vibranBlueText"}>
                                            <b>{formatMoney(item.benefits?.severe)}</b>
                                        </span>
                            </Grid.Column>

                            <Grid.Column className={"bkgNeutral100"}>
                                <div className={"neutral700Text smaller"}><b>Catastrophic</b></div>
                                <span className={"vibranBlueText"}>
                                            <b>{formatMoney(item.benefits?.catastrophic)}</b>
                                        </span>
                            </Grid.Column>

                            <Grid.Column className={"bkgNeutral100"}>
                                {!!item.benefits?.chronic &&
                                <React.Fragment>
                                    <div className={"neutral700Text smaller"}><b>Chronic</b></div>
                                    <span className={"vibranBlueText"}>
                                            <b>{formatMoney(item.benefits?.chronic)}</b>
                                            </span>
                                </React.Fragment>
                                }
                            </Grid.Column>

                            <Grid.Column className={"bkgNeutral100"}>
                                {!!item.benefits?.mental &&
                                <React.Fragment>
                                    <div className={"neutral700Text smaller"}><b>Mental</b></div>
                                    <span className={"vibranBlueText"}>
                                                <b>{formatMoney(item.benefits?.mental)}</b>
                                                </span>
                                </React.Fragment>
                                }
                            </Grid.Column>

                            <Grid.Column className={"bkgNeutral100"}>
                                <div className={"neutral700Text smaller"}><b>Tier</b></div>
                                <span className={"vibranBlueText"}>
                                            <b>{resolveTierShort(item.familyTier)}</b>
                                        </span>
                            </Grid.Column>


                        </Grid>
                    </CustomContainer>
                })}
            </CustomContainer>
        )
    }

    renderAdjustmentRow = (adjustment, index) => {
        const {amount, type, comment} = adjustment;

        return (
            <Table.Row key={index}>
                <Table.Cell>{type}</Table.Cell>
                <Table.Cell>{comment}</Table.Cell>
                <Table.Cell>{formatMoney(amount)}</Table.Cell>
            </Table.Row>
        )
    }

    downloadInvoice = async (docId) => {
        this.setState({loading: true, error: null})
        const {invoiceId} = this.state;
        try {
            const payload = {
                "invoiceId": invoiceId,
                "documentId": docId,
            }
            const result = await axios.post("/api/member/v2/downloadInvoice", payload, {headers: {'Content-Type': 'application/json'}});
            const data = result.data;
            dowloadBase64(data.document.name, data.document.contentType, data.document.body)
            this.setState({loading: false})
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to get the document"})
        }
    }

    callRefreshInvoice = async (showPaymentSuccess) => {
        const {invoiceId} = this.state;
        this.setState({loading: true, error: null})
        try {
            const payload = {"invoiceId": invoiceId}
            const result = await axios.post("/api/member/v2/invoiceDetails", payload, {headers: {'Content-Type': 'application/json'}});

            this.setState({
                loading: false,
                invoice: result.data.invoice,
                adjFilteredData: result.data.invoice.adjustments,
                showPaymentSuccess: showPaymentSuccess,
                employeeTransactions: result.data.transactions
            })
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, noInvoiceError: "Unable to get invoice info"})
        }
    }

    switchToBillingSettings = () => {
        this.props.history.push({pathname: "/billing", state: {activeTab: 'billingSettings'}});
    }

    parseInvoiceNumber(value) {
        let invoiceNumber = value.split("-")[1];
        return invoiceNumber ? "#" + invoiceNumber : value;
    }

    render() {
        const {user, prevRoute} = this.props;
        const {
            showPaymentSuccess,
            error,
            loading,
            invoice,
            adjSearch,
            adjFilteredData,
            adjColumn,
            adjDirection,
            noInvoiceError
        } = this.state;
        const title = "Invoice details";
        const trigger = <><Icon name={"download"} color={"grey"}/><a href>Download</a></>
        const {firstName, lastName} = user;
        return (
            <React.Fragment>
                <InvoiceDetailModalHeader modalTitle={title} prevRoute={prevRoute} done={true}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    {loading ? <Loader active/> :
                        noInvoiceError ? <CustomContainer basic padded><Message negative>{noInvoiceError}</Message></CustomContainer> :
                            !!invoice ? <Grid container stackable columns={1} centered>
                                <Grid.Column className={"pageContent"}>
                                    {invoice.paymentStatus !== "Closed" &&
                                    <>
                                        <Divider hidden/>
                                        <CustomContainer padded>
                                            <PaymentAuthorizationCard twoColumnsLayout
                                                                      accountBalance={invoice.amountDue}
                                                                      invoiceId={invoice.id}
                                                                      afterRefresh={this.callRefreshInvoice}
                                                                      showPaymentSuccess={showPaymentSuccess}
                                                                      switchToPaymentDetail={this.switchToBillingSettings}
                                            />
                                        </CustomContainer>
                                    </>
                                    }
                                    <CustomContainer basic>
                                    <Grid columns={2}>
                                        <Grid.Row verticalAlign={"middle"} style={{paddingBottom: 0}}>
                                        <Grid.Column>
                                            <BrandedLogo />
                                            </Grid.Column>
                                        <Grid.Column textAlign={"right"}>
                                            <div className={"small"}>
                                                <div>Brella Services Inc.</div>
                                                <div>2093 Philadelphia Pike #2496</div>
                                                <div>Claymont, DE 19703-2424</div>
                                                <div><SupportEmailLink/></div>
                                            </div>
                                        </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Column width={12}>
                                            <div className={"vibranBlueText"}>
                                                <b>{buildFullName({firstName, lastName})}</b>
                                            </div>
                                            <div className={"small"}>
                                                <div>{user.address.street}</div>
                                                <div>{user.address.city}, {user.address.state}, {user.address.zipCode}</div>
                                                <div className={"neutral700Text"}><b>Member ID #{user.memberId}</b></div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Row verticalAlign={"middle"}>
                                            <Grid.Column>
                                                <Header as={"h1"}
                                                >{strFormatMoney(invoice.totalAmount)}<span
                                                    style={{fontSize: '20px', paddingLeft: '1em'}}>total due</span></Header>
                                            </Grid.Column>
                                            <Grid.Column textAlign={"right"}>
                                                <Dropdown trigger={trigger} icon={null} direction={"left"}>
                                                    <Dropdown.Menu direction={"left"}
                                                                   style={{minWidth: '200px'}}>
                                                        <Dropdown.Item text={"Summary Details"} onClick={() => {
                                                            this.downloadInvoice(invoice.invoiceSummary).then()
                                                        }}/>
                                                        <Dropdown.Item text={"Full Invoice"} onClick={() => {
                                                            this.downloadInvoice(invoice.invoiceFullDetails).then()
                                                        }}/>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                    <Divider/>

                                    <Grid columns={4}>
                                        <Grid.Column>
                                            <div className={"neutral700Text small"}><b>Due date</b></div>
                                            <div className={"vibranBlueText"}>
                                                <b>{moment(invoice.dateDue).format("MMM D, YYYY")}</b></div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className={"neutral700Text small"}><b>Coverage period</b></div>
                                            <div className={"vibranBlueText"}>
                                                <b>{moment(invoice.billingPeriod.startDate).format("MMM D, YYYY")} - {moment(invoice.billingPeriod.endDate).format("MMM D, YYYY")}</b>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className={"neutral700Text small"}><b>Invoice number</b></div>
                                            <div className={"vibranBlueText"}><b>{this.parseInvoiceNumber(invoice.invoiceNumber)}</b></div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className={"neutral700Text small"}><b>Date generated</b></div>
                                            <div className={"vibranBlueText"}>
                                                <b>{moment(invoice.preparedDate).format("MMM D, YYYY")}</b></div>
                                        </Grid.Column>
                                    </Grid>

                                    <Divider hidden/><Divider hidden/><Divider hidden/>

                                    <Grid columns={2}>
                                        <Grid.Row verticalAlign={"middle"}>
                                            <Grid.Column><Header as={"h3"}>Invoice
                                                details</Header></Grid.Column>
                                            <Grid.Column textAlign={"right"}><span className={"small"}>Details look incorrect? Reach out to <a
                                                href={"mailto:billing@joinansel.com"}>Brella Concierge</a></span></Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                    {this.renderInvoiceInfo()}

                                    {invoice.adjustments.length > 0 && <React.Fragment>
                                        <Header as={"h3"}>Non employee related adjustments</Header>

                                        <Form style={{marginTop: 0}}>
                                            <Form.Input icon='search' iconPosition='left'
                                                        placeholder={"Filter by adjustment type..."} value={adjSearch}
                                                        name={adjSearch}
                                                        onChange={this.filterAdjustments}/>
                                        </Form>
                                        {error && <Message error> {error}</Message>}

                                        <Divider hidden/>
                                        <Table sortable basic={"very"}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{minWidth: "135px"}}
                                                                      sorted={adjColumn === 'type' ? adjDirection : null}
                                                                      onClick={() => this.sortAdj('type')}
                                                    >
                                                        Type {adjColumn !== 'type' && <Icon name="sort"/>}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Memo
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={adjColumn === 'amount' ? adjDirection : null}
                                                        onClick={() => this.sortAdj('amount')}
                                                    >
                                                        Amount {adjColumn !== 'amount' && <Icon name="sort"/>}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {adjFilteredData.map((item, index) => (
                                                    this.renderAdjustmentRow(item, index)
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </React.Fragment>}
                                    </CustomContainer>
                                </Grid.Column>
                            </Grid> : null
                    }
                    <Footer showTopFooter={false} showCenterData={true}/>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(InvoiceDetails));
