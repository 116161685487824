import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import deepMerge from 'lodash/merge'

export const SubThemeProvider = ({ theme, children }) => {
  const mergedTheme = useMemo(() => {
    const mergeThemes = siteTheme => ({ ...deepMerge(siteTheme, typeof theme === 'function' ? theme(siteTheme) : theme) });
    if (typeof theme !== 'function' && process.env.NODE_ENV !== 'production') {
      console.error('You must provide a function as theme to SubThemeProvider.');
    }
    return mergeThemes;
  }, [theme]);
  return (
    <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
  );
};

SubThemeProvider.displayName = 'DesignSystemSubTheme';