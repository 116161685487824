import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Header, Icon} from "semantic-ui-react";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {Link} from "../../../base";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        qleReason: state.planChange.qleReason,
        contractTerms: state.initialData.certificate.ratedClass.contractTerms
    }
};

class NotEligible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/change");
    }

    render() {
        const {contractTerms} = this.props;
        const benefitWaitingPeriod = !!contractTerms ? contractTerms.benefitWaitingPeriod : null;
        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                    <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                        <Grid.Column width={6} textAlign={"center"}>
                            <div style={{background: '#FFF7EC', borderRadius: '50%', width: '72px', height: '72px', margin: '0 auto 1.5em auto', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><Icon size={"large"} style={{color: '#616161', margin: 0}} name={"warning sign"}/></div>
                            <Header as={"h4"}>Your qualifying life event is invalid</Header>
                            <p className={"neutral700Text"}>Based on the date you entered, you're not eligible to make qualifying life event benefit changes.</p>
                            <p className={"neutral700Text"}>You may make changes to your benefits outside of a qualifying life event, but those changes are subject to a {benefitWaitingPeriod} day benefit waiting period.</p>
                            <Divider hidden/><Divider hidden/>
                                <Link to={"/plan"}>Back to your account</Link>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={false} showCenterData={true}/>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(NotEligible));
