// Global Site colors
// All color names should be imported from the palette.
// Components should use these colors, not the ones in palette.

import { palette } from './palette'

// As a thumb rule, anything with states should be defined as:
// [element].[variant].[state], example: button.primary.hover.
// We should always "assume" we would have variants, so please
// try to always use at least "primary" for all elements except,
// for the base colors.

export const colors = {
  brand: {
    primary: palette.voyaOrange,
    secondary: palette.darkOrange,
  },
  button: {
    primary: {
      default: palette.darkBlue,
      hover: palette.darkenedBlue,
    },
    outline: {
      default: palette.voyaNeutralGrey,
      hover: palette.voyaLightGrey,
    }
  },
  link: {
    primary: {
      default: palette.darkBlue,
      hover: palette.darkenedBlue,
    },
  },
  text: {
    primary: palette.neutral900,
    secondary: palette.neutral600,
    inverted: palette.white,
  },
  heading: {
    primary: palette.darkOrange,
  },
  neutrals: {
    neutral50: palette.neutral50,
    neutral100: palette.neutral100,
    neutral300: palette.neutral300,
    neutral600: palette.neutral600,
    neutral700: palette.neutral700,
    neutral900: palette.neutral900,
  },
  segment: {
    linen: palette.linen,
    subtlePink: palette.subtlePink,
  },
  secondary: {
    greenPin: palette.greenPin,
    fog: palette.fog,
  },

  // Base colors, we "shouldn't" use these..
  white: palette.white,
  black: palette.black,
}
