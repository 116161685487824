import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Form, Grid, Label} from "semantic-ui-react";
import {Button, Header} from "../../base";
import "./newClaims.css"
import {connect} from "react-redux";
import {setWhatHappened} from "../../../actions/newClaimActions";
import Footer from "../../footer";
import NewClaimSteps from "./newClaimSteps";
import ClaimStepsLayout from "../claimStepsLayout";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        claimant: state.newClaim.claimant,
        whatHappened: state.newClaim.whatHappened
    }
};

class WhatHappened extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }
        this.state = {
            validationErrors: {
                symptoms: null,
                providerDiagnosis: null,
                treatment: null,
            },
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/claims/new");
    }

    handleChange = (e, {name, value}) => {
        let {validationErrors} = this.state;
        let {whatHappened} = this.props;
        if (name !== "additionalInfo") {
            validationErrors[name] = false;
        }
        whatHappened[name] = value;
        this.props.dispatch(setWhatHappened(whatHappened))
        this.setState({validationErrors: validationErrors})
    }

    onNext = () => {
        if (this.validateForm() === true) {
            this.props.history.push("/claims/new/treatmentInfo")
        }
    }

    validateForm = () => {
        let {validationErrors} = this.state;
        const {whatHappened} = this.props;
        let valid = true;

        Object.keys(validationErrors).forEach(prop => {
            if (!whatHappened[prop]) {
                validationErrors[prop] = true;
                valid = false;
            }
        });
        this.setState({validationErrors: validationErrors})
        return valid;
    }

    renderInputRow = (
        validationErrors,
        key,
        value,
        label,
        placeholder,
        required,
        maxLength = 130000,
    ) => (
        <Form.TextArea
            label={
                <Label basic
                    className={!!required ? 'requiredField' : null}
                    style={{
                        border: 'none',
                        fontSize: '14px',
                        paddingLeft: 0,
                        fontWeight: 600,
                    }}
                >{label}</Label>
            }
            placeholder={placeholder}
            name={key}
            value={value || ''}
            onChange={this.handleChange}
            error={validationErrors[key]}
            maxLength={maxLength}
        />
    )

    render() {
        const {whatHappened} = this.props;
        const {validationErrors} = this.state;

        const keySymptoms = 'symptoms';
        const keyProviderDiagnosis = 'providerDiagnosis';
        const keyTreatment = 'treatment';
        const keyAdditionalInfo = 'additionalInfo';

        const symptomsValue = whatHappened[keySymptoms];
        const providerDiagnosisValue = whatHappened[keyProviderDiagnosis];
        const treatmentValue = whatHappened[keyTreatment];
        const additionalInfoValue = whatHappened[keyAdditionalInfo];
        const button = <Button primary content="Next step" onClick={this.onNext}/>;

        return (
            <React.Fragment>
                <NewClaimMenu onBack={this.onBackHandler}/>
                <ClaimStepsLayout>
                    <NewClaimSteps activeStepIdx={0}/>
                    <div style={{marginTop: "56px"}}>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={8} className={"pageContent"}>
                                <Header as={"h2"} style={{marginBottom: 8}}>What happened</Header>
                                <p className={"neutral700Text"} style={{marginBottom: 40}}>Please be as specific as possible—</p>
                                <Form>
                                    {this.renderInputRow(validationErrors, keySymptoms, symptomsValue, "What symptoms did you experience?", "Please type a description... ", true)}
                                    {this.renderInputRow(validationErrors, keyProviderDiagnosis, providerDiagnosisValue, "What did your provider tell you was wrong?", "Please type a description... ", true, 1000)}
                                    {this.renderInputRow(validationErrors, keyTreatment, treatmentValue, "What treatment did you receive?", "Please type a description... ", true)}
                                    {this.renderInputRow(validationErrors, keyAdditionalInfo, additionalInfoValue, "Is there anything else we should know?", "Please type a description... ", false)}
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </div>
                    <div style={{marginTop: "100px"}}>
                        <Footer showTopFooter={true}
                                showCenterData={true}
                                button={button}
                        />
                    </div>
                </ClaimStepsLayout>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(WhatHappened));
