import { mobileActionTypes } from '../actions/actionTypes'

const initialState = {
    useWeb: false,
    isResetPasswordFlow: false,
};

export const mobileReducer = (state = initialState, action) => {
    switch (action.type) {
        case mobileActionTypes.SET_USE_WEB:
            return {
                ...state,
                useWeb: !!action.payload,
            }
        case mobileActionTypes.SET_RESET_PASSWORD_FLOW:
            return {
                ...state,
                isResetPasswordFlow: action.payload,
            }
        default:
            return state
    }
}
