import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Dropdown, Grid, Icon, Loader, Menu} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {Button} from "../base";
import {connect} from "react-redux";
import {CHANGE_ROUTE,} from "../../utils/common";
import {buildFullName} from '../../utils/utils';
import {setClaimant} from "../../actions/newClaimActions";
import CoveredModal from "../coveredModal";
import PlanSummary from "./planSummary";
import TimeLine from "./timeline";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        dependents: state.initialData.dependents,
        certificateHolder: state.initialData.certificateHolder,
        certificate: state.initialData.certificate,
        contactSfId: state.auth.loginInfo.contactSfId,
        user: state.initialData.user,
        benefits: state.initialData.certificate.assetCoverages,
        documents: state.initialData.documents,
        payrollFrequency: state.initialData.payrollFrequency,
        cost: state.initialData.cost,
        employerContribution: state.initialData.employerContribution,
        userIsCertificateHolder: state.initialData.userIsCertificateHolder,
        contract: state.initialData.contract
    }
};

class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            displayCoveredModal: false,
            activeItem: 'summary'
        }
    }

    doDisplayCoveredModal = (status) => {
        let {displayTipsModal} = this.state;
        if (status) {
            displayTipsModal = false;
        }
        this.setState({
            displayTipsModal,
            displayCoveredModal: status,
        });
    }

    componentDidMount() {
        this.setState({
            loading: false,
        })
    }

    handleItemClick = (e, {name}) => {
        this.setState({
            activeItem: name
        })
    }

    viewSummaryTab = () => {
        this.setState({
            activeItem: 'summary'
        })
    }

    handleChange = (claimant) => {
        this.props.dispatch(setClaimant(claimant))
        this.props.history.push("/claims/new")
    }

    change = () => {
        const {certificate, contract} = this.props;
        if (!!certificate.useBrellaEnrollment && contract.planOffering !== "Brella Select Custom") {
            this.props.history.push(CHANGE_ROUTE)
        } else {
            this.props.history.push("/benAdmin")
        }
    }

    render() {
        const {loading, displayCoveredModal, activeItem} = this.state;
        const {
            dependents,
            certificateHolder,
            user,
            employerContribution,
            cost,
            userIsCertificateHolder,
            certificate
        } = this.props;
        const hasData = (certificate && certificateHolder && user && employerContribution !== null && cost !== null);

        const trigger = (
            <Button data-qa-button_new_claim primary style={{marginRight: 0, marginLeft: '8px'}}>File new claim <Icon name={"dropdown"}
                                                                                             style={{margin: 0}}/></Button>)
        const loader = <div style={{height: 'calc(100vh - 150px)'}}><Loader active/></div>
        const {firstName, lastName, suffix} = certificateHolder;
        return (<React.Fragment>
                {displayCoveredModal && <CoveredModal displayCoveredModal={displayCoveredModal}
                                                      onModalClose={() => this.doDisplayCoveredModal(false)}
                />}
                {!!loading || !hasData ? loader :
                    <CustomContainer padded>
                        <Grid columns={2} verticalAlign={"bottom"}>
                            <Grid.Column>
                                <Menu text size={"small"} className={"memberSectionMenu"}>
                                    <Menu.Item
                                        data-qa-button_summary
                                        name='summary'
                                        active={activeItem === 'summary'}
                                        onClick={this.handleItemClick}
                                    ><b>Summary</b></Menu.Item>
                                    {!!userIsCertificateHolder && <Menu.Item
                                        data-qa-button_timeline
                                        name='timeline'
                                        active={activeItem === 'timeline'}
                                        onClick={this.handleItemClick}
                                    ><b>Coverage timeline</b></Menu.Item>}
                                </Menu>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"}>
                                {/*ToDo: uncomment following lines to un-hide QLE button*/}
                                {/*{!!userIsCertificateHolder &&*/}
                                {/*<Button primary basic onClick={this.change}>Change my benefits</Button>}*/}
                                {!!dependents && dependents.length > 0 ?
                                    <Dropdown direction={"left"} trigger={trigger} icon={null}>
                                        <Dropdown.Menu style={{minWidth: '200px'}}>
                                            <Dropdown.Header content='Filing for:'/>
                                            <Dropdown.Item onClick={(e) => {
                                                this.handleChange(certificateHolder)
                                            }} key={certificateHolder.id}
                                                           text={buildFullName({firstName, lastName, suffix, relation: 'Primary'})}
                                                           value={certificateHolder.id}/>
                                            {dependents.map(item => {
                                                const {firstName, lastName, suffix, relToEmployee:relation, id} = item;
                                                return <Dropdown.Item onClick={(e) => {
                                                    this.handleChange(item)
                                                }} key={id}
                                                                      text={buildFullName({firstName, lastName, suffix, relation})}
                                                                      value={id}/>
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown> : <Button data-qa-button_new_claim onClick={(e) => {
                                        this.handleChange(certificateHolder)
                                    }} primary style={{marginRight: 0, marginLeft: '8px'}}>File new claim</Button>}
                            </Grid.Column>
                        </Grid>
                        <Divider section/>

                        {activeItem === "summary" && <PlanSummary/>}
                        {activeItem === "timeline" && <TimeLine viewSummaryTab={this.viewSummaryTab}/>}
                        <Divider hidden/>
                    </CustomContainer>
                }
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Plan));
