import {planChangeActionTypes} from "./actionTypes";

export function setPlanChangeInitialData(payload) {
    return {
        type: planChangeActionTypes.SET_PLAN_CHANGE_INITIAL_DATA,
        payload: payload
    }
}

export function setQLE(event) {
    return {
        type: planChangeActionTypes.SET_QLE,
        payload: event
    }
}

export function setQLEDate(date) {
    return {
        type: planChangeActionTypes.SET_QLE_DATE,
        payload: date
    }
}

export function setDependent(dependent, idx) {
    return {
        type: planChangeActionTypes.SET_DEPENDENT,
        payload: {dependent: dependent, idx: idx}
    }
}

export function hasAddedQLEDependent(value) {
    return {
        type: planChangeActionTypes.HAS_ADDED_QLE_DEPENDENT,
        payload: value
    }
}

export function setDependentList(list) {
    return {
        type: planChangeActionTypes.SET_DEPENDENT_LIST,
        payload: list
    }
}

export function removeDependent(idx) {
    return {
        type: planChangeActionTypes.REMOVE_DEPENDENT,
        payload: {idx: idx}
    }
}

export function setHaveStepChildren(date) {
    return {
        type: planChangeActionTypes.SET_HAVE_STEP_CHILDREN,
        payload: date
    }
}

export function setWantUpdatedRecommendations(value) {
    return {
        type: planChangeActionTypes.WANT_UPDATED_RECOMMENDATIONS,
        payload: value
    }
}

export function setHealthPlanDetails(typicallyPayInfo, highDeductibleInfo) {
    return {
        type: planChangeActionTypes.SET_HEALTH_PLAN_DETAILS,
        payload: {typicallyPayInfo, highDeductibleInfo}
    }
}

export function clearPlanChange() {
    return {
        type: planChangeActionTypes.CLEAR_PLAN_CHANGE,
        payload: null
    }
}

export function setCoverageOption(opt, deleteChildren, deleteSpouse) {
    return {
        type: planChangeActionTypes.SET_COVERAGE_OPTION,
        payload: {selectedCoverageOption: opt, deleteChildren, deleteSpouse }
    }
}

export function setCostPerOption(opt) {
    return {
        type: planChangeActionTypes.SET_COST_PER_OPTION,
        payload: opt
    }
}

export function setModerateCoverage(opt) {
    return {
        type: planChangeActionTypes.SET_MODERATE_COVERAGE,
        payload: opt
    }
}

export function setSevereCoverage(opt) {
    return {
        type: planChangeActionTypes.SET_SEVERE_COVERAGE,
        payload: opt
    }
}

export function setCatastrophicCoverage(opt) {
    return {
        type: planChangeActionTypes.SET_CATASTROPHIC_COVERAGE,
        payload: opt
    }
}

export function setPlanCoverageSelectedAndRecommended(moderate, moderateSelected, severe, severeSelected, catastrophic, catastrophicSelected) {
    return {
        type: planChangeActionTypes.SET_SELECTED_AND_RECOMMENDED_COVERAGE,
        payload: {moderate, moderateSelected, severe, severeSelected, catastrophic, catastrophicSelected}
    }
}

export function setPlanCoverageSelectedAndRecommendedSelect(costValue, costEnhanced, costPremier, contribValue, contribEnhanced, contribPremier) {
    return {
        type: planChangeActionTypes.SET_SELECT_COST,
        payload: {costValue, costEnhanced, costPremier, contribValue, contribEnhanced, contribPremier}
    }
}

export function setPlanContribAndCost(contrib, cost) {
    return {
        type: planChangeActionTypes.SET_PLAN_CONTRIB_COST,
        payload: {employerContribution: contrib, cost: cost}
    }
}

export function setBrellaSelectTierSelected(value, premier, enhanced) {
    return {
        type: planChangeActionTypes.SET_BRELLA_SELECT_TIER_SELECTED,
        payload: {value, premier, enhanced}
    }
}

export function setFetchPlanRecommendation(fetchPlanRecommendation) {
    return {
        type: planChangeActionTypes.SET_FETCH_PLAN_RECOMMENDATION,
        payload: {fetchPlanRecommendation}
    }
}

export function setLeftoverMoney(leftoverMoney) {
    return {
        type: planChangeActionTypes.SET_LEFTOVER_MONEY,
        payload: leftoverMoney
    }
}

export function setTotalDependents(list) {
    return {
        type: planChangeActionTypes.SET_TOTAL_DEPENDENTS,
        payload: list
    }
}

export function setMaritalStatus(status) {
    return {
        type: planChangeActionTypes.SET_MARITAL_STATUS,
        payload: status
    }
}

export function setDependentsId(id) {
    return {
        type: planChangeActionTypes.SET_DEPENDENTS_ID,
        payload: id
    }
}

export function setNewAddress(address) {
    return {
        type: planChangeActionTypes.SET_NEW_ADDRESS,
        payload: address
    }
}

export function setPrimaryAddressChange(value) {
    return {
        type: planChangeActionTypes.SET_PRIMARY_ADDRESS_CHANGE,
        payload: value
    }
}
