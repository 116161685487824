import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {CustomContainer} from './custom-common';

class FileUpload extends Component {
    onDrop = (files) => {
        const {onFilesLoad} = this.props;
        onFilesLoad(files);
    }

    render() {
        const { style, small } = this.props

        const customStyles = small ? {border: "2px dashed #CFCFCF", height: '170px', minHeight: '170px'} : {border: "2px dashed #CFCFCF", height: '300px', paddingTop: "100px"}

        const styles = {
            ...customStyles,
            ...style
        }

        const {disabled} = this.props;
        return (
            <Dropzone onDrop={this.onDrop} disabled={disabled}>
                {({getRootProps, getInputProps}) => (
                    <CustomContainer {...getRootProps({ refKey: 'innerref' })} textAlign="center" style={styles} className={"clickable"}>
                        {this.props.children}
                        <input {...getInputProps()} />
                    </CustomContainer>
                )}
            </Dropzone>
        );
    }
}

export default FileUpload;
