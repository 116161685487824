import {palette} from '../../../design-system'
import {buildS3ImageSrc} from "../../custom-common/s3Image";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
export const theme = {
  brand: {
    logo: buildS3ImageSrc({common:true, svg:true, name:'official_logo'}),
    invertedLogo: buildS3ImageSrc({common:true, svg:true, name:'official_logo_white'}),
    alt: STRATEGIC_PARTNER.LABEL,
  },
  panel: {
    foreground: '#fff',
    background: '#F8FEFF',
    backgroundImage: buildS3ImageSrc({name: 'login_foreground'}),
  },
  slogan: {
    color: palette.darkOrange,
    fontFamily: 'EuclidCircularA-Bold',
  },
  content: {
    color: '#ccc',
  },
  title: {
    color: palette.darkOrange,
  },
  links: {
    idle: palette.darkBlue,
    hover: palette.darkenedBlue,
  },
  footer: {
    color: palette.neutral700,
    links: {
      idle: palette.neutral500,
      hover: palette.neutral900,
    }
  },
  registerButton: {
    idle: palette.darkBlue,
    hover: palette.darkenedBlue,
  }
}
