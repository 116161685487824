import React, {Component} from "react"
import {Divider, Form, Grid, Icon} from "semantic-ui-react";
import {Button, Header} from "../../../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import {setFetchPlanRecommendation, setHealthPlanDetails} from "../../../../actions/planChangeActions";
import {resolveBrellaPlanPath, UPDATE_COVERAGE_ROUTE} from "../../../../utils/common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        wantUpdatedRecommendations: state.planChange.wantUpdatedRecommendations,
        benefits: state.initialData.certificate.assetCoverages,
        dependents: state.initialData.dependents,
        cost: state.initialData.cost,
        employerContribution: state.initialData.employerContribution,
        payrollFrequency: state.initialData.payrollFrequency,
        storedTypicallyPayInfo: state.planChange.typicallyPayInfo,
        storedHighDeductibleInfo: state.planChange.highDeductibleInfo,
        highDeductibleOptions: state.planChange.highDeductibleOptions,
        coPayOptions: state.planChange.coPayOptions,
        planType: state.initialData.contract.planOffering
    }
};

class HealthPlanDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highDeductibleSelected: props.storedHighDeductibleInfo,
            typicallyPaySelected: props.storedTypicallyPayInfo,
        };
    }

    onNext = () => {
        const {highDeductibleSelected, typicallyPaySelected} = this.state;
        const {dispatch} = this.props;
        dispatch(setHealthPlanDetails(typicallyPaySelected, highDeductibleSelected));
        dispatch(setFetchPlanRecommendation(true));
        this.props.history.push("/change/update/disposableIncome")
    }

    skip = () => {
        const plan = resolveBrellaPlanPath(this.props.planType)
        this.props.history.push(`${plan}?skipRecommendation`)
    }

    onBackHandler = () => {
        this.props.history.push(UPDATE_COVERAGE_ROUTE)
    }

    handleHighDeductibleChange = (selectedKey) => {
        let {typicallyPaySelected} = this.state;
        const clearWhenVisitingADoctor = this.showVisitingTheDoctorQuestion(selectedKey);
        if (clearWhenVisitingADoctor) {
            typicallyPaySelected = null;
        }
        this.setState({highDeductibleSelected: selectedKey, typicallyPaySelected});
    };

    handleTypicallyPayChange = (selectedKey) => {
        this.setState({typicallyPaySelected: selectedKey});
    };

    showVisitingTheDoctorQuestion = (highDeductibleSelected) => {
        const NOT_COVERED_ID = 3;
        return highDeductibleSelected !== NOT_COVERED_ID;
    }

    render() {
        const {highDeductibleSelected, typicallyPaySelected} = this.state;
        const {highDeductibleOptions, coPayOptions} = this.props;
        const showCoverageQuestion = this.showVisitingTheDoctorQuestion(highDeductibleSelected);
        const button = <Button primary content="Next step" onClick={this.onNext}
                               disabled={highDeductibleSelected === null || (showCoverageQuestion && typicallyPaySelected === null)}/>;
        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Grid stackable columns={1}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Header as="h2">Let’s talk about medical expenses</Header>
                                        <p>Tell us about your health insurance—</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form>
                                            <p className={"requiredField"}><b>Do you feel that your deductible is
                                                affordable?</b></p>
                                            <Form.Field>
                                                <Form.Group grouped>
                                                    {highDeductibleOptions.map((r) => {
                                                        return <React.Fragment key={r.id}>
                                                            <Form.Radio className={'radioNormalFontSize'}
                                                                        onChange={() => this.handleHighDeductibleChange(r.id)}
                                                                        checked={highDeductibleSelected === r.id}
                                                                        label={r.value}/>
                                                        </React.Fragment>
                                                    })}
                                                </Form.Group>
                                            </Form.Field>
                                            <Divider hidden/>

                                            {showCoverageQuestion && <React.Fragment>
                                                <p className={"requiredField"}><b>When visiting the doctor or picking up
                                                    a prescription...</b></p>
                                                <Form.Field>
                                                    <Form.Group grouped>
                                                        {coPayOptions.map((r) => {
                                                            return <React.Fragment key={r.id}>
                                                                <Form.Radio key={r.id} className={'radioNormalFontSize'}
                                                                            onChange={() => this.handleTypicallyPayChange(r.id)}
                                                                            checked={typicallyPaySelected === r.id}
                                                                            label={r.value}/>
                                                            </React.Fragment>
                                                        })}
                                                    </Form.Group>
                                                </Form.Field>
                                            </React.Fragment>
                                            }
                                        </Form>
                                        <Divider hidden/>
                                        I don’t want a recommendation.
                                        <span>{' '}
                                            <span onClick={this.skip} className={"linkText"}>Skip this<Icon
                                                name="long arrow alternate right"/></span>
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <Footer
                        showTopFooter={true} showCenterData={true} button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(HealthPlanDetails));
