import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import { Input, Divider } from "semantic-ui-react";
import {
  MFAButton,
  MFASegment,
  MFAHeading,
  MFAText,
  MFAEnableSettingBox,
  MFASettingItem,
  MFAGrid,
  MFAButtonOutlined,
  MFAIcon,
  MFAModal,
  MFAContainer,
  MFACodeBox,
  MFAForm,
  MFALabel,
} from "../styled";
import {MFA_TYPE} from "../constants";
import {MfaMethodItem} from "./mfaMethodItem";
import {ConfirmChangeMethodModal} from "./confirmChangeMethodModal";
import {MFA_ROUTE} from "../../utils/common";
import {show as showToast} from "../../actions/toastActions";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    mfaEmailSetUpped: state.auth?.mfaEmailSetUpped,
  }
}


const MfaSettingsReminder = ({ setUpMfa }) => {
  return (
    <MFASegment padded>
      <MFAHeading as={"h4"} color={"blue"}>
        Set up two-factor authentication
      </MFAHeading>
      <MFASegment padded className={"mfaSettingBox mfaSettingText"}>
        Two-factor authentication is an additional layer of security for your
        account. In addition to your normal credentials, you'll also need to
        provide an authentication code when logging in.
      </MFASegment>
      <MFAButton primary onClick={setUpMfa}>
        Set up two-factor authentication
      </MFAButton>
    </MFASegment>
  );
};

const MfaSettings = ({ mfaEnabled, setLeftNavigation, getMfaSettings, formatDate, generateBackupCodes, mfaEmailSetUpped }) => {
  const [mfaMethods, setMfaMethods] = useState({});
  const [backupCodeGeneratedTime, setBackupCodeGeneratedTime] = useState(null);
  const [showWarnBackupCodes, setShowWarnBackupCodes] = useState(false);
  const [showReConfigureMethod, setShowReConfigureMethod] = useState(false);
  const [showBackupCodes, setShowBackupCodes] = useState(false);
  const [mfaSavedCodes, setMfaSavedCodes] = useState(false);
  const [backupCodes, setBackupCodes] = useState(null);
  const [methodToChange, setMethodToChange] = useState(null);
  const [showChangeMethodModal, setShowChangeMethodModal] = useState(false)

  const history = useHistory();
  const dispatch = useDispatch();

  const setUpMfa = () => {
    dispatch(setLeftNavigation(false));
    history.push({ pathname: "/mfa",  setupMfaFromSettings: true});
  };

  const printCodes = () => {

    // Format the date as "MM-DD-YYYY"
    const formattedDate = formatDate(new Date(), null, 'MM-DD-YYYY');

    // Set the filename with the formatted date
    var filename = `Backup codes ${formattedDate}`;

    var divContents = document.getElementById("backUpCodes").innerHTML;
    var a = window.open("", "", "height=250, width=500");
    a.document.write("<html>");
    a.document.write(`<head><title>${filename}</title></head>`);
    a.document.write("<body> <h1>Backup Codes</h1> <br />");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  const copyToClipboard = () => {
    showToastSuccess();
    const formattedBackupCodes = backupCodes.join('\n');
    navigator.clipboard.writeText(formattedBackupCodes);
  }

  const showToastSuccess = () => {
    dispatch(showToast({
      type: 'success',
      title: "Copy backup codes",
      message: "Backup codes copied to clipboard.",
    }))
  }

  const regenerateBackupCodes = async () => {
    setShowWarnBackupCodes(false);
    setShowBackupCodes(true);

    await generateBackupCodes()
    .then((response) => {
      setBackupCodes(response.data.backupCodeList);
    })
    .catch((error) => {
      console.log('error: ', error);
    });
  };

  const reConfigureMethod = async () => {
    setShowReConfigureMethod(false);
    history.push({ pathname: '/mfa', changeMethod: methodToChange});
  };

  const onCloseChangeMethodModal = () => setShowChangeMethodModal(false)

  const handleSubmitChangeMethodModal = () => {
    setShowChangeMethodModal(false)
    let changeMethod = ''
    if (mfaMethods?.totpKey?.active) changeMethod = MFA_TYPE.TOTP
    if (mfaMethods?.email) changeMethod = MFA_TYPE.EMAIL
    setShowReConfigureMethod(false);
    history.push({ pathname: MFA_ROUTE, changeMethod});
  }

  const openhangeMethodModal = () => setShowChangeMethodModal(true)

  const getMFAInfo = async () => {
    await getMfaSettings()
      .then(async (response) => {
        setBackupCodeGeneratedTime(response.data.backupCodeGeneratedTime)
        setMfaMethods(response.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    getMFAInfo();
  }, []);

  return mfaEnabled ? (
    <>
      <MFASegment padded>
        <MFAHeading as="h4" textAlign="center">
          Two-factor authentication
        </MFAHeading>
        <MFAText>
          Your account is more secure with two-factor authentication.
        </MFAText>
        <MFAEnableSettingBox>
          <MFAIcon
            name="checkmark"
            size="small"
            className="verifiedIcon"
          />{" "}
          Two-factor authentication is enabled in your account.
        </MFAEnableSettingBox>

        {mfaMethods.totpKey?.active && (
          <MfaMethodItem
            title="Authenticator app"
            description="Authenticator app"
            createdTime={formatDate(mfaMethods.totpKey?.createdTime)}
            ableToBeReconfigured={false}
          />
        )}

        {mfaMethods.email && (
          <MfaMethodItem
            title="Email"
            description={mfaEmailSetUpped}
            createdTime={formatDate(mfaMethods.email?.createdTime)}
            ableToBeReconfigured={false}
          />
        )}

        <MFASettingItem>
          <MFAGrid>
            <MFAGrid.Row columns={2}>
              <MFAGrid.Column floated="left" width={6} className={"backupCodeColumn"}>
                <MFAHeading
                  as="h5"
                  textAlign="left"
                  className={"dark"}
                >
                  Backup codes
                </MFAHeading>
                <MFAText className={"settings marginBottomZero"}>
                  You will need these backup codes if you ever lose access to
                  your authentication method. Each code may be used only once.
                </MFAText>
                <MFAText className={"settingsItalic"}>
                  Last generated on {formatDate(backupCodeGeneratedTime)}
                </MFAText>
              </MFAGrid.Column>
              <MFAGrid.Column
                floated="right"
                className={'flexEnd backupCodeActionColumn'}
                width={2}
              >
                <MFAButtonOutlined
                  primary
                  onClick={() => {
                    setShowWarnBackupCodes(true);
                  }}
                  className={"settings"}
                >
                  Regenerate{" "}
                  <MFAIcon
                    name={"redo"}
                    floated={"right"}
                    className={"settingsIcon"}
                  />
                </MFAButtonOutlined>
              </MFAGrid.Column>
            </MFAGrid.Row>
          </MFAGrid>
        </MFASettingItem>

        <Divider />

        <MFAButton
          className="large"
          mt={8}
          onClick={openhangeMethodModal}
        >
          Change method
        </MFAButton>

      </MFASegment>

      <ConfirmChangeMethodModal
        onClose={onCloseChangeMethodModal}
        displayModal={showChangeMethodModal}
        submit={handleSubmitChangeMethodModal}
      />

      <MFAModal
        size={"tiny"}
        onClose={() => {
          setShowWarnBackupCodes(false);
        }}
        open={showWarnBackupCodes}
        className={"warnBackupCodes"}
      >
        <MFAModal.Header>
          <MFAText>
            Are you sure you want to regenerate backup codes?
          </MFAText>
          <MFAText className="floatRight">
            <MFAIcon
              name={"close"}
              floated={"right"}
              onClick={() => {
                setShowWarnBackupCodes(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </MFAText>
        </MFAModal.Header>
        <MFAModal.Content>
          <MFAText>
            Generating new codes will invalidate the previous set you have
            <br /> generated.
          </MFAText>
          <Divider hidden/><Divider hidden/>
          <MFASegment basic compact className={'footerAction'}>
            <MFAButtonOutlined
              onClick={() => {
                setShowWarnBackupCodes(false);
              }}
              className={"grey"}
            >
              {"Cancel"}
            </MFAButtonOutlined>
            <MFAButton primary onClick={() => regenerateBackupCodes()}>
              {"Continue"}
            </MFAButton>
          </MFASegment>
        </MFAModal.Content>
      </MFAModal>
      <MFAModal
        size={"tiny"}
        onClose={() => {
          setShowReConfigureMethod(false);
        }}
        open={showReConfigureMethod}
        className={"warnReConfigure"}
      >
        <MFAModal.Header>
          <MFAText>
            Are you sure you want to re-configure your authenticator app?
          </MFAText>
          <MFAText>
            <MFAIcon
              name={"close"}
              floated={"right"}
              onClick={() => {
                setShowReConfigureMethod(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </MFAText>
        </MFAModal.Header>
        <MFAModal.Content>
          <MFAText>
            Re-configuring your authentication will invalidate your previous
            authentication
            <br /> method.
          </MFAText>
          <Divider hidden/><Divider hidden/>
          <MFASegment basic compact className={'footerAction'}>
            <MFAButtonOutlined
              onClick={() => {
                setShowReConfigureMethod(false);
              }}
              className={"grey"}
            >
              {"Cancel"}
            </MFAButtonOutlined>
            <MFAButton primary onClick={() => reConfigureMethod()}>
              {"Continue"}
            </MFAButton>
          </MFASegment>
        </MFAModal.Content>
      </MFAModal>
      <MFAModal
        size={"tiny"}
        open={showBackupCodes}
        className={"backupCodesModal"}
      >
        <MFAModal.Header>
          <MFAText>
            Backup codes
          </MFAText>
        </MFAModal.Header>
        <MFAModal.Content style={{ height: "450px" }} className={"small"}>
          <MFAText>
            You will need these backup codes if you ever lose access to your
            authentication method. Each code may be used only once. Make sure
            you save a copy somewhere safe.
          </MFAText>
          <Divider hidden/><Divider hidden/>
          <MFAContainer className="sectionWrapper" maxWidth={"620px"}>
            <MFAGrid>
              <MFAGrid.Row>
                <MFAGrid.Column>
                  <MFACodeBox>
                    <MFAGrid.Row>
                      <MFAGrid.Column>
                        <MFAGrid
                          columns={5}
                          id="backUpCodes"
                          className={"backUpCodesBox"}
                        >
                          {backupCodes?.map((item, index) => {
                            return (
                              <MFAGrid.Column key={index}>
                                {item}
                              </MFAGrid.Column>
                            );
                          })}
                        </MFAGrid>
                      </MFAGrid.Column>
                    </MFAGrid.Row>
                    <MFAGrid.Row>
                      <MFAGrid.Column>
                        <MFAContainer className={"backupCodeWrapper"}>
                          <MFAButtonOutlined
                            onClick={() => {
                              if(backupCodes){
                                copyToClipboard();
                              }
                            }}
                          >
                            Copy
                          </MFAButtonOutlined>
                          <MFAButtonOutlined
                            onClick={() => {
                              if(backupCodes){
                                printCodes();
                              }
                            }}
                            className={"printBtn"}
                          >
                            Print
                          </MFAButtonOutlined>
                        </MFAContainer>
                      </MFAGrid.Column>
                    </MFAGrid.Row>
                    <MFAGrid.Row>
                      <MFAGrid.Column>
                        <MFAForm
                          className="saveCodes"
                        >
                          <MFAForm.Field inline>
                            <MFALabel className="labelSavedCodes">
                              <Input
                                type="checkbox"
                                name="savedCodes"
                                id="savedCodes"
                                onClick={(e) =>
                                  setMfaSavedCodes(e.target.checked)
                                }
                                value={!mfaSavedCodes}
                              />
                              I've saved my backup codes
                            </MFALabel>
                          </MFAForm.Field>
                        </MFAForm>
                      </MFAGrid.Column>
                    </MFAGrid.Row>
                  </MFACodeBox>
                </MFAGrid.Column>
              </MFAGrid.Row>
            </MFAGrid>
          </MFAContainer>
          <MFASegment basic className={'closeBackupContent'}>
                  <MFAButton
                    onClick={() => {
                      setShowBackupCodes(false);
                    }}
                    disabled={!mfaSavedCodes}
                    className={"closeModaldisabled"}
                  >
                    {"Close"}
                  </MFAButton>
                </MFASegment>
        </MFAModal.Content>
      </MFAModal>
    </>
  ) : (
    <MfaSettingsReminder setUpMfa={setUpMfa} />
  );
};

export default connect(mapStateToProps)(MfaSettings);
