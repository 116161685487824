import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Dropdown, Icon, Loader, Menu} from 'semantic-ui-react'
import {setLogout} from "../actions/authActions";
import {toggle as toggleGetHelpModal} from '../actions/getHelpActions';
import {memberName} from "../utils/common";
import {purgeState} from "../storePersistence";
import {MessagesMenuIcon} from './messages/partials/messagesMenuIcon';
import axios from 'axios';
import {MailToSupport} from "./common";
import STRATEGIC_PARTNER from '../constants/strategicPartners';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    profile: state.auth.loginInfo ? state.auth.loginInfo.profile : null,
    auth: state.auth,
    user: ownProps.user || (state.initialData && state.initialData.user) || state.auth.user,
    hasRestrictedAccess: state.initialData.hasRestrictedAccess || state.stopScreens.isStopscreenDisplayed,
});

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(setLogout()),
        toggleGetHelpModal: state => dispatch(toggleGetHelpModal(state)),
    }
};

class UserMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logoutStatus: 'idle'
        }
    }


    backendLogout = async () => {
        try {
            this.setState({logoutStatus: 'loading'})
            await axios.post("/api/member/v2/logout", null, {headers: {'Content-Type': 'application/json'}});
            this.setState({logoutStatus: 'resolved'})
        } catch (e) {
            this.setState({ logoutStatus: 'failed' })
            throw new Error("An error occured while loggin out")
        }
    }

    logout = async () => {
        const {logout} = this.props
        await this.backendLogout()
        purgeState();
        logout();
    };




    goToKnowledgebase = () => {
        const {auth} = this.props;

        if (auth?.loginInfo?.authToken) {
            const hostURL = window.location.origin;
            const authToken = auth.loginInfo.authToken;
            window.open(`${hostURL}/knowledge/autenticate?authToken=${authToken}`);
        }
    }

    render() {
        const {user, toggleGetHelpModal, hasRestrictedAccess, auth: { loginInfo }, hideSettings, mfaFlow} = this.props
        const {logoutStatus} = this.state;

        const isEConsentVisible = !hasRestrictedAccess && user?.eConsent;
        const isSettingsVisible = isEConsentVisible && !hideSettings;

        return (
            <Menu.Menu position="right">
                {isEConsentVisible && (
                    <Menu.Item style={{paddingRight: 0, position: 'relative', top: '4px'}}>
                        <MessagesMenuIcon/>
                    </Menu.Item>
                )}
                <Dropdown item className="menuUserDropdown" text={'Get help'}
                          onClick={() => this.setState({forceTipsPopUpOpen: false})}>
                    <Dropdown.Menu style={{right: 0, left: 'auto'}}>
                        {(loginInfo && !mfaFlow) && <>
                        <Dropdown.Item
                            style={{marginTop: '0.5em'}}
                            className={"small"}
                            text={'Send us a message'}
                            onClick={() => toggleGetHelpModal(true)}
                        />
                        <Dropdown.Divider/>
                        </>}
                        <div style={{margin: '1em', fontWeight: 'normal', fontSize: '14px'}}>
                            <span className={"neutral900Text"}> Email <MailToSupport /> </span>
                            <p className={"smaller neutral700Text"} style={{marginTop: '0.3em'}}>
                                Do not include sensitive information
                            </p>
                        </div>
                        <Dropdown.Divider/>
                        <div style={{margin: '1em', fontWeight: 'normal'}}>
                            <p className={"small neutral900Text"}>Call us at <b>{STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}</b></p>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                {(!!user) && (
                    <Dropdown item text={memberName(user)} className="menuUserDropdown" closeOnChange={false}>
                        <Dropdown.Menu open={logoutStatus === 'loading'}>
                            {(isSettingsVisible && loginInfo.authToken) && (
                                <Dropdown.Item as={Link} to={"/settings"}>
                                    <span className={"small"}><Icon name="setting"/>Settings</span>
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item as={Menu.Item} onClick={this.logout}>
                                {logoutStatus === 'loading'
                                    ? (
                                        <Loader active inline>
                                            <span style={{fontWeight: 'normal'}}>Logging out</span>
                                        </Loader>
                                    )
                                    : <span className={"small"}><Icon name="power off"/>Log out</span>
                                }
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </Menu.Menu>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
