const DOCUMENT_EXAMPLES = [
    {
        title: 'Things you may have received during care—',
        imageName: 'icon_stethescope_clipboard',
        listItems: [
            'Imaging results (X-ray, MRI, etc)',
            'Ambulance call report',
            'Image of hospital wristband',
            'Picture of a prescription bottle',
            'Test results',
        ]
    },
    {
        title: 'Documents or results you may have left treatment with—',
        imageName: 'icon_hospital_exit',
        listItems: [
            'Hospital discharge paperwork',
            'Summary of care',
            'Picture of a prescription',
            'Follow up care instructions',
            'Completed provider claim form',
        ]
    },
    {
        title: 'Information or documents you may have received later—',
        imageName: 'envelope_eob',
        listItems: [
            'Doctor’s bill',
            'Facility bill',
            'Lab bill',
            'Insurance EOB ',
            'Statement of benefits',
        ]
    },
];

export default DOCUMENT_EXAMPLES;
