import {
  MFAButton,
  MFACheckbox,
  MFAContainer,
  MFAForm,
  MFAInput,
  MFALabel,
  MFAText,
  MFATextError
} from "../styled";
import React, {useState} from "react";
import {setNewToken, validateMfa} from "../../actions/authActions";
import {BackupCodeButton} from "./backupCodeButton";
import {MFA_TYPE, MINIMUM_ATTEMPTS, LEFT_ATTEMPTS_FOR_WARN, MAX_CODE_LENGTH} from "../constants";

export const AuthAppToVerified = ({
    userName,
    mfaToken,
    redirectPath,
    history,
    dispatch,
    redirectToLogin,
    rememberDeviceDays,
    handleUseMethod
  }) => {

  const [mfaCode, setMfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState(false);
  const [validateMfaCodeError, setValidateMfaCodeError] = useState("");
  const [isMfaProcessing, setIsMfaProcessing] = useState(false);
  const [remainingTotpAttempts, setRemainingTotpAttempts] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);
  const [remember, setRemember] = useState(false);

  //Input Backup code handler and validation
  const codeHandler = (e) => {
    setValidationErrors(false);
    setValidateMfaCodeError("");
    setDisableBtn(!(e.currentTarget.value.length > 0))

    let sanitizedValue = e.currentTarget.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
    if (sanitizedValue.length > MAX_CODE_LENGTH || isNaN(sanitizedValue)) return;
    setMfaCode(sanitizedValue);
  };


  //Submit code to mfa/login
  const submitCode = async () => {
    setValidateMfaCodeError("");
    setIsMfaProcessing(true);
    if (mfaCode.length <= 5 || isNaN(mfaCode) || mfaCode === "") {
      setValidationErrors(true);
      setIsMfaProcessing(false);
      return false;
    }
    try {
      const response = await validateMfa(userName, mfaCode, mfaToken, remember, MFA_TYPE.TOTP)
      dispatch(setNewToken(response.data));
      history.push({ pathname: redirectPath });
    } catch (error) {
      const { errorMessage, remainingTotpCodeAttempts, remainingBackupCodeAttempts } =
        error.response.data;
      setIsMfaProcessing(false);
      setValidateMfaCodeError(errorMessage);
      if(remainingTotpCodeAttempts === MINIMUM_ATTEMPTS || remainingBackupCodeAttempts === MINIMUM_ATTEMPTS){
        redirectToLogin(true);
      }
      setRemainingTotpAttempts(remainingTotpCodeAttempts);
    }
  };

  return (
    <>
      <MFAText className={"alignLeft"}>
        Enter the 6-digit authentication code from your authenticator app to continue—
      </MFAText>

      {remainingTotpAttempts && (remainingTotpAttempts <= LEFT_ATTEMPTS_FOR_WARN) && (
          <MFAText className={"invalidAttempt"} style={{ textAlign: "left" }}>
            <span>
              {remainingTotpAttempts}{" "}
              invalid attempt(s) remaining!
            </span>{" "}
            After your <br/>account will be locked for security purposes.{" "}
          </MFAText>
        )}
      <MFAForm.Field className={"contentLeft"}>
        <MFALabel className={"labelVerify"}>
          6-digit authentication code
        </MFALabel>

        <MFAContainer className="changeMethodField">
          <MFAInput
            type="text"
            name="mfaCode"
            id="mfaCode"
            onChange={(e) => codeHandler(e)}
            value={mfaCode}
            maxLength={MAX_CODE_LENGTH}
            required
            error={validationErrors || validateMfaCodeError}
            disabled={isMfaProcessing}
            className={"verifyInput"}
          />
          {validationErrors && <MFATextError>Enter a 6-digit authentication code</MFATextError>}
          {validateMfaCodeError && (
            <MFATextError className={'verifyError'}>
              Invalid code, please try again.
            </MFATextError>
          )}
        </MFAContainer>
        <MFAContainer className="changeMethodField">
          <MFACheckbox
            label={`Remember me on this device for ${rememberDeviceDays} days`}
            name={"mfaRemember"}
            id={"mfaRemember"}
            onChange={() => setRemember(!remember)}
            className={"rememberDevice"}
          />

        </MFAContainer>
        <MFAButton
          style={{
            width: "100%",
            display: "inline-block",
            marginBottom: "16px",
          }}
          loading={isMfaProcessing}
          disabled={isMfaProcessing || disableBtn}
          onClick={submitCode}
        >
          Submit
        </MFAButton>
      </MFAForm.Field>

      <BackupCodeButton handleUseMethod={() => handleUseMethod(MFA_TYPE.BACKUP_CODES)} />
    </>
  )
}
