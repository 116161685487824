import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Header, Icon} from "semantic-ui-react";
import Footer from "../../footer";
import FullScreenPageMenu from "../../fullScreenPageMenu";
import {setLeftNavigation} from "../../../actions/navigationActions";
import {connect} from "react-redux";
import {Link, SupportEmailLink} from "../../base";

class BenAdminAlert extends Component {
    componentDidMount() {
        this.props.dispatch(setLeftNavigation(false))
    }

    onBackHandler = () => {
        this.props.history.replace("/plan");
    }

    render() {
        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                    <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                        <Grid.Column width={6} textAlign={"center"}>
                            <div style={{background: '#FFF7EC', borderRadius: '50%', width: '72px', height: '72px', margin: '0 auto 1.5em auto', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><Icon size={"large"} style={{color: '#616161', margin: 0}} name={"warning sign"}/></div>
                            <Header as={"h4"}>Your are not able to make a change</Header>
                            <p className={"neutral700Text"}>Your demographic and election information is sent to Ansel by a third party application. Please reach out to your employer to determine where this change needs to be made.</p>
                            <Divider hidden/><Divider hidden/>
                            <p className={"smaller"}>Still need help? Reach out to our concierge team at (888) 300-5382 or email <SupportEmailLink /></p>
                            <Divider hidden/><Divider hidden/>
                                <Link to={"/plan"}>Back to your account</Link>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={false} showCenterData={true}/>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect()(BenAdminAlert));
