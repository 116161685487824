export const MFA_TYPE = {
  TOTP: 'TOTP',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  BACKUP_CODES:'backUpCodes'
}

export const LABEL = {
  SEND_CODE: 'Send code',
  NEXT_STEP: 'Next step'
}

export const REMAINING_CODE_RESEND_SECONDS = 30

export const MINIMUM_ATTEMPTS = 0;
export const LEFT_ATTEMPTS_FOR_WARN = 3;
export const COOKIE_EXPIRATION_IN_DAYS = 30;

export const MAX_CODE_LENGTH = 6

export const MAX_CODE_BACKUP_LENGTH = 10

