import React, {useState, useEffect} from 'react';
import {Icon, Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const MAX_BANK_ACCOUNT_LENGTH = 17;

export const BankAccountInput = ({
                                     disabled = false,
                                     onChange,
                                     value,
                                     width,
                                     ...otherProps
                                 }) => {
    const [rawAN, setRawAN] = useState("");
    const [displayAN, setDisplayAN] = useState("");
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState(null);
    const [lastClickedElement, setLastClickedElement] = useState(null);

    useEffect(() => {
        if (value) {
            setRawAN(value)
            setDisplayAN(mask(value))
        }
    }, []);

    useEffect(() => {
        if (!visible && rawAN?.length > 0) {
            const timer = setTimeout(() => {
                setDisplayAN(mask(rawAN))
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [rawAN]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            setLastClickedElement(prevLastClickedElement => {
                if (prevLastClickedElement && prevLastClickedElement.id === "eye" && e.target.id !== "eye") onBlur();
                return e.target;
            });
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [rawAN]);

    const toggleVisibility = () => {
        setVisible(prevVisible => {
            setDisplayAN(mask(rawAN))
            return !prevVisible
        });
    };

    const visibilityIcon = (
        <Icon
            id={"eye"}
            className="visibility-toggle-icon"
            name={visible ? 'eye slash' : 'eye'}
            link
            onClick={toggleVisibility}
            aria-hidden="false"
            aria-label={visible ? 'Hide account number' : 'Show account number'}
        />
    );

    const handleSmallerInput = ({e, value, selectionStart}) => {
        const endSlicePos = selectionStart + (rawAN.length - value.length);
        const newRaw = `${rawAN.slice(0, selectionStart)}${rawAN.slice(endSlicePos)}`;
        setRawAN(newRaw);
        const display = visible ? newRaw : value;
        setDisplayAN(display);
        onChange(e, newRaw);
    };

    const handleLongerInput = ({e, value}) => {
        let newAN;
        if (visible) {
            newAN = value;
        } else {
            const match = value.match(/\d+/);
            const startIndex = match.index;
            const enteredDigit = match[0];
            const endIndex = startIndex + enteredDigit.length;

            const modifiedStart = `${startIndex === 0 ? enteredDigit : ''}${rawAN.slice(0, startIndex)}`;
            const middle = `${startIndex !== 0 ? enteredDigit : ''}`;
            const modifiedEnd = `${(endIndex === enteredDigit.length || endIndex === MAX_BANK_ACCOUNT_LENGTH) ? '' : rawAN.slice(startIndex + enteredDigit.length - 1)}`;

            newAN = `${modifiedStart}${middle}${modifiedEnd}`;
        }

        setRawAN(newAN);
        const display = visible ? newAN : value;
        setDisplayAN(display);
        onChange(e, newAN);
    };

    const shouldRejectChange = (value) => {
        const reg = /^(?=.*\d)[•\d]*$/;
        if (!reg.test(value)) {
            return !(!value.length || (value.length < rawAN.length && !visible));
        }
        return value.length > MAX_BANK_ACCOUNT_LENGTH;
    };

    const handleChange = (e) => {
        let {value, selectionStart} = e.target;

        if (shouldRejectChange(value)) {
            return;
        }

        if (value.length < rawAN.length) {
            handleSmallerInput({e, value, selectionStart});
        } else {
            handleLongerInput({e, value});
        }
    };

    const mask = (v) => {
        return v.replace(/[0-9]/g, "•");
    };

    const onBlur = () => {
        if (lastClickedElement && lastClickedElement.id === "eye") {
            return;
        }
        if (!rawAN) {
            setError("Required");
        }
    };

    return (
        <Form.Field width={width}>
            {disabled ?
                <Form.Input
                    className="disabled-input"
                    fluid
                    icon={visibilityIcon}
                    readOnly
                    required
                    value={visible ? value : mask(value)}
                    {...otherProps}
                />
                : <>
                    <Form.Input
                        error={!!error}
                        fluid
                        icon={visibilityIcon}
                        maxLength={MAX_BANK_ACCOUNT_LENGTH}
                        onBlur={onBlur}
                        onChange={handleChange}
                        onFocus={() => setError(null)}
                        required
                        value={visible ? rawAN : displayAN}
                        {...otherProps}
                    />
                    {!!error && <small className="warningRedText">{error}</small>}
                </>}
        </Form.Field>
    );
};

BankAccountInput.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    width: PropTypes.number,
};
