import {useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setLeftNavigation} from '../actions/navigationActions';
import {setIsStopscreenDisplayed} from '../actions/stopScreensActions';
import axios from 'axios';
import {purgeState} from '../storePersistence';
import {setLogout} from '../actions/authActions';

export const usePreventBackNavigation = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const unblockRef = useRef(null);

    const backendLogout = async () => {
        try {
            await axios.post('/api/member/v2/logout', null, {headers: {'Content-Type': 'application/json'}});
        } catch (e) {
            console.error('An error occurred while logging out:', e);
        }
    };

    const logout = async () => {
        await backendLogout();
        purgeState();
        dispatch(setLogout());
    };

    useEffect(() => {
        dispatch(setLeftNavigation(false));
        dispatch(setIsStopscreenDisplayed(true));

        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                logout().then(() => {
                    history.push('/');
                });
                return false;
            }
            return true;
        });

        unblockRef.current = unblock;

        return () => unblock();
    }, [history, dispatch]);

    return unblockRef.current;
};
