import React, {useState} from 'react'
import {Form} from "semantic-ui-react";
import {DateInput} from 'semantic-ui-calendar-react'
import {displayDateFormat} from '../../utils/date'
import {convertDisplayDateFormatToServer, formatPartialDate, isEvenNumber} from "../../utils/common";
import moment from "moment";
import PropTypes from 'prop-types';

const INVALID_DATE = "Invalid date";
const MAX_DOB_LENGTH = 10;
const DATE_REGEX = /^[0-9/]+$/

const isValidText = (e, value, isMasked) => {
    // when user selects date from keyboard or clicking on calendar
    if ((!e || !e.nativeEvent) && value.length === MAX_DOB_LENGTH) {
        return true;
    }
    // is pasting date and length is MAX_DOB_LENGTH
    if (e.nativeEvent.inputType === "insertFromPaste" && value.length === MAX_DOB_LENGTH) {
        return true;
    } else
        // is typing date only with numbers
    if (e.nativeEvent.inputType === "insertText" && !isMasked && !isNaN(value) && value.length < MAX_DOB_LENGTH && value.length > 0) {
        return true;
    } else
        // is removing characters (when input it's visible or user selected the whole date)
    if (e.nativeEvent.inputType === "deleteContentBackward") {
        if ((value.length > 0 && !isMasked) || value.length === 0) {
            return true;
        }
    } else
        // allow typing characters with no masking or date is picked from calendar
    if (((e.nativeEvent.inputType === "insertText" && !isMasked) || (e.nativeEvent.type === "click")) && (value.length === MAX_DOB_LENGTH || DATE_REGEX.test(value))) {
        return true;
    }
    return false;
}

export const DateSelector = ({
                                 onChange,
                                 onFocus = () => {},
                                 onBlur = () => {},
                                 setFormError = () => {},
                                 iconPosition = "right",
                                 minDate = null,
                                 maxDate = null,
                                 required = false,
                                 icon = false,
                                 inputClassName = "",
                                 isReadonly = false,
                                 duration = 0,
                                 isMasked = false,
                                 value,
                                 width,
                                 ...props
                             }) => {

    const [inputValue, setInputvalue] = useState(value);
    const [error, setError] = useState('');

    const handleBlur = () => {
        const isEmpty = !value || value.length === 0;
        if (isEmpty) {
            if (!!required) {
                setError(INVALID_DATE);
                setFormError(INVALID_DATE);
            }
        } else {
            const parts = value.split("/");
            let errorMessage;
            if (parts.length > 3) {
                errorMessage = INVALID_DATE;
                setFormError(errorMessage);
                setError(errorMessage);
                return;
            }
            let mm = parts[0];
            let dd = parts[1];
            let yyyy = parts[2];
            const digitMm = parseInt(mm);
            const digitDd = parseInt(dd);

            let invalidDate = false;
            if (digitMm > 12 || digitDd > 31) {
                invalidDate = true;
            } else {
                const isEvenMonth = isEvenNumber(digitMm);
                if (isEvenMonth) {
                    if (digitMm === 2 && digitDd > 29) {
                        invalidDate = true;
                    } else if (digitDd > 30) {
                        invalidDate = true;
                    }
                } else if (digitDd > 31) {
                    invalidDate = true;
                }
            }
            if (invalidDate || !dd || !mm || !yyyy) {
                errorMessage = `Format should be ${displayDateFormat}`
                setError(errorMessage);
                setFormError(errorMessage);
                return;
            }

            const validMomentFormat = convertDisplayDateFormatToServer(value);
            const mDate = moment(validMomentFormat);
            invalidDate = !mDate.isValid();
            let isDateAfterMaxDate = false;
            let isDateBeforeMinDate = false;
            if (!invalidDate) {
                isDateAfterMaxDate = maxDate && mDate.isAfter(maxDate);
                isDateBeforeMinDate = minDate && mDate.isBefore(minDate);
            }
            const requiredDateLength = value.length === 10;
            if (isMasked && !requiredDateLength) {
                errorMessage = INVALID_DATE;
            }
            if (!isMasked && (!requiredDateLength || invalidDate)) {
                errorMessage = INVALID_DATE;
            } else if (isDateAfterMaxDate) {
                errorMessage = `Date can't be after ${maxDate.format(displayDateFormat)}`;
            } else if (isDateBeforeMinDate) {
                errorMessage = `Date can't be before ${minDate.format(displayDateFormat)}`;
            }
            setError(errorMessage);
            setFormError(errorMessage);
        }
        if (!!onBlur) {
            onBlur();
        }
    };

    const handleChange = (event, {value}) => {
        if (!isValidText(event, value, isMasked)) {
            return;
        }
        if (!isMasked && value.length > 1) {
            const lastChar = value[value.length - 1]
            const isDeletingCharacters = value.length < inputValue.length;
            if (!(lastChar === "/" && isDeletingCharacters)) {
                const reg = /^\d*$/;
                if (!reg.test(lastChar)) {
                    return;
                }
            }
        }
        const date = formatPartialDate(value, inputValue);
        setError('');
        setFormError(null);
        onChange(event, {value: date});
        setInputvalue(date);
    };

    return (
        <Form.Field width={width}>
            <DateInput
                closable={true}
                readOnly={isReadonly}
                duration={duration}
                animation={""}
                startMode={"month"}
                error={!!error}
                dateFormat={displayDateFormat}
                placeholder={displayDateFormat}
                minDate={minDate}
                maxDate={maxDate}
                required={required}
                onFocus={() => {
                    setError('');
                    setFormError(null);
                    if (!!onFocus) {
                        onFocus();
                    }
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                iconPosition={iconPosition}
                value={value}
                icon={icon}
                {...props}
            />
            {error?.length > 0 && (
                <small className={"warningRedText"}>{error}</small>
            )}
        </Form.Field>
    )
};

DateSelector.propTypes = {
    onChange: (props, propName, componentName) => {
        if (!props.isReadonly && (props[propName] === undefined || typeof props[propName] !== 'function')) {
            return new Error(`Invalid prop ${propName} supplied to ${componentName}. When isReadonly is false, ${propName} is required and must be a function.`);
        }
    },
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    setFormError: PropTypes.func,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    isMasked: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    required: PropTypes.bool,
    isReadonly: PropTypes.bool,
    duration: PropTypes.number,
    value: PropTypes.string,
    width: PropTypes.number,
};
