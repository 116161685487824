import {userActionTypes} from "../actions/actionTypes";

const initialState = {
    lang: null,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActionTypes.SET_LANGUAGE:
            return {
                ...state,
                lang: action.payload,
            }
        default:
            return state
    }
};