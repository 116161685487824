import {newClaimActionTypes} from "./actionTypes"


export function setClaimant(claimant) {
    return {
        type: newClaimActionTypes.SET_CLAIMANT,
        payload: claimant
    }
}

export function setWhatHappened(whatHappened) {
    return {
        type: newClaimActionTypes.SET_WHAT_HAPPEND,
        payload: whatHappened
    }
}

export function setTreatmentInfo(treatmentInfo) {
    return {
        type: newClaimActionTypes.SET_TREATEMENT_INFO,
        payload: treatmentInfo
    }
}

export function appendFiles(files) {
    return {
        type: newClaimActionTypes.APPEND_FILES,
        payload: files
    }
}

export function removeFile(fileIndex) {
    return {
        type: newClaimActionTypes.REMOVE_FILE,
        payload: fileIndex
    }
}

export function cleanupNewClaim() {
    return {
        type: newClaimActionTypes.CLEANUP_NEW_CLAIM,
        payload: null
    }
}

export function setSelectedPayoutMethod(pm) {
    return {
        type: newClaimActionTypes.SET_SELECTED_PAYOUT_METHOD,
        payload: pm
    }
}