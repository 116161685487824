import React from "react";

function ClaimStepsLayout({ children, style, className }) {

    return (
        <div className={className ?? "member-main-container"} style={{ ...style, background: 'white', paddingBottom: '360px' }}>
            {children}
        </div>
    );
}

export default ClaimStepsLayout;