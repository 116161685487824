import React, {Component, useRef, useEffect} from 'react';
import {Divider, Grid, Message} from "semantic-ui-react";
import {CustomContainer} from './custom-common';
import {Button} from "./base";
import Footer from "./footer";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {setConsent} from "../actions/initialDataActions";
import {setIsStopscreenDisplayed} from "../actions/stopScreensActions";
import {resolveErrorMessage} from "../utils/common";
import MainMenu from "./MainMenu";
import ConsentContent from "./consentContent";
import {usePreventBackNavigation} from "../hooks";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        eConsent: state.initialData.user?.eConsent,
    }
};

const mapDispatchToProps = dispatch => ({
    setConsent: consent => dispatch(setConsent(consent)),
    setIsStopscreenDisplayed: isStopscreenDisplayed => dispatch(setIsStopscreenDisplayed(isStopscreenDisplayed)),
});

const withPreventBackNavigation = (WrappedComponent) => (props) => {
    const unblock = usePreventBackNavigation();
    const unblockRef = useRef(unblock);

    useEffect(() => {
        unblockRef.current = unblock;
    }, [unblock]);

    return <WrappedComponent {...props} unblock={unblockRef.current}/>;
};

class Consent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loadingConsent: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {eConsent, history, setIsStopscreenDisplayed, unblock} = this.props;
        if (eConsent) {
            if (unblock) {
                unblock();
            }
            setIsStopscreenDisplayed(false);
            history.push("/plan");
        }
    }

    updateConsent = async () => {
        const {setConsent} = this.props;
        this.setState({loadingConsent: true});
        const payload = {eConsent: true};
        try {
            await axios.post("/api/member/v2/updateEConsent", payload, {headers: {'Content-Type': 'application/json'}});
            setConsent(true);
        } catch (e) {
            this.setState({error: resolveErrorMessage(e, 'An unexpected error has occurred')});
        } finally {
            this.setState({loadingConsent: false});
        }
    }

    render() {
        const {loadingConsent, error} = this.state;
        const button = (
            <Button
                primary
                className='nowrap'
                content='I agree'
                loading={loadingConsent}
                onClick={this.updateConsent}
            />
        );
        return (
            <div className="member-main-container">
                <MainMenu/>
                <Grid container stackable columns={1} centered>
                    <Grid.Column className={"pageContent"}>
                        <Divider hidden/>
                        <CustomContainer padded={"very"}>
                            <ConsentContent/>
                        </CustomContainer>
                        {!!error && <Message negative>{error}</Message>}
                    </Grid.Column>
                </Grid>
                <Footer showTopFooter={true} showCenterData={true} button={button} largeButton/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withPreventBackNavigation(Consent)));
