import {billingActionTypes} from "../actions/actionTypes";

const initialState = {
    plaidEnvironment: null,
    plaidLinkToken: null,
    stripePublicKey: null,
    paymentMethods: [],
    microDepositsBankAccounts: [],
    autoPay: null,
    currentInvoices: null,
    previewInvoice: null,
    historicalInvoices: [],
    accountBalance: null,
    currentBilling: null,
    pastDue: null,
    today: null,
    chargeAttempts: [],
    lastPaymentFailed: null,
    accountSummaryCardLoading: false,
};

export const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case billingActionTypes.SET_BILLING_DATA:
            const billingData = action.payload;
            return {
                ...state,
                plaidEnvironment: billingData.plaidEnvironment,
                plaidLinkToken: billingData.plaidLinkToken,
                stripePublicKey: billingData.stripePublicKey,
                paymentMethods: billingData.paymentMethods,
                microDepositsBankAccounts: billingData.microDepositsBankAccounts,
                autoPay: billingData.autoPay,
                currentInvoices: billingData.currentInvoices,
                previewInvoice: billingData.previewInvoice,
                historicalInvoices: billingData.historicalInvoices,
                accountBalance: billingData.accountBalance,
                currentBilling: billingData.currentBilling,
                pastDue: billingData.pastDue,
                today: billingData.today,
                chargeAttempts: billingData.chargeAttempts,
                lastPaymentDate: billingData.lastPaymentDate,
                lastPaymentFailed: billingData.lastPaymentFailed,
                billingAccountId: billingData.billingAccountId,
            }
        case billingActionTypes.SET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.payload,
            }
        case billingActionTypes.SET_BANK_ACCOUNT:
            const {payload} = action
            const {metadata} = payload
            if (metadata && metadata.verificationStatus && metadata.verificationStatus === "manually_verified") {
                payload.status = "manually_verified"
            }
            return {
                ...state,
                paymentMethods: [...state.paymentMethods, payload],
            }
        case billingActionTypes.SET_MICRO_DEPOSITS_BANK_ACCOUNT:
            return {
                ...state,
                microDepositsBankAccounts: [...state.microDepositsBankAccounts, action.payload],
            }
        case billingActionTypes.SET_MICRO_DEPOSITS_BANK_ACCOUNTS:
            return {
                ...state,
                microDepositsBankAccounts: action.payload,
            }
        case billingActionTypes.DELETE_MICRO_DEPOSITS_BANK_ACCOUNT:
            return {
                ...state,
                microDepositsBankAccounts: state.microDepositsBankAccounts.filter(el => el.accountId !== action.payload),
            }
        case billingActionTypes.SET_ACCOUNT_SUMMARY_CARD_LOADING:
            return {
                ...state,
                accountSummaryCardLoading: action.payload,
            }
        case billingActionTypes.SET_AUTO_PAY:
            return {
                ...state,
                autoPay: action.payload,
            }
        case billingActionTypes.PATCH_MICRO_DEPOSIT_BANK_ACCOUNT:
            const newMicroDepositsBankAccounts = [ ...state.microDepositsBankAccounts ]
            const { accountId } = action.payload;

            for (let i = 0; i < newMicroDepositsBankAccounts.length; i++) {
                if (newMicroDepositsBankAccounts[i].accountId === accountId) {
                    newMicroDepositsBankAccounts[i].status = "verification_failed"
                    break;
                }
            }

            return {
                ...state,
                microDepositsBankAccounts: newMicroDepositsBankAccounts
            }
        default:
            return state
    }
}