import React from "react";
import {CustomContainer} from './custom-common';

export default function BorderedSegment(props) {
    const {children, borderColor} = props;
    const style = {borderLeft: '8px solid'+borderColor}
    return (
        <CustomContainer style={style}>
            {children}
        </CustomContainer>

    )

}
