import React, {useCallback} from 'react';
import {usePlaidLink} from 'react-plaid-link';
import axios from "axios";

const PlaidMicroDeposits = ({accountId, plaidEnviroment, bankAccountData, setPlaidData, hideAccount, setVerificationStatus, updateStatus}) => {

    const onSuccess = useCallback((_, data) => {
        const {account_id, public_token} = data
        const status = data.account?.verification_status
        const metadata = {"verificationStatus": status, "verifiedAt": (new Date()).toISOString()}
        updateStatus({account_id, status, public_token})
        setPlaidData({...data, metadata})
        hideAccount(account_id)
    }, [hideAccount, updateStatus, setPlaidData])

    const onExit = useCallback(async (error, metadata) => {
        if(!error) {
            return
        }
        if(error.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS'){
                const status = "verification_failed"
                setVerificationStatus(status)
                updateStatus({account_id: accountId, status})
        }
        console.log({error, metadata})
        try {
            await axios.post("/api/member/v2/plaidError", {error, metadata}, {headers: {'Content-Type': 'application/json'}});
        } catch (e) {

        }
    }, [accountId, setVerificationStatus, updateStatus]);

    const config = {
        token: bankAccountData.plaidLinkToken,
        env: plaidEnviroment,
        onSuccess,
        onExit,
    }

    const {open, ready, loading} = usePlaidLink(config)

        return (<>
            <a disabled={!ready || loading}
               onClick={() => open()}
               style={{lineHeight: '21px', float:'right', color:'#43806C', margin:"5px 0px 0px 8px"}}
               className={"small bold"}
               href
            >Verify account
            </a>
        </>)
}

export default PlaidMicroDeposits