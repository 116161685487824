import React from 'react';
import {Grid, Segment} from 'semantic-ui-react';
import ItemsDetailsTable from './itemsDetailsTable';
import {connect} from "react-redux";
import {toggle as toggleGetHelpModal} from "../actions/getHelpActions";
import {getLocalization} from "../utils/localization";
import {resolveDisplayTier} from "../utils/common";


const mapStateToProps = ({ initialData }, ownProps) => {
    const strings = getLocalization(initialData.user.lang);
    const { certificate } = initialData;
    const { assetCoverages } = certificate;
    const coverageDetails = {
        benefits: [
            { name: strings.coverageDetails.moderate, value: assetCoverages.moderate},
            { name: strings.coverageDetails.severe, value: assetCoverages.severe},
            { name: strings.coverageDetails.catastrophic, value: assetCoverages.catastrophic},
        ],
        additionals: []
    }
    if (assetCoverages?.chronic) {
        coverageDetails.additionals.push({ name: strings.coverageDetails.chronic, value: assetCoverages.chronic })
    }
    if (assetCoverages?.mental) {
        coverageDetails.additionals.push({ name: strings.coverageDetails.mental, value: assetCoverages.mental })
    }
    const displayTier = resolveDisplayTier(certificate.familyTier) ;

    return {
        ...ownProps,
        coverageDetails,
        displayTier,
        strings,
    }
};

const mapDispatchToProps = dispatch => ({
    toggleGetHelpModal: state => dispatch(toggleGetHelpModal(state)),
});

const CoverageDetails = ({ coverageDetails, toggleGetHelpModal, displayTier, strings, showReachOut }) => (
    <>
        <h4 className={'vibranBlueHeader'} style={{marginBottom: '15px', marginTop: '10px'}}>
            {strings.coverageDetails.title}
        </h4>
        <Segment style={{padding: '2em', paddingBottom: '3em'}}>
            <Grid>
                <Grid.Row>
                    <div className={'six wide column'}>
                        <ItemsDetailsTable title={strings.coverageDetails.benefitAmounts}
                                           items={coverageDetails.benefits}/>
                    </div>
                    {
                        coverageDetails.additionals.length > 0 &&
                        <div className={'six wide column'}>
                            <ItemsDetailsTable title={strings.coverageDetails.additionalCoverage}
                                               items={coverageDetails.additionals}/>
                        </div>
                    }
                </Grid.Row>
                <div>
                    <p className={'neutral600Text'} style={{fontSize: '14px', marginBottom: '5px'}}>
                        <b>{strings.coverageDetails.whosCovered}?</b>
                    </p>
                    <p className={'neutral900Text'}>
                        <b>{displayTier}</b>
                    </p>
                </div>
            </Grid>
        </Segment>
        {showReachOut &&
        <div className={'bkgFog'} style={{marginTop: '20px', padding: '20px'}}>
            <p>
                <span className={'neutral700Text'}><b>{strings.coverageDetails.note}:</b></span> {strings.coverageDetails.youveAlready}<b
                className={'vibranBlueHeader'} style={{cursor: "pointer"}}
                onClick={() => toggleGetHelpModal(true)}> {strings.coverageDetails.reachOut}</b>
            </p>
        </div>
        }
    </>
);

export default connect(mapStateToProps, mapDispatchToProps)(CoverageDetails);
