import React from 'react'
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";

const SupportEmailLink = ({textOverride}) => {
    const email = STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL;

    return (<a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer"><b>{textOverride || email}</b></a>);
}

export default SupportEmailLink;
