import React from 'react'

export default function Alert(props)  {
        return (
            <React.Fragment>
               <div style={props.style} className={`alert ${props.type}`}>{props.children}</div>
            </React.Fragment>
        )
}

