import React, {Component, createRef} from "react"
import {Button, Divider, Grid, Header, Table} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {firstCharUpper} from "../../utils/common";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import SupportEmailLink from "../base/support-email-link";
import {ClickableText} from "../custom-common";

const mapStateToProps = (state, ownProps) => {
    const {certificate: {conditionListVersionId, assetCoverages}} = state.initialData;
    const hasChronic = !!assetCoverages.chronic;
    const hasMental = !!assetCoverages.mental;

    return {
        ...ownProps,
        hasChronic,
        hasMental,
        conditionListVersionId
    }
};

class ConditionsWithKeyword extends Component {
    viewMoreRef = React.createRef();
    INCLUDED = 0;
    NOT_INCLUDED = 1;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tab: this.INCLUDED,
            page: 1,
            isLastPage: false,
            included: [],
            notIncluded: [],
        };
    }

    componentDidMount() {
        this.fetchData().then();
    }

    fetchData = async (overWritePage) => {
        this.setState({loading: true, error: null});
        const previousIncludedIdx = this.state.included.length > 0 ? this.state.included.length-1 : 0;
        const previousNotIncludedIdx =this.state.notIncluded.length > 0 ? this.state.notIncluded.length-1 : 0;
        try {
            const {hasChronic, hasMental, conditionListVersionId} = this.props;
            const {page} = this.state;
            let usedPage = !!overWritePage? overWritePage : page;
            const payload = {hasChronic, hasMental, page: usedPage, conditionListVersionSfId: conditionListVersionId};
            const result = await axios.post("/api/member/v2/listConditionsWithKeyword", payload, {headers: {'Content-Type': 'application/json'}});
            if (result.status !== 200) {
                throw Error(result.statusText);
            }
            const data = result.data;
            const curIncluded = data.conditions.filter(d => d.includedCondition);
            const curNotIncluded = data.conditions.filter(d => !d.includedCondition);
            let {included, notIncluded} = this.state;
            included = included.concat(curIncluded)
            notIncluded = notIncluded.concat(curNotIncluded)
            this.setState({
                loading: false,
                included: included,
                notIncluded: notIncluded,
                page: usedPage,
                isLastPage: data.isLastPage,
                previousIncludedIdx: previousIncludedIdx,
                previousNotIncludedIdx: previousNotIncludedIdx,
            });
        } catch (e) {
            console.warn(e);
            this.setState({loading: false, error: "It was not possible to load condition information"})
        }
    };

    setIncludedButtonRef = createRef()
    setNotIncludedButtonRef = createRef()

    setIncludedTab = () => {
        this.setIncludedButtonRef.current.ref.current.blur()
        this.setState({tab: this.INCLUDED});
    }

    setNotIncludedTab = () =>  {
        this.setNotIncludedButtonRef.current.ref.current.blur()
        this.setState({tab: this.NOT_INCLUDED});
    }

    renderDataArr = (dataArr, isIncluded) => {
        const response = [];
        if (!!dataArr) {
            let initial = "";
            let row = [];

            dataArr.forEach((condition, idx) => {
                const curInitial = condition.keywordName[0].toUpperCase();
                if(idx === 0){
                    initial = curInitial;
                }
                if((isIncluded && idx === this.state.previousIncludedIdx) || (!isIncluded && idx === this.state.previousNotIncludedIdx)){
                    row.push(<div key={idx} ref={this.viewMoreRef}/>)
                }

                const closeRow = () => {
                    response.push(<Table.Row key={condition.includedCondition? "a"+idx:"b"+idx} verticalAlign={"top"}>
                        <Table.Cell>
                            <Header as={'h2'}>{initial}</Header>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid width={12}>{row}</Grid>
                            <Divider hidden/>
                        </Table.Cell>
                    </Table.Row>);

                    initial = curInitial;
                    row = [];
                }

                if(initial !== curInitial){
                    closeRow();
                }

                row.push(<Grid.Row data-qa-conditionListDefault key={condition.includedCondition? "c"+idx:"d"+idx} columns={2}>
                    <Grid.Column width={10} style={{marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0}}>
                        <div className={"small"}><b>{condition.conditionName}</b></div>
                        <div style={{color: '#616161', marginTop: '5px', fontWeight: '400px'}} className={"small"}>{condition.keywordName}</div>
                    </Grid.Column>

                    <Grid.Column width={2} style={{marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0}}>
                        <div style={{color: '#616161', marginTop: '5px', fontWeight: '400px'}} className={"small"}>{firstCharUpper(condition.conditionGroupName)}</div>
                    </Grid.Column>
                </Grid.Row>);

                if(idx === dataArr.length - 1){
                    closeRow();
                }
            });
        }

        return response;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!!this.viewMoreRef.current && this.state.page > 1){
            this.viewMoreRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    render() {
        const {included, notIncluded, loading, tab, isLastPage} = this.state;

        return (
            <Grid.Column textAlign={'left'}>
                <Grid.Row columns={1}>
                    <Button primary
                            className={'clButton'}
                            basic={tab === this.NOT_INCLUDED}
                            style={{marginBottom: '1em', width: '12em'}}
                            onClick={this.setIncludedTab}
                            ref={this.setIncludedButtonRef}
                    >
                        <b>Included in plan</b>
                    </Button>
                    <Button primary
                            className={'clButton'}
                            basic={tab === this.INCLUDED}
                            style={{marginBottom: '1em', width: '12em'}}
                            onClick={this.setNotIncludedTab}
                            ref={this.setNotIncludedButtonRef}
                    >
                        <b>Not included</b>
                    </Button>
                </Grid.Row>

                <Grid.Row style={{marginTop: '0.5em'}}>
                    <Grid.Column>
                        <span>
                        {STRATEGIC_PARTNER.LABEL} pays benefits when you’re diagnosed with any of 13,000+ covered conditions as provided for in your policy.
                        To learn more about what’s covered and what’s not, look up a condition or browse the list below.
                        You’ll see conditions listed with the benefit category they are assigned in your contract.
                        We’ve used laymans’ terms for injuries and illnesses here, but physicians often use different scientific names and assign a variety of ICD-10 diagnosis codes to identify and distinguish between conditions.
                        To avoid confusion, when you file a claim we will evaluate whether or not you have a covered condition based on the ICD-10 code your physician uses to describe your diagnosis.
                        If you have any questions about coverage for specific conditions, contact us at {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER} or email </span><SupportEmailLink/>.
                    </Grid.Column>
                </Grid.Row>
                <Divider hidden/>

                <Divider style={{marginLeft: 0, marginRight: 0}}/>

                <Grid stackable columns={1}>
                    <Grid.Row columns={1} textAlign={'center'} verticalAlign={'middle'}>
                        <Grid.Column textAlign="center">
                            {this.state.loading ?
                                <React.Fragment>
                                    <Divider hidden/>
                                    <CustomContainer padded={"very"} loading={loading} vertical basic/>
                                </React.Fragment> : <React.Fragment>

                                    <Table basic={'very'}>
                                        <Table.Body>
                                            {tab === this.INCLUDED && this.renderDataArr(included, true)}
                                            {tab === this.NOT_INCLUDED && this.renderDataArr(notIncluded, false)}
                                        </Table.Body>
                                    </Table>
                                    {!isLastPage && <ClickableText onClick={async () => await this.fetchData(this.state.page + 1)}>
                                        View More
                                    </ClickableText>}
                                </React.Fragment>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ConditionsWithKeyword));
