import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  MFAHeading,
  MFASegment,
  MFAText,
  MFAContainer,
  MFALink,
} from "../styled";

import STRATEGIC_PARTNER from "../../constants/strategicPartners";

import {SelectMethodToVerified} from "./selectMethodToVerified";
import {EmailToVerified} from "./emailToVerified"
import {AuthAppToVerified} from "./authAppToVerified"
import {MFA_TYPE} from "../constants";
import {BackUpCodes} from "./backUpCodes";
import {PreviewEmailToVerified} from "./previewEmailToVerified";

const ValidateMFA = (props) => {

  const {
    userName,
    mfaToken,
    redirectPath,
    loginPath,
    rememberDeviceDays,
    mfaTypesConfigured,
    mfaEmailSetUpped
  } = props;

  const [submitted, setSubmitted] = useState(false)
  const [previewEmailWasChecked, setPreviewEmailWasChecked] = useState(false)
  const [usingMethod, setUsingMethod] = useState({
    [MFA_TYPE.EMAIL]: false,
    [MFA_TYPE.TOTP]: false,
    [MFA_TYPE.SMS]: false,
    backUpCodes: false,
  })
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if(mfaTypesConfigured?.length === 1) {
      handleUseMethod(mfaTypesConfigured[0])
      handleSubmit()
    }
  }, [mfaTypesConfigured]);

  const redirectToLogin = (isLocked) => {
    history.push({ pathname: loginPath, isLocked});
  };

  const handleUseMethod = (method) => {
    setUsingMethod(prevState => ({
      [MFA_TYPE.EMAIL]: false,
      [MFA_TYPE.TOTP]: false,
      [MFA_TYPE.SMS]: false,
      [MFA_TYPE.BACKUP_CODES]: false,
      [method]: true
    }))
  }

  const handleSubmit = () => setSubmitted(true)

  const handleCheckPreview = () => setPreviewEmailWasChecked(true)

  return (
    <>
      <MFAContainer
        maxWidth={"456px"}
        style={{ paddingTop: "125px", textAlign: "center" }}
      >
        <MFAHeading as="h2" textAlign="center">
          Authentication code required
        </MFAHeading>
        <MFASegment className={'verifySegment'}>
          {(!submitted && !usingMethod.backUpCodes) && (
            <SelectMethodToVerified
              usingMethod={usingMethod}
              handleUseMethod={handleUseMethod}
              submit={handleSubmit}
            />
          )}

          {submitted && (
            <>
              {usingMethod[MFA_TYPE.EMAIL] && (
                <>
                  {!previewEmailWasChecked && (
                    <PreviewEmailToVerified
                      mfaEmailSetUpped={mfaEmailSetUpped}
                      userName={userName}
                      mfaToken={mfaToken}
                      handleCheckPreview={handleCheckPreview}
                      handleUseMethod={handleUseMethod}
                    />
                  )}

                  {previewEmailWasChecked && (
                    <EmailToVerified
                      mfaEmailSetUpped={mfaEmailSetUpped}
                      userName={userName}
                      mfaToken={mfaToken}
                      handleUseMethod={handleUseMethod}
                      redirectPath={redirectPath}
                      history={history}
                      dispatch={dispatch}
                      redirectToLogin={redirectToLogin}
                      rememberDeviceDays={rememberDeviceDays}
                    />
                  )}
                </>
              )}
            </>
          )}

          {submitted && (
            <>
              {usingMethod[MFA_TYPE.TOTP] && (
                <AuthAppToVerified
                  userName={userName}
                  mfaToken={mfaToken}
                  redirectPath={redirectPath}
                  history={history}
                  dispatch={dispatch}
                  redirectToLogin={redirectToLogin}
                  rememberDeviceDays={rememberDeviceDays}
                  handleUseMethod={handleUseMethod}
                />
              )}
            </>
          )}

          {usingMethod.backUpCodes && (
            <BackUpCodes
              mfaToken={mfaToken}
              redirectPath={redirectPath}
              history={history}
              dispatch={dispatch}
              redirectToLogin={redirectToLogin}
            />
          )}

        </MFASegment>
        {usingMethod.backUpCodes && (
          <MFAContainer className="backupCodeText">
            <MFAText>
              Still having trouble? Contact us at{" "}
              <MFALink>{STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}</MFALink> or call (888)
              290-1153.
            </MFAText>
          </MFAContainer>
        )}
        <MFAContainer className="backupCodeText">
          <MFALink
            onClick={() => redirectToLogin()}
            className={"left"}
            style={{ textAlign: "left", fontSize: "14px" }}
          >
            Return to login screen
          </MFALink>
        </MFAContainer>
      </MFAContainer>
    </>
  );
};

export default ValidateMFA;
