import React, {useEffect, useState} from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';
import {Form} from "semantic-ui-react";
import {Button} from "../base";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";
import StripeField from "./stripeField";
import CreditCardErrors from "./creditCardErrors";

const CheckoutForm = (props) => {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(true);
    const strings = props.strings;

    const {
        hideCreditCardPm,
        setCreditCardPm,
    } = PaymentDetailsContext.useContainer();

    useEffect(() => {
        setProcessing(!props.stripe);
    }, [props.stripe]);

    const handleSubmit = (ev) => {
        ev.preventDefault()
        setProcessing(true);
        setError(null);
        props.stripe
            .createPaymentMethod('card')
            .then((result) => {
                setProcessing(false)
                if (result.error) {
                    setError(result.error);
                    console.log(result.error);
                } else {
                    setError(null);
                    setCreditCardPm(result.paymentMethod);
                    hideCreditCardPm();
                    console.log('Received Stripe PaymentMethod:', result.paymentMethod);
                }
            });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <CreditCardErrors errors={[error]} />
            <StripeField label={strings.paymentRequired.creditCard} component={CardNumberElement}/>
            <Form.Group widths="equal" style={{alignItems: "end"}}>
                <StripeField label={strings.paymentRequired.expiration} component={CardExpiryElement}/>
                <StripeField label={strings.paymentRequired.cvc} component={CardCVCElement}/>
            </Form.Group>
            <Button style={{marginRight: "1rem"}} primary basic onClick={hideCreditCardPm}>{strings.paymentRequired.cancel}</Button>
            <Button primary type="submit" disabled={processing}>{strings.paymentRequired.addCreditCard}</Button>
        </Form>
    );
}

export default injectStripe(CheckoutForm);
