import React from "react";
import {Header} from "../base";

const flexInlineStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 1em"
};

const YourCosts = ({cost, employerContribution, formattedCostPayment}) => (
    <>
        {!!employerContribution && employerContribution !== "0.00" && (
            <div style={flexInlineStyles}>
                <b className="neutral600Text" style={{fontSize: '12px'}}>Employer contribution</b>
                <span className="vibranBlueText"
                      style={{fontSize: '16px'}}><b>${employerContribution}/{formattedCostPayment}</b></span>
            </div>
        )}
        {!!cost && cost !== "0.00" && (
            <div style={flexInlineStyles}>
                <b className="vibranBlueText" style={{fontSize: '14px'}}>Your cost</b>
                <Header as="h4" style={{margin: 0, fontSize: '20px'}}>
                    <b>${cost}</b>/{formattedCostPayment}
                </Header>
            </div>
        )}
    </>
);

export default YourCosts;
