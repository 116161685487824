import {Icon} from "semantic-ui-react";
import React from "react";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";


const DownloadIcon = () => {
    const { SECONDARY: color } = STRATEGIC_PARTNER.PALETTE;
    return (
        <Icon
            name='download'
            style={{
                color,
                borderBottom: `3px solid ${color}`,
                paddingBottom: 25,
                position: 'absolute',
                top: '14px',
                right: '6px',
            }}
        />
    );
}

export default DownloadIcon;
