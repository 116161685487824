import React, {Component} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {Label} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import RemoveUnverifiedBankAccountModal from "./removeUnverifiedBankAccountModal";
import PlaidMicroDeposits from "./PlaidMicroDeposits";
import {getLocalization} from "../../utils/localization";
import BorderedSegment from "../borderedSegment";
import {patchMicroDepositBankAccount} from "../../actions/billingActions";

const mapStateToProps = (state, ownProps) => {
    const strings = getLocalization(state.initialData.user.lang);
    return {
        ...ownProps,
        plaidLinkToken: state.billing.plaidLinkToken,
        plaidEnvironment: state.billing.plaidEnvironment,
        microDepositsBankAccounts: state.microDepositsBankAccounts,
        strings
    }
};

class MicroDepositsBankAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRemovePMModal: false,
            verificationStatus: "",
            verificationStatusLabelStyle: "",
            verificationIconStyle: "",
            borderColor: ""
        }
    }

    setVerificationStatus = (status) => {
        switch (status) {
            case "verification_failed":
                this.setState({verificationStatus: "Unable to verify"})
                this.setState({verificationStatusLabelStyle: "label-verificationStatus label-bkgWarning50"})
                this.setState( {verificationIconStyle: "icon-secondary-deep-purple  icon-small icon-vertical-allign"})
                this.setState({borderColor: "#DF4C32"})
                break
            case "pending_manual_verification":
                this.setState({verificationStatus: "Pending verification"})
                this.setState({verificationStatusLabelStyle: "label-verificationStatus label-bkgSunshade50"})
                this.setState( {verificationIconStyle: "icon-primary-sunshade  icon-small icon-vertical-allign"})
                this.setState({borderColor: "#F0A446"})
                break
            default:
                this.setState({verificationStatus: status})
                break
        }
    }

    componentDidMount() {
            this.setVerificationStatus(this.props.data.status);
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.verificationStatus !== prevState.verificationStatus) {
            this.props.dispatch(patchMicroDepositBankAccount(this.props.data.accountId, this.state.verificationStatus))
            this.setVerificationStatus(this.props.data.status);
        }
    }

    isVerificationPending = () => this.state.verificationStatus === "Pending verification"
    isVerificationFailed = () => this.state.verificationStatus === "Unable to verify"

    handleDeleteBankAccount = (bankAccountData) => {
        const {hideAccount, removeBankAccount} = this.props
        if(this.isVerificationFailed()) {
            hideAccount(bankAccountData.accountId);
            removeBankAccount(bankAccountData.accountId);
        }else {
            this.setState({showRemovePMModal: true})
        }
    }

    renderContent = () => {
        const {
            data,
            plaidEnviroment,
            setPlaidData,
            hideAccount,
            updateStatus,
        } = this.props;

        const {
            mask,
            name,
            accountId,
        } = data

        const {
            verificationStatus,
            verificationStatusLabelStyle,
            verificationIconStyle,
        } = this.state

        return <Grid columns={2}>
            <Grid.Column verticalAlign={'top'} width={10} stretched={false} className={"paymentMethod"}>
                <div style={{float: 'left', marginRight: '10px'}} className="neutral600Text">
                    <Icon name={'university'} style={{fontSize: 20}}/>
                </div>
                <div style={{float: 'left', maxHeight:'30px'}}>
                    <span className="neutral600Text"><b>{name}</b></span>
                    <Label className={verificationStatusLabelStyle} size={"mini"} style={{marginLeft: '4px'}}>
                        <p style={{fontSize: '14px', fontWeight: '400', color: '#000000', marginBottom: '2px'}}>
                            <Icon className={verificationIconStyle} name="circle"/>{verificationStatus}
                        </p>
                    </Label>
                    { !this.isVerificationFailed() &&
                        <PlaidMicroDeposits
                            accountId={accountId}
                            setPlaidData={setPlaidData}
                            plaidEnviroment={plaidEnviroment}
                            bankAccountData={data}
                            hideAccount={hideAccount}
                            setVerificationStatus={this.setVerificationStatus}
                            updateStatus={updateStatus}
                        />
                    }
                </div>
                <div style={{display: 'block', width:'100%', float:'left', marginLeft:'40px'}}>
                    <p><span className="smaller">{`******${mask}`}</span></p>
                </div>
            </Grid.Column>
            <Grid.Column textAlign={"right"} width={2}>
                <Dropdown icon={"ellipsis horizontal"}>
                    <Dropdown.Menu>
                        <Dropdown.Item text='Delete' onClick={() => { this.handleDeleteBankAccount(data) }}/>
                    </Dropdown.Menu>
                </Dropdown>
            </Grid.Column>
        </Grid>
    }

    render() {
        const {
            data,
            removeBankAccount,
            hideAccount,
            strings,
        } = this.props;

        const {
            showRemovePMModal,
            borderColor,
        } = this.state;

        return <>
            {!!showRemovePMModal &&
                <RemoveUnverifiedBankAccountModal
                    show={showRemovePMModal}
                    close={() => {
                        this.setState({showRemovePMModal: false})
                    }}
                    removeBankAccount={removeBankAccount}
                    bankAccountData={data}
                    strings={strings.confirmRemovePendingVerification}
                    hide={hideAccount}
                />
            }
            <BorderedSegment borderColor={borderColor}>
                <div style={{paddingLeft: '8px'}}>
                    {this.renderContent()}
                </div>
            </BorderedSegment>
        </>
    }
}

export default connect(mapStateToProps)(withRouter(MicroDepositsBankAccount));
