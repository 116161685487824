import React, {useState} from "react";
import {
  MFAButton,
  MFAContainer,
  MFAForm,
  MFAInput,
  MFALabel,
  MFAText,
  MFATextError
} from "../styled";
import {setNewToken, validateBackupCode} from "../../actions/authActions";
import {LEFT_ATTEMPTS_FOR_WARN, MAX_CODE_BACKUP_LENGTH, MINIMUM_ATTEMPTS} from "../constants";

export const BackUpCodes = ({
  mfaToken,
  redirectPath,
  history,
  dispatch,
  redirectToLogin,
}) => {

  const [mfaBackupCode, setMfaBackupCode] = useState("");
  const [validationErrors, setValidationErrors] = useState(false);
  const [validateMfaCodeError, setValidateMfaCodeError] = useState("");
  const [isMfaProcessing, setIsMfaProcessing] = useState(false);
  const [remainingBackupAttempts, setRemainingBackupAttempts] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);

  const codeHandler = (e) => {
    setValidationErrors(false);
    setValidateMfaCodeError("");
    setDisableBtn(!e.currentTarget.value.length > 0)
    if (e.currentTarget.value.length > MAX_CODE_BACKUP_LENGTH) return;
    setMfaBackupCode(e.currentTarget.value);
  };


  //Submit code to mfa/login
  const submitCode = async () => {
    setValidateMfaCodeError("");
    setIsMfaProcessing(true);
    if (mfaBackupCode.length < MAX_CODE_BACKUP_LENGTH) {
      setValidationErrors(true);
      setIsMfaProcessing(false);
      return false;
    }

    await validateBackupCode(mfaBackupCode, mfaToken)
      .then(async (response) => {
        dispatch(setNewToken(response.data));
        history.push({ pathname: redirectPath });
      })
      .catch((error) => {
        const { errorMessage, remainingBackupCodeAttempts } =
          error.response.data;
        setIsMfaProcessing(false);
        setValidateMfaCodeError(errorMessage);
        if(remainingBackupCodeAttempts === MINIMUM_ATTEMPTS){
          redirectToLogin(true);
        }
        setRemainingBackupAttempts(remainingBackupCodeAttempts);
      });
  };

  return (
    <>
      <MFAText className={"alignLeft"}>
        Enter one of your backup codes to access your account—
      </MFAText>

      {remainingBackupAttempts && (remainingBackupAttempts <= LEFT_ATTEMPTS_FOR_WARN) && (
          <MFAText className={"invalidAttempt"} style={{ textAlign: "left" }}>
            <span>
              {remainingBackupAttempts}{" "}
              invalid attempt(s) remaining!
            </span>{" "}
            After your <br/>account will be locked for security purposes.{" "}
          </MFAText>
        )}
      <MFAForm.Field className={"contentLeft"}>
        <MFALabel className={"labelVerify"}>
          10-digit backup code
        </MFALabel>

        <MFAContainer className="changeMethodField">
          <MFAInput
            type="text"
            name={"backupCode"}
            id={"backupCode"}
            onChange={(e) => codeHandler(e)}
            value={mfaBackupCode}
            maxLength={MAX_CODE_BACKUP_LENGTH}
            required
            error={validationErrors || validateMfaCodeError}
            disabled={isMfaProcessing}
            className={"verifyInput"}
          />
          {validationErrors && <MFATextError> Enter a 10-digit backup code </MFATextError>}
          {validateMfaCodeError && (
            <MFATextError className={'verifyError'}>
              Invalid code, please try again.
            </MFATextError>
          )}
        </MFAContainer>

        <MFAButton
          style={{
            width: "100%",
            display: "inline-block",
            marginBottom: "16px",
          }}
          loading={isMfaProcessing}
          disabled={isMfaProcessing || disableBtn}
          onClick={submitCode}
        >
          Submit
        </MFAButton>
      </MFAForm.Field>
    </>
  )
}
