import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import Plan from "./plan";
import Alert from "../alert";
import {setLeftNavigation} from "../../actions/navigationActions";
import Beneficiaries from "./beneficiaries";
import Footer from "../footer";
import moment from 'moment';
import {aOEStatus, longDateFormat} from "../../utils/common";
import BillingAlert from "../billing/billingAlert";
import FailedPaymentAlert from "../billing/failedPaymentAlert";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        certificate: state.initialData.certificate,
        userIsCertificateHolder: state.initialData.userIsCertificateHolder,
        futurePendingAsset: state.initialData.futurePendingAsset,
        NIGOClaims: state.claims.NIGOClaims,
        annualOpenEnrollmentStatus: state.anualOpenEnrollment.status,
        openEnrollment: state.initialData.openEnrollment,
        reEnrollRequired: state.initialData.openEnrollment ? state.initialData.openEnrollment.reEnrollRequired : false,
        lastPaymentFailed: state.billing.lastPaymentFailed,
        contract: state.initialData.contract,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLeftNavigation: state => dispatch(setLeftNavigation(state)),
    }
};

class PlanInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeItem: "planInfo"
        }
    }

    componentDidMount() {
        const {setLeftNavigation} = this.props
        setLeftNavigation(true)
    }

    handleItemClick = (e, {name}) => {
        this.setState({
            activeItem: name
        })
    }

    viewClaimDetail = (claimId) => {
        this.props.history.push({pathname: "/claims/details", state: {claimId: claimId}})
    }

    render() {
        const {activeItem} = this.state;
        const {
            certificate,
            futurePendingAsset,
            NIGOClaims,
            annualOpenEnrollmentStatus,
            reEnrollRequired,
            openEnrollment,
            contract,
            lastPaymentFailed
        } = this.props;

        let showEffectiveDateAlert = false;
        let showExpirationDateAlert = false;
        let showVoidedAlert = false;
        let showExpiratedCoverage = false;
        const made2YElections = annualOpenEnrollmentStatus === aOEStatus.completed;
        const declined = annualOpenEnrollmentStatus === aOEStatus.declined;
        if (!!certificate) {
            const mNow = moment(Date.now());
            const mEffectiveDate = moment(certificate.effectiveDate);
            const mExpirationDate = moment(certificate.expirationDate);

            if (certificate.status === "Voided") {
                showVoidedAlert = true
            } else {
                if (mEffectiveDate.isAfter(mNow) && mExpirationDate.isAfter(mEffectiveDate)) {
                    showEffectiveDateAlert = true
                }

                if (mExpirationDate.diff(mNow, 'days') < 30 && mExpirationDate.diff(mNow, 'days') >= 0 && !futurePendingAsset) {
                    if (contract["renewedToContractId"] === null || openEnrollment === null) {
                        showExpirationDateAlert = true;
                    } else {
                        showExpirationDateAlert = !((!reEnrollRequired && !declined) || made2YElections);
                    }
                }

                if (mExpirationDate.diff(mNow, 'days') < 0 && !futurePendingAsset) {
                    showExpiratedCoverage = true;
                }
            }
        }

        const showAlerts = showEffectiveDateAlert || showExpirationDateAlert || showExpiratedCoverage || showVoidedAlert || (!!NIGOClaims && NIGOClaims.length > 0);
        const alerts = [showEffectiveDateAlert, showExpirationDateAlert, showExpiratedCoverage, (!!NIGOClaims && NIGOClaims.length > 0), lastPaymentFailed, showVoidedAlert];
        let count = 0;
        alerts.forEach(alert => {
                if (!!alert) {
                    count = count + 1
                }
            }
        )
        const marginTop = count !== 0 ? count * 38 : -16;
        return (
            <React.Fragment>
                <div className="member-main-container">
                    <div className={"alertContainer"}>
                        {showAlerts && <React.Fragment>
                            {(showEffectiveDateAlert) && <Alert style={{width: "calc(100% - 220px)"}}
                                                                type={"warning"}><span>Your coverage is effective on <b>{moment(certificate.effectiveDate).format(longDateFormat)}</b></span></Alert>}
                            {(showExpirationDateAlert) && <Alert style={{width: "calc(100% - 220px)"}}
                                                                 type={"warning"}><span>Your {STRATEGIC_PARTNER.LABEL} coverage ends on <b>{moment(certificate.expirationDate).format(longDateFormat)}</b></span></Alert>}
                            {(showExpiratedCoverage) && <Alert style={{width: "calc(100% - 220px)"}}
                                                               type={"warning"}><span>Your {STRATEGIC_PARTNER.LABEL} coverage ended on <b>{moment(certificate.expirationDate).format(longDateFormat)}</b></span></Alert>}
                            {(showVoidedAlert) && <Alert style={{width: "calc(100% - 220px)"}}
                                                         type={"warning"}><span>VOIDED: Policy information below is considered void from its effective date.</span></Alert>}
                            {(!!NIGOClaims && NIGOClaims.length > 0) && <React.Fragment>
                                <Alert style={{width: "calc(100% - 220px)"}} type={"warning"}>
                                    {NIGOClaims.length > 1 ?
                                        <span>We need some additional information from you to process some of your claims. <span onClick={() => {
                                            this.props.history.push("/claims")
                                        }} className={"link"}><b>View claims→</b></span></span> :
                                        <span>We need some additional information from you to process your claim. <span onClick={() => {
                                            this.viewClaimDetail(NIGOClaims[0].id)
                                        }} className={"link"}><b>View claim→</b></span></span>}
                                </Alert>
                            </React.Fragment>}
                        </React.Fragment>}
                        {lastPaymentFailed && <FailedPaymentAlert switchToSettings={() => {
                            this.props.history.push({pathname: "/billing", state: {activeTab: 'billingSettings'}})
                        }}/>}
                        <BillingAlert switchToOverview={() => {
                            this.props.history.push('/billing')
                        }}/>
                    </div>
                    <Divider hidden/>
                    <Grid container stackable columns={1} centered style={{marginTop: `${marginTop}px`}}>
                        <Grid.Column className={"pageContent"}>
                            <Divider hidden/>
                            <Menu text size={"small"} className={"memberSectionMenu"}>
                                <Menu.Item
                                    name='planInfo'
                                    active={activeItem === 'planInfo'}
                                    onClick={this.handleItemClick}
                                ><b>Plan info</b></Menu.Item>
                                {activeItem === "planInfo" && <Menu.Menu position={"right"}>
                                    <Menu.Item><span>If anything is incorrect or needs to be updated
                                        call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.</span></Menu.Item>
                                </Menu.Menu>}
                            </Menu>

                            {activeItem === 'planInfo' && <Plan/>}
                            {activeItem === "beneficiaries" && <Beneficiaries/>}
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={false} showCenterData={true}/>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlanInfo));
