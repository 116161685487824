import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CustomToast} from "../custom-common";

const mapStateToProps = (state, ownProps) => {
  return {
      ...ownProps,
      toasts: state.toast.list,
    }
};

class Toasts extends Component {
  render() {
      const {toasts} = this.props

      if (!toasts.length) return null

      const toastProps = toasts[0]
      const { id } = toastProps

      return <CustomToast key={id} {...toastProps} />
  }
}

export default connect(mapStateToProps)(Toasts)
