import {Icon} from "semantic-ui-react";
import {Header} from "../base";
import React from "react";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";

const flexInlineStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 1em"
};


const BankAccountInfo = ({removeBankAccount}) => {
    const {
        bankAccountPmData,
        removePm,
        hasEPaymentFailed,
    } = PaymentDetailsContext.useContainer()

    const showIsVerified = () => {
        if(!bankAccountPmData || !bankAccountPmData.metadata){
            return false
        }
        const {metadata} = bankAccountPmData
        return metadata.verificationStatus &&  metadata.verificationStatus === "manually_verified"
    }

    const getBankAccountName = () => bankAccountPmData.bank_name || bankAccountPmData.brand

    return (
        <>
            <Header as="h5" style={{display: "flex"}}>
                <strong>Bank account</strong>
                {hasEPaymentFailed &&
                    <Icon color="red" name="attention" style={{fontSize: "15px", marginLeft: "5px"}}/>
                }
            </Header>
            <div style={flexInlineStyles}>
                {`${getBankAccountName()}...${bankAccountPmData.last4}`}
                {!hasEPaymentFailed && !showIsVerified() &&
                    <a href onClick={() => {
                        removeBankAccount(bankAccountPmData.paymentMethodId);
                        removePm()
                    }}><Icon name="close" color="green"/></a>
                }
                { showIsVerified() &&
                    <p className={"vibranBlueText"} style={{fontSize:"14px!important"}}>
                        <Icon  name="checkmark" /> <span style={{fontWeight:"bold"}}>Verified</span>
                    </p>
                }
            </div>

        </>
    )
}

export default BankAccountInfo;
