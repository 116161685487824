import React, {useState} from "react";
import PropTypes from 'prop-types';
import {DateSelector} from "./dateSelector";
import {VisibilityIcon} from "./visibilityIcon";

const MAX_DOB_LENGTH = 10;

export const DOBInput = ({value, setFormError, onChange, isReadonly, visibilityIconVisible = true, ...props}) => {

    const [visible, setVisible] = useState(false);
    const [displayValue, setDisplayValue] = useState(value);

    const toggleVisibility = () => {
        setVisible(prevVisible => {
            return !prevVisible
        });
    };

    const mask = (v) => {
        return v.replace(/[0-9]/g, "•");
    };

    const maskYear = (v) => {
        const date = v.split("/");
        if(date.length > 2 && date[2] !== ""){
            return `${date[0]}/${date[1]}/${mask(date[2])}`
        }
        return v
    }

    const handleChange = (e, {value}) => {
        setDisplayValue(value)
        if(value.length === MAX_DOB_LENGTH) {
            setVisible(false)
        } else {
            setVisible(true)
        }
        onChange(e, {value})
    }

    return (
        <DateSelector
            value={visible ? displayValue : maskYear(displayValue)}
            className={isReadonly ? "disabled-input" : ""}
            setFormError={setFormError}
            onChange={handleChange}
            clearable
            onClear={toggleVisibility}
            clearIcon={visibilityIconVisible ? <VisibilityIcon ariaLabel={"date of birth"} isVisible={visible} onClick={toggleVisibility}/> : ''}
            isMasked={displayValue && !visible}
            isReadonly={isReadonly}
            {...props}
        />
    );
};

DOBInput.propTypes = {
    isReadonly: PropTypes.bool,
    onChange: (props, propName, componentName) => {
        if (!props.isReadonly && (props[propName] === undefined || typeof props[propName] !== 'function')) {
            return new Error(`Invalid prop ${propName} supplied to ${componentName}. When isReadonly is false, ${propName} is required and must be a function.`);
        }
    },
    setFormError: PropTypes.func,
    value: PropTypes.string,
    visibilityIconVisible: PropTypes.bool,
};
