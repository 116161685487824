import React from 'react';
import {Container, Grid, Image} from 'semantic-ui-react';
import {CustomContainer} from './custom-common';
import CoverageDetails from "./coverageDetails";
import PaperCheckInstructions from "./paperCheckInstructions";
import Footer from "./footer";
import {connect} from "react-redux";
import moment from "moment";
import {getLocalization} from "../utils/localization";
import {PaymentDetailsContext} from "./paymentDetails/PaymentDetailsContainer";
import PaymentDetailsCard from "./paymentDetails/paymentDetailsCard";
import PaperCheckModal from "./paymentDetails/paperCheckModal";
import {usePreventBackNavigation} from "../hooks";


const mapStateToProps = ({initialData, billing}, ownProps) => {
    const {user, certificate, employerContribution, token, stopScreenInfo, isOpenEnrollment} = initialData;
    const strings = getLocalization(initialData.user.lang);
    const effectiveDate = moment(certificate.effectiveDate, "YYYY-MM-DD").format("MMM DD, YYYY");
    const payrollFrequency = initialData.payrollFrequency === "Monthly"
        ? strings.paymentGeneral.mo : strings.paymentGeneral.paycheck;
    const {
        enrollmentPaymentMethod,
        bankAccountPaymentStatus,
        enrollmentCost,
        creditCardPaymentStatus,
    } = stopScreenInfo;
    const assetId = certificate.id;
    const firstName = user.firstName;
    return {
        ...ownProps,
        effectiveDate,
        stopScreenInfo,
        employerContribution,
        payrollFrequency,
        strings,
        billing,
        token,
        user,
        enrollmentPaymentMethod,
        bankAccountPaymentStatus,
        enrollmentCost,
        assetId,
        firstName,
        creditCardPaymentStatus,
        isOpenEnrollment,
    }
};

const PermisonBox = ({strings, effectiveDate}) => (
    <CustomContainer className={'deepPurpleCard'} style={{marginBottom: '40px'}}>
        <Grid>
            <div style={{paddingTop: '21px', paddingBottom: '18px', paddingLeft: 0, display: 'flex', alignItems: 'center'}}>
                <Image style={{width: 128, padding: '0 25px', minWidth: 100, maxWidth: 100}}
                       centered src={process.env.PUBLIC_URL + '/images/icon_alert.png'}/>
                <p><b>{strings.paymentRequired.paymentIsStillNeeded}</b> {strings.paymentRequired.weHaventReceived}</p>
            </div>
            <div style={{background: '#FFF7EC', width: '100%', height: '100%', borderRadius: '8px', paddingLeft: '100px', paddingTop: '18px', paddingBottom: '21px'}}>
                <p className={'neutral600Text'} style={{fontWeight: 'bold', marginBottom: '0'}}>
                    {strings.paymentRequired.effectiveDate}
                </p>
                <h4 className={'neutral900Text'} style={{margin: '0'}}>
                    <b>
                        {effectiveDate}
                        <span className={'deepPurpleHeader'}>*</span>
                    </b>
                </h4>
                <p style={{fontSize: '14px', fontWeight: 'lighter'}}>
                    <i>
                        <span className={'deepPurpleHeader'}>*</span> {strings.paymentRequired.effectiveDateSubject}
                    </i>
                </p>
            </div>
        </Grid>
    </CustomContainer>
);

const PaperCheckRequired = ({payrollFrequency, strings, billing,
                                paymentDetailsLoading, token, user, cost, employerContribution}) => (
    <Grid style={{marginBottom: '40px'}}>
        <PaymentDetailsCard
            plaidEnviroment={billing.plaidEnvironment}
            plaidLinkToken={billing.plaidLinkToken}
            stripePublicKey={billing.stripePublicKey}
            billingAccountId={billing.billingAccountId}
            user={{...user, email: user?.email}}
            formattedCostPayment={payrollFrequency}
            loading={paymentDetailsLoading}
            token={token}
            cost={cost}
            employerContribution={employerContribution}
            strings={strings}
        />
        <p className={'neutral900Text'}><b>{strings.paymentRequired.iStillWantToMail}</b></p>
        <PaperCheckInstructions strings={strings} />
    </Grid>
);

const EPaymentFailed = ({payrollFrequency, strings, billing, paymentDetailsLoading,
                            token, user, cost, employerContribution}) => {
    const [displayPaperCheckInstrucions, setDisplayPaperCheckInstrucions] = React.useState(false);

    return <Grid>
        <PaymentDetailsCard
            plaidEnviroment={billing.plaidEnvironment}
            plaidLinkToken={billing.plaidLinkToken}
            stripePublicKey={billing.stripePublicKey}
            billingAccountId={billing.billingAccountId}
            user={{...user, email: user?.email}}
            formattedCostPayment={payrollFrequency}
            loading={paymentDetailsLoading}
            token={token}
            cost={cost}
            employerContribution={employerContribution}
            strings={strings}
        />
        <p className={'neutral700Text'} style={{marginTop: "-1.5em", marginBottom: "2.5em"}}>
            {strings.paymentRequired.iDontWantToMake}
            <a href onClick={() => setDisplayPaperCheckInstrucions(true)}>&nbsp;{strings.paymentRequired.seeInstructions}</a>
        </p>
        <PaperCheckModal open={displayPaperCheckInstrucions}
                         onClose={() => setDisplayPaperCheckInstrucions(false)}
                         strings={strings}
        />
    </Grid>
}

const PaymentRequired = ({effectiveDate, employerContribution, payrollFrequency, stopScreenInfo,
                             strings, user, enrollmentPaymentMethod, billing, token, enrollmentCost,
                             assetId, firstName, creditCardPaymentStatus, isOpenEnrollment}) => {

    usePreventBackNavigation();

    const enrollmentPaymentMethods = ['Check', 'Bank Account with Micro Deposits']
    const [isMethodPaperCheck] = React.useState(enrollmentPaymentMethods.includes(enrollmentPaymentMethod));
    const [paymentDetailsLoading, setPaymentDetailsLoading] = React.useState(false);
    const {microDepositsBankAccount} = stopScreenInfo

    return (
        <PaymentDetailsContext.Provider initialState={{
            paymentMethods: billing.paymentMethods,
            initialAutoPay: !!billing.autoPay,
            billingAccountId: billing.billingAccountId,
            setPaymentDetailsLoading,
            assetId,
            enrollmentPaymentMethod,
            effectiveDate,
            firstName,
            creditCardPaymentStatus,
            microDepositsBankAccount: microDepositsBankAccount,
        }}>
            <Container className={'stopScreens'}>
                <h1 className={'vibranBlueHeader'} style={{fontSize: '32px', marginBottom: '10px'}}>
                    {isMethodPaperCheck ?
                        strings.paymentRequired.weStillHavent : strings.paymentRequired.weCouldntProcess}
                </h1>
                <p className={'neutral700Text'}>{strings.paymentRequired.pleaseReview}</p>
                <PermisonBox strings={strings} effectiveDate={effectiveDate} />
                { isMethodPaperCheck && (
                    <PaperCheckRequired cost={enrollmentCost}
                                        employerContribution={employerContribution}
                                        payrollFrequency={payrollFrequency}
                                        strings={strings}
                                        billing={billing}
                                        user={user}
                                        token={token}
                                        paymentDetailsLoading={paymentDetailsLoading}
                    />)
                }
                { !isMethodPaperCheck &&
                    <EPaymentFailed
                        cost={enrollmentCost}
                        employerContribution={employerContribution}
                        payrollFrequency={payrollFrequency}
                        strings={strings}
                        billing={billing}
                        user={user}
                        token={token}
                        paymentDetailsLoading={paymentDetailsLoading}
                    />
                }
                <CoverageDetails showReachOut={isOpenEnrollment} />
            </Container>
            <Footer showCenterData={true} />
        </PaymentDetailsContext.Provider>
    );
}

export default connect(mapStateToProps)(PaymentRequired);
