import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Divider, Grid,} from "semantic-ui-react";
import {Button, Header} from "../../base";
import "./newClaims.css"
import {connect} from "react-redux";
import Footer from "../../footer";
import NewClaimSteps from "./newClaimSteps";
import PayoutMethods from "../../payoutMethods";
import ClaimStepsLayout from "../claimStepsLayout";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        claimant: state.newClaim.claimant,
        selectedPayoutMethod: state.newClaim.selectedPayoutMethod
    }
};

class PaymentPreference extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }
        this.state = {
            pmLoading: true
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/claims/new/confirmUploads");
    }

    onNext = () => {
        this.props.history.push("/claims/new/review")
    }

    updatePMLoadingState = (value) => {
        this.setState({
            pmLoading: value
        })
    }

    render() {
        const {pmLoading} = this.state;
        const button = <Button primary content="Next step" onClick={this.onNext} disabled={pmLoading}/>;

        return (
            <React.Fragment>
                <NewClaimMenu onBack={this.onBackHandler}/>
                <ClaimStepsLayout>
                    <NewClaimSteps activeStepIdx={2}/>
                    <div style={{marginTop: "56px"}}>
                        <Grid container stackable centered>
                            <Grid.Row>
                                <Grid.Column width={8} className={"pageContent"}>
                                    <Header as={"h2"}>Payment preference</Header>
                                        <p className="neutral700Text small">Add or select an electronic payment method
                                            for the
                                            fastest claim payment. If you do not select an electronic payment method,
                                            you will be
                                            sent a paper check.</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Divider hidden/>
                            <Grid.Row style={{marginLeft: '1rem'}}>
                                <Grid.Column width={8}>
                                    <PayoutMethods showRadio={true} updatePMLoadingState={this.updatePMLoadingState} pmLoading={pmLoading}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div style={{marginTop: "100px"}}>
                        <Footer showTopFooter={true}
                                showCenterData={true}
                                button={button}
                        />
                    </div>
                </ClaimStepsLayout>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PaymentPreference));
