import {newClaimActionTypes} from "../actions/actionTypes";

const initialState = {
    claimant: null,
    whatHappened: {
        symptoms: null,
        providerDiagnosis: null,
        treatment: null,
        additionalInfo: null,
    },
    treatmentInfo: {
        facilities: [],
        doctorsOffice: [],
        urgentCare: [],
        place: null,
        facilitiesKeys: []
    },
    files: [],
    selectedPayoutMethod: null
};

export const newClaimReducer = (state = initialState, action) => {
    switch (action.type) {
        case newClaimActionTypes.SET_CLAIMANT:
            return {
                ...state,
                claimant: action.payload
            };
        case newClaimActionTypes.SET_WHAT_HAPPEND:
            return {
                ...state,
                whatHappened: action.payload
            };
        case newClaimActionTypes.SET_TREATEMENT_INFO:
            return {
                ...state,
                treatmentInfo: action.payload
            };
        case newClaimActionTypes.SET_SELECTED_PAYOUT_METHOD:
            return {
                ...state,
                selectedPayoutMethod: action.payload
            };
        case newClaimActionTypes.APPEND_FILES:
            return {
                ...state,
                files: [...state.files, ...action.payload]
            };
        case newClaimActionTypes.REMOVE_FILE:
            return {
                ...state,
                files: state.files.filter((item, index) => action.payload !== index)
            };
        case newClaimActionTypes.CLEANUP_NEW_CLAIM:
            return {
                claimant: null,
                whatHappened: {
                    symptoms: null,
                    providerDiagnosis: null,
                    treatment: null,
                    additionalInfo: null,
                },
                treatmentInfo: {
                    facilities: [],
                    doctorsOffice: [],
                    urgentCare: [],
                    place: null,
                    facilitiesKeys: []
                },
                files: [],
                selectedPayoutMethod: null
            }
        default:
            return state
    }
};