import React from 'react'
import Steps from "../steps/steps";
import {parseMultilineString} from "../../utils/localization";
import {connect} from "react-redux";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        keepCoverage: state.anualOpenEnrollment.keepCoverage
    }
};

class AOESteps extends React.Component{
    render() {
        const stepProps = [
            {
                active: false,
                completed: false,
                disabled: true,
                label: parseMultilineString("Review personal\n information") ,
            },
            {
                active: false,
                completed: false,
                disabled: true,
                label: "Select",
            },
            {
                active: false,
                completed: false,
                disabled: true,
                label: "Review",
            }
        ];

        const {activeStepIdx} = this.props;
        for (let i = 0; i < activeStepIdx; i++) {
            stepProps[i].disabled = false;
            stepProps[i].completed = true;
        }
        stepProps[activeStepIdx].active = true;
        stepProps[activeStepIdx].disabled = false;

        return (
            <Steps stepProps={stepProps}/>
        )
    }
}

export default connect(mapStateToProps)(AOESteps);
