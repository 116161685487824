import React from 'react'
import { ThemeProvider } from 'styled-components'
import deepMerge from 'lodash/merge'

export const SubThemeProvider = ({ theme, children }) => {
  let newTheme

  if (typeof theme !== 'function') {
    console.error('You must provide a function as theme to SubThemeProvider.')
    try {
      newTheme = siteTheme => ({ ...deepMerge(siteTheme,  theme) })
    } catch (e) {
      newTheme = siteTheme => siteTheme
    }
  } else {
    newTheme = siteTheme => ({ ...deepMerge(siteTheme,  theme(siteTheme)) })
  }

  return (
    <ThemeProvider theme={newTheme}>{children}</ThemeProvider>
  )
}
SubThemeProvider.displayName = 'DesignSystemSubTheme'
