import {planChangeActionTypes} from "../actions/actionTypes";

const initialState = {
    genderOptions: [],
    nameSuffixOptions: [],
    states: [],
    relationshipOptions: [],
    countries: [],
    highDeductibleOptions: [],
    coPayOptions: [],
    disposableIncomeOptions: [],
    dependentsOptions: [],
    costPerOptions: [],
    qleReason: null,
    qleDate: null,
    planChangeDependents: [],
    haveStepChildren: null,
    wantUpdatedRecommendations: null,
    typicallyPayInfo: null,
    highDeductibleInfo: null,
    coverageOption: null,
    costPerOption: null,
    moderateCoverageSelected: null,
    severeCoverageSelected: null,
    catastrophicCoverageSelected: null,
    moderateCoverageRecommended: null,
    severeCoverageRecommended: null,
    catastrophicCoverageRecommended: null,
    costValue: null,
    costEnhanced: null,
    costPremier: null,
    fetchPlanRecommendation: true,
    contribValue: null,
    contribEnhanced: null,
    contribPremier: null,
    leftoverMoney: null,
    selectedTierBrellaSelect: {
        value: false,
        premier: false,
        enhanced: false,
    },
    totalDependents: [],
    maritalStatus: null,
    dependentsId: null,
    familyTierOptions: [],
    employerContribution: null,
    cost: null,
    newAddress: {
        zipCode: null,
        street: null,
        state: null,
        stateObject: null,
        country: null,
        city: null
    },
    hasAddedQLEDependent: false
};

export const planChangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case planChangeActionTypes.SET_QLE:
            return {
                ...state,
                qleReason: action.payload
            };
        case planChangeActionTypes.SET_QLE_DATE:
            return {
                ...state,
                qleDate: action.payload
            };
        case planChangeActionTypes.SET_DEPENDENT:
            const {dependent, idx} = action.payload;
            let {planChangeDependents, ...restOfState} = state;
            if(!planChangeDependents){
                planChangeDependents = [];
            }
            planChangeDependents[idx] = dependent;
            return {
                ...restOfState,
                planChangeDependents: [...planChangeDependents]
            };
        case planChangeActionTypes.REMOVE_DEPENDENT:{
            const { idx } = action.payload;
            let {planChangeDependents, ...rest} = state;
            planChangeDependents.splice(idx, 1);
            return {
                ...rest,
                planChangeDependents: [...planChangeDependents]
            };
        }
        case planChangeActionTypes.SET_DEPENDENT_LIST:
            return {
                ...state,
                planChangeDependents: action.payload
            }
        case planChangeActionTypes.SET_HAVE_STEP_CHILDREN:
            return {
                ...state,
                haveStepChildren: action.payload
            };
        case planChangeActionTypes.WANT_UPDATED_RECOMMENDATIONS:
            return {
                ...state,
                wantUpdatedRecommendations: action.payload
            };
        case planChangeActionTypes.SET_HEALTH_PLAN_DETAILS:
            return {
                ...state,
                typicallyPayInfo: action.payload.typicallyPayInfo,
                highDeductibleInfo: action.payload.highDeductibleInfo,
            };
        case planChangeActionTypes.SET_PLAN_CHANGE_INITIAL_DATA:
            return {
                ...state,
                genderOptions: action.payload.genderOptions,
                nameSuffixOptions: action.payload.nameSuffixOptions,
                states: action.payload.states,
                relationshipOptions: action.payload.relationshipOptions,
                countries: action.payload.countries,
                highDeductibleOptions: action.payload.highDeductibleOptions,
                coPayOptions: action.payload.coPayOptions,
                disposableIncomeOptions: action.payload.disposableIncomeOptions,
                dependentsOptions: action.payload.dependentsOptions,
                costPerOptions: action.payload.costPerOptions,
                familyTierOptions: action.payload.familyTierOptions
            }
        case planChangeActionTypes.CLEAR_PLAN_CHANGE:
            return {
                qleReason: null,
                qleDate: null,
                planChangeDependents: [],
                haveStepChildren: null,
                wantUpdatedRecommendations: null,
                typicallyPayInfo: null,
                highDeductibleInfo: null,
                genderOptions: [],
                nameSuffixOptions: [],
                states: [],
                relationshipOptions: [],
                countries: [],
                highDeductibleOptions: [],
                coPayOptions: [],
                disposableIncomeOptions: [],
                dependentsOptions: [],
                costPerOptions: [],
                coverageOption: null,
                costPerOption: null,
                moderateCoverageSelected: null,
                severeCoverageSelected: null,
                catastrophicCoverageSelected: null,
                moderateCoverageRecommended: null,
                severeCoverageRecommended: null,
                catastrophicCoverageRecommended: null,
                costValue: null,
                costEnhanced: null,
                costPremier: null,
                fetchPlanRecommendation: true,
                contribValue: null,
                contribEnhanced: null,
                contribPremier: null,
                leftoverMoney: null,
                totalDependents: [],
                maritalStatus: null,
                dependentsId: null,
                familyTierOptions: [],
                employerContribution: null,
                cost: null,
                newAddress: {
                    zipCode: null,
                    street: null,
                    state: null,
                    stateObject: null,
                    country: null,
                    city: null
                },
                changePrimaryAddress: null,
                hasAddedQLEDependent: false
            }

        case planChangeActionTypes.SET_COVERAGE_OPTION:
            const {selectedCoverageOption, deleteChildren, deleteSpouse} = action.payload;
            let deps = state.totalDependents;
            if(!!deps && deps.length > 0){
                if(deleteChildren){
                    deps = deps.filter((d)=>d.relationship !== "Child");
                }
                if(deleteSpouse){
                    deps = deps.filter((d)=>d.relationship !== "Spouse" && d.relationship !== "Domestic Partner");
                }
            }
            return {
                ...state,
                totalDependents: [...deps],
                coverageOption: selectedCoverageOption,
            };
        case planChangeActionTypes.SET_COST_PER_OPTION:
            return {
                ...state,
                costPerOption: action.payload
            };

        case planChangeActionTypes.SET_MODERATE_COVERAGE:
            return {
                ...state,
                moderateCoverageSelected: action.payload
            };
        case planChangeActionTypes.SET_SEVERE_COVERAGE:
            return {
                ...state,
                severeCoverageSelected: action.payload
            };
        case planChangeActionTypes.SET_CATASTROPHIC_COVERAGE:
            return {
                ...state,
                catastrophicCoverageSelected: action.payload
            };
        case planChangeActionTypes.SET_SELECTED_AND_RECOMMENDED_COVERAGE:
            return {
                ...state,
                moderateCoverageRecommended: action.payload.moderate,
                severeCoverageRecommended: action.payload.severe,
                catastrophicCoverageRecommended: action.payload.catastrophic,
                moderateCoverageSelected: action.payload.moderateSelected,
                severeCoverageSelected: action.payload.severeSelected,
                catastrophicCoverageSelected: action.payload.catastrophicSelected,
            };
        case planChangeActionTypes.SET_SELECT_COST:
            return {
                ...state,
                costValue: action.payload.costValue,
                costEnhanced: action.payload.costEnhanced,
                costPremier: action.payload.costPremier,
                contribValue: action.payload.contribValue,
                contribEnhanced: action.payload.contribEnhanced,
                contribPremier: action.payload.contribPremier,
            };
        case planChangeActionTypes.SET_FETCH_PLAN_RECOMMENDATION: {
            const {fetchPlanRecommendation} = action.payload;
            return {
                ...state,
                fetchPlanRecommendation,
            }
        }
        case planChangeActionTypes.SET_LEFTOVER_MONEY:
            return {
                ...state,
                leftoverMoney: action.payload
            };
        case planChangeActionTypes.SET_TOTAL_DEPENDENTS:
            return {
                ...state,
                totalDependents: action.payload
            };
        case planChangeActionTypes.SET_BRELLA_SELECT_TIER_SELECTED: {
            const {value, premier, enhanced} = action.payload;
            return {
                ...state,
                selectedTierBrellaSelect: {value, premier, enhanced},
            }
        }
        case planChangeActionTypes.SET_MARITAL_STATUS:
            return {
                ...state,
                maritalStatus: action.payload
            };
        case planChangeActionTypes.SET_DEPENDENTS_ID:
            return {
                ...state,
                dependentsId: action.payload
            };
        case planChangeActionTypes.SET_PLAN_CONTRIB_COST:
            return {
                ...state,
                employerContribution: action.payload.employerContribution,
                cost: action.payload.cost,
            };
        case planChangeActionTypes.SET_NEW_ADDRESS:
            return {
                ...state,
                newAddress: {
                    zipCode: action.payload.zipCode,
                    street: action.payload.street,
                    state: action.payload.state,
                    stateObject: action.payload.stateObject,
                    country: action.payload.country,
                    city: action.payload.city
                }
            };
        case planChangeActionTypes.SET_PRIMARY_ADDRESS_CHANGE:
            return {
                ...state,
                changePrimaryAddress: action.payload
            };
        case planChangeActionTypes.HAS_ADDED_QLE_DEPENDENT:
            return {
                ...state,
                hasAddedQLEDependent: action.payload
            };
        default:
            return state
    }
};