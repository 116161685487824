import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import FullScreenPageMenu from '../fullScreenPageMenu';
import {
    AOE_SUCCESS_ROUTE, savePoints,
} from "../../utils/common";
import {setLastVisitedSavePoint} from "../../actions/anualOpenEnrollmentActions";

const mapStateToProps = (state, ownProps) => {
    return {
            prevRoute: state.navigation.prevRoute,
            lang: state.user.lang,
            lastVisitedSavePoint: state.anualOpenEnrollment.lastVisitedSavePoint,
            ...ownProps
    }
};

class AnualOpenEnrollmentMenu extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {prevRoute, lastVisitedSavePoint} = this.props;

        let route = window.location.pathname;
        const hideBackBtn = !route || route === "/" || route === "" || route === AOE_SUCCESS_ROUTE;
        if (!hideBackBtn && !prevRoute && route !== "/member/") {
            //this.props.history.replace("/"); // CHECK!!! THIS BREAKS THE APP
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const {step} = lastVisitedSavePoint;
            if (route.startsWith("/member/")) {
                route = route.split("/member")[1];
            }
            if (!!savePoints[route] && savePoints[route].step > step) {
                const savePoint = savePoints[route];
                this.props.dispatch(setLastVisitedSavePoint({route, step: savePoint.step}))
            }
        }
    }

    render() {
        const {onBack, user} = this.props;
        let route = window.location.pathname;
        return (
            <FullScreenPageMenu
                onBack={route === AOE_SUCCESS_ROUTE ? null : onBack}
                center={"Annual open enrollment"}
                user={user}
            />
        )
    }
}

export default connect(mapStateToProps)(withRouter(AnualOpenEnrollmentMenu));