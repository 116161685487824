import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';

export const VisibilityIcon = ({ariaLabel, isVisible, onClick}) => (
    <Icon
        className="visibility-toggle-icon"
        data-qa="toggle-visibility-icon"
        name={isVisible ? 'eye slash' : 'eye'}
        link
        onClick={onClick}
        aria-label={`${isVisible ? 'Hide' : 'Show'} ${ariaLabel}`}
    />
);

VisibilityIcon.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
