import React, {Component} from 'react'
import {connect} from 'react-redux'
import axios from 'axios'
import {Divider, Form, Icon, Loader, Message as SemanticMessage, TextArea} from 'semantic-ui-react';
import {CustomContainer} from '../../custom-common';
import {Button, Header} from "../../base";
import {readFileAsync, resolveErrorMessage} from '../../../utils/common';
import {clearMessage, setFiles, setMessageText} from '../../../actions/newMessageActions'
import {show as showToast} from '../../../actions/toastActions'
import {MessagesContext} from '../../../contexts/messages'
import Message from './message'
import {UploadFile} from './uploadFile'

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        newMessages: state.newMessages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setText: (text, caseId) => dispatch(setMessageText(text, caseId)),
        setFiles: (files, caseId) => dispatch(setFiles(files, caseId)),
        clear: caseId => dispatch(clearMessage(caseId)),
        showToast: (toastDetails) => dispatch(showToast(toastDetails))
    }
}

export class ThreadCard extends Component {
    static contextType = MessagesContext

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            showAttachmentArea: false,
            showThread: false,
            error: null
        }
    }

    get firstMessage() {
        const { thread: { messages } } = this.props
        if (!messages || !messages.length) return null

        return messages && messages.length ? messages[0] : null
    }

    get betweenMessages() {
        const { thread: { messages } } = this.props
        if (!messages || (messages.length < 3)) return null

        return messages.slice(1, -1)
    }

    get lastMessage() {
        const { thread: { messages } } = this.props
        if (!messages || (messages.length < 2) ) return null

        return messages.slice(-1).pop()
    }

    get hasReplies() {
        const { thread: { messages } } = this.props

        return messages.length > 1
    }

    get newMessage() {
        const { thread, newMessages } = this.props
        const caseId = thread?.caseId
        const newMessage = (caseId && newMessages[caseId]) || {text: ''}

        return newMessage
    }

    get validData() {
        const { text } = this.newMessage

        return !!text?.length
    }

    toggleThread = (state = null) => {
        const { showThread } = this.state
        const newState = state !== null ? !!state : !showThread
        this.setState({ showThread: newState })
    }

    toggleAttachmentArea = (state = null) => {
        const {showAttachmentArea} = this.state
        const newState = state !== null ? !!state : !showAttachmentArea
        this.setState({showAttachmentArea: newState})
    }

    handleFilesUpdate = (files, caseId) => {
        const { setFiles } = this.props

        setFiles(files, caseId)
    }

    processAttachments = async (files) => {
        if (!files?.length) return

        return Promise.all(files.map(async file => {
            const content = await readFileAsync(file)
            const contentData = content.split(',')[1]

            return {
                fileName: file.name,
                contentType: file.type,
                contentData
            }
        }))
    }

    submitMessage = async () => {
        const {refresh} = this.context
        const {clear, showToast} = this.props
        const {caseId, text: message, files} = this.newMessage

        this.setState({
            loading: true,
            error: null
        })

        try {
            const attachments = await this.processAttachments(files)
            const payload = {
                caseId,
                message,
                attachments
            }
            await axios.post('/api/member/v2/submitMessage', payload);
            await refresh()
            clear(caseId)
            showToast({type:'success', message:'Your message has been sent!'})
            this.setState({loading: false})
        } catch (e) {
            this.setState({
                loading: false,
                error: resolveErrorMessage(e, 'An unexpected error occurred.')
            })
        }
    }

    renderLoader = () => {
        return <div style={{ height: 'calc(100vh - 150px)' }}><Loader active /></div>
    }

    render() {
        const {thread, setText} = this.props
        const {loading, showThread, showAttachmentArea, error} = this.state
        const {caseId, subject} = thread

        return (
            <CustomContainer padded >
                <Header as="h4">{subject}</Header>
                <Message data={this.firstMessage} startCollapsed={this.hasReplies}/>
                {!!this.betweenMessages && (<>
                    <Divider horizontal>
                        <Button primary basic compact size="mini" onClick={() => this.toggleThread()}>
                            {`Show ${this.betweenMessages.length} ${showThread ? 'less' : 'more'}`}
                        </Button>
                    </Divider>
                    {showThread && this.betweenMessages.map((msg, i) => (
                        <React.Fragment key={i}>
                            <Message data={msg} />
                            <Divider />
                        </React.Fragment>
                    ))}
                </>)}
                {this.lastMessage && (<>
                    {!this.betweenMessages && <Divider />}
                    <Message
                        data={this.lastMessage}
                        startCollapsed={false}
                    />
                </>)}
                {caseId && (
                    <div style={{marginLeft: '56px', marginTop: '24px'}}>
                        <Form onSubmit={this.submitMessage} autoComplete="off">
                            <TextArea
                                placeholder='Type your message here...'
                                value={this.newMessage.text}
                                onChange={e => setText(e.target.value, caseId)}
                                disabled={loading}
                            />
                            <UploadFile
                                disabled={loading}
                                files={this.newMessage.files}
                                validFileTypes={[
                                    'image/jpeg',
                                    'image/jpg',
                                    'image/png',
                                    'application/pdf'
                                ]}
                                hideAttachmentArea={!showAttachmentArea}
                                onFilesUpdate={files => this.handleFilesUpdate(files, caseId)}
                            />
                            {!!error && (
                                <SemanticMessage
                                    negative style={{
                                    marginTop: '0.8em',
                                    marginBottom: '0.8em'
                                }}
                                >{error}</SemanticMessage>
                            )}
                            <div style={{ marginTop: '1em' }}>
                                <Button
                                    basic compact
                                    primary={!loading && this.validData}
                                    color={(loading || !this.validData) ? 'grey' : null}
                                    type="submit"
                                    size="medium"
                                    disabled={loading || !this.validData}
                                    loading={loading}
                                >
                                    Send reply&nbsp;&nbsp;<Icon fitted name="pencil"/>
                                </Button>
                                <Icon
                                    link
                                    name="paperclip"
                                    disabled={loading}
                                    style={{ marginLeft: '1em' }}
                                    color={ showAttachmentArea ? 'black' : 'grey'}
                                    onClick={() => this.toggleAttachmentArea()}
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </CustomContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreadCard)
