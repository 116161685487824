import React, {useEffect, useState} from "react";
import {
  MFAButton,
  MFACheckbox,
  MFAContainer,
  MFAForm,
  MFAGrid,
  MFAInput,
  MFALabel,
  MFALink, MFAMethodSendCode,
  MFAMethodValidatorContainer, MFAMethodValidatorMsg,
  MFASegment,
  MFAText,
  MFATextError
} from "../styled";
import {BackupCodeButton} from "./backupCodeButton";
import Toast from "../../components/billing/toast";
import {sendCode, setNewToken, validateMfa} from "../../actions/authActions";
import {LEFT_ATTEMPTS_FOR_WARN, MAX_CODE_LENGTH, MFA_TYPE, MINIMUM_ATTEMPTS, REMAINING_CODE_RESEND_SECONDS} from "../constants";
import {segToHours} from "../../utils/date";

export const EmailToVerified = ({
  handleUseMethod,
  userName,
  mfaToken,
  redirectPath,
  history,
  dispatch,
  redirectToLogin,
  mfaEmailSetUpped,
  rememberDeviceDays
  }) => {
  const [timeoutToSendEmailAgain, setTimeoutToSendEmailAgain] = useState(REMAINING_CODE_RESEND_SECONDS)
  const [code, setCode] = useState("")
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)
  const [counterSendEmail, setCounterSendEmail] = useState(timeoutToSendEmailAgain);
  const [runTime, setRunTime] = useState(false);
  const [toastEnabled, setToastEnabled] = useState(false)
  const [validateMfaCodeError, setValidateMfaCodeError] = useState(false);
  const [validationErrors, setValidationErrors] = useState(false);
  const [isMfaProcessing, setIsMfaProcessing] = useState(false);
  const [remember, setRemember] = useState(false);
  // states for mfa/login
  const [remainingCodeAttempts, setRemainingCodeAttempts] = useState(null);
  // states for sendCode
  const [remainingCodeRequestAttempts, setRemainingCodeRequestAttempts] = useState(null)
  const [remainingLockedOutTimeSeconds, setRemainingLockedOutTimeSeconds] = useState(null)
  const [hasReachedMaxCodeRequests, setHasReachedMaxCodeRequests] = useState(false)
  const [showRequestsRemainingWarningMsg, setShowRequestsRemainingWarningMsg] = useState(false)

  useEffect(() => {
    if(runTime) {
      setCounterSendEmail(timeoutToSendEmailAgain);
      setCounterSendEmail((time) => time - 1 );

      const interval = setInterval(() => {
        setCounterSendEmail((time) => time - 1 );
      }, 1000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [runTime])

  useEffect(() => {
    const fewAttemptsLeft = remainingCodeRequestAttempts && remainingCodeRequestAttempts < LEFT_ATTEMPTS_FOR_WARN
    if(!hasReachedMaxCodeRequests && fewAttemptsLeft) {
      setShowRequestsRemainingWarningMsg(true)
    }
  }, [hasReachedMaxCodeRequests, remainingCodeRequestAttempts]);

  useEffect(() => {
    if(counterSendEmail === 0) {
      setRunTime(false)
    }
  }, [counterSendEmail]);

  useEffect(() => {
    setRunTime(true)
  }, []);

  const toggleToast = () => setToastEnabled(!toastEnabled);

  const codeHandler = (e) => {
    setValidationErrors(false);
    setValidateMfaCodeError("");
    let sanitizedValue = e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    if (sanitizedValue.length > MAX_CODE_LENGTH || isNaN(sanitizedValue)) {
      return
    }

    setCode(sanitizedValue)

    if (sanitizedValue.length === MAX_CODE_LENGTH) {
      setDisabledSubmitButton(false)
    } else {
      setDisabledSubmitButton(true)
    }
  };

  const sendCodeByEmailHandler = async () => {
    if (runTime || hasReachedMaxCodeRequests) return
    try {
      const {data} = await sendCode(userName, MFA_TYPE.EMAIL, mfaToken)
      const hasReachedMaxCode = data.remainingSendCodeAttempts === MINIMUM_ATTEMPTS
      if(!hasReachedMaxCode) toggleToast()
      setRunTime(true)
      // time to wait for re-request a new code
      setTimeoutToSendEmailAgain(data.codeResendWindowSeconds)
      // time to unlock email auth method
      setRemainingLockedOutTimeSeconds(data.remainingLockedOutTimeWindowSeconds)
      // resting code to request
      setRemainingCodeRequestAttempts(data.remainingSendCodeAttempts)
      setHasReachedMaxCodeRequests(hasReachedMaxCode)
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async () => {
    setValidateMfaCodeError("");
    setIsMfaProcessing(true);
    if (code.length <= 5 || isNaN(code) || code === "") {
      setValidationErrors(true);
      setIsMfaProcessing(false);
      return;
    }

    try {
      const response = await validateMfa(userName, code, mfaToken, remember, MFA_TYPE.EMAIL)
      dispatch(setNewToken(response.data));
      history.push({ pathname: redirectPath });
    } catch (error) {
      const { remainingTotpCodeAttempts } = error.response.data;
      if(remainingTotpCodeAttempts === MINIMUM_ATTEMPTS){
        redirectToLogin(true);
      }
      setIsMfaProcessing(false);
      setValidateMfaCodeError(true);
      setRemainingCodeAttempts(remainingTotpCodeAttempts);
    }
  }

  const validateForm = (event) => {
    const authCode = event.target.value;
    if (authCode.length < MAX_CODE_LENGTH || isNaN(authCode)) {
      setValidationErrors(true)
      return false
    }
    setValidationErrors(false)
    return true;
  };

  return (
    <>
      <MFAText className={"alignLeft"}>
        Please enter the 6-digit code we sent you to {mfaEmailSetUpped} to verify setup below—
      </MFAText>

      {remainingCodeAttempts && (remainingCodeAttempts <= LEFT_ATTEMPTS_FOR_WARN) && (
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFASegment padded mt={2} mb={16} className={"mfaErrorBox mfaInvalidAttemptRemaininglBox"}>
              <strong>{remainingCodeAttempts} invalid attempt(s) remaining!</strong>&nbsp;
              After your account will be locked for security purposes.
            </MFASegment>
          </MFAGrid.Column>
        </MFAGrid.Row>
      )}

      <MFAForm>
        <MFAForm.Field
          className="verifyWrapper"
        >
          <MFALabel
            className="verifyLabel"
          >
            6-digit authentication code
          </MFALabel>
          <MFAInput
            type="text"
            name="emailCode"
            id="emailCode"
            value={code}
            onChange={(e) => codeHandler(e)}
            maxLength={MAX_CODE_LENGTH}
            required
            error={validationErrors || validateMfaCodeError}
            disabled={isMfaProcessing || hasReachedMaxCodeRequests}
            className={"verifyInput"}
            onBlur={validateForm}
          />
          <MFAMethodValidatorContainer>
            <MFAMethodValidatorMsg>
              {validateMfaCodeError &&
                <MFATextError className={'verifyError'}>
                  Invalid code, please try again.
                </MFATextError>
              }

              {validationErrors &&
                <MFATextError className={'verifyError'}>
                  Enter a 6-digit authentication code
                </MFATextError>
              }
            </MFAMethodValidatorMsg>

            <MFAMethodSendCode>
              <MFALink
                className={`${runTime && "disabledPostrequiredLogin"} ${hasReachedMaxCodeRequests && "disabledMaxRequest"}`}
                onClick={sendCodeByEmailHandler}
              >
                Resend code {(runTime) && `in ${counterSendEmail}s`}
              </MFALink>
            </MFAMethodSendCode>
          </MFAMethodValidatorContainer>

        </MFAForm.Field>
      </MFAForm>

      <MFAContainer className="changeMethodField">
        <MFACheckbox
          label={`Remember me on this device for ${rememberDeviceDays} days`}
          name={"mfaRemember"}
          id={"mfaRemember"}
          onChange={() => setRemember(!remember)}
          className={"rememberDevice"}
        />
      </MFAContainer>

      <MFAButton
        mt={16}
        className="submitMethod"
        disabled={disabledSubmitButton || hasReachedMaxCodeRequests}
        onClick={handleSubmit}
      >
        Submit
      </MFAButton>

      {hasReachedMaxCodeRequests && (
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFASegment padded mt={16} className={"mfaErrorBox mfaRequestRemaininglBox"}>
              <strong>You have reached the maximum number of code requests.</strong>&nbsp;
              Please try again in {segToHours(remainingLockedOutTimeSeconds)} hours or try a different authentication method.
            </MFASegment>
          </MFAGrid.Column>
        </MFAGrid.Row>
      )}

      {/* will be added in another ticket*/}
      {/*{(showRequestsRemainingWarningMsg && !hasReachedMaxCodeRequests) && (*/}
      {/*  <MFAGrid.Row>*/}
      {/*    <MFAGrid.Column>*/}
      {/*      <MFASegment padded mt={16} className={"mfaSettingEmailBox mfaRequestRemaininglBox"}>*/}
      {/*        <strong> {remainingCodeRequestAttempts} code requests remaining!</strong> <br/>*/}
      {/*        After you will need to wait {segToHours(remainingLockedOutTimeSeconds)} hs to send it again or try a different method.*/}
      {/*      </MFASegment>*/}
      {/*    </MFAGrid.Column>*/}
      {/*  </MFAGrid.Row>*/}
      {/*)}*/}

      <MFAText mt={16} mb={16} className={"settingsItalic alignLeft"}>
        If you haven’t received the email after a few minutes,
        please check your junk mail folder as it may have been
        mistaken for spam.
      </MFAText>

      {toastEnabled && (
        <Toast
          success
          title={"Email sent successfully"}
          text={"A 6-digit code was sent for two-factor authentication."}
          onClose={toggleToast}
          autodismiss={5000}
          customStyle={{
            textAlign: 'left'
          }}
        />
      )}

      <BackupCodeButton handleUseMethod={() => handleUseMethod(MFA_TYPE.BACKUP_CODES)} />
    </>
  )
}
