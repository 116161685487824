import React from "react";
import {
  MFAGrid,
  MFAHeading,
  MFAText,
  MFAButton,
  MFAImage,
  MFAContainer
} from "../../styled";

const SuccessMFASetup = ({ continueToPortal }) => {
  return (
    <>
    <MFAContainer className="successWrapper" maxWidth={"590px"}>
      <MFAGrid>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAGrid>
              <MFAGrid.Row>
                <MFAGrid.Column
                  className="successWrapperColumn"
                >
                  <MFAImage
                    name={"check"}
                    size="tiny"
                    centered
                    height={80}
                  />
                  <MFAHeading as="h1" className="success">Success</MFAHeading>
                  <MFAText className="successText">
                    Two-factor authentication has been enabled for your account.
                  </MFAText>
                </MFAGrid.Column>
              </MFAGrid.Row>
              <MFAGrid.Row>
                <MFAGrid.Column
                  className="successWrapperColumn"
                >
                  <MFAButton onClick={() => continueToPortal()}>
                    Continue
                  </MFAButton>
                </MFAGrid.Column>
              </MFAGrid.Row>
            </MFAGrid>
          </MFAGrid.Column>
        </MFAGrid.Row>
      </MFAGrid>
    </MFAContainer>
    </>
  );
};

export default SuccessMFASetup;
