import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Icon, Image, Popup} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {Header} from "../base";
import {connect} from "react-redux";
import {formatMoneyAmount, getQleIcon, resolveDisplayTier} from "../../utils/common";
import NumberFormat from "react-number-format";
import Event from "./event";
import moment from 'moment';
import {getPayrollFrecuencyLabel} from "../../utils/utils";
import Header3Blue from "./common/header3Blue";
import Header4Black from "./common/header4Black";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import BottomDivider from "./common/bottomDivider";
import Header2Black from "./common/header2Black";

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    dependents: state.initialData.dependents,
    contactSfId: state.auth.loginInfo.contactSfId,
    user: state.initialData.user,
    benefits: state.initialData.certificate.assetCoverages,
    documents: state.initialData.documents,
    certificate: state.initialData.certificate,
    payrollFrequency: state.initialData.payrollFrequency,
    cost: state.initialData.cost,
    employerContribution: state.initialData.employerContribution,
    userIsCertificateHolder: state.initialData.userIsCertificateHolder,
    contract: state.initialData.contract,
    coverageHistory: state.initialData.coverageHistory
});

class TimeLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayTier: null,
            displayPayrollFrecuency: null,
            selectedEvent: null
        }
    }

    componentDidMount() {
        this.setState({
            loading: false,
            displayPayrollFrecuency: getPayrollFrecuencyLabel(this.props.payrollFrequency, true)
        })
    }

    viewEvent = (event) => {
        if (event.certificateId === this.props.certificate.id) {
            this.props.viewSummaryTab()
        } else {
            this.setState({
                selectedEvent: event
            })
        }
    }

    render() {
        const {selectedEvent, displayPayrollFrecuency} = this.state;
        const {userIsCertificateHolder, coverageHistory, certificate} = this.props;
        const orderedCoverageHistory = coverageHistory.sort((a, b) => new Date(b.coverageEffectiveDate) - new Date(a.coverageEffectiveDate))


        const infoIcon = <Icon className={'nonprintable'} circular name={'info'}
                               style={{color: 'black', marginLeft: '1em', fontSize: '10px', cursor: 'pointer'}}
                               onClick={() => this.setState({displayInformationAboutCategoriesModal: true})}/>;
        const moderateTooltipText = "Injuries or illnesses that likely require a short visit to the ER or urgent care."
        const severeTooltipText = "Serious conditions that require more intensive medical treatment and attention."
        const catastrophicTooltipText = "Dangerous or life-threatening conditions that require immediate medical intervention."
        const chronicTooltipText = "Coverage for over 4,100 additional chronic conditions spanning asthma, diabetes, epilepsy, and many more."
        const mentalTooltipText = "Coverage for 600 different mental health disorders like anxiety, bipolar, and depression."

        const specialEvents = ['annual open enrollment', 'new hire', 'newly eligible']
        const onlyQLEEvents = coverageHistory.filter(event => !specialEvents.includes(!!event.versionReason && event.versionReason.toLowerCase() && !event.versionReason.toLowerCase() === 'contract amendment'))

        return (<React.Fragment>
                <Header as="h2">History</Header>
                <Divider hidden/>
                {(!!orderedCoverageHistory && orderedCoverageHistory.length > 0) && orderedCoverageHistory.map(event => {
                        if (!!event.versionReason) {
                            return <Event
                                iconName={getQleIcon(event.versionReason).iconName}
                                colorClass={getQleIcon(event.versionReason).iconColorClass}
                                title={event.versionReason}
                                effective={event.coverageEffectiveDate}
                                submitted={event.createdDate}
                                viewAction={() => {
                                    this.viewEvent(event)
                                }}
                                isSelected={!!selectedEvent ? selectedEvent.certificateId === event.certificateId : false}
                                isCurrentCoverage={event.certificateId === certificate.id && event.status !== "Pending"}
                            />
                        } else return null
                    }
                )
                }
                {onlyQLEEvents.length === 0 && <CustomContainer textAlign="center" basic padded={"very"}>
                    <Image style={{width: "110px"}} centered
                           src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Claims-Flow/EmptyState_greyscale.png"}
                    />
                    <Header as="h4" className="neutral700Text">
                        You have not registered any life events
                    </Header>
                    <Divider hidden/>
                    <Divider hidden/>
                    <Divider hidden/>
                </CustomContainer>
                }

                <Divider hidden/>

                {!!selectedEvent && <React.Fragment>
                    <Header3Blue>Coverage</Header3Blue>
                    {!!selectedEvent.coverageEffectiveDate &&
                    <p className={"small"}>
                        <b>Effective {moment(selectedEvent.coverageEffectiveDate).format('MMM Do, YYYY')}</b></p>}

                    <Divider hidden/>

                    {!!selectedEvent.benefits && <Grid>
                        <Grid.Column width={7}>
                            <Header4Black>Benefit categories</Header4Black>
                            <CustomContainer padded>
                                <Grid columns={3} stackable>
                                    <Grid.Column style={{paddingRight: 0}}>
                                        <Header4Black>
                                            <NumberFormat value={selectedEvent.benefits.moderate} displayType={'text'}
                                                          thousandSeparator={true} prefix={'$'}/>
                                        </Header4Black>
                                        <p><span className={"vibranBlueText"}>Moderate</span>
                                            <Popup position={'top left'}
                                                   className={'greenPopup'}
                                                   on={'hover'}
                                                   style={{cursor: 'pointer'}}
                                                   trigger={
                                                       <span>{infoIcon}</span>
                                                   }
                                            ><span className={"smaller"}>{moderateTooltipText}</span>
                                            </Popup>
                                        </p>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Header4Black>
                                            <NumberFormat value={selectedEvent.benefits.severe} displayType={'text'}
                                                          thousandSeparator={true}
                                                          prefix={'$'}/>
                                        </Header4Black>
                                        <p>
                                            <span className={"vibranBlueText"}>Severe</span>
                                            <Popup position={'top left'}
                                                   className={'greenPopup'}
                                                   on={'hover'}
                                                   style={{cursor: 'pointer'}}
                                                   trigger={
                                                       <span>{infoIcon}</span>
                                                   }
                                            ><span className={"smaller"}>{severeTooltipText}</span>
                                            </Popup>
                                        </p>
                                    </Grid.Column>

                                    <Grid.Column style={{paddingRight: 0, paddingLeft: 0}}>
                                        <Header4Black><NumberFormat value={selectedEvent.benefits.catastrophic}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true} prefix={'$'}/>
                                        </Header4Black>
                                        <p>
                                            <span className={"vibranBlueText"}>Catastrophic</span>
                                            <Popup position={'top left'}
                                                   className={'greenPopup'}
                                                   on={'hover'}
                                                   style={{cursor: 'pointer'}}
                                                   trigger={
                                                       <span>{infoIcon}</span>
                                                   }
                                            ><span className={"smaller"}>{catastrophicTooltipText}</span>
                                            </Popup>
                                        </p>
                                    </Grid.Column>
                                </Grid>
                            </CustomContainer>
                        </Grid.Column>
                        {(!!selectedEvent.benefits.chronic || !!selectedEvent.benefits.mental) &&
                        <Grid.Column width={6}>
                            <Header4Black>
                                {STRATEGIC_PARTNER.LABEL} <span className={'icon-secondary-deep-purple'}>+</span>
                            </Header4Black>
                            <CustomContainer padded>
                                <Grid columns={3} stackable>
                                    {!!selectedEvent.benefits.chronic &&
                                    <Grid.Column><Header4Black><NumberFormat value={selectedEvent.benefits.chronic}
                                                                                 displayType={'text'}
                                                                                 thousandSeparator={true}
                                                                                 prefix={'$'}/></Header4Black>
                                        <p>
                                            <span className={"vibranBlueText"}>Chronic</span>
                                            <Popup position={'top left'}
                                                   className={'greenPopup'}
                                                   on={'hover'}
                                                   style={{cursor: 'pointer'}}
                                                   trigger={
                                                       <span>{infoIcon}</span>
                                                   }
                                            ><span className={"smaller"}>{chronicTooltipText}</span>
                                            </Popup>
                                        </p>
                                    </Grid.Column>}
                                    {!!selectedEvent.benefits.mental &&
                                    <Grid.Column><Header4Black><NumberFormat value={selectedEvent.benefits.mental}
                                                                                 displayType={'text'}
                                                                                 thousandSeparator={true}
                                                                                 prefix={'$'}/></Header4Black>
                                        <p>
                                            <span className={"vibranBlueText"}>Mental</span>
                                            <Popup position={'top left'}
                                                   className={'greenPopup'}
                                                   on={'hover'}
                                                   style={{cursor: 'pointer'}}
                                                   trigger={
                                                       <span>{infoIcon}</span>
                                                   }
                                            ><span className={"smaller"}>{mentalTooltipText}</span>
                                            </Popup>
                                        </p>
                                    </Grid.Column>}
                                </Grid>
                            </CustomContainer>
                        </Grid.Column>}
                    </Grid>}

                    {!!userIsCertificateHolder && <React.Fragment>
                        <Divider hidden/>
                        <Header3Blue>Family info</Header3Blue>
                        <Grid>
                            <Grid.Column width={4}>
                                <p>
                                    <b>Tier</b><br/>
                                    {resolveDisplayTier(selectedEvent.familyTier)}
                                </p>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <div>
                                    <div><b>Covered</b></div>
                                    {(!!selectedEvent.covered && selectedEvent.covered.length > 0) && selectedEvent.covered.map((item, i) => {
                                        return <div key={i}>{item.firstName} {item.lastName}</div>
                                    })}
                                </div>
                            </Grid.Column>
                        </Grid>
                        <Divider hidden/>
                    </React.Fragment>
                    }
                    <Divider hidden/>
                    {!!userIsCertificateHolder && <React.Fragment>
                        <BottomDivider/>
                        <Header3Blue>Costs</Header3Blue>
                        <Grid>
                            <Grid.Column width={6}>
                                <CustomContainer padded>
                                    <span className={"vibranBlueText"}><b>I pay</b></span>
                                    <Header2Black>
                                        ${formatMoneyAmount(selectedEvent.cost)}/{displayPayrollFrecuency}
                                    </Header2Black>
                                </CustomContainer>
                                {selectedEvent.contrib > 0 && <p className={'neutral900Text'}>
                                    Employer contributes ${formatMoneyAmount(selectedEvent.contrib)}/{displayPayrollFrecuency}
                                </p>}
                            </Grid.Column>
                        </Grid>
                    </React.Fragment>
                    }
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(TimeLine));
