import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Icon, Label, Loader, Message} from "semantic-ui-react";
import {CustomContainer, S3Image} from '../custom-common';
import {Button, Header} from "../base";
import {connect} from "react-redux";
import Footer from "../footer";
import {setLeftNavigation, setPrevRoute} from "../../actions/navigationActions";
import {handleSaveUserProgress, resolveErrorMessage, savePoints} from "../../utils/common";
import axios from "axios";
import {setAOEDependents, setAOEInitialData, setAOEInitialDependents, setAOEUserInitialData, setKeepCoverage, setProgressData} from "../../actions/anualOpenEnrollmentActions";
import {cancelAOEFlow} from "../../utils/utils";
import CoverageComparisonModal from "./coverageComparisonModal";
import moment from "moment";
import CoverageComparisonTable from "./coverageComparisonTable";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import CancelLink from "./cancelLink";
import { ClickableText } from '../custom-common';
import IMAGE from "../../constants/images";

const mapStateToProps = (state, ownProps) => {
    const {anualOpenEnrollment, navigation: {lastRoute}, initialData: {openEnrollment}} = state;
    return {
        ...ownProps,
        anualOpenEnrollment,
        openEnrollment,
        dependents: anualOpenEnrollment.dependents,
        previousUrl: anualOpenEnrollment.previousUrl,
        lastRoute,
        upcomingPolicyEffectiveDate: anualOpenEnrollment.generalInitialData.upcomingPolicyEffectiveDate,
    }
};

class ReviewPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submitError: null,
            loadError: null,
            showModal: false,
            planChange: null,
            costChange: null
        }
    }

    async componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.previousUrl))
        this.props.dispatch(setLeftNavigation(false))
        try {
            this.setState({loading: true})
            const result = await axios.post("/api/member/v2/prepareReview", null, {headers: {'Content-Type': 'application/json'}});
            const initialData = {
                genderOptions: result.data.genderOptions,
                nameSuffixOptions: result.data.nameSuffixOptions,
                phoneTypeOptions: result.data.phoneTypeOptions,
                stateOptions: result.data.stateOptions,
                relationshipOptions: result.data.relationshipOptions,
                highDeductibleOptions: result.data.highDeductibleOptions,
                coPayOptions: result.data.coPayOptions,
                disposableIncomeOptions: result.data.disposableIncomeOptions,
                coverageOptions: result.data.familyTiers,
                costPerOptions: result.data.costPerOptions,
                payrollFrequencySelected: result.data.payrollFrequencySelected,
                payrollFrequencyMonthly: result.data.payrollFrequencyMonthly,
                quoteInfo: result.data.quoteInfo,
                presets: result.data.presets,
                ratedClass: result.data.ratedClass,
                limitations: result.data.limitations,
                increments: result.data.increments,
                frauds: result.data.frauds,
                eligibility: result.data.eligibility,
                contract: result.data.contract,
                employer: result.data.employer,
                employerContributions: result.data.employerContribution,
                planComparison: result.data.planComparison,
                upcomingPolicyEffectiveDate: result.data.upcomingPolicyEffectiveDate,
                currentSelectPlan: result.data.currentSelectPlan,
                studentStatusOptions: result.data.studentStatusOptions,

        }
            const user = result.data.certificateHolder;
            user.certificateHolderPhone = result.data.certificateHolderPhone;
            user.certificateHolderPhoneType = result.data.certificateHolderPhoneType;
            const state = user.address.state;
            if (user.address.state !== null) {
                user.address.state = result.data.stateOptions.filter(option => option.text === state || option.value === state )[0].value
            }

            let sanitizedDependentList = []
            this.props.dependents.forEach(dependent => {
                const copy = {...dependent}
                if (!!copy.address && copy.address.state !== null) {
                    copy.address.state = result.data.stateOptions.filter(option => option.text === state || option.value === state)[0].value
                }
                sanitizedDependentList.push(copy);
            })

            this.props.dispatch(setAOEInitialData({initialData}))
            this.props.dispatch(setAOEUserInitialData({user}))
            this.props.dispatch(setAOEInitialDependents(sanitizedDependentList))

            const comesFromOutOfAOEFlow = !this.props.lastRoute.startsWith('/anualOpenEnrollment')

            if (!!result.data.shoppingProgress && comesFromOutOfAOEFlow) {
                await this.handleRestoreUserProgress(result.data.shoppingProgress);
            } else if(!!result.data.shoppingProgress && !this.props.anualOpenEnrollment.aOEDependents.length) {
                const { aOEDependents = [] } = result?.data?.shoppingProgress?.data?.anualOpenEnrollment || {};
                this.props.dispatch(setAOEDependents(aOEDependents));
            }
            this.setState({
                loading: false,
                planChange: result.data.planChange,
                costChange: result.data.costChange,
                reEnrollmentRequired: result.data.reEnrollmentRequired
            })
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, prepareError: resolveErrorMessage(e, "An unexpected error ocurred")})
        }
    }

    getCoverageChangedText = () => {
        const {anualOpenEnrollment: {generalInitialData: {planComparison}}} = this.props;
        const currentPlan = planComparison.currentPlan;
        const upcomingPlan = planComparison.upcomingPlan;
        const hasAddedChronic = currentPlan.benefitAmounts.chronic === null && !!upcomingPlan.benefitAmounts.chronic;
        const hasAddedMental = currentPlan.benefitAmounts.mental === null && !!upcomingPlan.benefitAmounts.mental;
        const hasLostChronic = !!currentPlan.benefitAmounts.chronic && upcomingPlan.benefitAmounts.chronic === null;
        const hasLostMental = !!currentPlan.benefitAmounts.mental && upcomingPlan.benefitAmounts.mental === null;
        let text = null;
        if ((hasAddedChronic && hasAddedMental) || (hasLostChronic && hasLostMental)) {
            text = "Chronic and Mental"
        } else if (hasAddedChronic || hasLostChronic) {
            text = "Chronic"
        } else if (hasAddedMental || hasLostMental) {
            text = "Mental"
        }
        return text
    }

    getPageInfo = () => {
        const {planChange, costChange, reEnrollmentRequired} = this.state;
        let headerText;
        let paragraph1Text;
        let paragraph2Text;
        let noActionText;
        let primaryCTAShop = false;
        let showTooltip = false;
        let icon;
        let iconWidth = '120px';
        let showTable = true;

        const coverageChangedText = this.getCoverageChangedText()

        if (planChange === "No Plan Change") {
            if (costChange === 1) {
                headerText = "Let's review what's changing"
                paragraph1Text = `The cost of your ${STRATEGIC_PARTNER.LABEL} coverage for next year is changing.`
                paragraph2Text = "See below for updated cost information—"
                showTooltip = true
                icon = IMAGE.CLIPBOARD_WITH_MAGNIFYING_GLASS
            } else if (costChange === -1) {
                headerText = "Great news!"
                paragraph1Text = `The cost of your ${STRATEGIC_PARTNER.LABEL} coverage for next year is decreasing!`
                paragraph2Text = "This means you may be able to increase your benefit payouts and keep your monthly cost the same—"
                primaryCTAShop = true
                icon = IMAGE.SUCCESS_CONFETTI_150PX
            } else {
                headerText = "Great news!"
                paragraph1Text = `Nothing about your ${STRATEGIC_PARTNER.LABEL} coverage is changing, which means you can continue your exact coverage as is—`
                paragraph2Text = null
                icon = IMAGE.SUCCESS_CONFETTI_150PX
            }
        } else if (planChange === "Coverage added (rider)") {
            if (costChange === 1) {
                headerText = "Let's review your coverage changes"
                paragraph1Text = `Great news! Your ${STRATEGIC_PARTNER.LABEL} coverage will now include coverage for an additional ${coverageChangedText}, which is paid for by your employer.`
                paragraph2Text = `However, the cost of your ${STRATEGIC_PARTNER.LABEL} coverage for next year is also changing, see below for updated cost information—`
                showTooltip = true
                icon = IMAGE.CLIPBOARD_WITH_MAGNIFYING_GLASS
            } else if (costChange === -1) {
                headerText = "Let's review your coverage changes"
                paragraph1Text = `Next year your coverage also includes coverage for ${coverageChangedText}.`
                paragraph2Text = `The best part? Even with this additional benefit, the cost of your ${STRATEGIC_PARTNER.LABEL} coverage for next year is decreasing. This means you may be able to increase your benefit payouts and keep your monthly cost the same—`
                primaryCTAShop = true
                icon = IMAGE.SUCCESS_CONFETTI_150PX
            } else {
                headerText = "Great news!"
                paragraph1Text = `Next year your coverage also includes coverage for ${coverageChangedText}.`
                paragraph2Text = "The best part? This is at no cost to you!"
                icon = IMAGE.SUCCESS_CONFETTI_150PX
            }
        } else if (planChange === "Coverage lost (rider)") {
            if (costChange === 1) {
                headerText = "Let's review what's changing"
                paragraph1Text = `The cost of your ${STRATEGIC_PARTNER.LABEL} coverage for next year is changing. See below for updated cost information—`
                paragraph2Text = `Next year your coverage no longer includes coverage for ${coverageChangedText}.`
                showTooltip = true
                icon = IMAGE.CLIPBOARD_WITH_MAGNIFYING_GLASS
            } else if (costChange === -1) {
                headerText = "Let's review what's changing"
                paragraph1Text = `Next year your coverage no longer includes coverage for ${coverageChangedText}.`
                paragraph2Text = "However, this means you may be able to increase your coverage in other categories and keep your monthly cost the same."
                primaryCTAShop = true
                icon = IMAGE.CLIPBOARD_WITH_MAGNIFYING_GLASS
            } else {
                headerText = "Let's review what's changing"
                paragraph1Text = `next year your ${STRATEGIC_PARTNER.LABEL} coverage no longer includes coverage for ${coverageChangedText}.`
                paragraph2Text = "However, the cost of the rider was paid by your employer, so there's no change to your cost."
                icon = IMAGE.CLIPBOARD_WITH_MAGNIFYING_GLASS
            }
        } else {
            headerText = "Let's review your coverage changes"
            paragraph1Text = `Your ${STRATEGIC_PARTNER.LABEL} payout options have changed.`
            paragraph2Text = `The ${STRATEGIC_PARTNER.LABEL} coverage and benefit payout options available to you have changed for the upcoming coverage year. Review your options to continue your coverage —`
            primaryCTAShop = true
            icon = IMAGE.CLIPBOARD_WITH_MAGNIFYING_GLASS
            showTable = false
        }

        const date = moment(this.props.openEnrollment.enrollmentEndDate).format("MMM DD, YYYY")

        if (reEnrollmentRequired) {
            noActionText = `Your current coverage is ending soon. Take action to continue your coverage by ${date}.`
        } else {
            noActionText = `Your coverage will continue as displayed below. Make any changes by ${date}.`
        }

        return ({
            headerText: headerText,
            paragraph1Text: paragraph1Text,
            paragraph2Text: paragraph2Text,
            noActionText: noActionText,
            primaryCTAShop: primaryCTAShop,
            showTooltip: showTooltip,
            icon: icon,
            iconWidth: iconWidth,
            showTable: showTable
        })
    }

    handleRestoreUserProgress = async (userProgressObj) => {
        const {data} = userProgressObj;
        let didChangeUserRoute = false;

        if (!!data && !!data.anualOpenEnrollment) {
            await this.props.dispatch(setProgressData(data.anualOpenEnrollment));
            if (!!data.anualOpenEnrollment.lastVisitedSavePoint) {
                const savePointRoute = !!data.anualOpenEnrollment && !!data.anualOpenEnrollment.lastVisitedSavePoint && data.anualOpenEnrollment.lastVisitedSavePoint.route;
                const savePoint = savePoints[savePointRoute]
                if (!!savePoint) {
                    didChangeUserRoute = true;
                    this.props.history.push(savePoint.redirectTo);
                }
            }
        }
        return didChangeUserRoute;
    }

    showModal = (value) => {
        this.setState({
            showModal: value
        })
    }

    keepCoverage = () => {
        this.props.dispatch(setKeepCoverage(true))
        this.props.history.push(this.props.nextRoute)
    }

    next = () => {
        this.props.dispatch(setKeepCoverage(false))
        handleSaveUserProgress(this.props.anualOpenEnrollment).then();
        this.props.history.push(this.props.nextRoute)
    }

    render() {
        const {loading, submitError, loadError, showModal} = this.state;
        const pageInfo = this.getPageInfo();

        const button = <Button primary
                               content={pageInfo.primaryCTAShop ? "Review my options" : "Keep my current coverage"}
                               disabled={loading} loading={loading}
                               onClick={pageInfo.primaryCTAShop ? this.next : this.keepCoverage}
        />;
        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;

        if (!!loadError) {
            return <CustomContainer basic><Message negative>{loadError}</Message></CustomContainer>
        } else if (!!loading) {
            return <Loader active/>
        } else {
            return (
                <React.Fragment>
                    {showModal && <CoverageComparisonModal handleClose={this.showModal} show/>}
                    <div className="member-main-container" style={{background: 'white'}}>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={8} className={"pageContent"}>
                                <Divider hidden/><Divider hidden/>

                                <Header as="h2">{pageInfo.headerText}</Header>

                                <Divider hidden/>

                                <CustomContainer raised style={{padding: '24px 40px'}} className={"bkgNeutral50"}>
                                    <Grid verticalAlign={"middle"}>
                                        <Grid.Column width={8}>
                                            <p><b>{pageInfo.paragraph1Text}</b></p>
                                            <p>{pageInfo.paragraph2Text}</p>
                                            <p>{pageInfo.noActionText}</p>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <S3Image {...pageInfo.icon}/>
                                        </Grid.Column>
                                    </Grid>
                                </CustomContainer>

                                <Divider hidden/>

                                {pageInfo.showTable && <React.Fragment>
                                    <Header as="h4"><Icon name={"calendar check outline"}
                                                          style={{fontSize: '1.3em', marginRight: '24px'}}
                                                          className={'circularIcon60 bkgSunshade'}/>Effective
                                        date: {moment(this.props.upcomingPolicyEffectiveDate).format("MMM DD, YYYY")}
                                    </Header>

                                    <Divider hidden/>

                                    <Grid columns={1}>
                                        <Grid.Column>If you keep your current coverage, it will look like:<Label
                                            className={"draft"} style={{marginLeft: '2em', marginRight: '2em'}}><Icon
                                            name={"circle"}/>Changed</Label><ClickableText
                                            onClick={() => {
                                            this.showModal(true)
                                        }}><Icon name={"exchange"}/>Compare</ClickableText></Grid.Column>
                                    </Grid>

                                    <Divider hidden/>

                                    <CoverageComparisonTable showTooltip={pageInfo.showTooltip}/>

                                    <Divider hidden/><Divider hidden/>

                                    <CustomContainer padded>
                                        {pageInfo.primaryCTAShop ?
                                            <React.Fragment>
                                                <b>Don't want to make a change?</b><ClickableText style={{paddingLeft: '.1em'}} onClick={this.keepCoverage}> Keep my current {STRATEGIC_PARTNER.LABEL} coverage as is.</ClickableText>
                                            </React.Fragment> :
                                            <React.Fragment>
                                                <b>Want to review your coverage options? </b><ClickableText style={{paddingLeft: '.1em'}} onClick={this.next}>Click here.</ClickableText>
                                            </React.Fragment>
                                        }
                                    </CustomContainer>
                                    <Divider hidden/><Divider hidden/>

                                </React.Fragment>}

                                {!!submitError && <Message negative>{submitError}</Message>}
                            </Grid.Column>
                        </Grid>
                        <Footer showTopFooter showCenterData button={button} link={pageInfo.primaryCTAShop ? cancel : null} largeButton/>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default connect(mapStateToProps)(withRouter(ReviewPlan));
