import {getHelpActionTypes, INIT} from "../actions/actionTypes";

const initialState = {
    show: false,
};

export const getHelpReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT:
            return initialState // Clear on init
        case getHelpActionTypes.TOGGLE:
            const show = action.payload !== undefined ? !!action.payload : !state.show
            return {
                ...state,
                show
            }
        default:
            return state
    }
};