import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom";
import {Header} from "../base";
import {ClickableText, CustomContainer, CustomDivider, S3Image} from '../custom-common';
import {setResetPasswordFlow, setUseWeb} from '../../actions/mobileActions'
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import IMAGE from "../../constants/images";

const mapStateToProps = (state) => ({
    isResetPasswordFlow: state.mobile.isResetPasswordFlow,
    useWeb: state.mobile.useWeb,
});

const mapDispatchToProps = dispatch => ({
    setUseWeb: state => dispatch(setUseWeb(state)),
    setResetPasswordFlow: state => dispatch(setResetPasswordFlow(state)),
});

class MobileAppDialog extends Component {
    handleContinue = (e) => {
        e.preventDefault();

        const { setUseWeb, history, isResetPasswordFlow, setResetPasswordFlow, useWeb, nextRoute } = this.props;

        setUseWeb(true);

        setResetPasswordFlow(false);

        if (isResetPasswordFlow) {
            history.push('/plan');
        } else {
            history.push(nextRoute);
        }
    }
    render() {
        return (
            <CustomContainer basic className={'mobileAppContainer'}>
                <div>
                    <S3Image {...IMAGE.ISOLOGO}/>
                    <CustomDivider small hidden/>
                    <CustomDivider hidden/>
                    <Header as='h2' textAlign='center'>
                        {STRATEGIC_PARTNER.LABEL}<br /> is better on the App.
                    </Header>
                    <CustomDivider hidden/>
                    <CustomDivider small hidden/>
                    <p>
                        Check your benefits and file claims faster<br /> right on your phone
                    </p>
                    <CustomDivider hidden/>
                    <CustomDivider small hidden/>
                    <div  className={'mobileButtonContainer'}>
                        <a href="https://apps.apple.com/hr/app/voya-protect/id6480015576" style={{ paddingRight: '12px' }}>
                            <S3Image {...IMAGE.APPLE_STORE_BUTTON}/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.voya.ansel.protect" style={{ paddingLeft: '12px' }}>
                            <S3Image {...IMAGE.GOOGLE_PLAY_BUTTON}/>
                        </a>
                    </div>
                    <CustomDivider small hidden/>
                    <CustomDivider hidden/>
                    <p className={'small'}>
                        For the best web experience, we recommend<br /> switching to your desktop.
                    </p>
                    <CustomDivider hidden/>
                    <CustomDivider small hidden/>
                    <ClickableText onClick={this.handleContinue}> Continue to web experience</ClickableText>
                </div>
            </CustomContainer>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileAppDialog))