import React from "react"
import connect from "react-redux/es/connect/connect"
import {Redirect, Route} from "react-router-dom"
import {LOGIN_ROUTE} from "../utils/common";
import MfaAuth from "../components/mfaAuth"

const mapStateToProps = (state, ownProps) => {
    return {
        authToken: state.auth.loginInfo ? state.auth.loginInfo.authToken : null,
        mfaToken: state.auth.loginInfo?.mfaToken ? state.auth.loginInfo.mfaToken : null,
        ...ownProps
    }
};

const PrivateRoute = ({ component: Component, authToken, mfaToken, ...rest }) => (
    <Route {...rest} render={(props) => (
        authToken
            ? <Component {...props} />
            : mfaToken ? <MfaAuth {...props} />
            : <Redirect to={{
                pathname: LOGIN_ROUTE,
                state: { from: props.location }
            }} />
    )} />
);

export default connect(mapStateToProps)(PrivateRoute);