import React from "react";
import {purgeState} from "../storePersistence";
import {setLanguageFormatDate} from "./date";
import STRATEGIC_PARTNER from "../constants/strategicPartners";

const {
    LABEL,
} = STRATEGIC_PARTNER;

export const languages = {
    ENGLISH: "en",
    SPANISH: "es",
};

export const getLocalization = (language) => {
    const defaultLang = languages.ENGLISH;
    let strings = localization[defaultLang];
    if (language === languages.ENGLISH || language === languages.SPANISH) {
        strings = localization[language];
        setLanguageFormatDate(language);
    }
    return strings;
};

export const interpolateString = (str, [...args]) => {
    try {
        args.forEach((a, idx) => {
            const key = "$" + (idx + 1);
            while (str.indexOf(key) > -1) {
                str = str.replace(key, a);
            }
        });
        return str;
    } catch (e) {
        console.error("Interpolate String error", {str}, {args}, {e});
        purgeState();
        window.location.reload(true);
    }
};

export const parseMultilineString = (str, args = null) => {
    try {
        if (args !== null) {
            str = interpolateString(str, args);
        }
        const lines = str.split("\n");
        const linesCount = lines.length;
        return lines.map((line, idx) => {
            const nbsps = line.split("&nbsp;");
            return <React.Fragment key={line}>{nbsps.join('\u00A0')}{idx < linesCount - 1 && <br/>}</React.Fragment>
        });
    } catch (e) {
        console.error({e});
        purgeState();
        window.location.reload(true);
    }
};

export const parseSingleUnderlinedWordInString = (str) => {
    try {
        if (str === null) {
            return str;
        }
        const parts = str.split("__");
        if (parts.length !== 3) {
            console.error("parseSingleUnderlinedWordInString only works for one word in the given string");
            return str;
        }
        return <React.Fragment>{parts[0]}<u>{parts[1]}</u>{parts[2]}</React.Fragment>;
    } catch (e) {
        console.error({e});
        purgeState();
        window.location.reload(true);
    }
}

const localization = {
    [languages.ENGLISH]: {
        next: "Next step",
        dropdownOptions: {
            female: "Female",
            male: "Male",
            mobile: "Mobile",
            home: "Home",
            standard: "Standard",
            domesticPartner: "Domestic partner",
            spouse: "Spouse",
            child: "Child",
        },
        reviewPersonalInfo: {
            title: "Review personal information",
            homeAddress: "Your address",
            streetAddress: "Street address",
            zipCode: "Zip code",
            state: "State",
            aptSuiteOther: "Apartment",
            city: "City",
            yourDetails: "Your details",
            firstName: "First name",
            middleName: "Middle name",
            middleInitial: "Middle initial",
            lastName: "Last name",
            suffix: "Suffix",
            dob: "Date of birth",
            ssn: "Social Security Number",
            daytimePhone: "Daytime phone number",
            phoneType: "Type of phone",
            gender: "Gender",
            submit: "Submit",
            theGreyedOut: "The greyed out fields were provided by your employer, please reach out to them if they need to be corrected or updated.",
            invalidPhoneNumber: "Invalid Phone Number",
            invalidZipCode: "Invalid Zip code",
            genericErrTitle: "Oops!",
            genericErr: "Please correct the errors above before proceeding.",
            important: "IMPORTANT"
        },
        reviewFamily: {
            title: "Review family members",
            homeAddress: "Your address",
            streetAddress: "Street address",
            zipCode: "Zip code",
            state: "State",
            aptSuiteOther: "Apartment",
            city: "City",
            dependents: "Your family",
            spouse: "Spouse",
            addChildT: "Add Child",
            addSpouse: "Add Spouse",
            addDependent: "Add dependent",
            firstName: "First name",
            middleName: "Middle name",
            middleInitial: "Middle initial",
            lastName: "Last name",
            suffix: "Suffix",
            dob: "Date of birth",
            ssn: "Social Security Number",
            role: "Role",
            relationship: "Relationship to employee",
            domesticPartner: "Domestic partner",
            address: "Address",
            gender: "Gender",
            sameAsEmployee: "Same as employee",
            details: "Details",
            cancel: "Cancel",
            submit: "Submit",
            save: "Save",
            optional: "optional",
            theGreyedOut: "The greyed out fields were provided by your employer, please reach out to them if they need to be corrected or updated.",
            invalidPhoneNumber: "Invalid Phone Number",
            invalidZipCode: "Invalid Zip code",
            note: "Note: ",
            delete: "Delete",
            confirmDelete: "Confirm delete",
            dependent: "Dependent ",
            willBeRemoved: " will be removed.",
            theCoverageTierDep: " The coverage tier you selected doesn’t include dependents.",
            editDetails: "Edit details ",
            goBack: "go back",
            inOrderForYour: "In order for your child over $1 to be covered, the following must apply—",
            cIsIncapable: "the child is incapable of self-sustaining employment because of a mental or physical disability; and",
            cIsChiefly: "the child is chiefly dependent on You for support and maintenance; and",
            cProofIs: "proof is provided of the disability upon our request (proof will be required at the time of claim).",
            iAgree: 'I acknowledge that my child meets the criteria above',
            child: "Child",
            genericErrTitle: "Oops!",
            genericErr: "Please correct the errors above before proceeding.",
            basedOnDependentAge: "Based on your spouse’s age, they will receive $1% of any benefit you select here.",
            important: "IMPORTANT",
            duplicateDependent: "A dependent with this information already exists.",
            requestError: "There was an error in the request",
            firstNameUpdateError: "The First Name can not be changed.",
            lastNameUpdateError: "The Last Name can not be changed.",
            birthdateUpdateError: "The Birthdate can not be changed.",
            ssnUpdateError: "The SSN can not be changed.",
            roleUpdateError: "The Role can not be changed.",
            studentStatus: "Student status",
            studentStatusFullTime: "Full-time",
            studentStatusPartTime: "Part-time",
            studentStatusNotAStudent: "Not a student",
            inOrderToDetermin: `In order to determine if your child qualifies for ${LABEL} coverage, please review and acknowledge the following—`,
            inOrderForChildToQualify: `In order for your child to qualify for ${LABEL} coverage, the following must apply—`,
            iAckTheCriteriaAppliesToMyChild: 'I acknowledge that the criteria above applies to my child',
            pleaseNoteAboveCriteria: "Please note, at least one of the above criteria must apply to click save.",
            cIsUnmarried: "the child is unmarried and does not have a dependent of his or her own; and",
            cIsFLResident: "the child is a resident of Florida or is a part-time or a full-time student (must be indicated in the fields above).",
        },
        wouldYouMind: {
            title: "$1, let's personalize your plan",
            subTitle: "We have three more questions to help us make a\n recommendation just for you.",
            or: "Or",
            skip: "Skip recommendations",
            youAreReady: "You are ready to adjust your benefits."
        },
        healthPlanDetails: {
            title: "Let’s talk about medical expenses",
            subTitle: "Tell us about your health insurance—",
            budget: "We’ll keep your budget in mind",
            subTitleFinancial: "Tell us a bit about your financial situation—",
            doesYourHealth: "Do you feel that your deductible is affordable?",
            yes: "Yes",
            no: "No",
            imNot: "I'm not covered by health insurance",
            whenVisitingDoctor: "When visiting the doctor or picking up a prescription...",
            iPay: "I typically pay copays",
            copays: "copays",
            theFullCost: "I typically pay the full cost until I reach my deductible",
            iDontWant: "I don't want a recommendation.",
            skip: "Skip this",
            income: {
                disposableIncome: "Dispossable Income",
                afterYouPay: "After you pay for your ",
                necesities: "necessities",
                houseingCar: " (housing, car, loans, utilities, & food), about how\nmuch money do you have leftover",
                eachMonth: "each month",
                forDiscretionary: " for discretionary spending?",
                disclaimer: "*Don't worry, we won't share this information with your employer.",
                selectOne: "select one",
                opt1: "I spend it all on necessities",
                opt2: "I'm able to buy 1-2 \"nice to haves\" each month",
                opt3: "I generally can purchase things I want without thinking too much",
                opt4: "I can purchase what I want and add to my savings",
            },
        },
        plan: {
            title: "Our recommendation for you",
            basedOn: "Based on the information you provided, we recommend the following. Use the sliders to select or adjust your plan’s payouts and who’s covered.",
            basedOnAlternate: "Based on the information you provided, we recommend the",
            basedOnAlternate2: "You can choose or adjust your plan and who's covered below—",
            basedOnModal: "See why we recommended this plan",
            valuePlan: "Value Plan",
            enhancedPlan: "Enhanced Plan",
            premierPlan: "Premier Plan",
            enhanced: "Enhanced",
            value: "Value",
            premier: "Premier",
            getTips: "and get tips for customizing each category→",
            descriptionGreen: "See why we chose these amounts",
            titleSkipped: `Select your ${LABEL} benefits`,
            descriptionSkipped: "Need some help? Answer 3 questions to get a",
            descriptionGreenSkipped: "personalized recommendation",
            conditions: "Conditions",
            recommended: "Recommended",
            iWantToCover: "I want to cover",
            costPer: "Cost per",
            employerContrib: "Employer contribution",
            employeeContrib: "Your cost",
            coverOpt1: "Myself",
            coverOpt2: "My spouse and me",
            coverOpt3: "My child(ren) and me",
            coverOpt4: "My family and me",
            coverOpt2Under: "My __spouse__ and me",
            coverOpt3Under: "My __child(ren)__ and me",
            costPerOpt1: "Paycheck",
            justAMoment: "Just a moment...",
            jamDescr: "We're personalizing your plan",
            okay: "Okay, got it",
            categoryExp: "Category details",
            moderate: "Moderate",
            severe: "Severe",
            catastrophic: "Catastrophic",
            emailMyRecommendation: "Email my recommendation",
            emailMyRecommendationDescr: "Please enter your email address below to receive a copy of your recommendations—",
            emailAddress: "Email address",
            cancel: "Cancel",
            submit: "Submit",
            emailSubmitted: "Your email has been sent!",
            goToYourEnrollmentPlat: `Visit your enrollment platform to submit your ${LABEL} selections. If you'd like, you can take these recommendations to go and reference them when you sign up.`,
            readyToMakeYourSelec: "Ready to enroll?",
            printRecomm: "Print recommendations",
            emailRecomm: "Email recommendations",
            dontSeeCoverageTier: "Don't see a coverage tier? Make sure to",
            dontSeeCoverageTierSelect: "Don't see a dependent coverage tier? Go back and",
            editYourFamily: "edit your family details.",
            goBackAndEdit: "go back and edit",
            yourFamilyDetails: "your family details.",
            descrM: "Examples include simple fractures, lacerations, dehydration, and kidney stones.",
            descrS: "Examples include appendicitis, compound fractures, pulmonary embolism, and torn ACL.",
            descrC: "Examples include malignant lung cancer, heart attack, stroke, and major organ failure.",
            seeWhyWeChoseTitle: "Recommendation overview",
            modalP1: `Your needs are unique to you. That’s why we made ${LABEL}’s plans customizable—so you can choose the payout amounts that are right for you. We assess a number of different factors to make a personalized recommendation. Here’s how each factor comes into play.`,
            modalP2Title: "Age",
            modalP2: `The types and severity of health issues we face changes over time. As we get older, the risk of serious and dangerous medical issues increases, so we generally recommend increasing payout amounts for the Severe and Catastrophic benefit categories with age. On the other hand, when we are younger and tend to be more active, ${LABEL} recommends higher payout amounts in the Moderate benefit category.`,
            modalP3Title: "Family status",
            modalP3: "Your family makeup helps us understand who is eligible for coverage and make some assumptions about their health risks. Given their zest for life, children generally tend to have less serious but more frequent injuries and illnesses, so if you have children we recommend higher Moderate payout amounts.",
            modalP4Title: "Medical expenses",
            modalP4: "How you feel about the affordability of your primary health plan’s deductible and your share of costs give us a sense of your financial exposure in the event of an unexpected health issue. If you have concerns about your deductible and / or health cost responsibilities we recommend higher payouts across all three categories.",
            modalP5Title: "Your budget",
            modalP5: `Your discretionary income helps us recommend a plan with premiums that work for your budget. If you don’t have much left over at the end of the month, we might recommend a ${LABEL} plan with a more affordable premium. For those with more disposable income, we may recommend more coverage in line with other factors like your age and family status.`,
            seeWhyWeRecommendedTitle: "Why we recommended this plan",
            recommendedForYou: "-Recommended-",
            iDontWant: `I don't want ${LABEL} coverage`,
            or: "or",
            yourOpenEnrollment: "Your open enrollment begins on $1. Come back then to finalize your elections. Don't worry, we'll save your progress for when you come back.",
            paycheck: 'paycheck',
            perPaycheck: 'Per paycheck',
            monthly: 'Monthly',
            month: 'month',
            employerContribution: "Employer contribution",
            payouts: "Payouts",
            important: "IMPORTANT",
            basedOnBenefit: "Based on your age, you will receive $1% of any benefit you select here.",
            nextSteps: "Next steps",
            includesDomestic: "Includes domestic partnerships and civil unions.",
            childrenAreElegible: "Children are eligible\nup to age 26.",
            paycheckInfo: "Estimated based on a payroll frequency of $1 payroll deductions per year, and may not match exact payroll withdrawals. Contractual premiums are on a monthly basis.",
            sliderWarningLabel: "Please review.",
            sliderWarningDescription: "Your benefit payout for {{plan1}} must be less than {{plan2}}."
        },
        yourBrellaPlanAlsoIncludes: {
            title: "Optional riders",
            yourBrellaPlan: "Your plan also covers these conditions—",
            payoutListed: "the payout for these conditions is listed.",
            chronicDetails1: "Receive coverage for over ",
            chronicDetails2: " additional $riderLabel conditions spanning asthma, diabetes, epilepsy, and many more.",
            mentalDetails1: "Additional coverage for ",
            mentalDetails2: " different $riderLabel health disorders like anxiety, bipolar, and depression.",
            nextButton: "Next",
            seeFullList: "See the full list of conditions",
        },
        success: {
            title: "Thanks, $1!",
            description: "Your information has been successfully submitted.",
            lookOut: `Look out for an email from us confirming your elections. As\nalways you can reference your benefit details here in your\n${LABEL} online account.`,
            allSet: "You're all set!",
            yourChanges: "Your changes take effect starting ",
            return: "Return to my account"
        },
        coverageDetails: {
            title: "Coverage details",
            benefitAmounts: "Benefit amounts",
            additionalCoverage: "Additional coverage",
            moderate: "Moderate",
            severe: "Severe",
            catastrophic: "Catastrophic",
            whosCovered: "Who's covered",
            note: "Note",
            youveAlready: `You've already made your ${LABEL} elections, If you need to make a change, `,
            reachOut: "reach out to our technical support team →",
        },
        paymentSuccessful: {
            youAreAllSet: "You're all set!",
            yourBrellaCoverage: `Your ${LABEL} coverage has been selected and your plan details are below—`,
            coverageEffectiveDate: "Coverage effective date",
        },
        paymentRequired: {
            weStillHavent: "We still haven't received your payment",
            weCouldntProcess: "We couldn't process your payment",
            pleaseReview: `Please review the details below and reach out to ${LABEL}'s technical support team if you have any questions or need help making a payment.`,
            paymentIsStillNeeded: "Payment is still needed for your coverage to be effective.",
            weHaventReceived: `We haven’t received payment from you. Payment needs to be made in order for your ${LABEL} coverage to take effect.`,
            effectiveDateSubject: "effective date subject to payment being received",
            effectiveDate: "Effective date",
            iStillWantToMail: "I still want to mail a paper check",
            youHaveNoEPayment: "You have no e-payment option set up for the initial payment!",
            addBankAccount: "Add bank account",
            addCreditCard: "Add credit card",
            paymentIssue: "Payment issue.",
            or: "or",
            cardNumber: "Card number",
            expiration: "Expiration",
            cvc: "CVC",
            country: "Country",
            bankAccount: "Bank account",
            creditCard: "Credit Card",
            iDontWantToMake: "I don't want to make an e-payment. I will mail a check instead.",
            seeInstructions: "See instructions.",
            mailInYourPayment: "Mail in your payment",
            preferToMailInA: "Prefer to mail in a check? Please download the PDF and enclose it with your payment—",
            checksShouldBe: `Checks should be payable to Brella Services Inc.`,
            mailTo: "Mail To",
            downloadPDF: "Download PDF",
            setItAndForgetIt: "Set it and forget it",
            enableAutoPayToSave: "Enable AutoPay to save your payment method and have your monthly premium charged automatically!",
            weWillNotifyYou: "We’ll still notify you before each payment date, and you can change your AutoPay settings at any time.",
            cancel: "Cancel",
            confirm: "Confirm",
            autoPay: "AutoPay",
            autoPayIsOn: "AutoPay is on",
            enableAutoPay: "Enable AutoPay",
            turnOffAutoPay: "Turn off AutoPay?",
            areYouSureYouWantToTurnOff: "Are you sure you want to turn off automatic payments? You’ll need to make payments manually going forward.",
            savePaymentMethod: "Save payment method for future use",
            iAuthorizeBrellaServices: `I authorize Brella Services, Inc. to automatically charge this payment source for all future invoices. You can cancel automatic payments or change settings at any time.`,
            addBankAccountError: "There was an error trying to add your bank account.",
        },
        eligibilityLoss: {
            thereIsAn: "There’s an issue with your eligibility",
            basedOnThe: `Based on the information we’ve been provided, you are no longer eligible for ${LABEL} coverage at this time.`,
            theCoverage: "The coverage you selected to start on",
            willNotTake: "will not take effect.",
            ifYourInitial: "If your initial premium payment has been made, it will be refunded to your original method of payment.",
            ifYouFeel: "If you feel this is incorrect, please reach out to your employer or",
            brellaConcierge: `${LABEL} Concierge`,
            forAdditional: "for additional information and next steps.",
        },
        enrollmentEnded: {
            looksLike: "Looks like you don’t want",
            brellaAnymore: `${LABEL} anymore..`,
            weHave: `We’ve reached out several times to remind you about an outstanding payment for the ${LABEL} coverage you requested. Because we haven’t received a payment for more than 31 days, the ${LABEL} coverage you requested has been cancelled.`,
            ifYouDecide: `If you decide you want ${LABEL} in the future, please note that a benefit waiting period will apply. `,
            contactOur: "Contact our technical support team",
            forMore: " for more information!",
        },
        paymentGeneral: {
            mo: "mo",
            paycheck: "paycheck",
        },
        confirmPaymentModal: {
            confirmYourPayment: "Confirm your payment",
            pleaseConfirmTheDetails: "Please confirm the details below before you submit your payment—",
            amount: "Amount",
            date: "Date",
            paymentMethod: "Payment Method",
            submitPayment: "Submit payment",
            cancel: "Cancel",
        },
        confirmRemovePendingVerification: {
            header: "Are you sure you want to use a different payment method?",
            contentSwitching: "Switching your payment method will result in the loss of your bank account's verification status. It can take up to an additional 2 business days to re-verify your bank account.",
            contentDeleting: "Deleting your payment method will result in the loss of your bank account's verification status. It can take up to an additional 2 business days to re-verify your bank account.",
            confirm: "I'm sure",
            cancel: "Cancel",
        }
    },
    [languages.SPANISH]: {
        next: "Paso Siguiente",
        dropdownOptions: {
            female: "Femenino",
            male: "Masculino",
            mobile: "Teléfono celular",
            home: "Teléfono fijo",
            standard: "Estándar",
            domesticPartner: "Pareja",
            spouse: "Cónyuge",
            child: "Hijo(a)",
        },
        reviewPersonalInfo: {
            title: "Información personal",
            homeAddress: "Tu dirección",
            streetAddress: "Dirección",
            zipCode: "Código postal",
            state: "Estado",
            aptSuiteOther: "Apartamento",
            city: "Ciudad",
            yourDetails: "Tus datos",
            firstName: "Primer nombre",
            middleName: "Segundo nombre",
            middleInitial: "Inicial del segundo nombre",
            lastName: "Apellido",
            suffix: "Sufijo",
            dob: "Fecha de nacimiento",
            ssn: "Número de seguro social",
            daytimePhone: "Número de teléfono (día)",
            phoneType: "Tipo de teléfono",
            gender: "Género",
            submit: "Submit",
            theGreyedOut: "Los campos que aparecen deshabilitados fueron proporcionados por tu empleador, por favor, contáctalo si es necesario que sean corregidos o actualizados.",
            invalidPhoneNumber: "Teléfono inválido",
            invalidZipCode: "Código postal inválido",
            genericErrTitle: "¡Huy!",
            genericErr: "Por favor, corrige los errores anteriores antes de continuar.",
            important: "IMPORTANTE"
        },
        reviewFamily: {
            title: "Familia",
            streetAddress: "Dirección",
            zipCode: "Código postal",
            state: "Estado",
            aptSuiteOther: "Apartamento",
            city: "Ciudad",
            dependents: "Tu familia",
            spouse: "Cónyuge",
            addChildT: "Agregar Hijo",
            addSpouse: "Agregar Esposo/a",
            addDependent: "Añadir un(a) dependiente",
            firstName: "Primer nombre",
            middleName: "Segundo nombre",
            middleInitial: "Inicial del segundo nombre",
            lastName: "Apellido",
            suffix: "Sufijo",
            dob: "Fecha de nacimiento",
            ssn: "Número de seguro social",
            role: "Rol",
            relationship: "Relación con el empleado",
            domesticPartner: "Pareja",
            address: "Dirección",
            gender: "Género",
            sameAsEmployee: "La misma del empleado",
            details: "Detalles",
            cancel: "Cancelar",
            submit: "Submit",
            save: "Guardar",
            optional: "opcional",
            theGreyedOut: "Los campos que aparecen deshabilitados fueron proporcionados por tu empleador, por favor, contáctalo si es necesario que sean corregidos o actualizados.",
            invalidPhoneNumber: "Teléfono inválido",
            invalidZipCode: "Código postal inválido",
            note: "Atención: ",
            delete: "Eliminar",
            confirmDelete: "Confirmar la eliminación",
            dependent: "El dependiente ",
            willBeRemoved: " será eliminado.",
            theCoverageTierDep: " La cobertura seleccionada no incluye dependientes.",
            editDetails: "Editar detalles ",
            goBack: "regresa y cambia",
            inOrderForYour: "Para que tu hijo(a) de más de $1 años esté cubierto, lo siguiente debe aplicar:",
            cIsIncapable: "Hijo(a) incapaz de tener un empleo autosuficiente debido a una discapacidad mental o física; y",
            cIsChiefly: "Hijo(a) depende principalmente de ti para su manutención y apoyo; y",
            cProofIs: `Prueba de la discapacidad  requerida por ${LABEL} en el momento de hacer un reclamo.`,
            iAgree: 'Confirmo que mi hijo cumple con los criterios anteriormente mencionados.',
            child: "Hijo(a)",
            genericErrTitle: "¡Huy!",
            genericErr: "Por favor, corrige los errores anteriores antes de continuar.",
            basedOnDependentAge: "con base en la edad de tu cónyuge, esta persona recibirá el $1% de los beneficios que selecciones aquí.",
            important: "IMPORTANTE",
            duplicateDependent: "Ya existe un Dependiente con esta información",
            requestError: "Hubo un error en el pedido",
            firstNameUpdateError: "El Nombre no se puede cambiar.",
            lastNameUpdateError: "El Apellido no se puede cambiar.",
            birthdateUpdateError: "La fecha de nacimiento no se puede cambiar.",
            ssnUpdateError: "El número de seguro social no se puede cambiar.",
            roleUpdateError: "El rol no se puede cambiar",
            studentStatus: "Estatus de estudiante",
            studentStatusFullTime: "Tiempo completo",
            studentStatusPartTime: "Tiempo parcial",
            studentStatusNotAStudent: "No es estudiante",
            inOrderToDetermin: "Para determinar si su hijo(a) califica para ser cubierto, por favor revise y confirme lo siguiente—",
            inOrderForChildToQualify: `Para que su hijo(a) califique para cobertura de ${LABEL}, debe aplicar lo siguiente—`,
            iAckTheCriteriaAppliesToMyChild: 'Reconozco que las condiciones detalladas arriba aplican a mi hijo',
            pleaseNoteAboveCriteria: "Ten en cuenta que al menos uno de los criterios anteriores debe cumplirse para hacer clic en guardar.",
            cIsUnmarried: "Hijo(a) no está casado no tiene dependientes propios; y",
            cIsFLResident: "Hijo(a) is residente de Florida o es estudiante (debe ser indicado en los campos de arriba).",
        },
        wouldYouMind: {
            title: "$1, vamos a personalizar tu plan",
            subTitle: "Tenemos tres preguntas más que nos ayudarán\na darte una recomendación específica",
            or: "Or",
            skip: "Skip recommendations",
            youAreReady: "You are ready to adjust your benefits."
        },
        healthPlanDetails: {
            title: "Hablemos de gastos médicos",
            subTitle: "Cuéntanos de tu seguro médico...",
            budget: "Tendremos en cuenta tu presupuesto",
            subTitleFinancial: "Cuéntanos un poco sobre tu situación financiera:",
            doesYourHealth: "¿Crees que su deducible es asequible?",
            yes: "Sí",
            no: "No",
            imNot: "No tengo seguro médico",
            whenVisitingDoctor: "Cuando visitas al doctor o recoge una receta médica",
            iPay: "Normalmente pago los copagos",
            copays: "copago",
            theFullCost: "Normalmente pago el costo total hasta que alcanzo mi deducible.",
            iDontWant: "No quiero una recomendación.",
            skip: "Saltar este paso",
            income: {
                disposableIncome: "Ingresos disponibles",
                afterYouPay: "Después de cubrir tus ",
                necesities: "necesidades",
                houseingCar: " (vivienda, auto, préstamos, servicios públicos, comida, entre otros),\n¿cuánto dinero te queda ",
                eachMonth: "cada mes",
                forDiscretionary: " para gastos opcionales?",
                disclaimer: "*No te preocupes, no compartiremos esta información con tu empleador.",
                selectOne: "seleccione una",
                opt1: "Lo gasto todo en mis necesidades",
                opt2: "Puedo comprar 1 o 2 artículos complementarios cada mes",
                opt3: "Generalmente puedo comprar lo que quiero sin pensarlo demasiado",
                opt4: "Puedo comprar lo que quiera y continuar ahorrando",
            },
        },
        plan: {
            title: "Nuestra recomendación para ti",
            basedOn: "Con base en la información proporcionada, te recomendamos lo siguiente: utiliza los controles deslizantes para seleccionar o ajustar los desembolsos de tu plan y a quién cubriría.",
            basedOnAlternate: "Con base en la información proporcionada, te recomendamos el plan",
            basedOnAlternate2: "A continuación, puedes elegir o ajustar tu plan y a quién cubre.",
            basedOnModal: "Descubre por qué recomendamos este plan.",
            valuePlan: "Value",
            enhancedPlan: "Enhanced",
            premierPlan: "Premier",
            enhanced: "Enhanced",
            value: "Value",
            premier: "Premier",
            getTips: "y recibe consejos para personalizar cada categoría",
            descriptionGreen: "Descubre por qué elegimos estas cantidades",
            titleSkipped: `Seleccione sus beneficios ${LABEL}`,
            descriptionSkipped: "¿Necesitas ayuda? Responde 3 preguntas para recibir una",
            descriptionGreenSkipped: "recomendación personalizada→",
            conditions: "Condiciones",
            recommended: "Recomendado",
            iWantToCover: "Quiero cobertura para:",
            costPer: "Costo por",
            employerContrib: "Contribución de tu empleador",
            employeeContrib: "Tu costo",
            coverOpt1: "A mí mismo(a)",
            coverOpt2: "Mi cónyuge y yo",
            coverOpt3: "Mi(s) hijo(s) y yo",
            coverOpt4: "Mi familia y yo",
            coverOpt2Under: "Mi __cónyuge__ y yo",
            coverOpt3Under: "Mi(s) __hijo(s)__ y yo",
            costPerOpt1: "Cheque",
            justAMoment: "Un momento...",
            jamDescr: "Estamos personalizando tu plan",
            okay: "Entendido",
            categoryExp: "Explicación de categorías",
            moderate: "Moderado",
            severe: "Grave",
            catastrophic: "Catastrófico",
            emailMyRecommendation: "Enviar mi recomendación por correo electrónico",
            emailMyRecommendationDescr: "Por favor, ingresa tu dirección de correo electrónico para recibir una copia de tus recomendaciones.",
            emailAddress: "Dirección de correo electrónico",
            cancel: "Cancelar",
            submit: "Enviar",
            emailSubmitted: "Tu correo electrónico ha sido enviado",
            goToYourEnrollmentPlat: `Visita el programa de inscripción para enviar tus selecciones de ${LABEL}. Si lo deseas, puedes consultar estas recomendaciones cuando te inscribas.`,
            readyToMakeYourSelec: "¿Listo para inscribirte?",
            printRecomm: "Imprimir las recomendaciones",
            emailRecomm: "Enviar por correo las recomendaciones",
            dontSeeCoverageTier: "¿No ves un nivel de cobertura para dependientes? ",
            dontSeeCoverageTierSelect: "¿No ves un nivel de cobertura? Regresa y ",
            editYourFamily: "edita los detalles de tu familia.",
            goBackAndEdit: "Regresa y edita",
            yourFamilyDetails: "los datos de tu familia.",
            descrM: "Condiciones que requieren una visita corta a la sala de emergencias or diagnóstico urgente",
            descrS: "Condiciones serias que frecuentemente requieren cirugía o tratamiento intensivo",
            descrC: "Condiciones peligrosas o mortales que requieren intervención médica urgente",
            seeWhyWeChoseTitle: "Sinopsis de la recomendación",
            modalP1: `Tus necesidades son únicas. Por eso los planes de ${LABEL} son personalizables, para que puedas elegir los montos de desembolso que más te convengan. Evaluamos una serie de factores para hacerte una recomendación a tu medida. A continuación, te explicamos cómo entra en juego cada factor.`,
            modalP2Title: "Edad",
            modalP2: `Los problemas de salud que enfrentamos cambian, en tipo y gravedad, con el tiempo. A medidaque envejecemos, aumenta el riesgo de problemas médicos graves y peligrosos, por lo que generalmente recomendamos aumentar los montos de los desembolsos para las categorías de beneficios Graves y Catastróficos, en función de la edad. Por otro lado, cuando somos más jóvenes y tendemos a ser más activos, ${LABEL} recomienda montos de desembolsos más altos en la categoría de beneficios Moderados.`,
            modalP3Title: "Situación familiar",
            modalP3: "La estructura de tu familia nos ayuda a entender quién es apto para la cobertura y a hacer algunas suposiciones sobre sus riesgos de salud. Por su energía y entusiasmo, los niños tienden a tener lesiones y enfermedades menos graves —pero más frecuentes— por lo que te recomendamos montos de desembolsos más altos para categoría Moderada, si tienes hijos.",
            modalP4Title: "Gastos médicos",
            modalP4: "Tu opinión sobre la asequibilidad del deducible de tu plan de salud primario y tus gastos nos dauna idea de tu vulnerabilidad financiera en caso de un problema de salud inesperado. Si te preocupa tu deducible y las responsabilidades de los costos de salud, te recomendamos montos de desembolsos más altos en las tres categorías.",
            modalP5Title: "Tu presupuesto",
            modalP5: `Tu ingreso discrecional nos ayuda a recomendar un plan con primas adaptadas a tu presupuesto. Si no te sobra mucho a fin de mes, podríamos recomendarte un plan de ${LABEL} con un monto de prima más asequible. A medida que tus ingresos disponibles aumentan, podemos recomendar una mayor cobertura en función de otros factores como tu edad y tu situación familiar.`,
            seeWhyWeRecommendedTitle: "Por qué recomendamos este plan",
            recommendedForYou: "- Recomendado para usted -",
            iDontWant: `no quiero la cobertura de ${LABEL}`,
            or: "o",
            yourOpenEnrollment: "Tu período inscripción abierta comenzará el $1. Vuelve en esta fecha para completar tu selección. No te preocupes, guardaremos tu progreso para cuando regreses.",
            paycheck: 'nómina de pago',
            perPaycheck: 'Por nómina de pago',
            monthly: 'Mensual',
            month: 'mes',
            employerContribution: "Contribución de tu empleador",
            payouts: "Desembolsos",
            important: "IMPORTANTE",
            basedOnBenefit: "con base en tu edad, recibirás el $1 % de cualquier beneficio que selecciones aquí.",
            nextSteps: "Pasos siguientes",
            includesDomestic: "Incluye parejas y uniones civiles.",
            childrenAreElegible: "Hijos(as) cubiertos hasta los 26 años.",
            paycheckInfo: "Esta cotización se basa en una frecuencia de $1 deducciones de nómina al año, y puede no coincidir con los retiros de nómina exactos. Las primas contractuales son mensuales.",
            sliderWarningLabel: "Revisa esto, por favor.",
            sliderWarningDescription: "El pago de tu desembolso para {{plan1}} debe ser inferior a {{plan2}}."
        },
        yourBrellaPlanAlsoIncludes: {
            title: "Clausulas opcionales",
            yourBrellaPlan: "Tu plan también cubre estas enfermedades",
            payoutListed: " y su desembolso está enumerado.",
            chronic: "Crónicas",
            mental: "Mentales",
            chronicDetails1: "Recibe cobertura para más de ",
            chronicDetails2: " enfermedades crónicas adicionales que abarcan: asma, diabetes, epilepsia y muchas más.",
            mentalDetails1: "Cobertura adicional para ",
            mentalDetails2: " trastornos de salud mental como ansiedad, trastorno bipolar y depresión.",
            nextButton: "Siguiente",
            seeFullList: "Ver la lista completa de enfermedades",
        },
        success: {
            title: "¡Gracias, $1!",
            description: "Your information has been successfully submitted.",
            lookOut: `Look out for an email from us confirming your elections. As always you can reference your benefit details here in your ${LABEL} online account.`,
            allSet: "All set!",
            yourChanges: "Your changes take effect starting ",
            return: "Return to my account"
        },
        coverageDetails: {
            title: "Coverage details",
            benefitAmounts: "Benefit amounts",
            additionalCoverage: "Additional coverage",
            moderate: "Moderate",
            severe: "Severe",
            catastrophic: "Catastrophic",
            whosCovered: "Who's covered",
            chronic: "Chronic",
            mental: "Mental",
            note: "Note",
            youveAlready: `You've already made your ${LABEL} elections, If you need to make a change, `,
            reachOut: "reach out to our technical support team →",
        },
        paymentSuccessful: {
            youAreAllSet: "¡Todo listo!",
            yourBrellaCoverage: `Tu cobertura ${LABEL} ha sido seleccionada y los detalles de tu plan están a continuación:`,
            coverageEffectiveDate: "Fecha de entrada en vigor de la cobertura",
        },
        paymentRequired: {
            weStillHavent: "We still haven't received your payment",
            weCouldntProcess: "No hemos podido procesar tu pago",
            pleaseReview: `Revisa los detalles que aparecen a continuación y ponte en contacto con el equipo de Conserjería de ${LABEL} si tienes alguna pregunta o necesitas ayuda para realizar el pago.`,
            paymentIsStillNeeded: "No hemos recibido tu pago y este es necesario para que tu cobertura sea efectiva.",
            weHaventReceived: `Es necesario realizar el pago para que tu cobertura de ${LABEL} entre en vigor.`,
            effectiveDate: "Fecha de entrada en vigor",
            effectiveDateSubject: "Fecha de entrada en vigor sujeta a la recepción del pago",
            iStillWantToMail: "I still want to mail a paper check",
            youHaveNoEPayment: "¡No tienes configurada la opción de pago electrónico para tu pago inicial!",
            addBankAccount: "Añade tu cuenta bancaria",
            addCreditCard: "Añade tu tarjeta de crédito",
            paymentIssue: "Problema de pago.",
            or: "o",
            bankAccount: "Cuenta bancaria",
            creditCard: "Tarjeta de crédito",
            cardNumber: "Número de tarjeta",
            expiration: "Fecha de vencimiento",
            cvc: "Código de seguridad",
            country: "País",
            iDontWantToMake: "No quiero hacer un pago electrónico. En su lugar, enviaré un cheque por correo.",
            seeInstructions: "Consulta las instrucciones.",
            mailInYourPayment: "Envía tu pago por correo",
            preferToMailInA: "¿Prefieres enviar un cheque por correo? Descarga el documento PDF y adjúntalo a tu pago.",
            checksShouldBe: `Los cheques deben ser pagaderos a Brella Services Inc.`,
            mailTo: "Envíalo por correo a:",
            downloadPDF: "Descargar PDF",
            // Autopay:
            setItAndForgetIt: "Configúralo y olvídalo",
            enableAutoPayToSave: "Activa el Pago Automático para guardar tu método de pago y que tu prima mensual sea debitada automáticamente.",
            weWillNotifyYou: "Te seguiremos avisando antes de cada fecha de pago, y puedes cambiar la configuración del Pago Automático en cualquier momento.",
            cancel: "Cancelar",
            confirm: "Confirmar",
            autoPay: "Pago Automático",
            autoPayIsOn: "Pago Automático activado",
            enableAutoPay: "Pago Automático activado",
            turnOffAutoPay: "¿Desactivar el Pago Automático?",
            areYouSureYouWantToTurnOff: "¿Estás seguro de que quieres desactivar los pagos automáticos? A partir de ahora tendrás que realizar los pagos manualmente.",
            savePaymentMethod: "Guardar esta forma de pago para uso futuro",
            iAuthorizeBrellaServices: `Autorizo a Brella Services, Inc. a debitar automáticamente esta fuente de pago para todas las facturas futuras. Puedes cancelar los pagos automáticos o cambiar la configuración en cualquier momento.`,
            addBankAccountError: "Ha habido un error al añadir la cuenta bancaria.",
        },
        eligibilityLoss: {
            thereIsAn: "There’s an issue with your eligibility",
            basedOnThe: `Based on the information we’ve been provided, you are no longer eligible for ${LABEL} coverage at this time.`,
            theCoverage: "The coverage you selected to start on",
            willNotTake: "will not take effect.",
            ifYourInitial: "If your initial premium payment has been made, it will be refunded to your original method of payment.",
            ifYouFeel: "If you feel this is incorrect, please reach out to your employer or",
            brellaConcierge: `${LABEL} Concierge`,
            forAdditional: "for additional information and next steps.",
        },
        enrollmentEnded: {
            looksLike: "Looks like you don’t want",
            brellaAnymore: `${LABEL} anymore..`,
            weHave: `We’ve reached out several times to remind you about an outstanding payment for the ${LABEL} coverage you requested. Because we haven’t received a payment for more than 31 days, the ${LABEL} coverage you requested has been cancelled.`,
            ifYouDecide: `If you decide you want ${LABEL} in the future, please note that a benefit waiting period will apply. `,
            contactOur: "Contact our technical support team",
            forMore: " for more information!",
        },
        paymentGeneral: {
            mo: "mes",
            paycheck: "cheque",
        },
        confirmPaymentModal: {
            confirmYourPayment: "Confirma tu pago",
            pleaseConfirmTheDetails: "Confirma los siguientes datos antes de enviar el pago:",
            amount: "Monto",
            date: "Fecha",
            paymentMethod: "Método de pago",
            submitPayment: "Enviar el pago",
            cancel: "Cancelar"
        },
        confirmRemovePendingVerification: {
            header: "Are you sure you want to use a different payment method?",
            contentSwitching: "Switching your payment method will result in the loss of your bank account's verification status. It can take up to an additional 2 business days to re-verify your bank account.",
            contentDeleting: "Deleting your payment method will result in the loss of your bank account's verification status. It can take up to an additional 2 business days to re-verify your bank account.",
            confirm: "I'm sure",
            cancel: "Cancel",
        }
    }
}
