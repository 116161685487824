import React, {Component} from 'react';
import {Icon, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import {setLogout} from "../../actions/authActions";
import {withRouter} from "react-router-dom";
import {setLeftNavigation} from "../../actions/navigationActions";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
    }
};

class InvoiceDetailModalHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    nukeStore(){
        const {dispatch} = this.props;
        dispatch(setLogout())
        this.props.history.push({pathname: "/login"});
    }

    manageModal = (value) => {
        this.setState({open: value})
    }

    back = () => {
        this.props.dispatch(setLeftNavigation(true))
        this.props.history.goBack()
    }

    render() {
        const {modalTitle} = this.props;
        return (
            <React.Fragment>
                <Menu fixed='top' secondary pointing className="member-menuContainer">
                    <div style={{ width: '33%'}}>
                            <Icon
                                link
                                name="left arrow"
                                onClick={this.back}
                                style={{ padding: '10px 18px' }}
                            />
                    </div>
                    {modalTitle && (
                        <div style={{ width: '34%', textAlign: 'center', lineHeight: '44px', fontWeight: 'bold'}}>
                            {modalTitle}
                        </div>
                    )}
                    <div style={{ width: '34%', textAlign: 'right', lineHeight: '44px', paddingRight: '18px'}}><span className={"linkText"} onClick={this.back}><b>Done</b></span></div>
                </Menu>
                <div className={"member-header-shadow"}/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(InvoiceDetailModalHeader));
