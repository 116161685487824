import {Icon, Modal} from "semantic-ui-react";
import React from "react";
import {Button} from "../../components/base";

export const ConfirmChangeMethodModal = ({onClose, displayModal, submit}) => {

  return (
    <Modal size={'small'} onClose={onClose} open={displayModal} style={{maxWidth: '624px'}}>
      <Modal.Header>
      <span>
        Are you sure you want to change two-factor authentication method?
      </span>
        <Icon name="close" onClick={onClose} link />
      </Modal.Header>

      <Modal.Content style={{overflowY: 'auto'}} className={"small"}>
        <p style={{fontSize: '16px'}}>
          Re-configuring your authentication will invalidate your previous authentication method.
        </p>

        <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
          <Button basic primary onClick={onClose}>
            Cancel
          </Button>
          <Button primary
                  onClick={submit}>
            Continue
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}
