import React, {Component} from "react"
import {Dimmer, Divider, Form, Grid, Loader} from "semantic-ui-react";
import {Button, Header} from "./base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {convertDisplayDateFormatToServer,} from "../utils/common";
import Footer from "./footer";
import {setLeftNavigation} from "../actions/navigationActions";
import {clearVerify, setLogout, setVerifyName, setVerifyToken} from "../actions/authActions";
import {purgeState} from "../storePersistence";
import {clearInitialData} from "../actions/initialDataActions";
import {cleanupNewClaim} from "../actions/newClaimActions";
import {clearClaims} from "../actions/claimsActions";
import {clearPlanChange} from "../actions/planChangeActions";
import MainMenu from "./MainMenu";
import STRATEGIC_PARTNER from "../constants/strategicPartners"
import SupportEmailLink from "./base/support-email-link";
import {SSNInput, DOBInput} from "./custom-common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        verifyToken: state.auth.verifyToken
    }
};

class VerifyItsYou extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ssn: '',
            birthdate: '',
            errorMessage: '',
            submitting: false,
            dobError: false,
            ssnError: false,
            fetching: true
        }
    }

    async componentDidMount() {
        const { history } = this.props
        const token = this.token

        purgeState();
        this.props.dispatch(clearVerify())
        this.props.dispatch(setLogout())
        this.props.dispatch(clearInitialData())
        this.props.dispatch(cleanupNewClaim())
        this.props.dispatch(clearClaims())
        this.props.dispatch(clearPlanChange())
        this.props.dispatch(setLeftNavigation(false))

        if (!token) return history.push('/expiredRegisterToken')

        try {
            const response = await axios.post("/api/member/v2/verifyRegisterToken", {
                token
            });

            if (response.status !== 200) {
                throw Error(response.statusText)
            }

            const status = response.data.status

            switch (status) {
                case "used": {
                    history.push('/login')
                    break
                }
                case "unused": {
                    this.props.dispatch(setVerifyToken(token))
        this.setState({ fetching: false })
                    break
                }
                default: {
                    this.props.dispatch(setVerifyToken(token))
                    history.push('/expiredRegisterToken')
                    break
                }
            }
        } catch (e) {
            if (!!e && !!e.response && !!e.response.data) {
                console.log(e.response.data);
            } else {
                console.log({e});
            }

            history.push('/register')
        }
    }

    handleFormChange = (e, {name, value}) => {
        this.setState({
            [name]: value,
            error: false,
            errorMessage: '',
        });
    }

    handleSSNChange = (ssn) => {
        this.setState({
            ssn,
            ssnError: false,
        });
    }

    handleSubmit = async () => {
        this.setState({submitting: true});
        const {ssn, birthdate} = this.state;

        try {
            const response = await axios.post("/api/member/v2/verifyMember", {
                token: this.props.verifyToken,
                birthDate: convertDisplayDateFormatToServer(birthdate),
                socialSecurityNumber: ssn,
            }, {headers: {'Content-Type': 'application/json'}});
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            this.props.dispatch(setVerifyName(response.data.firstName))
            this.props.history.push(this.props.nextRoute);
        } catch (e) {
            if (!!e && !!e.response && !!e.response.data) {
                console.log(e.response.data);
            } else {
                console.log({e});
            }

            this.setState({
                submitting: false,
                invalidToken: true,
                errorMessage: "invalidInformation"
            })
        }

    }

    get token() {
        return new URLSearchParams(window.location.search).get("token")
    }

    setError = (msg) => {
        this.setState({
            dobError: msg,
        })
    }

    setSSNError = (value) => {
        this.setState({
            ssnError: value,
        })
    }

    render() {
        const {ssn, dobError, birthdate, submitting, fetching, ssnError} = this.state;

        if (fetching) {
            return <Dimmer active inverted><Loader>Loading...</Loader></Dimmer>
        }

        const hasError = this.state.errorMessage.length > 0;
        const missingData = !birthdate || !ssn;
        const disableSubmit = hasError || missingData || !!dobError || ssnError;
        const strings = {
            subTitle: "So we know it’s you, please tell us your—",
            birthdate: "Date of birth",
            ssn: "Social Security Number",
            nextButton: "Next step",
            weWereUnable: "We were unable to verify your information. Please try again.",
            stillHavingTr: "Still having trouble? Check with your employer, or contact us at",
            accNotFound: "Account not found for this converted lead",
        }
        const button = <Button primary content="Next Step" loading={submitting}
                               disabled={ssn.length === 0 || birthdate.length === 0 || !!hasError || !!dobError || submitting || ssnError}
                               onClick={this.handleSubmit}/>;
        return (
            <div className="member-main-container" style={{background: 'white'}}>
                <MainMenu center="Create account"/>
                <Divider hidden/><Divider hidden/><Divider hidden/><Divider hidden/>
                <Grid container stackable columns={1} centered>
                    <React.Fragment>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Header as='h2' textAlign='center' className="bold">
                                    {strings.subTitle}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='left' width={4} style={{padding: 0}}>
                                <Form>
                                    <Form.Group>
                                        <Form.Field required width={12}>
                                            <DOBInput
                                                value={!!birthdate ? birthdate : ""}
                                                label={strings.birthdate}
                                                setFormError={(msg) => {this.setError(msg)}}
                                                onChange={(e, { value }) => {
                                                    this.handleFormChange(e, {
                                                        name: "birthdate",
                                                        value: value,
                                                    });
                                                }}
                                                required
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group>
                                        <SSNInput
                                            label={strings.ssn}
                                            onChange={(_, value) => {
                                                this.handleSSNChange(value);
                                            }}
                                            required
                                            setSSNError={this.setSSNError}
                                            value={ssn}
                                            width={12}
                                        />
                                    </Form.Group>
                                    {hasError &&
                                        <div style={{padding: '1em', textAlign: 'left', backgroundColor: '#ffeeee'}}
                                             className={"small"}>
                                            <div style={{marginBottom: '0.5em'}}>
                                                <b>{strings.weWereUnable}</b>
                                            </div>
                                            <p>
                                                {strings.stillHavingTr}{' '}
                                                <span className={'spaceNoWrap'}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}</span>{" or"}<br/>
                                                <b><SupportEmailLink/></b>
                                            </p>
                                        </div>}
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </React.Fragment>
                </Grid>
                <Footer
                    showTopFooter={true} showCenterData={true}
                    loading={this.state.loading}
                    disabled={disableSubmit}
                    button={button}
                    onClickCallback={this.handleSubmit}
                />
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(VerifyItsYou));
