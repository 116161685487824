import {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import axios from "axios"
import {setLogout} from "../actions/authActions"

const mapStateToProps = (state, ownProps) => {
    return {
        loginInfo: state.auth.loginInfo,
        ...ownProps
    }
};

class Config extends Component {
    getToken = () => {
        return this.props.loginInfo ? this.props.loginInfo.authToken : null;
    };

    UNSAFE_componentWillMount() {
        const getToken = this.getToken;
        const getDispatcher = this.props.dispatch;
        axios.interceptors.request.use(function (config) {
            try {
                const authToken = getToken();
                if (!config.url.includes('http') && !!authToken) {
                    config.headers['authToken'] = authToken;
                }
            } catch (e) {
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error && error.response && error.response.status === 401) {
                getDispatcher(setLogout());
            }
            return Promise.reject(error);
        });

    }

    render() {
        return this.props.children
    }
}

export default withRouter(connect(mapStateToProps)(Config))
