import React, {Component} from "react"
import {Form, Grid} from "semantic-ui-react";
import {Button, Header} from "../../../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setFetchPlanRecommendation, setLeftoverMoney} from "../../../../actions/planChangeActions";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import {HEALTH_PLAN_DETAILS_ROUTE, resolveBrellaPlanPath} from "../../../../utils/common";

const mapStateToProps = (state, ownProps) => {
    const {planChange} = state;
    return {
        disposableIncomeOptions: planChange.disposableIncomeOptions,
        disposableIncomeSelect: planChange.leftoverMoney,
        planType: state.initialData.contract.planOffering,
        ...ownProps
    }
};

class DisposableIncome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleDisposableIncomeChange = (selectedKey) => {
        this.props.dispatch(setLeftoverMoney(selectedKey));
        this.props.dispatch(setFetchPlanRecommendation(true));
    };

    onNext = () => {
        const plan = resolveBrellaPlanPath(this.props.planType)
        this.props.history.push(plan)
    }

    onBackHandler = () => {
        this.props.history.push(HEALTH_PLAN_DETAILS_ROUTE);
    }

    render() {
        const {disposableIncomeOptions, disposableIncomeSelect} = this.props;
        const button = <Button primary onClick={this.onNext}
                       disabled={!disposableIncomeSelect}>Next step</Button>

        const leftoverMoneyOpts = disposableIncomeOptions.map((o) => {
            return {key: o.id, value: o.id, text: o.value};
        });

        return <React.Fragment>
            <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
            <div className="member-main-container" style={{background: 'white'}}>
                <ChangeSteps activeStepIdx={0}/>

                <Grid container stackable columns={1} centered>
                    <Grid.Column width={8} className={"pageContent"}>
                        <Grid stackable columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as="h2">We’ll keep your budget in mind</Header>
                                    <p>Tell us a bit about your financial situation—</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <p>
                                        After you pay for your <b>necessities</b> (housing, car, loans, utilities, & food), about how
                                        much money do you have leftover<b> each month</b>  for discretionary spending?
                                    </p>

                                    <Form>
                                        <Form.Field>
                                            <Form.Group grouped>
                                            {leftoverMoneyOpts.map((lm) => {
                                                return <React.Fragment key={lm.key}>
                                                    <Form.Radio label={lm.text} className={'radioNormalFontSize'}
                                                                value={lm.value}
                                                                checked={disposableIncomeSelect === lm.value}
                                                                onChange={() => this.handleDisposableIncomeChange(lm.key)}
                                                    />
                                                </React.Fragment>
                                            })}
                                            </Form.Group>
                                        </Form.Field>
                                    </Form>
                                    <p style={{paddingTop: '2em'}}><b>*Don't worry, we won't share this information with your employer.</b></p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </div>
            <Footer
                showTopFooter={true} showCenterData={true}
                button={button}
            />
        </React.Fragment>;
    }
}

export default withRouter(connect(mapStateToProps)(DisposableIncome));
