import {useState} from "react";
import {connect} from "react-redux";

import {
  MFAContainer,
  MFAGrid,
  MFAHeading,
  MFAText,
  MFAForm,
  MFALabel,
  MFAInput,
  MFATextError,
  MFAMethodValidatorMsg,
  MFAMethodValidatorContainer
} from "../../../styled";
import {isValidEmail} from "../../../../utils/utils";


const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    user: state.auth.userName,
  }
}

const EmailConfig = ({ toggleFooterActionDisabled, setEmailSetUpToMFA }) => {
  const [email, setEmail] = useState('')
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)

  const handleChange = (e) => {
    const { value } = e.target
    setEmail(value)
    setEmailSetUpToMFA(value)
    validateEmail(e)
  }

  const validateEmail = (e) => {
    const { value } = e.target
    const isValid = isValidEmail(value)
    setIsInvalidEmail(!isValid)
    toggleFooterActionDisabled(!isValid)
  }

  return (
    <>
      <MFAContainer className="sectionWrapper" maxWidth={"633px"}>
        <MFAGrid>

          <MFAGrid.Row>
            <MFAGrid.Column>
              <MFAHeading as="h2">Configure two-factor authentication</MFAHeading>
              <MFAText>
                Please provide the email address that we will utilize for two-factor authentication below—
              </MFAText>
            </MFAGrid.Column>
          </MFAGrid.Row>

          <MFAGrid.Row>
            <MFAGrid.Column>

              <MFAForm.Field
                className="verifyWrapper"
              >
                <MFALabel
                  className="verifyLabel requiredField"
                >
                  Email address
                </MFALabel>
                <MFAInput
                  type='email'
                  name='email'
                  large={300}
                  value={email}
                  onChange={handleChange}
                  onBlur={validateEmail}
                  error={isInvalidEmail}
                />
                <MFAMethodValidatorContainer>
                  <MFAMethodValidatorMsg>
                    {isInvalidEmail && (
                      <MFATextError>
                        Invalid email format
                      </MFATextError>
                    )}
                  </MFAMethodValidatorMsg>
                </MFAMethodValidatorContainer>
              </MFAForm.Field>
            </MFAGrid.Column>
          </MFAGrid.Row>

        </MFAGrid>
      </MFAContainer>
    </>
  )
}

export default connect(mapStateToProps)(EmailConfig);
