import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios'
import { processMessages } from './utils/messages'
import { resolveErrorMessage } from '../utils/common';

const MESSAGE_REFRESH_INTERVAL =  5 * 60 * 1000 // 5 minutes

export const MessagesContext = React.createContext()

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        syncMessages: !(state.initialData.hasRestrictedAccess || state.stopScreens.isStopscreenDisplayed),
    }
};

class MessagesProvider extends Component {
    state = {
        fetching: true,
        lastFetch: null,
        list: null,
        fetchingError: null,
    }

    timer = null

    componentDidMount() {
        const { syncMessages } = this.props

        if (syncMessages) {
            this.refresh()
        } else {
            this.setState({ fetching: false })
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    get hasUnread() {
        const { list } = this.state

        if (!list?.length) return false

        const unread = list.some(thread => thread?.messages?.some(msg => msg.isUnread))

        return unread
    }

    refresh = async () => {
        const { syncMessages } = this.props

        this.setState({
            fetching: true,
            fetchingError: null
        })

        try {
            clearInterval(this.timer)
            const result = await axios.post('/api/member/v2/prepareMessaging', null)
            this.setState({
                fetching: false,
                list: processMessages(result.data),
                lastFetch: `${new Date()}`,
            })
            if (syncMessages) {
                this.timer = setTimeout(() => {
                    this.refresh()
                }, MESSAGE_REFRESH_INTERVAL)
            }
        } catch (e) {
            this.setState({
                fetching: false,
                fetchingError: resolveErrorMessage(e, 'An unexpected error occurred.')
            })
        }
    }

    toggleUnread = (messageId, state = null) => {
        const { list } = this.state

        if (!list?.length) return

        const listIndex = list.findIndex(({ messages }) => messages.find(({ id }) => id === messageId))
        const messageIndex = list[listIndex].messages.findIndex(({ id }) => id === messageId)
        const { isUnread } = list[listIndex].messages[messageIndex]

        // Immutable Update for the message isUnread attribute keeping everything in order.
        const newList = [...list]
        const newThread = {
            ...newList[listIndex],
            messages: [...newList[listIndex].messages]
        }

        const newState = state !== null ? !!state : !isUnread

        const newMessage = {
            ...newThread.messages[messageIndex],
            isUnread: newState
        }

        newThread.messages[messageIndex] = newMessage
        newList[listIndex] = newThread

        this.setState({ list: newList })
    }

    render() {
        return (
            <MessagesContext.Provider
                value={{
                    ...this.state,
                    hasUnread: this.hasUnread,
                    refresh: this.refresh,
                    toggleUnread: this.toggleUnread,
                }}
            >
                {this.props.children}
            </MessagesContext.Provider>
        )
    }
}

export default connect(mapStateToProps)(MessagesProvider);
