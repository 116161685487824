import React from 'react';
import {Container, Image} from 'semantic-ui-react';
import Footer from "./footer";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getLocalization} from "../utils/localization";
import moment from "moment";
import {toggle as toggleGetHelpModal} from "../actions/getHelpActions";
import {usePreventBackNavigation} from "../hooks";

const mapStateToProps = ({initialData}, ownProps) => {
    const strings = getLocalization(initialData.user.lang);
    const effectiveDate = moment(initialData.certificate.effectiveDate, "YYYY-MM-DD")
        .format("MMM DD, YYYY");

    return {
        ...ownProps,
        effectiveDate,
        strings,
    }
};

const mapDispatchToProps = dispatch => ({
    toggleGetHelpModal: state => dispatch(toggleGetHelpModal(state)),
});

const EligibilityLoss = ({effectiveDate, toggleGetHelpModal, strings}) => {
    usePreventBackNavigation();

    return (
        <>
            <Container className={'stopScreens'}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{
                        height: "200px",
                        width: "200px",
                        backgroundColor: "#FFF7EC",
                        borderRadius: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Image style={{minWidth: 80, maxWidth: 80}}
                               centered src={process.env.PUBLIC_URL + '/images/icon_alert.png'} />
                    </div>
                </div>
                <h1 className={'vibranBlueHeader'} style={{fontSize: '32px', marginBottom: '5px', textAlign: "center"}}>
                    { strings.eligibilityLoss.thereIsAn }
                </h1>
                <br/>
                <p className={'neutral700Text'}>
                    { strings.eligibilityLoss.basedOnThe }
                </p>
                <p className={'neutral700Text'}>
                    { strings.eligibilityLoss.theCoverage } { effectiveDate } { strings.eligibilityLoss.willNotTake }
                </p>
                <p className={'neutral700Text'}>
                    { strings.eligibilityLoss.ifYourInitial }
                </p>
                <div className={'bkgFog'} style={{marginTop: '20px', padding: '20px'}}>
                    <p className={'neutral700Text'}>
                        { strings.eligibilityLoss.ifYouFeel } <a href onClick={() => toggleGetHelpModal(true)}>{ strings.eligibilityLoss.brellaConcierge }</a> { strings.eligibilityLoss.forAdditional }
                    </p>
                </div>
            </Container>
            <Footer showTopFooter={false} showCenterData={false} />
        </>
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EligibilityLoss));
