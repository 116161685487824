import { mobileActionTypes } from './actionTypes'

export function setUseWeb(newState) {
    return {
        type: mobileActionTypes.SET_USE_WEB,
        payload: newState
    }
}

export function setResetPasswordFlow(isResetPasswordFlow) {
    return {
        type: mobileActionTypes.SET_RESET_PASSWORD_FLOW,
        payload: isResetPasswordFlow
    };
}