import React from 'react'
import Steps from "../../../steps/steps";

export default function ChangeAddressSteps(props) {
    const stepProps = [
        {
            active: false,
            completed: false,
            disabled: true,
            label: "Life event",
        },
        {
            active: false,
            completed: false,
            disabled: true,
            label: "Change address",
        },
        {
            active: false,
            completed: false,
            disabled: true,
            label: "Confirm",
        }
    ];

    const {activeStepIdx} = props;
    for (let i = 0; i < activeStepIdx; i++) {
        stepProps[i].disabled = false;
        stepProps[i].completed = true;
    }
    stepProps[activeStepIdx].active = true;
    stepProps[activeStepIdx].disabled = false;

    return (
        <Steps stepProps={stepProps}/>
    )
}