import React, {Component} from 'react'
import {connect} from "react-redux";
import {Elements, StripeProvider} from "react-stripe-elements";


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        stripePublicKey: state.billing.stripePublicKey,
    }
};

class StripeWrapper extends Component {

    render() {
        const {stripePublicKey} = this.props;
        let content = <div></div>;
        if (stripePublicKey) {
            content = <StripeProvider apiKey={stripePublicKey}>
                <Elements>
                    <>
                     {this.props.children}
                    </>
                </Elements>
            </StripeProvider>;
        } else {
            console.error("No stripePublicKey")
        }

        return content;
    }
}

export default connect(mapStateToProps)(StripeWrapper);
