import React, {useState, forwardRef, useImperativeHandle, useEffect} from "react";
import {
  MFAContainer,
} from "../styled";
import Steps from "./steps/";
import {LABEL, MFA_TYPE} from "../constants";

const STEPS_CONFIG = [
  { btnLabel: "Next step", disabled: false, step: 0, backArrow: true },
  { btnLabel: "Next step", disabled: false, step: 1, backArrow: true  },
  { btnLabel: "Next step", disabled: true, step: 2, backArrow: false  },
  { btnLabel: "Finish", disabled: true, step: 3, backArrow: false  },
  { btnLabel: null, disabled: false, step: 4, backArrow: false  },
];

const MAX_CODE_LENGTH = 6;

const MfaConfig = forwardRef(function MfaConfig(props, ref) {
  const [methodType, setMethodType] = useState(props.mfaType);
  const STEPS_CONFIG_INIT = props.stepsConfigOverride ? props.stepsConfigOverride : STEPS_CONFIG

  const INIT_STEP = STEPS_CONFIG_INIT[0].step;
  const LAST_STEP = STEPS_CONFIG_INIT.length;

  //Steps
  const [stepConfig, setStepConfig] = useState(props.mfaEnabled && !props.stepsConfigOverride ? STEPS_CONFIG_INIT[4] : STEPS_CONFIG_INIT[0]);

  //Code Validation
  const [code, setCode] = useState('');
  const [validationErrors, setValidationErrors] = useState(false);

  useEffect(() => {
    if (props.addAnotherMethod) {
      goBackStepOne()
    }
  }, []);

   const onBackHandler = () => {
      if (stepConfig.step > INIT_STEP)
        setStepConfig(STEPS_CONFIG_INIT[stepConfig.step - 1]);
  };

  const onNextHandler = () => {
    if (stepConfig.step < LAST_STEP)
      setStepConfig(STEPS_CONFIG_INIT[stepConfig.step + 1]);
  };

  const selectMethodHandler = (methodSelected) => {
    setMethodType(methodSelected);
  }

  const codeHandler = (e) => {
    setValidationErrors(false)
    props.setValidateMfaCodeError(null);
    let sanitizedValue = e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    if (sanitizedValue.length > MAX_CODE_LENGTH || isNaN(sanitizedValue)) {
      props.setIsMfaProcessing(false)
      return
    };

    setCode(sanitizedValue)

    if(sanitizedValue.length === MAX_CODE_LENGTH){
      props.validateMfaCode(sanitizedValue);
    }
  };

  const sendCodeByEmailHandler = async () => {
    await props.sendCodeHandler();
  }

  const goBackStepOne = () => {
    setStepConfig(STEPS_CONFIG[0])
    props.resetSteps()
  };

  const validateForm = (code) => {
      if (code?.length < MAX_CODE_LENGTH || isNaN(code)) {
          setValidationErrors(true)
          props.setIsMfaProcessing(false)
          return false
      }
      setValidationErrors(false)
      return true;
  };


  useImperativeHandle(ref, () => {
    return {
      getStepConfig() {
        if (methodType === MFA_TYPE.EMAIL && stepConfig.step === 1) {
          stepConfig.btnLabel = LABEL.SEND_CODE
        } else {
          stepConfig.btnLabel = LABEL.NEXT_STEP
        }
        return stepConfig;
      },
      async backAction() {
        setValidationErrors(false)
        await onBackHandler();
        return stepConfig;
      },
      async nextAction() {
        await onNextHandler();
        return stepConfig;
      },
      getMethodSelected(){
        return methodType;
      },
      getMfaCodeEntered(){
        return validateForm(code) ? code : null;
      }
    };
  }, [stepConfig, methodType, code]);


  return (
    <>
        <MFAContainer>
        {stepConfig && (
          <Steps
            {...props}
            methodType={methodType}
            currentStep={stepConfig.step}
            selectMethodHandler={selectMethodHandler}
            code={code}
            codeHandler={codeHandler}
            validationErrors={validationErrors}
            STEPS_CONFIG_INIT={STEPS_CONFIG_INIT}
            validateForm={validateForm}
            changeMethodSettings={!!props.stepsConfigOverride}
            sendCodeByEmailHandler={sendCodeByEmailHandler}
            goBackStepOne={goBackStepOne}
          />
        )}
        </MFAContainer>
    </>
  );
});

export default MfaConfig;
