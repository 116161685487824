import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Checkbox, Divider, Grid, Table} from "semantic-ui-react";
import {Button, Header} from "../../../base";
import {connect} from "react-redux";
import {removeDependent, setDependent, setDependentList, setPrimaryAddressChange,} from "../../../../actions/planChangeActions";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {CHANGE_ADDRESS_FORM_ROUTE, WHEN_ROUTE} from "../../../../utils/common";
import moment from "moment";
import ChangeAddressSteps from "./changeAddressSteps";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        dependents: state.initialData.dependents,
        planChangeDependents: state.planChange.planChangeDependents,
        certificateHolder: state.initialData.certificateHolder,
        changePrimaryAddress: state.planChange.changePrimaryAddress,
    }
};

class ChangeAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkAll: false
        }
    }

    componentDidMount() {
        this.props.dispatch(setPrimaryAddressChange(true))
    }

    handleAllChecked = () => {
        const current = this.state.checkAll
        this.setState({
            checkAll: !current
        })
        if (current === true) {
            this.props.dispatch(setDependentList([]))
        } else {
            this.props.dispatch(setDependentList(this.props.dependents))
        }
    }

    /*handlePrimaryCheck = () => {
        const {changePrimaryAddress} = this.props;
        if (!!changePrimaryAddress) {
            this.setState({
                checkAll: false
            })
            this.props.dispatch(setPrimaryAddressChange(false))
        } else {
            this.props.dispatch(setPrimaryAddressChange(true))
        }
    }*/

    handleCheck = (dependent, index) => {
        const {planChangeDependents} = this.props;
        const isAlready = planChangeDependents.filter(dep => dep.memberId === dependent.memberId).length > 0;
        if (!!isAlready) {
            this.setState({
                checkAll: false
            })
            this.props.dispatch(removeDependent({idx: index}))
        } else {
            let dependentIdx = !!planChangeDependents ? planChangeDependents.length : 0;
            this.props.dispatch(setDependent(dependent, dependentIdx));
        }
    }

    onBackHandler = () => {
        this.props.history.replace(WHEN_ROUTE);
    }

    onNext = () => {
        this.props.history.push(CHANGE_ADDRESS_FORM_ROUTE)
    }

    render() {
        const {dependents, certificateHolder, planChangeDependents} = this.props;

        const button = <Button primary content="Next step" onClick={this.onNext}/>;
        const mNow = moment(Date.now());
        const mDate = moment(certificateHolder.birthDate)
        const primaryAge = mNow.diff(mDate, "years")

        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeAddressSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Header as={"h2"}>Who’s address needs to be updated?</Header>
                            <p className={"neutral700Text"}>Select which family members this address changes applies
                                too—</p>
                            <Divider hidden/>
                            <Table basic>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Checkbox checked={!!this.state.checkAll} onChange={() => {
                                                this.handleAllChecked()
                                            }}/>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>First name</b>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>Last name</b>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>Relationship</b>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>Age</b>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell><Checkbox checked={true} disabled={true}/></Table.Cell>
                                        <Table.Cell>{certificateHolder.firstName}
                                        </Table.Cell>
                                        <Table.Cell>{certificateHolder.lastName}</Table.Cell>
                                        <Table.Cell>Primary</Table.Cell>
                                        <Table.Cell>{primaryAge}</Table.Cell>
                                    </Table.Row>
                                    {(!!dependents && dependents.length > 0) && dependents.map(((dependent, i) => {
                                        const mNow = moment(Date.now());
                                        const mDate = moment(dependent.birthDate)
                                        const age = mNow.diff(mDate, "years")
                                        const isChecked = planChangeDependents.filter(dep => dep.memberId === dependent.memberId).length > 0;
                                        return <Table.Row key={i}>
                                            <Table.Cell><Checkbox checked={isChecked || this.state.checkAll}
                                                                  onChange={() => {
                                                                      this.handleCheck(dependent, i)
                                                                  }}/></Table.Cell>
                                            <Table.Cell>{dependent.firstName}
                                            </Table.Cell>
                                            <Table.Cell>{dependent.lastName}</Table.Cell>
                                            <Table.Cell>{dependent.relToEmployee}</Table.Cell>
                                            <Table.Cell>{age}</Table.Cell>
                                        </Table.Row>
                                    }))}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ChangeAddress));
