const STRATEGIC_PARTNER = Object.freeze({
    LABEL: 'Voya Protect',
    SHORT_LABEL: 'Voya',
    PALETTE: {
        PRIMARY: '#D75426',
        SECONDARY: '#145A7B',
        ACCENTS: '#551B57'
    },
    CONTACT_SUPPORT: {
        EMAIL: 'voyaprotectsupport@joinansel.com',
        NUMBER: '(888) 290-1153'
    },
    S3: {
        BASE_URL: 'https://brella-platform-assets.s3.amazonaws.com',
        SP_NAME: 'voya',
        APPLICATION: 'member',
    }
});

export default STRATEGIC_PARTNER;
