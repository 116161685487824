import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { PLAN_ROUTE, LOGIN_ROUTE, SETTINGS_ROUTE } from "../../utils/common";

import {
  setMfaType,
  setMfaSetupParams,
  validateMfa,
  sendCode,
  setMfaPrepare,
  prepareAddMethod,
  setNewToken,
  generateBackupCodes,
  setMfaIsSetup,
  forcePrepareAddMethod,
  clearTrashToken,
  getMfaSettings,
  trackSkipMfa,
  removeMethodMfa,
  setMfaEmailSetUpped
} from "../../actions/authActions";

import { setLeftNavigation } from "../../actions/navigationActions";

import FullScreenPageMenu from "../fullScreenPageMenu";
import Footer from "../footer";
import Mfa from "../../mfa";
import { MFAMainContainer } from "../../mfa/styled";
import {LABEL, MINIMUM_ATTEMPTS, REMAINING_CODE_RESEND_SECONDS} from "../../mfa/constants";

const AL_LEAST_ONE_CONFIGURATION = 1;
const REDIRECT_PATH = PLAN_ROUTE;
const LOGIN_PATH = LOGIN_ROUTE;

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    loginInfo: state.auth.loginInfo,
    userName: state.auth.userName,
    mfaSetupParams: state.auth.mfaSetupParams,
    mfaEnabled: state.auth.mfaEnabled,
    mfaType: state.auth.mfaType,
    mfaToken: state.auth?.loginInfo?.mfaToken
      ? state.auth.loginInfo.mfaToken
      : state.auth.mfaToken,
    mfaNotEnabledGracePeriodEnded: state.auth.mfaNotEnabledGracePeriodEnded,
    rememberDeviceDays: state.auth?.loginInfo?.rememberDeviceDays,
    mfaTypesConfigured: state.auth?.mfaTypesConfigured,
    mfaEmailSetUpped: state.auth?.mfaEmailSetUpped,
  };
};

const resolveErrorMessage = (e, defaultError) => {
  if (e.response && e.response.data && e.response.data.errorMessage) {
      return e.response.data.errorMessage;
  }

  return defaultError;
}

const MfaAuth = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mfaConfigured = history?.location?.mfaConfigured || [];
  const addAnotherMethod = mfaConfigured.length >= AL_LEAST_ONE_CONFIGURATION;
  const mfaRef = useRef(null);
  const [mfaStepConfig, setMfaStepConfig] = useState(mfaRef?.current?.getMfaData() ? mfaRef?.current?.getMfaData() : [{ disabled: false, step: 0, backArrow: undefined }]);
  const [mfaSetup, setMfaSetup] = useState(history?.location?.setupMfaFromSettings);
  const [isMfaProcessing, setIsMfaProcessing] = useState(false);
  const [mfaCodeAreSaved, setMfaCodeAreSaved] = useState(false);
  const [validateMfaCodeError, setValidateMfaCodeError] = useState(null);
  const [backupCodes, setBackupCodes] = useState(null);
  const [footerActionDisabled, setFooterActionDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [changeMethod, setChangeMethod] = useState(history?.location?.changeMethod);
  const [isMethodChanged, setIsMethodChanged] = useState(false);
  const [stepsConfigOverride, setStepsConfigOverride] = useState(null);
  const [hasReachedMaxCodeRequest, setHasReachedMaxCodeRequest] = useState(false)
  const [remainingCodeResendWindowSeconds, setRemainingCodeResendWindowSeconds] = useState(REMAINING_CODE_RESEND_SECONDS)
  const [remainingLockedOutTimeWindowSeconds, setRemainingLockedOutTimeWindowSeconds] = useState(null);
  const [isCodeEmailVerified, setIsCodeEmailVerified] = useState(false)
  const {
    userName,
    mfaType,
    mfaToken,
    mfaEnabled,
    loginInfo,
    mfaNotEnabledGracePeriodEnded,
    rememberDeviceDays,
    mfaEmailSetUpped
  } = props;

  const handleSetMfaEmailSetUpped = (emailToSendCode) => {
    dispatch(setMfaEmailSetUpped(emailToSendCode));
  }


  const skipForNow = () => {
    trackSkipMfa();
    dispatch(setLeftNavigation(true));
    history.push({ pathname: REDIRECT_PATH });
  };

  const configMFA = () => {
    if(!mfaNotEnabledGracePeriodEnded){
      dispatch(clearTrashToken());
    }
    setMfaSetup(true);
    nextHandler();
  };

  const backHandler = async () => {
    setValidateMfaCodeError(null);

    if (!mfaRef?.current) return;

    if (currentStep === 0) {
      if(history?.location?.setupMfaFromSettings){
        dispatch(setLeftNavigation(true));
        history.push({ pathname: SETTINGS_ROUTE, state:{backToSettings:history?.location?.setupMfaFromSettings} });
      }
      setMfaSetup(false);
    } else if(currentStep === 1) {
      dispatch(clearTrashToken());
      setMfaStepConfig(mfaRef?.current.triggerBackStep());
    } else if (currentStep === 2) {
      if(!mfaEnabled) setMfaStepConfig(mfaRef?.current.triggerBackStep());
    } else {
      setMfaStepConfig(mfaRef?.current.triggerBackStep());
    }
  };

  const handleStepZero = async () => {
    const currentMFAType = mfaRef?.current?.getMfaType() || mfaType
    if (!currentMFAType || !userName) return;
    setIsMfaProcessing(true);
    setFooterActionDisabled(true);
    if (!mfaToken && !stepsConfigOverride) {

      try {
        const response = await prepareAddMethod(userName, currentMFAType);
        prepareMethodCallback(response);
      } catch (error) {
        setIsMfaProcessing(false);
      }

    } else if(mfaToken && !stepsConfigOverride) {

      try {
        const response = await forcePrepareAddMethod(userName, currentMFAType, mfaToken, mfaEmailSetUpped);
        prepareMethodCallback(response);
      } catch (error) {
        setIsMfaProcessing(false);
      }

    } else {
      setMfaStepConfig(mfaRef?.current.triggerNextStep());
    }

    setFooterActionDisabled(false);
  }

  const handleStepTwoNotOverrideAndOneMethodChanged = async () => {
    if (addAnotherMethod) {
      // skip backup code, is must be the same as the first method
      setMfaStepConfig(mfaRef?.current.triggerNextStep());
    } else {
      await generateBackupCodeList();
    }
  }

  const handleStepThreeNotOverrideAndTwoMethodChanged = () => {
    if (mfaCodeAreSaved) {
      setMfaStepConfig(mfaRef?.current.triggerNextStep());
      setMfaSetup(false);
    }
  }

  const handleStepSendCode = async () => {
    const currentMFAType = mfaRef?.current?.getMfaType() || mfaType
    if (!currentMFAType || !userName) return;

    setIsMfaProcessing(true);
    setFooterActionDisabled(true);

    try {
      const response = await forcePrepareAddMethod(userName, currentMFAType, mfaToken, mfaEmailSetUpped);
      await sendCodeHandler()
      prepareMethodCallback(response);
    } catch (error) {
      setIsMfaProcessing(false);
    }
  }

  const nextHandler = async () => {
    if (!mfaRef?.current) return;

    if (currentStep === 0) return handleStepZero()

    if (currentStep === 1 && mfaStepConfig?.btnLabel === LABEL.SEND_CODE) return handleStepSendCode()

    if ((currentStep === 2  && !stepsConfigOverride) ||
      (currentStep === 1 && isMethodChanged)) return handleStepTwoNotOverrideAndOneMethodChanged()

    if((currentStep === 3 && !stepsConfigOverride) ||
      (currentStep === 2 && isMethodChanged)) return handleStepThreeNotOverrideAndTwoMethodChanged()

    setMfaStepConfig(mfaRef?.current.triggerNextStep());
  };

  const prepareMethodCallback = (response) => {
    dispatch(setMfaSetupParams(response.data.setup));
    dispatch(setMfaPrepare(response.data));
    setMfaStepConfig(mfaRef?.current.triggerNextStep());
    setIsMfaProcessing(false);
  };

  const setMfaAuthType = (type) => {
    if (props.mfaType !== type) {
      dispatch(setMfaType(type ? type : "TOTP"));
    }
  };

  const sendCodeHandler= async () => {
    try {
      const {data} = await sendCode(userName, mfaType, mfaToken)
      setHasReachedMaxCodeRequest(data.remainingSendCodeAttempts === MINIMUM_ATTEMPTS)
      setRemainingCodeResendWindowSeconds(data.codeResendWindowSeconds)
      setRemainingLockedOutTimeWindowSeconds(data.remainingLockedOutTimeWindowSeconds)
    } catch (e) {
      console.log(e)
      setIsMfaProcessing(false);
    }
  }

  const validateMfaCode = async (authCode) => {
    setIsMfaProcessing(true);
    let code = authCode ? authCode : mfaRef?.current.getMfaCode();
    if (code) {
      try {
        setValidateMfaCodeError(null);
        const response = await validateMfa(userName, code, mfaToken, false, mfaType);
        dispatch(setNewToken(response.data));
        dispatch(setMfaIsSetup(true));
        setIsCodeEmailVerified(true);
        setIsMfaProcessing(false);
        setFooterActionDisabled(false);
      } catch (error) {
        setIsMfaProcessing(false);
        if (error.request) {
          setValidateMfaCodeError(resolveErrorMessage(error, 'Unable to check code right now.'));
        } else {
          setValidateMfaCodeError(resolveErrorMessage(null, 'Invalid code. Please try again.'));
        }
      }

    }
  };

  const removeMethod = async (password) => {
    try {
      setIsMfaProcessing(true);
      setValidateMfaCodeError(null);

      if(!changeMethod) return;

      const response = await removeMethodMfa(changeMethod, password);
      if (response.status !== 200) return;

      const prepareResponse = await prepareAddMethod(userName, mfaType);
      prepareMethodCallback(prepareResponse);
      setMfaSetup(true);
      dispatch(setMfaType(null));
      dispatch(setMfaIsSetup(false));
      setChangeMethod(false);
      setFooterActionDisabled(false);
      setIsMethodChanged(false)

    } catch (error) {
      setIsMfaProcessing(false);
      setValidateMfaCodeError(
        resolveErrorMessage(error, 'Invalid password. Please try again.')
      );
    }
  }

  const generateBackupCodeList = async () => {
    setIsMfaProcessing(true);
    generateBackupCodes()
      .then((response) => {
        setBackupCodes(response.data.backupCodeList);
        setIsMfaProcessing(false);
        setMfaStepConfig(mfaRef?.current.triggerNextStep());
      })
      .catch((error) => {
        setIsMfaProcessing(false);
      });
  };

  const setMfaSavedCodes = (bool) => {
    setMfaCodeAreSaved(bool);
  };

  const continueToPortal = () => {
    history.push({ pathname: REDIRECT_PATH });
  };

  const resetSteps = () => {
    setCurrentStep(0)
    setMfaStepConfig([{ disabled: false, step: 0, backArrow: undefined }])
  }

  //Init config and data used through MFA flow
  const init = () => {
    if (loginInfo.authToken && loginInfo.mfaEnabled && !stepsConfigOverride && !changeMethod && !mfaConfigured) {
      history.push({ pathname: REDIRECT_PATH });
    }
    if (loginInfo.mfaEnable) {
      dispatch(setLeftNavigation(false));
    }


    if (mfaRef.current) {
      setIsMfaProcessing(false);
      if (mfaCodeAreSaved) {
        setFooterActionDisabled(false);
      } else {
        setFooterActionDisabled(mfaStepConfig?.disabled);
      }

      setMfaStepConfig(mfaRef?.current?.getMfaData());
      setMfaAuthType(mfaRef?.current?.getMfaType());
      setCurrentStep(mfaStepConfig?.step);
    }
  };

  useEffect(() => {
    init();
  }, [mfaRef, mfaStepConfig, mfaSetup, mfaCodeAreSaved]);

  const toggleFooterActionDisabled = (disabled) => {
    setFooterActionDisabled(disabled)
  }

  const showFotter = () => {
    const isMFADisabledAndNotStepFour = currentStep !== 4 && mfaSetup
    const isMFASetupRequired = currentStep !== 3 && !changeMethod && mfaSetup && loginInfo.mfaEnabled
    const hideFotter = !isNaN(currentStep) && !(currentStep === 4)
    return isMFADisabledAndNotStepFour || isMFASetupRequired || hideFotter
  }

  return (
    <>
      <MFAMainContainer>
        <FullScreenPageMenu
          showArrow={mfaStepConfig?.backArrow}
          onBack={backHandler}
          center={changeMethod ? '' : (mfaSetup || !loginInfo.mfaEnabled || loginInfo.authToken) ? "Set up two-factor authentication" : "Two-factor authentication"}
          hideSettings={true}
        />
      <Mfa
        {...props}
        ref={mfaRef}
        mfaSetup={mfaSetup}
        configMFA={configMFA}
        skipForNow={skipForNow}
        setMfaSavedCodes={setMfaSavedCodes}
        mfaCodeAreSaved={mfaCodeAreSaved}
        stepsConfigOverride={stepsConfigOverride}
        validateMfaCode={validateMfaCode}
        validateMfaCodeError={validateMfaCodeError}
        loading={isMfaProcessing}
        mfaIsSetup={loginInfo.mfaEnabled}
        backupCodes={backupCodes}
        continueToPortal={continueToPortal}
        redirectPath={REDIRECT_PATH}
        loginPath={LOGIN_PATH}
        mfaToken={mfaToken}
        setIsMfaProcessing={setIsMfaProcessing}
        getMfaSettings={getMfaSettings}
        setValidateMfaCodeError={setValidateMfaCodeError}
        rememberDeviceDays={rememberDeviceDays}
        withToken={loginInfo.authToken}
        changeMethod={changeMethod}
        removeMethod={removeMethod}
        isMethodChanged={isMethodChanged}
        resetSteps={resetSteps}
        mfaConfigured={mfaConfigured}
        addAnotherMethod={addAnotherMethod}
        sendCodeHandler={sendCodeHandler}
        hasReachedMaxCodeRequests={hasReachedMaxCodeRequest}
        isCodeEmailVerified={isCodeEmailVerified}
        remainingCodeResendWindowSeconds={remainingCodeResendWindowSeconds}
        remainingLockedOutTimeWindowSeconds={remainingLockedOutTimeWindowSeconds}
        toggleFooterActionDisabled={toggleFooterActionDisabled}
        setEmailSetUpToMFA={handleSetMfaEmailSetUpped}
      />
        <Footer
          showTopFooter={showFotter()}
          button={
            <Button
              primary
              content={mfaStepConfig?.btnLabel || LABEL.NEXT_STEP}
              disabled={footerActionDisabled}
              onClick={nextHandler}
              loading={footerActionDisabled ? !!isMfaProcessing : isMfaProcessing}
            />
          }
        />
</MFAMainContainer>
    </>
  );
};

export default connect(mapStateToProps)(withRouter(MfaAuth));
