import React, {Component} from "react"
import {Container, Divider, Grid, Loader, Message, Segment, Table} from "semantic-ui-react";
import {CustomContainer} from './custom-common';
import {Header, SupportEmailLink} from "./base";
import Footer from "./footer";
import moment from "moment";
import 'moment-timezone';
import {formatMoneyAmount, resolveDisplayTier} from "../utils/common";
import {connect} from "react-redux";
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import {usePreventBackNavigation} from "../hooks";

const mapStateToProps = (state, ownProps) => {
    const {certificate, certificateHolder, contract, cost, employer, employerContribution, user} = state.initialData;
    const {
        initialData: {
            quoteInfo: {
                chronicRider: chronic,
                mentalRider: mental,
            }
        }
    } = state;

    return {
        ...ownProps,
        authToken: state.auth.loginInfo ? state.auth.loginInfo.authToken : null,
        certificateHolder,
        user,
        contract,
        benefits: certificate.assetCoverages,
        certificate,
        cost,
        employerContribution,
        employer,
        chronic,
        mental,
    }
};

const withPreventBackNavigation = (WrappedComponent) => (props) => {
    usePreventBackNavigation();
    return <WrappedComponent {...props} />;
};

class SummaryReturningUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            chronic: 0,
            mental: 0,
            loadError: null,
        };
    }

    componentDidMount() {
        const {certificate} = this.props;
        this.setState({
            loading: false,
            displayTier: !!this.props.certificate ? resolveDisplayTier(this.props.certificate.familyTier) : '',
            effectiveDate: moment(certificate.effectiveDate, "YYYY-MM-DD").toISOString(),
            loadError: null,
        });
    }

    render() {
        const {
            effectiveDate,
            displayTier,
        } = this.state;

        const {
            cost,
            employerContribution,
            contract,
            certificate,
            certificateHolder,
            benefits,
            chronic,
            mental,
        } = this.props;

        const hasData = (certificateHolder && benefits && contract && employerContribution !== null && cost !== null);

        if (this.state.loading || !hasData) {
            return (<Loader active/>)
        }

        if (!!this.state.loadError) {
            return <Container><CustomContainer basic style={{marginTop: '5em'}} padded><Message
                error>{this.state.loadError}</Message></CustomContainer></Container>
        }


        let displayAgeReduction = false;
        const {ageReduction, ageReductionAge, ageReductionPercentage} = contract;
        if (ageReduction) {
            if (certificate.ageAtPolicyEffectiveDate >= ageReductionAge) {
                displayAgeReduction = true;
            }
        }

        const monthlyOptions = ["Monthly", "Multiple", "Other (all costs will show monthly to employees)"]
        const isMonthly = monthlyOptions.includes(certificateHolder.payrollFrequency);
        let costPerOption = "Paycheck";
        if (isMonthly) {
            costPerOption = 'Mo';
        }

        return (
            <React.Fragment>
                <div className="member-main-container" style={{background: "white"}}>
                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Divider hidden/><Divider hidden/><Divider hidden/>

                            <Header as="h2">Review your plan selections</Header>
                            <p>Here are the plan details you’ve selected—</p>

                            {!!displayAgeReduction && <React.Fragment>
                                <Divider hidden/>
                                <CustomContainer basic className={"bkgLinen small"}>
                                    <b>IMPORTANT</b>: Based on your age, you will receive {ageReductionPercentage}% of
                                    any benefit you select here.
                                </CustomContainer>
                                <Divider hidden/>
                            </React.Fragment>}

                            <Segment style={{padding: '3em', marginBottom: '0', boxShadow: 'unset'}}>
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Grid.Row>
                                            <div className="small neutral600Text"><b>Benefit amounts</b></div>
                                            <Table basic={'very'} style={{marginTop: 0}}>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell><b>Moderate</b></Table.Cell>
                                                        <Table.Cell>${benefits.moderate}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><b>Severe</b></Table.Cell>
                                                        <Table.Cell>${benefits.severe}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><b>Catastrophic</b></Table.Cell>
                                                        <Table.Cell>${benefits.catastrophic}</Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>

                                            {employerContribution > 0 && <React.Fragment>
                                                <div className="small neutral600Text" style={{marginBottom: '0.4em'}}>
                                                    <b>Employer contribution</b></div>
                                                <b>${formatMoneyAmount(employerContribution)} / {costPerOption}</b>
                                            </React.Fragment>
                                            }

                                            <div style={{marginTop: '1em'}}>
                                                <div className="small neutral600Text" style={{marginBottom: '0.4em'}}>
                                                    <b>Who’s covered?</b></div>
                                                <b>{displayTier}</b>
                                            </div>
                                        </Grid.Row>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Grid.Row>
                                            {(chronic > 0 || mental > 0) &&
                                                <React.Fragment>
                                                    <div className="small neutral600Text"><b>Additional coverage</b></div>
                                                    <Table basic={'very'} style={{marginTop: 0}}>
                                                        <Table.Body>
                                                            {chronic > 0 &&
                                                                <Table.Row>
                                                                    <Table.Cell><b>Chronic</b></Table.Cell>
                                                                    <Table.Cell>${chronic}</Table.Cell>
                                                                </Table.Row>
                                                            }

                                                            {mental > 0 &&
                                                                <Table.Row>
                                                                    <Table.Cell><b>Mental</b></Table.Cell>
                                                                    <Table.Cell>${mental}</Table.Cell>
                                                                </Table.Row>
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                </React.Fragment>
                                            }

                                            <div className="small neutral600Text" style={{marginBottom: '0.4em'}}>
                                                <b>Effective date</b>
                                            </div>
                                            <b>{moment(effectiveDate).format("MMM DD, YYYY")}</b>

                                            <div style={{marginTop: '1em'}}>
                                                <div className="small neutral600Text" style={{marginBottom: '0.4em'}}>
                                                    <b>Your cost</b>
                                                </div>
                                                <b>${formatMoneyAmount(cost)} / {costPerOption}</b>
                                            </div>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                            <div className="returning-user-note">
                                <b>Note: </b><span>You've already made your {STRATEGIC_PARTNER.LABEL} elections, If you need to make a change, <SupportEmailLink
                                textOverride={'reach out to our technical support team →'}/></span>
                            </div>
                        </Grid.Column>
                    </Grid>
                </div>

                <Footer showTopFooter={false} showCenterData={true}/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withPreventBackNavigation(SummaryReturningUser));
