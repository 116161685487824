import React, {Component} from 'react'
import {Icon, Menu, Divider,} from "semantic-ui-react";
import {withRouter, Redirect, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import GetHelpModal from "./getHelpModal";
import MainMenu from "./MainMenu";
import OESidebarWidget from './anualOpenEnrollment/sidebarWidget'
import AnualOpenEnrollmentMenu from "./anualOpenEnrollment/anualOpenEnrollmentMenu";
import SummaryReturningUser from "./summaryReturningUser";
import {onBackHandler} from "../utils/utils";
import {BrandedLogo} from './base';
import Mfa from '../mfa';


const mapStateToProps = ({
                             auth,
                             billing: {pastDue},
                             navigation: {showLeftNavigation, prevRoute},
                             initialData: {userIsCertificateHolder, isNotEligible, isDirectBillingEnabled, openEnrollment, hasRestrictedAccess}
                         }, ownProps) => {
    return {
        ...ownProps,
        authenticated: !!auth.loginInfo?.authToken,
        showLeftNavigation,
        prevRoute,
        isCertificateHolder: userIsCertificateHolder,
        oeStatus: openEnrollment?.status,
        hasRestrictedAccess,
        isDirectBillingEnabled,
        pastDue,
        isNotEligible,
        mfaEnabled: auth.mfaEnabled,
    }
};

class MemberContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            claims: [],
            loading: false
        }
    }

    get showOEWidget() {
        const {oeStatus, isCertificateHolder, isNotEligible} = this.props
        return (oeStatus === 'started') && isCertificateHolder && !isNotEligible
    }

    render() {
        const {
            authenticated,
            children,
            hasRestrictedAccess,
            isDirectBillingEnabled,
            mfaEnabled,
            pastDue,
            showLeftNavigation,
        } = this.props;

        if (!authenticated) return <Redirect to='/login'/>

        const marginLeft = showLeftNavigation ? '220px' : '0px';
        const isAnualOpenEnrollmentFlow = this.props.location.pathname.startsWith('/anualOpenEnrollment');

        return (
            <>
                {showLeftNavigation && <div className={"memberNavigation"}>
                    <div style={{flex: 1}}>
                        <BrandedLogo/>
                        <Divider hidden/>
                        <Divider hidden/>
                        <Menu text vertical>
                            <Menu.Item data-qa-button_plan as={NavLink} exact to="/plan" className={"navigationItem"}>
                                <b><Icon name='briefcase'/>Plan info</b>
                            </Menu.Item>
                            <Menu.Item data-qa-button_claims as={NavLink} exact to="/claims" className={"navigationItem"}>
                                <b><Icon name='file outline'/>Claims</b>
                            </Menu.Item>
                            {isDirectBillingEnabled && <Menu.Item data-qa-button_billing as={NavLink} exact to="/billing" className="navigationItem">
                                <b><Icon name='credit card outline'/>Billing</b>
                                {!!pastDue && pastDue !== "0.00" && <Icon color={"red"} name='exclamation circle'/>}
                            </Menu.Item>
                            }
                            <Menu.Item data-qa-button_my_info as={NavLink} exact to="/info" className={"navigationItem"}>
                                <b><Icon name='user outline'/>My info</b>
                            </Menu.Item>
                        </Menu>
                        {!mfaEnabled && (<Mfa showReminder={true}/>)}
                        <Divider hidden/>
                        {this.showOEWidget && <OESidebarWidget/>}
                    </div>
                </div>}

                <div style={{marginLeft: marginLeft, minWidth: '550px'}}>
                    {isAnualOpenEnrollmentFlow ? <AnualOpenEnrollmentMenu onBack={() => {
                        onBackHandler(this.props)
                    }}/> : <MainMenu/>}
                    {(hasRestrictedAccess && !isDirectBillingEnabled) ? (
                        <SummaryReturningUser/>
                    ) : children}
                    <GetHelpModal/>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(withRouter(MemberContainer));
