// Global Site color palette
// All color values must be defined here.
// The palette should only be used within the theme (not the components).
import STRATEGIC_PARTNER from "../../constants/strategicPartners";


export const palette = {
  // Primary
  primaryBlue: '#0047BB',

  // Secondary
  blue: '#0561CF',
  darkBlue: STRATEGIC_PARTNER.PALETTE.SECONDARY,
  darkGreen: '#5F9B1B',
  voyaOrange: '#F58000',
  darkOrange: STRATEGIC_PARTNER.PALETTE.PRIMARY,
  darkMint: '#008775',
  green: '#8ABF2E',
  greenPin: '#214E41',
  fog: '#E3EEED',
  lightBlue: '#FAFAFA',
  lightGreen: '#D1DD4A',
  mediumBlue: '#1F74DB',
  orange: '#FFB000',
  secondaryBlue: '#05599D',
  voyaNeutralGrey: '#767676',
  voyaLightGrey: '#838383',
  skyBlue: '#5BBBEB',
  linen: '#FFF7EC',
  subtlePink: '#FBE8E5',


  // Neutrals
  neutral50: '#FAFAFA',
  neutral100: '#F5F5F5',
  neutral200: '#EEEEEE',
  neutral300: '#E0E0E0',
  neutral400: '#BDBDBD',
  neutral500: '#9E9E9E',
  neutral600: '#757575',
  neutral700: '#616161',
  neutral800: '#424242',
  neutral900: '#212121',

  // Accents
  darkenedBlue: '#0c4b69',

  // Generic
  white: '#fff',
  black: '#000',
}
