import {stopScreensActionTypes} from "../actions/actionTypes";

const initialState = {
    isStopscreenDisplayed: false,
};

export const stopScreensReducer = (state = initialState, action) => {
    switch (action.type) {
        case stopScreensActionTypes.SET_IS_STOPSCREEN_DISPLAYED:
            const {isStopscreenDisplayed} = action;
            return {
                ...state,
                isStopscreenDisplayed,
            }
        default:
            return state
    }
}