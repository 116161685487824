import React, { useState } from "react";
import { Form } from "semantic-ui-react";

const STRIPE_BASE_INPUT_STYLES = {
    fontSize: "14px",
    flex: "auto",
    color: "#212121",
    border: "1px solid #9E9E9E",
    borderRadius: "7px",
    fontFamily: "'Raleway', 'Helvetica Neue', Arial, Helvetica, sans-serif",
    padding: "8px 8px",
    outline: "none",
    margin: "0em",
    boxShadow: "0em 0em 0em 0em transparent inset",
    transition: "color 0.1s ease, border-color 0.1s ease",
};  

const StripeField = ({ component: Component, label }) => {
    const [state, setState] = useState({ focused: true, empty: false, error: false });

    const onBlur = () => {
        setState({ ...state, focused: false });
    }
  
    const onFocus = () => {
        setState({ ...state, focused: true });
    }

    const onChange = ({ empty, error }) => {
        setState({ ...state, empty, error });
    };

    const { error } = state;

    return (
        <Form.Field error={!!error?.message}>
            <label>{label}</label>
            <div style={STRIPE_BASE_INPUT_STYLES}>
                <Component onChange={onChange} onFocus={onFocus} onBlur={onBlur}/>
            </div>
        </Form.Field>
    )
}

export default StripeField;
