import React, {useMemo} from "react";
import {Image} from "semantic-ui-react";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";

const {BASE_URL, APPLICATION, SP_NAME} = STRATEGIC_PARTNER.S3;
export const buildS3ImageSrc = ({common, svg, name}) => `${BASE_URL}/${SP_NAME}/${common ? 'common' : APPLICATION}/${name}.${svg ? 'svg' : 'png'}`;

export const S3Image = ({common = false, svg = false, name, ...otherProps}) => {
    const s3ImageSrc = useMemo(() => buildS3ImageSrc({common, svg, name}), [common, name, svg]);
    return <Image src={s3ImageSrc} {...otherProps}/>;
};
