import React from "react";
import {Icon, Table} from "semantic-ui-react";


const CategoryItem = ({label, value, title, description, references, setCategoryItemSelected, categoryItemSelected}) => {
    const isSelected = categoryItemSelected === label;
    const toggleSelect = () => setCategoryItemSelected(isSelected ? '' : label);

    return <>
        <Table.Row>
            <Table.Cell><b>{label}</b></Table.Cell>
            <Table.Cell>{value}</Table.Cell>
            <Table.Cell style={{width: '150px'}} textAlign={"right"}>
                <span data-qa-button_expand={label} className="clickable secondaryBlueText" onClick={toggleSelect}>
                    <b>{isSelected ? 'Collapse' : 'Expand'}</b>
                    <Icon color={'black'} name={isSelected ? 'caret up' : 'caret down'} />
                </span>
            </Table.Cell>
        </Table.Row>
        { isSelected &&
            <Table.Row>
                <Table.Cell colSpan={3} style={{borderTop: 0}}>
                    <b>{title.toUpperCase()}:</b>
                    <p>{description}</p>
                    <p className={'small'}>{references}</p>
                </Table.Cell>
            </Table.Row>
        }
    </>
};

export default CategoryItem;