import React, {Component} from "react";
import {Image, Transition} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {S3Image} from "../custom-common";
import IMAGE from "../../constants/images";

class Toast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        }
        this.timer = null
    }

    componentDidMount() {
        const {autodismiss, onClose} = this.props
        if (autodismiss && onClose) {
            this.timer = setTimeout(this.close, autodismiss)
        }
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer)
    }

    close = () => {
        this.setState({visible: false})
    }

    render() {
        const {visible} = this.state
        const {title, text, onClose = null, error = false, success = false, customStyle = {}} = this.props

        const styles = {
            backgroundColor: '#FFFFFF',
            border: '1px solid #EEEEEE',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
            borderRadius: '4px',
            color: '#212121',
            margin: 'auto',
            position: 'fixed',
            bottom: '60px',
            right: '10px',
            zIndex: 99999,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '16px',
            width: '400px',
            fontFamily: 'Raleway',
            fontSize: '14px',
            lineHeight: '150%',
            ...customStyle
        }

        return (
            <Transition visible={visible} animation='scale' duration={500} onComplete={onClose}>
                <CustomContainer inverted compact className="small"
                         style={styles}>
                    <Grid columns={3}>
                        <Grid.Column width={2} style={{padding: 0, paddingTop: 10, paddingBottom: 10}}>
                            { success && <S3Image {...IMAGE.CHECK_CIRCLE_OUTLINE} /> }
                            { error && <S3Image {...IMAGE.HIGHLIGHT_OFF} />  }
                        </Grid.Column>
                        <Grid.Column width={8} style={{padding: 0, paddingTop: 10, paddingBottom: 10}}>
                            {title && (<p><b>{title}</b></p>)}
                            {text && (<span dangerouslySetInnerHTML={{__html: text}}/>)}
                        </Grid.Column>
                        <Grid.Column textAlign={"center"} width={2} style={{padding: 0, paddingTop: 10, paddingBottom: 10}}>
                            {onClose && <S3Image {...IMAGE.CLOSE} as={'a'} onClick={this.close} /> }
                        </Grid.Column>
                    </Grid>
                </CustomContainer>
            </Transition>
        )
    }
}

export default Toast
