import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import {setLogin, setLogout} from '../../../actions/authActions'
import {theme} from './theme'
import {
  Brand,
  Content,
  Footer,
  Form,
  Layout,
  RegisterButton,
  RegisterText,
  RegisterWrapper,
  Separator,
  Slogan,
  Subtitle,
  Title,
} from './styled'
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const goToRegister = () => {
    history.push({pathname: "/register"});
  }

  useEffect(() => {
    dispatch(setLogout())

    const urlSearchParams = new URLSearchParams(window.location.search);
    const authToken = urlSearchParams.get('t');

    if (!authToken) return

    const contractSfId = urlSearchParams.get('cSfId');
    const step = urlSearchParams.get('s');
    const username = urlSearchParams.get('n');

    setLoading(true)

    dispatch(setLogin({authToken, contractSfId, step, username}));
    // history.push({pathname: resolveStepUrl(step)});
  }, [dispatch, history])

  return (
      <ThemeProvider theme={theme}>
        <Layout loading={loading}>
          <Layout.InfoPanel>
            <Brand>
              <Brand.Desktop/>
              <Brand.Mobile/>
            </Brand>
            <Layout.Info>
              <Slogan>
                Simple, group limited benefit insurance for all.
              </Slogan>
            </Layout.Info>
            <Separator/>
          </Layout.InfoPanel>
          <Layout.ContentPanel>
            <RegisterWrapper>
              <RegisterText>
                Not yet registered? Create an account
              </RegisterText>
              <RegisterButton onClick={goToRegister}>Register</RegisterButton>
            </RegisterWrapper>
            <Content>
              <Title>Sign in to your Member Portal</Title>
              <Subtitle>Enter your details below to access {STRATEGIC_PARTNER.LABEL}</Subtitle>
              <Form/>
              <div className={"small"}>
                <i>ReliaStar Life Insurance Company (Minneapolis, MN), a member of the Voya® family of companies.</i>
              </div>
            </Content>
            <Footer>
              <Footer.Copy>
                © Ansel Health, Inc. All Rights Reserved
              </Footer.Copy>
              <Footer.Links>
                <Footer.Link href="https://www.joinansel.com/terms" target="_blank">
                  Terms
                </Footer.Link>
                <Footer.Link href="https://www.joinansel.com/privacy-policy" target="_blank">
                  Privacy
                </Footer.Link>
              </Footer.Links>
            </Footer>
          </Layout.ContentPanel>
        </Layout>
      </ThemeProvider>
  )
}

export default Login
