import {billingActionTypes} from "./actionTypes";

export function setBillingData(billingData) {
    return {
        type: billingActionTypes.SET_BILLING_DATA,
        payload: billingData
    }
}

export function setPaymentMethods(paymentMethods) {
    return {
        type: billingActionTypes.SET_PAYMENT_METHODS,
        payload: paymentMethods
    }
}

export function setMicroDepositsBankAccount(microDepositsBankAccount) {
    return {
        type: billingActionTypes.SET_MICRO_DEPOSITS_BANK_ACCOUNT,
        payload: microDepositsBankAccount
    }
}


export function setMicroDepositsBankAccounts(microDepositsBankAccounts) {
    return {
        type: billingActionTypes.SET_MICRO_DEPOSITS_BANK_ACCOUNTS,
        payload: microDepositsBankAccounts
    }
}

export function deleteMicroDepositsBankAccount(microDepositsBankAccountId) {
    return {
        type: billingActionTypes.DELETE_MICRO_DEPOSITS_BANK_ACCOUNT,
        payload: microDepositsBankAccountId
    }
}

export function setMicroDepositsBankAccountVerificationFailed(accountId) {
    return {
        type: billingActionTypes.SET_MICRO_DEPOSITS_BANK_ACCOUNT_VERIFICATION_FAILED,
        payload: accountId
    }
}

export function setAccountSummaryCardLoading(value) {
    return{
        type: billingActionTypes.SET_ACCOUNT_SUMMARY_CARD_LOADING,
        payload: value
    }
}

export function setAutoPay(autoPay) {
    return {
        type: billingActionTypes.SET_AUTO_PAY,
        payload: autoPay
    }
}

export function patchMicroDepositBankAccount(accountId, verificationStatus) {
    return {
        type: billingActionTypes.PATCH_MICRO_DEPOSIT_BANK_ACCOUNT,
        payload: { accountId, verificationStatus }
    }
}
