
export const INIT = '@@INIT'

export const newClaimActionTypes = {
    SET_CLAIMANT: "SET_CLAIMANT",
    SET_WHAT_HAPPEND: "SET_WHAT_HAPPENED",
    SET_TREATEMENT_INFO: "SET_TREATEMENT_INFO",
    APPEND_FILES: "APPEND_FILES",
    REMOVE_FILE: "REMOVE_FILE",
    CLEANUP_NEW_CLAIM: "CLEANUP_NEW_CLAIM",
    SET_SELECTED_PAYOUT_METHOD: "SET_SELECTED_PAYOUT_METHOD"
};

export const authActionTypes = {
    SET_LOGIN: "SET_LOGIN",
    SET_LOGOUT: "SET_LOGOUT",
    SET_VERIFY_NAME: "SET_VERIFY_NAME",
    SET_VERIFY_TOKEN: "SET_VERIFY_TOKEN",
    CLEAR_VERIFY: "CLEAR_VERIFY",
    METADATA_SET: "METADATA_SET",
    METADATA_SETUP: "METADATA_SETUP",
    MFA_TYPE_SET: "MFA_TYPE_SET",
    MFA_TYPES_CONFIGURED_SET: "MFA_TYPES_CONFIGURED_SET",
    MFA_EMAIL_SET_UPPED: "MFA_EMAIL_SET_UPPED",
    MFA_PREPARE_SET: "MFA_PREPARE_SET",
    SET_NEW_TOKEN: "SET_NEW_TOKEN",
    MFA_IS_SETUP: "MFA_IS_SETUP",
    CLEAR_TOKEN: "CLEAR_TOKEN",
    MFA_REMEMBER_DEVICE_DAYS_SET: "MFA_REMEMBER_DEVICE_DAYS_SET"
}

export const navigationActionTypes = {
    SET_LEFT_NAV: "SET_LEFT_NAV",
    SET_PREV_ROUTE: "SET_PREV_ROUTE",
    SET_LAST_ROUTE: "SET_LAST_ROUTE"
}

export const initialDataActionTypes = {
    SET_CONSENT: "SET_CONSENT",
    SET_INITIAL_DATA: "SET_INITIAL_DATA",
    CLEAR_INITIAL_DATA: "CLEAR_INITIAL_DATA",
    UPDATE_DEPENDENTS: "UPDATE_DEPENDENTS",
}

export const claimsActionTypes = {
    SET_CLAIM_LIST: "SET_CLAIM_LIST",
    SET_SELECTED_CLAIM: "SET_SELECTED_CLAIM",
    CLEAR_CLAIMS: "CLEAR_CLAIMS",
    SET_NIGO_CLAIMS: "SET_NIGO_CLAIMS",
    UPDATE_CLAIM_LIST: "UPDATE_CLAIM_LIST"
}

export const planChangeActionTypes = {
    SET_QLE: "SET_QLE",
    SET_QLE_DATE: "SET_QLE_DATE",
    SET_QLE_DEPENDENTS: "SET_QLE_DEPENDENTS",
    SET_DEPENDENT: "SET_DEPENDENT",
    CLEAR_PLAN_CHANGE: "CLEAR_PLAN_CHANGE",
    SET_HAVE_STEP_CHILDREN: "SET_HAVE_STEP_CHILDREN",
    WANT_UPDATED_RECOMMENDATIONS: "WANT_UPDATED_RECOMMENDATIONS",
    SET_PLAN_CHANGE_INITIAL_DATA: "SET_PLAN_CHANGE_INITIAL_DATA",
    SET_LEFTOVER_MONEY: "SET_LEFTOVER_MONEY",
    SET_PLAN_HIGH_DEDUCTIBLE: "SET_PLAN_HIGH_DEDUCTIBLE",
    SET_PAY_COPAYS: "SET_PAY_COPAYS",
    SET_COVERAGE_OPTION: "SET_COVERAGE_OPTION",
    SET_COST_PER_OPTION: "SET_COST_PER_OPTION",
    SET_MODERATE_COVERAGE: "SET_MODERATE_COVERAGE",
    SET_SEVERE_COVERAGE: "SET_SEVERE_COVERAGE",
    SET_CATASTROPHIC_COVERAGE: "SET_CATASTROPHIC_COVERAGE",
    SET_SLIDER_MODERATE_COVERAGE: "SET_SLIDER_MODERATE_COVERAGE",
    SET_SLIDER_SEVERE_COVERAGE: "SET_SLIDER_SEVERE_COVERAGE",
    SET_SLIDER_CATASTROPHIC_COVERAGE: "SET_CSLIDER_ATASTROPHIC_COVERAGE",
    SET_SELECTED_AND_RECOMMENDED_COVERAGE: "SET_SELECTED_AND_RECOMMENDED_COVERAGE",
    SET_SELECT_COST: "SET_SELECT_COST",
    SET_FETCH_PLAN_RECOMMENDATION: "SET_FETCH_PLAN_RECOMMENDATION",
    SET_TOTAL_DEPENDENTS: "SET_TOTAL_DEPENDENTS",
    SET_BRELLA_SELECT_TIER_SELECTED: "SET_BRELLA_SELECT_TIER_SELECTED",
    SET_MARITAL_STATUS: "SET_MARITAL_STATUS",
    SET_DEPENDENTS_ID: "SET_DEPENDENTS_ID",
    SET_HEALTH_PLAN_DETAILS: "SET_HEALTH_PLAN_DETAILS",
    SET_PLAN_CONTRIB_COST: "SET_PLAN_CONTRIB_COST",
    SET_NEW_ADDRESS: "SET_NEW_ADDRESS",
    SET_DEPENDENT_LIST: "SET_DEPENDENT_LIST",
    REMOVE_DEPENDENT: "REMOVE_DEPENDENT",
    SET_PRIMARY_ADDRESS_CHANGE: "SET_PRIMARY_ADDRESS_CHANGE",
    SET_SLIDER_VALUES: "SET_SLIDER_VALUES",
    HAS_ADDED_QLE_DEPENDENT: "HAS_ADDED_DEPENDENT"
}

export const messageActionTypes = {
    SET_MESSAGE_LIST: 'message/SET_MESSAGE_LIST',
    SET_SELECTED_MESSAGE: 'message/SET_SELECTED_MESSAGE',
    CLEAR_MESSAGES: 'message/CLEAR_MESSAGES'
}

export const newMessageActionTypes = {
    SET_MESSAGE_TEXT: 'newMessage/SET_MESSAGE_TEXT',
    SET_FILES: 'newMessage/SET_FILES',
    APPEND_FILES: 'newMessage/APPEND_FILES',
    REMOVE_FILE: 'newMessage/REMOVE_FILE',
    CLEANUP_MESSAGE: 'newMessage/CLEANUP_MESSAGE',
}

export const getHelpActionTypes = {
    TOGGLE: 'getHelp/TOGGLE'
}

export const toastActionTypes = {
    SHOW: 'toast/SHOW',
    HIDE: 'toast/HIDE',
    CLEAR: 'toast/CLEAR',
    RESET: 'toast/RESET'
}



export const userActionTypes = {
    SET_LANGUAGE: 'SET_LANGUAGE'
}

export const anualOpenEnrollmentActionTypes = {
    SET_AOE_INITIAL_DATA: 'anualOpenEnrollment/SET_AOE_INITIAL_DATA',
    SET_AOE_USER_INITIAL_DATA: 'anualOpenEnrollment/SET_AOE_USER_INITIAL_DATA',
    SET_AOE_INITIAL_DEPENDENTS: 'anualOpenEnrollment/SET_AOE_INITIAL_DEPENDENTS',
    SET_DEPENDENTS: 'anualOpenEnrollment/SET_DEPENDENTS',
    SET_AOE_DEPENDENTS: 'anualOpenEnrollment/SET_AOE_DEPENDENTS',
    SET_AOE_DEPENDENT: 'anualOpenEnrollment/SET_AOE_DEPENDENT',
    REMOVE_AOE_DEPENDENT: 'anualOpenEnrollment/REMOVE_AOE_DEPENDENT',
    SET_DEPENDENT: 'anualOpenEnrollment/SET_DEPENDENT',
    REMOVE_DEPENDENT: 'anualOpenEnrollment/REMOVE_DEPENDENT',
    SET_PHONE_TYPE: "anualOpenEnrollment/SET_PHONE_TYPE",
    SET_DAYTIME_PHONE: "anualOpenEnrollment/SET_DAYTIME_PHONE",
    SET_FIRST_NAME: "anualOpenEnrollment/SET_FIRST_NAME",
    SET_LAST_NAME: "anualOpenEnrollment/SET_LAST_NAME",
    SET_GENDER: "anualOpenEnrollment/SET_GENDER",
    SET_SELECTED_STATE: "anualOpenEnrollment/SET_SELECTED_STATE",
    SET_STREET_ADDRESS: "anualOpenEnrollment/SET_STREET_ADDRESS",
    SET_APPARTMENT: "anualOpenEnrollment/SET_APPARTMENT",
    SET_ZIP_CODE: "anualOpenEnrollment/SET_ZIP_CODE",
    SET_CITY: "anualOpenEnrollment/SET_CITY",
    SET_MIDDLE_NAME: "anualOpenEnrollment/SET_MIDDLE_NAME",
    CLEAR_AOE_DATA: 'anualOpenEnrollment/CLEAR_AOE_DATA',
    SET_HEALTH_PLAN_DETAILS: 'anualOpenEnrollment/SET_HEALTH_PLAN_DETAILS',
    SET_FETCH_PLAN_RECOMMENDATION: 'anualOpenEnrollment/SET_FETCH_PLAN_RECOMMENDATION',
    SET_LEFTOVER_MONEY: 'anualOpenEnrollment/SET_LEFTOVER_MONEY',
    SET_PAY_COPAYS: "anualOpenEnrollment/SET_PAY_COPAYS",
    SET_COVERAGE_OPTION: "anualOpenEnrollment/SET_COVERAGE_OPTION",
    SET_COST_PER_OPTION: "anualOpenEnrollment/SET_COST_PER_OPTION",
    SET_MODERATE_COVERAGE: "anualOpenEnrollment/SET_MODERATE_COVERAGE",
    SET_SEVERE_COVERAGE: "anualOpenEnrollment/SET_SEVERE_COVERAGE",
    SET_CATASTROPHIC_COVERAGE: "anualOpenEnrollment/SET_CATASTROPHIC_COVERAGE",
    SET_SELECTED_AND_RECOMMENDED_COVERAGE: "anualOpenEnrollment/SET_SELECTED_AND_RECOMMENDED_COVERAGE",
    SET_BRELLA_SELECT_TIER_SELECTED: 'anualOpenEnrollment/SET_BRELLA_SELECT_TIER_SELECTED',
    SET_SELECT_COST: 'anualOpenEnrollment/SET_SELECT_COST',
    SET_PROGRESS_DATA: 'anualOpenEnrollment/SET_PROGRESS_DATA',
    SET_LAST_VISITED_SAVEPOINT_ROUTE: 'anualOpenEnrollment/SET_LAST_VISITED_SAVEPOINT_ROUTE',
    SET_PLAN_CONTRIB_COST: 'anualOpenEnrollment/SET_PLAN_CONTRIB_COST',
    SET_KEEP_COVERAGE: 'anualOpenEnrollment/SET_KEEP_COVERAGE',
    SHOW_CANCEL_AOE_MODAL: 'anualOpenEnrollment/SHOW_CANCEL_AOE_MODAL',
    SET_SUFFIX: 'anualOpenEnrollment/SET_SUFFIX',
    SET_STATUS: 'anualOpenEnrollment/SET_STATUS',
    SET_PREVIOUS_URL: 'anualOpenEnrollment/SET_PREVIOUS_URL',
    SET_FAMILY_CHANGED: 'anualOpenEnrollment/SET_FAMILY_CHANGED',
    SET_SKIP_RECOMMENDATION: 'anualOpenEnrollment/SET_SKIP_RECOMMENDATION'
}

export const billingActionTypes = {
    SET_BILLING_DATA: "SET_BILLING_DATA",
    SET_PAYMENT_METHODS: "SET_PAYMENT_METHODS",
    SET_ACCOUNT_SUMMARY_CARD_LOADING: "SET_ACCOUNT_SUMMARY_LOADING",
    SET_MICRO_DEPOSITS_BANK_ACCOUNT: "SET_MICRO_DEPOSITS_BANK_ACCOUNT",
    SET_MICRO_DEPOSITS_BANK_ACCOUNTS: "SET_MICRO_DEPOSITS_BANK_ACCOUNTS",
    DELETE_MICRO_DEPOSITS_BANK_ACCOUNT: "DELETE_MICRO_DEPOSITS_BANK_ACCOUNT",
    SET_AUTO_PAY: "SET_AUTO_PAY",
    PATCH_MICRO_DEPOSIT_BANK_ACCOUNT: "PATCH_MICRO_DEPOSIT_BANK_ACCOUNT",
}

export const stopScreensActionTypes = {
    SET_IS_STOPSCREEN_DISPLAYED: "SET_IS_STOPSCREEN_DISPLAYED"
}

export const bankAccountActionTypes = {
    SET_BANK_ACCOUNTS: "SET_BANK_ACCOUNTS",
    SET_DEFAULT_BANK_ACCOUNT: "SET_DEFAULT_BANK_ACCOUNT",
    SET_SELECTED_BANK_ACCOUNT: "SET_SELECTED_BANK_ACCOUNT",
    SET_EDITION_VISIBILITY: "SET_EDITION_VISIBILITY",
    SET_CREATION_VISIBILITY: "SET_CREATION_VISIBILITY",
    DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
    SET_BANK_ACCOUNTS_DATA: "SET_BANK_ACCOUNTS_DATA",
}

export const mobileActionTypes = {
    SET_USE_WEB: "mobile/SET_USE_WEB",
    SET_RESET_PASSWORD_FLOW: "mobile/SET_RESET_PASSWORD_FLOW",
}
