import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import {Icon} from 'semantic-ui-react'
import {CustomContainer, S3Image} from '../custom-common';
import { parseDate } from '../../utils/date'
import {AOE_REVIEW_PLAN_ROUTE, aOEStatus} from '../../utils/common'
import { setAOEPreviousUrl } from '../../actions/anualOpenEnrollmentActions'
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import IMAGE from "../../constants/images";

const mapStateToProps = (state, ownProps) => {
    const oe = state.initialData.openEnrollment || {}

    return {
        ...ownProps,
        isCertHolder: state.initialData.userIsCertificateHolder,
        enrollmentStatus: state.anualOpenEnrollment.status,
        reEnrollRequired: oe.reEnrollRequired,
        preEnrollmentEducationLaunchDate: parseDate(oe.preEnrollmentEducationLaunchDate),
        enrollmentStartDate: parseDate(oe.enrollmentStartDate),
        enrollmentEndDate: parseDate(oe.enrollmentEndDate),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAOEPreviousUrl: (path) => {
            dispatch(setAOEPreviousUrl(path))
        }
    }
}

const icon = <Icon name='exclamation circle' color='red' />

const contents = {
    notStartedRequired: {
        title: <>Your coverage ends soon. Re-enroll to keep your coverage {icon}</>,
        description: 'Explore your options and confirm your elections before open enrollment ends.',
        link: 'Get started',
        image: IMAGE.ICON_CALENDAR,
    },
    notStarted: {
        title: 'Now’s your chance to adjust your coverage.',
        description: 'Want to make changes? Explore your options, and confirm any changes before open enrollment ends.',
        link: 'See my options',
        image: IMAGE.ICON_CALENDAR,
    },
    startedRequired: {
        title: <>There’s still time to keep your coverage {icon}</>,
        description: 'Your coverage is ending soon. Review your options and confirm your elections before open enrollment ends.',
        link: 'Get started',
        image: IMAGE.STOPWATCH,
    },
    started: {
        title: `You’re all set for another year of ${STRATEGIC_PARTNER.LABEL} coverage!`,
        description: 'If you still want to make changes, be sure to confirm them before open enrollment ends.',
        link: 'See my options',
        image: IMAGE.STOPWATCH,
    },
    completed: {
        title: 'You’re all set, but you can still make changes.',
        description: 'If you still want to make changes, be sure to confirm them before open enrollment ends.',
        link: 'Change my coverage',
        image: IMAGE.SUCCESS_CONFETTI_90PX,
    },
}

class SidebarWidget extends Component {
    get content() {
        const {
            reEnrollRequired,
            enrollmentStatus,
        } = this.props

        if (reEnrollRequired) {
            switch (enrollmentStatus) {
                case aOEStatus.pending: return contents.notStartedRequired
                case aOEStatus.inProgress: return contents.startedRequired
                case aOEStatus.declined: return contents.startedRequired
                case aOEStatus.completed: return contents.completed
                default: return null
            }
        }

        switch (enrollmentStatus) {
            case aOEStatus.pending: return contents.notStarted
            case aOEStatus.inProgress: return contents.started
            case aOEStatus.declined: return contents.started
            case aOEStatus.completed: return contents.completed
            default: return null
        }
    }

    handleClick = () => {
        const { setAOEPreviousUrl, location: { pathname } } = this.props
        setAOEPreviousUrl(pathname) // Save current path
    }

    render() {
        const { isCertHolder } = this.props

        if (!isCertHolder || !this.content) return null

        const { enrollmentStartDate, enrollmentEndDate } = this.props
        const {title, description, link, image} = this.content

        return (
            <CustomContainer basic className={'AOEsidebarWidgetContainer'}>
                <div>
                    <div className={'small'}><b>Annual open enrollment</b></div>
                    <div className={'smaller'}>From <b>{parseDate(enrollmentStartDate)}</b><br />
                        to <b>{parseDate(enrollmentEndDate)}</b><br />
                    </div>
                </div>
                <div style={{
                    margin: '16px 0',
                    borderBottom: '1px solid #E0E0E0',
                }} />
                <div style={{ marginBottom: '1rem' }}><b>{title}</b></div>
                <p>{description}</p>
                <NavLink exact to={AOE_REVIEW_PLAN_ROUTE} onClick={this.handleClick} style={{ letterSpacing: '-0.1px' }}>
                    {link}<Icon name='long arrow alternate right' style={{ marginLeft: '6px', marginRight: 0 }} />
                </NavLink>
                <S3Image {...image} style={{margin: '20px 0 0 auto'}}/>
            </CustomContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarWidget))
