// Global Site properties
import STRATEGIC_PARTNER from "../constants/strategicPartners";

export const ui = {
  brand: {
    logo: `${STRATEGIC_PARTNER.S3.BASE_URL}/${STRATEGIC_PARTNER.S3.SP_NAME}/common/logo.svg`,
    invertedLogo: `${STRATEGIC_PARTNER.S3.BASE_URL}/${STRATEGIC_PARTNER.S3.SP_NAME}/common/logo_white.svg`,
  },
  heading: {
    fontFamily: 'EuclidCircularA-Bold, sans-serif',
  },
}
