import React from "react";

const PaperCheckInstructions = ({strings}) =>
    (
        <div>
            <p>{strings.paymentRequired.preferToMailInA}</p>
            <p>{strings.paymentRequired.checksShouldBe}</p>
            <p>
                <span className="vibranBlueText" style={{fontWeight: "bold"}}>{strings.paymentRequired.mailTo}</span><br/>
                Brella Services Inc.<br/>
                P.O. Box 735880<br/>
                Dallas, TX, 75373-5880
            </p>
            <a href>{strings.paymentRequired.downloadPDF}</a>
        </div>
    )

export default PaperCheckInstructions;
