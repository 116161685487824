import {initialDataActionTypes} from "./actionTypes";

export function setInitialData(payload) {
    return {
        type: initialDataActionTypes.SET_INITIAL_DATA,
        payload: payload
    }
}

export function clearInitialData() {
    return {
        type: initialDataActionTypes.CLEAR_INITIAL_DATA,
        payload: null
    }
}

export function updateDependents(payload) {
    return {
        type: initialDataActionTypes.UPDATE_DEPENDENTS,
        payload: payload
    }
}

/*export function setUser(payload) {
    return {
        type: initialDataActionTypes.SET_USER,
        payload: payload
    }
}



export function setEmployer(payload) {
    return {
        type: initialDataActionTypes.SET_EMPLOYER,
        payload: payload
    }
}

export function setCertificateHolder(payload) {
    return {
        type: initialDataActionTypes.SET_CERTIFICATE_HOLDER,
        payload: payload
    }
}

export function setBenefits(payload) {
    return {
        type: initialDataActionTypes.SET_BENEFITS,
        payload: payload
    }
}

export function setPolicy(payload) {
    return {
        type: initialDataActionTypes.SET_POLICY,
        payload: payload
    }
}

export function setTreatmentOptions(treatmentOptions) {
    return {
        type: initialDataActionTypes.SET_TREATEMENT_OPTIONS,
        payload: treatmentOptions
    }
}

export function setDocuments(payload) {
    return {
        type: initialDataActionTypes.SET_DOCUMENTS,
        payload: payload
    }
}
*/

export function setConsent(payload) {
    return {
        type: initialDataActionTypes.SET_CONSENT,
        payload: payload
    }
}



