import React from 'react';

import STRATEGIC_PARTNER from "../../../constants/strategicPartners";

const MailToSupport = (props) =>
    <a
       href={`mailto:${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`}
       target={"_blank"} rel="noopener noreferrer">
        {STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}
    </a>;

export default MailToSupport;