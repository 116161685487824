import React from "react";
import {Message, Modal} from "semantic-ui-react";
import {CustomModalHeader} from "../custom-common";

const PaperCheckModal = ({ strings, size = "small", onClose, open, msgText = null, msgType = "warning", children }) => {
    return (
        <Modal onClose={onClose} open={open} size={size}>
            <CustomModalHeader title={strings.paymentRequired.mailInYourPayment} onClose={onClose} className="customModalHeader" />
            <Modal.Content style={{maxHeight:'513px', overflowY:'auto'}}>
                {!!msgText && <Message style={{border: "none", borderRadius: 0}} content={msgText} {...{[msgType]: true}} />}
                <p>{strings.paymentRequired.preferToMailInA}</p>
                <p>{strings.paymentRequired.checksShouldBe}</p>
                <p>
                    <span className="vibranBlueText" style={{fontWeight: "bold"}}>{strings.paymentRequired.mailTo}</span><br />
                    Brella Services Inc.<br />
                    P.O. Box 735880<br />
                    Dallas, TX, 75373-5880
                </p>
                <a href>{strings.paymentRequired.downloadPDF}</a>
            </Modal.Content>
            {children}
        </Modal>
    )
}

export default PaperCheckModal;
