import {Grid, Icon, Message} from "semantic-ui-react";
import React from "react";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";
import {Header} from "../base";


const flexInlineStyles = {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 10px 1em 0px",
    width: "100%",
};

const CreditCardInfo = () => {
    const {creditCardPmData, removePm, showCreditCardPm, hasEPaymentFailed, makePaymentError} = PaymentDetailsContext.useContainer()

    return (
        <>
            {hasEPaymentFailed &&
            <Message style={{width: "100%"}} negative>
                <b>Payment Issue</b>. {makePaymentError}
            </Message>
            }
            <Header as="h5" style={{display: "flex"}}>
                <strong>Credit card</strong>
                {hasEPaymentFailed &&
                    <Icon color='red' name='attention' style={{fontSize: "15px", marginLeft: "5px"}}/>
                }
            </Header>
            <Grid>
                <Grid.Row>
                    <div style={flexInlineStyles}>
                        <p style={{marginLeft: '1em'}}>
                            Ending with {creditCardPmData.card.last4}
                        </p>
                        {!hasEPaymentFailed &&
                        <div>
                            <a href onClick={() => {
                                removePm();
                                showCreditCardPm();
                            }} style={{margin: "0 1em"}}>
                                <small>Edit&nbsp;</small><Icon name="pencil" color="green" size="small"/>
                            </a>
                            <a href onClick={removePm}><Icon name="close" color="green" /></a>
                        </div>
                        }
                    </div>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default CreditCardInfo;
