import React, {Component} from 'react'
import {connect} from 'react-redux'
import axios from 'axios'
import {Comment, Icon, Label, List, Popup, Ref, Transition, Visibility} from 'semantic-ui-react'
import {Button} from "../../base";
import cx from 'classnames'
import moment from 'moment'
import {dowloadBase64, memberName, resolveErrorMessage} from '../../../utils/common'
import {MessagesContext} from '../../../contexts/messages'
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";

const CONCIERGE_NAME = STRATEGIC_PARTNER.LABEL;
const CONCIERGE_LONG_NAME = STRATEGIC_PARTNER.LABEL + ' Support' ;
const USER_NAME = 'You';
const MAX_FILE_NAME_CHARS = 13;

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        user: state.initialData.user,
    }
}

const readDotStyles = {
    float: 'right' // Git blame this..
}

export class Message extends Component {
    static contextType = MessagesContext

    contentRef = null

    constructor(props) {
        super(props)

        const {startCollapsed = true} = props

        this.state = {
            firstRender: true,
            isCollapsable: false,
            collapsed: startCollapsed,
            fetchingFiles: {}
        }

        this.contentRef = React.createRef()
    }

    componentDidMount() {
        const e = this.contentRef.current

        const isCollapsable = (e.offsetWidth < e.scrollWidth)

        this.setState({
            isCollapsable,
            firstRender: false
        })
    }

    toggle = (state) => {
        const {collapsed} = this.state
        const newState = state !== undefined ? state : !collapsed
        this.setState({collapsed: newState})
    }

    trimmedAttachmentName = (filename) => {
        const [name, ext] = filename.split(/\.(?=[^.]+$)/)
        if (name.length <= MAX_FILE_NAME_CHARS) return filename

        return `${name.substring(0, MAX_FILE_NAME_CHARS - 2)}...${ext}`
    }

    downloadDoc = async (documentId) => {
        const {fetchingFiles} = this.state

        if (fetchingFiles[documentId]?.fetching) return

        const newFetchingFiles = {...fetchingFiles, [documentId]: {fetching: true, error: null}}

        this.setState({
            fetchingFiles: newFetchingFiles
        })

        try {
            const result = await axios.post('/api/member/v2/getMessageDocument', {'documentId': documentId})
            const data = result.data
            dowloadBase64(data.document.name, data.document.contentType, data.document.body)
            this.setState(prevState => ({
                fetchingFiles: {
                    ...prevState.fetchingFiles,
                    [documentId]: {
                        fetching: false,
                        error: null
                    }
                }
            }))
        } catch (e) {
            this.setState(prevState => ({
                fetchingFiles: {
                    ...prevState.fetchingFiles,
                    [documentId]: {
                        fetching: false,
                        error: resolveErrorMessage(e)
                    }
                }
            }))
        }
    }

    renderUnreadIndicator = () => {
        const {data: {isUnread}} = this.props

        return (
            <Transition visible={isUnread} animation='fade' duration={15000}>
                <Icon name="circle" size="tiny" color="red" style={readDotStyles}/>
            </Transition>
        )
    }

    handleMessageShown = () => {
        const {toggleUnread} = this.context
        const {data: {id, isUnread}} = this.props

        if (!isUnread) return

        axios.post('/api/member/v2/markMessageAsRead', {messageId: id})
            .catch(e => console.error(`Failed to tag message "${id}" as read: ${e.message}.`))

        toggleUnread(id, false) // Local update of the unread state
    }

    render() {
        const {lastFetch} = this.context
        const {data, user} = this.props
        const {firstRender, isCollapsable, collapsed, fetchingFiles} = this.state

        const {
            submittedDate,
            message,
            isConcierge,
            attachments,
        } = data

        const date = moment(submittedDate).format('MMM Do, YYYY')

        const avatarName = isConcierge ? CONCIERGE_NAME : memberName(user)
        const name = isConcierge ? CONCIERGE_LONG_NAME : USER_NAME
        const avatarBackgroundColor = (isConcierge ? STRATEGIC_PARTNER.PALETTE.PRIMARY : STRATEGIC_PARTNER.PALETTE.ACCENTS).replace('#', '');

        return (
            <Visibility key={`last-${lastFetch}`} fireOnMount onBottomVisible={this.handleMessageShown}>
                <Comment.Group className="width-auto">
                    <Comment>
                        <Comment.Avatar
                            style={{borderRadius: '50%', overflow: 'hidden'}}
                            src={`https://ui-avatars.com/api/?name=${avatarName}&color=fff&bold=true&background=${avatarBackgroundColor}`}
                        />
                        <Comment.Content>
                            <Comment.Author>{name}</Comment.Author>
                            {this.renderUnreadIndicator()}
                            <Comment.Metadata style={{marginLeft: 0, marginBottom: '16px'}}>
                                <div><b>{date}</b></div>
                            </Comment.Metadata>
                            <Ref innerRef={this.contentRef}>
                                <Comment.Text
                                    className={cx('small', 'neutral900Text', {'text-collapsed': firstRender || (isCollapsable && collapsed)})}>{message}</Comment.Text>
                            </Ref>
                        </Comment.Content>
                    </Comment>
                    {isCollapsable && (
                        <div style={{marginLeft: '56px'}}>
                            <Label as={Button} circular onClick={() => this.toggle()}>&nbsp;●●●&nbsp;</Label>
                        </div>
                    )}
                    {!!attachments?.length && (
                        <div className="small" style={{marginLeft: '56px', marginTop: '1em'}}>
                            <p><b>Attachments</b></p>
                            <List horizontal size="tiny" style={{marginTop: '0'}}>
                                {attachments.map(({name, documentId}, i) => (
                                    <List.Item
                                        as='a'
                                        key={i}
                                        className='hovereable clickable'
                                        onClick={() => this.downloadDoc(documentId)}
                                        disabled={fetchingFiles[documentId]?.fetching}
                                    >
                                        {fetchingFiles[documentId]?.fetching && (<>
                                            <Icon name="spinner loading icon" disabled/>
                                            &nbsp;
                                        </>)}
                                        {fetchingFiles[documentId]?.error && (<>
                                            <Popup
                                                basic
                                                size='mini'
                                                trigger={<Icon name='times circle' color='red'/>}
                                                style={{
                                                    color: '#9f3a38',
                                                    background: '#fff6f6'
                                                }}
                                            >
                                                <Popup.Header>Error fetching file</Popup.Header>
                                                <Popup.Content>{fetchingFiles[documentId].error}</Popup.Content>
                                            </Popup>
                                            &nbsp;
                                        </>)}
                                        {this.trimmedAttachmentName(name)}
                                    </List.Item>
                                ))}
                            </List>
                        </div>
                    )}
                </Comment.Group>
            </Visibility>
        )
    }
}

export default connect(mapStateToProps)(Message)
