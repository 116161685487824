import {authActionTypes} from "../actions/actionTypes";

const initialState = {
    loginInfo: null,
    verifyToken: null,
    verifyName: null,
    userName: null,
    mfaEnabled: null,
    mfaType: null,
    mfaAction: null,
    mfaSetupParams: null,
    mfaNotEnabledGracePeriodEnded: null,
    mfaToken: null,
    rememberDeviceDays: 0,
    mfaTypesConfigured: null,
    mfaEmailSetUpped: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.SET_LOGIN:
            return {
                ...state,
                loginInfo: action.payload,
                userName: state.userName || `${action.payload.firstName} ${action.payload.lastName}`,
                user: {
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName
                },
                mfaEnabled: state.mfaEnabled,
                mfaSetupParams: state.mfaSetupParams,
                mfaType: state.mfaType,
                mfaAction: state.mfaAction,
                mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                mfaToken: state.mfaToken
            }
        case authActionTypes.SET_LOGOUT:
            return {
                loginInfo: null,
                userName: null,
                mfaEnabled: null,
                mfaSetupParams: null,
                mfaAction: null,
                mfaType: null,
                mfaNotEnabledGracePeriodEnded: null,
                mfaToken: null
            }
        case authActionTypes.SET_VERIFY_NAME:
            return {
                ...state,
                verifyName: action.payload,
            }
        case authActionTypes.SET_VERIFY_TOKEN:
            return {
                ...state,
                verifyToken: action.payload,
            }
        case authActionTypes.CLEAR_VERIFY:
            return {
                verifyToken: null,
                verifyName: null
            }
            case authActionTypes.METADATA_SET:
                return {
                    ...state,
                    loginInfo: state.loginInfo,
                    userName: action.payload.userName,
                    mfaEnabled: action.payload.mfaEnabled,
                    mfaSetupParams: state.mfaSetupParams,
                    mfaType: state.mfaType,
                    mfaAction: state.mfaAction,
                    mfaNotEnabledGracePeriodEnded: action.payload.mfaNotEnabledGracePeriodEnded,
                    mfaToken: state.mfaToken
                }
            case authActionTypes.METADATA_SETUP:
                return {
                    ...state,
                    loginInfo: state.loginInfo,
                    userName: state.userName,
                    mfaEnabled: state.mfaEnabled,
                    mfaSetupParams: action.payload.mfaSetupParams,
                    mfaType: state.mfaType,
                    mfaAction: state.mfaAction,
                    mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                    mfaToken: state.mfaToken
                }
            case authActionTypes.MFA_TYPE_SET:
                return {
                    ...state,
                    loginInfo: state.loginInfo,
                    userName: state.userName,
                    mfaEnabled: state.mfaEnabled,
                    mfaSetupParams: state.mfaSetupParams,
                    mfaType: action.payload.mfaType,
                    mfaAction: state.mfaAction,
                    mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                    mfaToken: state.mfaToken
                }
            case authActionTypes.MFA_TYPES_CONFIGURED_SET:
                return {
                    ...state,
                    mfaTypesConfigured: action.payload.mfaTypesConfigured
                }
            case authActionTypes.MFA_EMAIL_SET_UPPED:
                return {
                    ...state,
                    mfaEmailSetUpped: action.payload.mfaEmailSetUpped
                }
            case authActionTypes.MFA_PREPARE_SET:
                return {
                    ...state,
                    loginInfo: state.loginInfo,
                    userName: state.userName,
                    mfaEnabled: state.mfaEnabled,
                    mfaSetupParams: state.mfaSetupParams,
                    mfaType: state.mfaType,
                    mfaAction: state.mfaAction,
                    mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                    mfaToken: action.payload.mfaToken
                }
            case authActionTypes.SET_NEW_TOKEN:
                const {authToken, ...rest} = state.loginInfo;
                const newLoginInfo = {...action.payload, mfaToken: null, ...rest}
                return {
                    ...state,
                    loginInfo: newLoginInfo,
                    userName: state.userName,
                    mfaEnabled: state.mfaEnabled,
                    mfaSetupParams: state.mfaSetupParams,
                    mfaType: state.mfaType,
                    mfaAction: state.mfaAction,
                    mfaNotEnabledGracePeriodEnded: state.mfaNotEnabledGracePeriodEnded,
                    mfaToken: null
                }
            case authActionTypes.MFA_IS_SETUP:
                return {
                    ...state,
                    mfaEnabled: action.payload.mfaEnabled,
                }
            case authActionTypes.CLEAR_TOKEN:
                return {
                    ...state,
                    mfaToken: null
                }
            case authActionTypes.MFA_REMEMBER_DEVICE_DAYS_SET:
                return {
                    ...state,
                    rememberDeviceDays: action.payload.rememberDeviceDays
                }
        default:
            return state
    }
};
