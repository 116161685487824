import React, {Component} from 'react';
import {Divider, Icon, Menu, Sidebar} from "semantic-ui-react";
import MainMenu from "../MainMenu";
import {NavLink} from "react-router-dom";
import {BrandedLogo} from '../base';

class ClaimsSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarVisible: false
        }
    }

    render() {
        return (
            <Sidebar.Pushable style={{height: '100vh'}} className={'member-menuContainer'}>
                <Sidebar as={Menu}
                         animation='overlay'
                         style={{padding: '1em'}}
                         onHide={() => this.setState({sidebarVisible: false})}
                         vertical
                         visible={this.state.sidebarVisible}
                         >
                    <BrandedLogo />
                    <Divider hidden/>
                    <Divider hidden/>
                    <div className="sidebarMenu">
                        <div className="sidebarItem active"><NavLink to={"/claims"}><Icon name='file outline'/>Claims</NavLink></div>
                        <div className="sidebarItem"><Icon name='briefcase'/>Plan info</div>
                        <div className="sidebarItem"><Icon name='user outline'/>Profile</div>
                        <div className="sidebarItem"><Icon name='setting'/>Settings</div>
                    </div>
                </Sidebar>

                <Sidebar.Pusher>
                    <MainMenu />
                    {this.props.children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}


export default ClaimsSidebar;
