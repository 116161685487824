import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SSN from '../../constants/ssn';
import {VisibilityIcon} from "./visibilityIcon";

const formatSSN = (v) => {
    if (v && v.length === SSN.RAW_LENGTH) {
        return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
    }
    return v;
};

export const SSNDisplay = ({ssn, label = 'Social Security Number', visibilityIconVisible = true}) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(prevIsVisible => !prevIsVisible);

    return (
        <>
            <b>{label} {(ssn && visibilityIconVisible) && <VisibilityIcon ariaLabel={"SSN"} isVisible={isVisible} onClick={toggleVisibility}/>}</b>
            <div>{ssn ? (isVisible ? formatSSN(ssn) : SSN.MASK) : '-'}</div>
        </>
    );
};

SSNDisplay.propTypes = {
    ssn: PropTypes.string,
    label: PropTypes.string,
    visibilityIconVisible: PropTypes.bool,
};
