import React from "react";
import { Input } from "semantic-ui-react";
import {
  MFAGrid,
  MFAHeading,
  MFAText,
  MFACodeBox,
  MFAButtonOutlined,
  MFAForm,
  MFALabel,
  MFAContainer,
} from "../../styled";
import { formatDate } from "../../../utils/common";
import {show as showToast} from "../../../actions/toastActions";
import {useDispatch} from "react-redux";

const BackupCodes = ({ backupCodes, codesAreCopied, setMfaSavedCodes }) => {
  const dispatch = useDispatch();

  const printCodes = () => {

    // Format the date as "MM-DD-YYYY"
    const formattedDate = formatDate(new Date(), null, 'MM-DD-YYYY');

    // Set the filename with the formatted date
    var filename = `Backup codes ${formattedDate}`;

    var divContents = document.getElementById("backUpCodes").innerHTML;
    var a = window.open("", "", "height=250, width=500");
    a.document.write("<html>");
    a.document.write(`<head><title>${filename}</title></head>`);
    a.document.write("<body> <h1>Backup Codes</h1> <br />");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  const copyToClipboard = () => {
    showToastSuccess();
    const formattedBackupCodes = backupCodes.join('\n');
    navigator.clipboard.writeText(formattedBackupCodes);
  }

  const showToastSuccess = () => {
    dispatch(showToast({
      type: 'success',
      title: "Copy backup codes",
      message: "Backup codes copied to clipboard.",
    }))
  }

  return (
    <>
      <MFAContainer className="sectionWrapper" maxWidth={"620px"}>
        <MFAGrid>
          <MFAGrid.Row>
            <MFAGrid.Column>
              <MFAHeading as="h2">Backup codes</MFAHeading>
              <MFAText>
                You will need these backup codes if you ever lose access to your
                authentication method. Each code may be used only once. Make
                sure you save a copy somewhere safe.
              </MFAText>
            </MFAGrid.Column>
          </MFAGrid.Row>
          <MFAGrid.Row>
            <MFAGrid.Column>
              <MFACodeBox>
                <MFAGrid.Row>
                  <MFAGrid.Column>
                    <MFAGrid columns={5} id="backUpCodes" className={"backUpCodesBox"}>
                      {backupCodes?.map((item, index) => {
                        return (
                          <MFAGrid.Column key={index}>{item}</MFAGrid.Column>
                        );
                      })}
                    </MFAGrid>
                  </MFAGrid.Column>
                </MFAGrid.Row>
                <MFAGrid.Row>
                  <MFAGrid.Column>
                    <MFAContainer className={"backupCodeWrapper"}>
                      <MFAButtonOutlined
                        onClick={() => {
                          if (backupCodes) {
                            copyToClipboard();
                          }
                        }}
                      >
                        Copy
                      </MFAButtonOutlined>
                      <MFAButtonOutlined
                        onClick={() => {
                          if (backupCodes) {
                            printCodes();
                          }
                        }}
                        className={"printBtn"}
                      >
                        Print
                      </MFAButtonOutlined>
                    </MFAContainer>
                  </MFAGrid.Column>
                </MFAGrid.Row>
                <MFAGrid.Row>
                  <MFAGrid.Column>
                    <MFAForm
                          className="saveCodes"
                      >
                      <MFAForm.Field inline>
                        <MFALabel className="labelSavedCodes">
                          <Input
                            type="checkbox"
                            name="savedCodes"
                            id="savedCodes"
                            onClick={(e) => setMfaSavedCodes(e.target.checked)}
                            value={!codesAreCopied}
                          />
                          I've saved my backup codes
                        </MFALabel>
                      </MFAForm.Field>
                    </MFAForm>
                  </MFAGrid.Column>
                </MFAGrid.Row>
              </MFACodeBox>
            </MFAGrid.Column>
          </MFAGrid.Row>
        </MFAGrid>
      </MFAContainer>
    </>
  );
};

export default BackupCodes;
