import React from "react";
import QRCode from "react-qr-code";

import {
  MFAContainer,
  MFAGrid,
  MFAHeading,
  MFAText,
  MFAList,
  MFACustomCard,
} from "../../../styled";

const AuthenticatorConfig = ({ mfaSetupParams }) => {

  return (
    <>
    <MFAContainer className="sectionWrapper" maxWidth={"633px"}>
      <MFAGrid>
        <MFAGrid.Row>
          <MFAGrid.Column>
            <MFAHeading as="h2">Configure two-factor authentication</MFAHeading>
            <MFAText>Follow the instructions to complete setup—</MFAText>
          </MFAGrid.Column>
        </MFAGrid.Row>
        <MFAGrid.Row columns={2} className={'spacingTop'}>
          <MFAGrid.Column width={7}>
            <MFAList ordered>
              <MFAList.Item>
                <MFAList.Content>
                  Install or open your authenticator app (e.g. Google Authenticator, Duo Mobile, Authenticator).
                </MFAList.Content>
              </MFAList.Item>
              <MFAList.Item>
                <MFAList.Content>
                  Scan the image to the right using your device's camera or manually enter your secret key.
                </MFAList.Content>
              </MFAList.Item>
              <MFAList.Item>
                <MFAList.Content>
                  If the app displays a 6-digit code, click Next.
                </MFAList.Content>
              </MFAList.Item>
            </MFAList>
          </MFAGrid.Column>
          <MFAGrid.Column width={5}>
            <MFACustomCard textAlign="center">
              {mfaSetupParams?.totp && (
              <QRCode
              value={encodeURI(`otpauth://totp/${mfaSetupParams?.totp?.issuer}:${mfaSetupParams?.totp?.username}?secret=${mfaSetupParams?.totp?.key}&issuer=${mfaSetupParams?.totp?.issuer}`)}
                style={{
                  height: "150px",
                  maxWidth: "100%",
                  width: "150px",
                  marginBottom: "24px",
                }}
              />
              )}
              <MFAText className="keyLabel">Secret key </MFAText>
              <div style={{wordWrap:'break-word', fontWeight: 'bold'}}>
                {mfaSetupParams?.totp?.key}
              </div>
            </MFACustomCard>
          </MFAGrid.Column>
          </MFAGrid.Row>
        </MFAGrid>
      </MFAContainer>
    </>
  );
};

export default AuthenticatorConfig;
