import {newMessageActionTypes} from './actionTypes'

export function setMessageText(text, caseId = 'new') {
    return {
        type: newMessageActionTypes.SET_MESSAGE_TEXT,
        payload: {
            caseId,
            text
        }
    }
}

export function setFiles(files, caseId = 'new') {
    return {
        type: newMessageActionTypes.SET_FILES,
        payload: {
            caseId,
            files
        }
    }
}

export function appendFiles(files, caseId = 'new') {
    return {
        type: newMessageActionTypes.APPEND_FILES,
        payload: {
            caseId,
            files
        }
    }
}

export function removeFile(fileIndex, caseId = 'new') {
    return {
        type: newMessageActionTypes.REMOVE_FILE,
        payload: {
            caseId,
            fileIndex
        }
    }
}

export function clearMessage(caseId = 'new') {
    return {
        type: newMessageActionTypes.CLEANUP_MESSAGE,
        payload: {caseId}
    }
}
