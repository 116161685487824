import React from 'react';
import {Modal} from 'semantic-ui-react';
import {Button} from "../base";
import {showCancelAOEModal} from "../../actions/anualOpenEnrollmentActions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {CustomModalHeader, CustomDivider} from "../custom-common";

const CancelModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const showCancelAOEFlowModal = useSelector(state => state.anualOpenEnrollment.showCancelAOEFlowModal);
    const previousUrl = useSelector(state => state.anualOpenEnrollment.previousUrl);

    const continueHandler = () => {
        dispatch(showCancelAOEModal(false));
    }

    const exitHandler = () => {
        dispatch(showCancelAOEModal(false));
        history.push(previousUrl);
    }

    return (
        <Modal size='tiny' onClose={continueHandler} open={showCancelAOEFlowModal}>
            <CustomModalHeader title="Wait! Your changes haven’t been submitted" onClose={continueHandler} className="customModalHeader"/>
            <Modal.Content>
                <p>Are you sure you want to go? Your elections haven’t been submitted to {STRATEGIC_PARTNER.LABEL}, any changes you made aren’t finalized. </p>
                <CustomDivider hidden/>
                <div style={{textAlign: 'right'}}>
                    <Button basic onClick={exitHandler} color='grey'>Exit</Button>
                    <Button primary onClick={continueHandler}>Continue shopping</Button>
                </div>
            </Modal.Content>
        </Modal>
    );
}

export default CancelModal;
