import React, { useRef, forwardRef, useImperativeHandle } from "react";

import MfaSplash from "./splash";
import MfaConfig from "./config";
import ValidateMFA from "./verify";
import MfaSetting from "./settings"
import MfaReminder from "./reminder";
import ValidateChangeMFA from "./changeMethod";

const Mfa = forwardRef((props, ref) => {
  const { mfaSetup, mfaEnabled, mfaIsSetup, mfaShowSettings, showReminder, withToken, changeMethod, stepsConfigOverride } = props;
  const mfaConfigRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        getMfaData() {
          if (mfaConfigRef?.current) {
            return mfaConfigRef.current.getStepConfig();
          }
        },
        triggerBackStep() {
          if (mfaConfigRef?.current) {
            return mfaConfigRef.current.backAction();
          }
        },
        triggerNextStep() {
          if (mfaConfigRef?.current) {
            return mfaConfigRef.current.nextAction();
          }
        },
        getMfaType(){
            if (mfaConfigRef?.current) {
              return mfaConfigRef.current.getMethodSelected();
            }
        },
      };
    },
    [mfaConfigRef]
  );

    if (mfaShowSettings && !withToken) {
      return <MfaSetting {...props} />
    } else if(showReminder){
      return <MfaReminder />
    } else if (!mfaSetup && !mfaEnabled) {
      return <MfaSplash {...props} />;
    } else if (mfaIsSetup && !withToken && !changeMethod && !stepsConfigOverride) {
      return <ValidateMFA {...props} />;
    } else if(changeMethod){
      return <ValidateChangeMFA {...props} />;
    } else {
      return <MfaConfig {...props} ref={mfaConfigRef} />;
    }
});

export default Mfa;
