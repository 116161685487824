import React, {Component} from 'react'
import {Container, Divider, Form, Grid, Icon, Image, Label, List, Loader, Message} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {Button, Header} from "../base";
import {setLeftNavigation} from "../../actions/navigationActions";
import FullScreenPageMenu from "../fullScreenPageMenu";
import Footer from "../footer";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setSelectedClaim} from "../../actions/claimsActions";
import axios from "axios";
import {bank, check, convertServerDateFormatToDisplay, dowloadBase64, getClaimStatusLabel, paycheck, paypal, readFileAsync, resolveErrorMessage, venmo} from "../../utils/common";
import {Document, Page} from "react-pdf";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import FileUpload from "../fileUploads";
import {ClaimPayment} from './claimPayment';
import {MAX_FILES_AMOUNT, MAX_FILE_SIZE_MB} from "../../utils/constants";
import DocumentExamplesModal from "./documentExamples/documentExamplesModal";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {isValidClaimFileType, isValidClaimFileSize} from "../../utils/file";
import { ClickableText } from '../custom-common';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    selectedClaim: state.claims.selectedClaim
});

const mapDispatchToProps = dispatch => ({
    setLeftNavigation: state => dispatch(setLeftNavigation(state)),
    setSelectedClaim: claim => dispatch(setSelectedClaim(claim)),
});

class ClaimDetails extends Component {
    constructor(props) {
        super(props);
        const {claimId} = this.props.location.state;
        if (!claimId) {
            this.props.history.replace("/claims");
        }
        this.state = {
            loading: true,
            symptoms: {
                requested: false,
                claimAditionalInfo: null,
                value: '',
                response: ''
            },
            providerDiagnosis: {
                requested: false,
                claimAditionalInfo: null,
                value: '',
                response: ''
            },
            treatment: {
                requested: false,
                claimAditionalInfo: null,
                value: '',
                response: ''
            },
            additionalInfo: {
                requested: false,
                claimAditionalInfo: null,
                value: '',
                response: ''
            },
            facility: {
                requested: false,
                claimAditionalInfo: null,
                value: '',
                response: ''
            },
            additionalDocuments: {
                requested: false,
                claimAditionalInfo: null,
                validFiles: [],
                invalidFiles: [],
            },
            documentRequest: {
                requested: false,
                claimAditionalInfo: null,
                validFiles: [],
                invalidFiles: [],
            },
            documentRequested: false,
            showModal: false,
        }
    }

    async componentDidMount() {
        const {setLeftNavigation, setSelectedClaim} = this.props

        setLeftNavigation(false)

        try {
            this.setState({loading: true})
            const {claimId} = this.props.location.state;
            const payload = {claimId: claimId}
            const result = await axios.post("/api/member/v2/getClaimDetailsPm", payload, {headers: {'Content-Type': 'application/json'}});
            const claim = {
                documents: result.data.documents,
                claim: result.data.claim,
                facilities: result.data.facilities,
                uploadsComplete: !result.data.hasPendingDocuments,
                showNotAllAttachmentsVisibleMessage: result.data.showNotAllAttachmentsVisibleMessage
            }
            setSelectedClaim(claim)
            this.setState({loading: false})
            if (!!result.data.claim.claimAdditionalInfos) {
                this.analizeRequestedAditionalInfo(result.data.claim.claimAdditionalInfos);
            }
        } catch (e) {
            this.setState({loading: false, error: resolveErrorMessage(e, 'An unexpected error has occurred 2')})
        }
    }

    get payments() {
        const { selectedClaim: { claim } } = this.props

        if (!claim) return []

        if (!claim.paymentApplications || !claim.paymentApplications.length) {
            return [{
                method: claim.selectedPayoutMethod.method,
                status: 'Pending',
                amount: 'Pending',
                issuedDate: null
            }]
        }

        return claim.paymentApplications.map(({ payment: { type: method, status, amount, processedDate: issuedDate } }) => ({
            method,
            status,
            amount,
            issuedDate
        }))
    }

    onBackHandler = () => {
        this.props.history.goBack();
    }

    downloadDoc = async (docId) => {
        this.setState({loading: true, error: null})
        try {
            const result = await axios.post("/api/member/v2/downloadDocument", {"documentId": docId}, {headers: {'Content-Type': 'application/json'}});
            const data = result.data;
            dowloadBase64(data.document.name, data.document.contentType, data.document.body)
            this.setState({loading: false})
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to get the document"})
        }
    }

    handleAdiotionalInfoChange = (event, {name, value}) => {
        let aditionalInfoAnswer = this.state[name];
        aditionalInfoAnswer.value = value;
        this.setState({[name]: aditionalInfoAnswer});
    }

    analizeRequestedAditionalInfo = (claimAditionalInfos) => {
        let isDocumentRequested = false;

        claimAditionalInfos.forEach((claimAditionalInfo) => {
            if (!!claimAditionalInfo.status && !!claimAditionalInfo.type) {
                if (claimAditionalInfo.type === "Clarifying Question" && !!claimAditionalInfo.subtype) {
                    if (claimAditionalInfo.subtype === "Symptoms") {
                        this.showRequiredAditionalInfoField(claimAditionalInfo, 'symptoms');
                    } else if (claimAditionalInfo.subtype === "Diagnosis") {
                        this.showRequiredAditionalInfoField(claimAditionalInfo, 'providerDiagnosis');
                    } else if (claimAditionalInfo.subtype === "Treatment") {
                        this.showRequiredAditionalInfoField(claimAditionalInfo, 'treatment');
                    } else if (claimAditionalInfo.subtype === "Facility") {
                        this.showRequiredAditionalInfoField(claimAditionalInfo, 'facility');
                    }
                } else if (claimAditionalInfo.type === "New Question") {
                    this.showRequiredAditionalInfoField(claimAditionalInfo, 'additionalInfo');
                } else if (claimAditionalInfo.type === "Additional Documents" && claimAditionalInfo.status === "Requested") {
                    isDocumentRequested = true;
                    this.showRequiredAditionalInfoField(claimAditionalInfo, 'additionalDocuments');
                } else if (claimAditionalInfo.type === "Document Request" && claimAditionalInfo.status === "Requested") {
                    isDocumentRequested = true;
                    this.showRequiredAditionalInfoField(claimAditionalInfo, 'documentRequest');
                }
            }
        });

        if (isDocumentRequested) {
            this.setState({documentRequested: true})
        }
    }

    showRequiredAditionalInfoField(claimAditionalInfo, field) {
        let aditionalInfoAnswer = this.state[field];
        if (claimAditionalInfo.status === "Requested") aditionalInfoAnswer.requested = true;
        if (claimAditionalInfo.status === "Submitted" || claimAditionalInfo.status === "No Longer Needed") aditionalInfoAnswer.response = claimAditionalInfo.response;
        aditionalInfoAnswer.claimAditionalInfo = claimAditionalInfo;
        this.setState({[field]: aditionalInfoAnswer});
    }

    onFilesLoadAdditionalDocuments = (newFiles) => {
        const {selectedClaim} = this.props;
        let invalidFiles = [];
        let validFiles = newFiles.filter((file) => isValidClaimFileType(file) && isValidClaimFileSize(file));
        let invalidFilesByType = newFiles.filter((file) => !isValidClaimFileType(file));
        invalidFilesByType.forEach((file) => {
            invalidFiles.push({name: file.name, message: "Unsupported file type."})
        })

        let invalidFilesBySize = newFiles.filter((file) => !isValidClaimFileSize(file));
        invalidFilesBySize.forEach((file) => {
            invalidFiles.push({name: file.name, message: `The file exceeds ${MAX_FILE_SIZE_MB}MB`})
        })

        let updatedField = this.state.additionalDocuments;
        const totalFiles = (updatedField.validFiles?.length ? updatedField.validFiles.length : 0) + (selectedClaim.documents ? selectedClaim.documents.length + validFiles.length : validFiles.length);

        if (totalFiles > MAX_FILES_AMOUNT) {
            const diff = totalFiles - MAX_FILES_AMOUNT;
            const rest = validFiles.slice(-diff);
            rest.forEach((file) => {
                invalidFiles.push({name: file.name, message: "This file exceeds the maximum file count of " + MAX_FILES_AMOUNT})
            })
            invalidFiles = [...invalidFiles, ...rest];
            validFiles = validFiles.slice(0, validFiles.length - diff);
        }
        updatedField.validFiles = [...this.state.additionalDocuments.validFiles, ...validFiles];
        updatedField.invalidFiles = invalidFiles;
        this.setState({additionalDocuments: updatedField});
    }

    onFilesLoadDocumentRequest = (newFiles) => {
        const {selectedClaim} = this.props;
        let invalidFiles = [];
        let validFiles = newFiles.filter((file) => isValidClaimFileType(file) && isValidClaimFileSize(file));
        let invalidFilesByType = newFiles.filter((file) => !isValidClaimFileType(file));
        invalidFilesByType.forEach((file) => {
            invalidFiles.push({name: file.name, message: "Unsupported file type."})
        })

        let invalidFilesBySize = newFiles.filter((file) => !isValidClaimFileSize(file));
        invalidFilesBySize.forEach((file) => {
            invalidFiles.push({name: file.name, message: `The file exceeds ${MAX_FILE_SIZE_MB}MB`})
        })

        let updatedField = this.state.documentRequest;
        const totalFiles = selectedClaim.documents?.length || 0 + validFiles.length;

        if (totalFiles > MAX_FILES_AMOUNT) {
            const diff = totalFiles - MAX_FILES_AMOUNT;
            const rest = validFiles.slice(-diff);
            rest.forEach((file) => {
                invalidFiles.push({name: file.name, message: "This file exceeds the maximum file count of " + MAX_FILES_AMOUNT})
            })
            invalidFiles = [...invalidFiles, ...rest];
            validFiles = validFiles.slice(0, validFiles.length - diff);
        }
        updatedField.validFiles = [...this.state.documentRequest.validFiles, ...validFiles];
        updatedField.invalidFiles = invalidFiles;
        this.setState({documentRequest: updatedField});
    }

    showSubmitButton() {
        const {symptoms, providerDiagnosis, treatment, additionalInfo, facility, additionalDocuments, documentRequest} = this.state;
        if (symptoms.requested) return true;
        if (providerDiagnosis.requested) return true;
        if (treatment.requested) return true;
        if (additionalInfo.requested) return true;
        if (facility.requested) return true;
        if (additionalDocuments.requested) return true;
        return documentRequest.requested;

    }

    disableSubmit() {
        const {symptoms, providerDiagnosis, treatment, additionalInfo, facility, additionalDocuments, documentRequest} = this.state;
        if (symptoms.requested && symptoms.value === "") return true;
        if (providerDiagnosis.requested && providerDiagnosis.value === "") return true;
        if (treatment.requested && treatment.value === "") return true;
        if (additionalInfo.requested && additionalInfo.value === "") return true;
        if (facility.requested && facility.value === "") return true;
        if (additionalDocuments.requested && additionalDocuments.validFiles.length === 0) return true;
        return documentRequest.requested && documentRequest.validFiles.length === 0;
    }

    filesDocument = async (files) => {
        return Promise.all(files.map(async file => {
            const content = await readFileAsync(file)
            return {fileName: file.name, contentType: file.type, contentData: content.split(",")[1]}
        }))
    }

    submitClaimsAdditionalInfos = async () => {
        const {selectedClaim} = this.props;
        const {symptoms, providerDiagnosis, treatment, additionalInfo, facility, additionalDocuments, documentRequest} = this.state;
        this.setState({loading: true})
        try {
            let additionalInfosAnswers = [];
            if (symptoms.requested && symptoms.value !== "") {
                additionalInfosAnswers.push({
                    claimAdditionInfoId: symptoms.claimAditionalInfo.id,
                    response: symptoms.value
                });
            }
            if (providerDiagnosis.requested && providerDiagnosis.value !== "") {
                additionalInfosAnswers.push({
                    claimAdditionInfoId: providerDiagnosis.claimAditionalInfo.id,
                    response: providerDiagnosis.value
                });
            }
            if (treatment.requested && treatment.value !== "") {
                additionalInfosAnswers.push({
                    claimAdditionInfoId: treatment.claimAditionalInfo.id,
                    response: treatment.value
                });
            }
            if (additionalInfo.requested && additionalInfo.value !== "") {
                additionalInfosAnswers.push({
                    claimAdditionInfoId: additionalInfo.claimAditionalInfo.id,
                    response: additionalInfo.value
                });
            }
            if (facility.requested && facility.value !== "") {
                additionalInfosAnswers.push({
                    claimAdditionInfoId: facility.claimAditionalInfo.id,
                    response: facility.value
                });
            }
            if (additionalDocuments.requested && additionalDocuments.validFiles.length > 0) {
                const documents = await this.filesDocument(additionalDocuments.validFiles)
                documents.forEach((document) => {
                    additionalInfosAnswers.push({
                        claimAdditionInfoId: additionalDocuments.claimAditionalInfo.id,
                        file: document,
                        additionalDocuments: true
                    });
                });
            }
            if (documentRequest.requested && documentRequest.validFiles.length > 0) {
                const documents = await this.filesDocument(documentRequest.validFiles)
                documents.forEach((document) => {
                    additionalInfosAnswers.push({
                        claimAdditionInfoId: documentRequest.claimAditionalInfo.id,
                        file: document,
                        documentRequest: true
                    });
                });
            }
            const payload = {
                claimId: selectedClaim.claim.id,
                claimAdditionalInfos: additionalInfosAnswers
            }
            await axios.post("/api/member/v2/submitClaimAdditionalInfos", payload, {headers: {'Content-Type': 'application/json'}});
            this.props.history.push("/claims")
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, submitError: resolveErrorMessage(e, 'An unexpected error occurred.')})
        }
    }

    openModal = () => {
        this.setState({
            showModal: true
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const {selectedClaim} = this.props;
        const {showModal} = this.state;
        const {
            symptoms,
            providerDiagnosis,
            treatment,
            additionalInfo,
            facility,
            additionalDocuments,
            documentRequest,
            error,
            loading,
            documentRequested
        } = this.state;

        const pm = {
            [venmo]: {
                imgUrl: "https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Payment+methods/venmo%402x.png",
                title: "Venmo"
            },
            [paypal]: {
                imgUrl: "https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Payment+methods/paypal%402x.png",
                title: "PayPal"
            },
            [bank]: {
                imgUrl: null,
                title: "Bank"
            },
            [paycheck]: {
                imgUrl: null,
                title: "Paper Check"
            },
            [check]: {
                imgUrl: null,
                title: "Paper Check"
            },
        }

        const status = selectedClaim?.claim?.status

        const claimStatus = getClaimStatusLabel(status)

        const showPaymentInfo = status === "Approved"
        const denied = status === "Denied"

        return (
            <div className="member-main-container claimDetails">
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Claim details"}/>
                <Grid container stackable columns={1} centered>
                    <Grid.Column className={"pageContent"}>
                        <Divider hidden/><Divider hidden/>
                        {!!loading ? <div style={{height: 'calc(100vh - 150px)'}}><Loader active/></div> :
                            <CustomContainer padded={"very"}>
                                {!!error ? <Message negative>{error}</Message> : !!selectedClaim ?
                                    <React.Fragment>
                                        <Header as="h2">Claim #{selectedClaim.claim.claimNumber}</Header>
                                        <Grid columns={2}>
                                            <Grid.Column width={8}>
                                                <p>
                                                    Filed
                                                    for {selectedClaim.claim.claimant.firstName} {selectedClaim.claim.claimant.lastName}<br/>
                                                    Submitted {convertServerDateFormatToDisplay(selectedClaim.claim.submissionDate)}<br/>
                                                    Last
                                                    updated {convertServerDateFormatToDisplay(selectedClaim.claim.lastUpdated)}
                                                </p>

                                                <Label className={claimStatus.className}>
                                                    <Icon name={"circle"}/>{claimStatus.text}
                                                </Label>
                                            </Grid.Column>
                                            <Grid.Column width={4}>
                                                <Divider hidden/>
                                                {(!!selectedClaim.claim.notifications && selectedClaim.claim.notifications.length > 0) &&
                                                <div style={{float: "right"}}>
                                                    <p>
                                                        Documents<br/>
                                                        {selectedClaim.claim.notifications.map((notif, i) => {
                                                            if (!!notif.notificationVersions && notif.notificationVersions.length > 0) {
                                                                const ordered = notif.notificationVersions.sort((a, b) => (a.versionNumber > b.versionNumber) ? 1 : ((b.versionNumber > a.versionNumber) ? -1 : 0));
                                                                const last = ordered[ordered.length - 1];
                                                                return <ClickableText onClick={() => {
                                                                    this.downloadDoc(last.id).then()
                                                                }} key={i}>{notif.notificationType}<br/></ClickableText>
                                                            } else {
                                                                return null
                                                            }
                                                        })}
                                                    </p>
                                                </div>}
                                            </Grid.Column>
                                        </Grid>

                                        {documentRequested ? (
                                            <CustomContainer basic className={"bkgLinen"}>
                                                <span>Call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER} with more information.</span>
                                            </CustomContainer>
                                        ) : (
                                            <Divider hidden />
                                        )}

                                        {(!showPaymentInfo && !denied) && (
                                            <Container>
                                                <Header as="h4">Payment preference</Header>
                                                {!!pm[selectedClaim.claim.selectedPayoutMethod.method] && !!pm[selectedClaim.claim.selectedPayoutMethod.method].imgUrl &&
                                                <Image style={{width: '130px', display: 'inline-block'}}
                                                    src={pm[selectedClaim.claim.selectedPayoutMethod.method].imgUrl}/>
                                                }
                                                {!!pm[selectedClaim.claim.selectedPayoutMethod.method] && pm[selectedClaim.claim.selectedPayoutMethod.method].imgUrl === null &&
                                                <span
                                                    className={"neutral700Text"}><b>{pm[selectedClaim.claim.selectedPayoutMethod.method].title}</b></span>
                                                }
                                            </Container>
                                        )}

                                        <Divider section/>

                                        {showPaymentInfo && !!this.payments.length && (
                                            <CustomContainer basic className={"bkgFog"} style={{borderRadius: '8px'}}>
                                                {this.payments.map((payment, key) => (
                                                    <ClaimPayment key={key} {...payment} />
                                                ))}
                                            </CustomContainer>
                                        )}

                                        <Header as="h4">What happened</Header>
                                        <Form>
                                            <p>
                                                <strong>What symptoms did you experience?</strong><br/>
                                                {selectedClaim.claim.symptoms}
                                            </p>
                                            {!symptoms.requested && symptoms.response !== "" &&
                                                <p>{symptoms.claimAditionalInfo.response}</p>
                                            }
                                            {symptoms.requested &&
                                                <Form.Field
                                                    name='symptoms'
                                                    value={symptoms.value}
                                                    onChange={this.handleAdiotionalInfoChange}
                                                    control={TextArea}
                                                    label={!!symptoms.claimAditionalInfo.question ? symptoms.claimAditionalInfo.question + ' *' : 'We need more elaboration here *'}
                                                    error
                                                />
                                            }
                                            <Divider hidden/>


                                            <p onClick={this.onEditWhatHappened}>
                                                <strong>What did your provider tell you was wrong?</strong><br/>
                                                {selectedClaim.claim.providerDiagnosis}
                                            </p>
                                            {!providerDiagnosis.requested && providerDiagnosis.response !== "" &&
                                                <p>{providerDiagnosis.claimAditionalInfo.response}</p>
                                            }
                                            {providerDiagnosis.requested &&
                                                <Form.Field
                                                    name='providerDiagnosis'
                                                    value={providerDiagnosis.value}
                                                    onChange={this.handleAdiotionalInfoChange}
                                                    control={TextArea}
                                                    label={!!providerDiagnosis.claimAditionalInfo.question ? providerDiagnosis.claimAditionalInfo.question + ' *' : 'We need more elaboration here *'}
                                                    error
                                                />
                                            }
                                            <Divider hidden/>

                                            <p>
                                                <strong>What treatment did you receive?</strong><br/>
                                                {selectedClaim.claim.treatment}
                                            </p>
                                            {!treatment.requested && treatment.response !== "" &&
                                                <p>{treatment.claimAditionalInfo.response}</p>
                                            }
                                            {treatment.requested &&
                                                <Form.Field
                                                    name='treatment'
                                                    value={treatment.value}
                                                    onChange={this.handleAdiotionalInfoChange}
                                                    control={TextArea}
                                                    label={!!treatment.claimAditionalInfo.question ? treatment.claimAditionalInfo.question + ' *' : 'We need more elaboration here *'}
                                                    error
                                                />
                                            }

                                            <Divider hidden/>

                                            <p>
                                                <strong>Is there anything else we should know?</strong><br/>
                                                {selectedClaim.claim.additionalInfo}
                                            </p>
                                            {!additionalInfo.requested && additionalInfo.response !== "" &&
                                                <>
                                                    <Divider hidden/>
                                                    <p>
                                                        <strong>{additionalInfo.claimAditionalInfo.question}</strong><br/>
                                                        {additionalInfo.claimAditionalInfo.response}
                                                    </p>
                                                </>
                                            }
                                            {additionalInfo.requested &&
                                                <>
                                                    <Divider hidden/>
                                                    <Form.Field
                                                        name='additionalInfo'
                                                        value={additionalInfo.value}
                                                        onChange={this.handleAdiotionalInfoChange}
                                                        control={TextArea}
                                                        label={additionalInfo.claimAditionalInfo.question + '*'}
                                                        error
                                                    />
                                                </>
                                            }

                                            <Divider section/>

                                            <Header as="h4">Where you were treated</Header>
                                            <p>
                                                <strong>What type of facility did you go to?</strong><br/>
                                                {selectedClaim.claim.facilities}
                                            </p>
                                            {!facility.requested && facility.response !== "" &&
                                                <p>{facility.claimAditionalInfo.response}</p>
                                            }
                                            {facility.requested &&
                                                <Form.Field
                                                    name='facility'
                                                    value={facility.value}
                                                    onChange={this.handleAdiotionalInfoChange}
                                                    control={TextArea}
                                                    label={!!facility.claimAditionalInfo.question ? facility.claimAditionalInfo.question + ' *' : 'We need more elaboration here *'}
                                                    error
                                                />
                                            }

                                            <Divider hidden/>
                                            <p><strong>Where did you receive care?</strong><br/>
                                                {selectedClaim.facilities?.map((facility, index) =>
                                                    <label key={`facility-${index}`}>
                                                        {facility}<br/>
                                                    </label>
                                                )}
                                            </p>
                                        </Form>
                                        <Divider section/>
                                        <Header as="h4">Uploads</Header>
                                        {selectedClaim.documents && selectedClaim.documents.length > 0 ?
                                            <Grid columns={2}>
                                                {!selectedClaim.uploadsComplete &&
                                                    <p className="neutral700"
                                                       style={{fontStyle: 'italic', marginTop: '1em'}}>
                                                    We're processing your uploads. It may take up to a few minutes for them to appear here...
                                                    </p>}
                                                {selectedClaim.documents.map((file, index) => {
                                                    return <Grid.Row key={index} verticalAlign={"middle"}>
                                                        <Grid.Column verticalAlign={"middle"} style={{
                                                            width: 'calc(75px + 1em)',
                                                            paddingRight: 0
                                                        }}>{file.contentType.startsWith("image/") ? (
                                                                <div style={{height: '56px', width: '72px'}}><Image centered
                                                                                                                    verticalAlign='middle'
                                                                                                                    bordered
                                                                                                                    style={{
                                                                                                                        objectFit: 'cover',
                                                                                                                        height: '100%',
                                                                                                                        width: '100%'
                                                                                                                    }}
                                                                                                                    src={`data:${file.contentType};base64,${file.contentData}`}/>
                                                                </div>)
                                                            : (<div style={{
                                                                height: '56px',
                                                                width: '75px',
                                                                border: '1px solid rgba(0, 0, 0, 0.1)',
                                                                overflow: 'hidden',
                                                                display: "inline-block",
                                                                verticalAlign: 'middle'
                                                            }}>
                                                                <Document
                                                                    file={`data:${file.contentType};base64,${file.contentData}`}
                                                                ><Page pageNumber={1} width={73}/></Document>
                                                            </div>)
                                                        }
                                                        </Grid.Column>
                                                        <Grid.Column>{file.fileName}</Grid.Column>
                                                    </Grid.Row>
                                                })}
                                                <Divider hidden />
                                            </Grid>
                                            : <p>{!selectedClaim.uploadsComplete ? (
                                                    <p className="neutral700"
                                                       style={{fontStyle: 'italic', marginTop: '1em'}}>
                                                        We're processing your uploads. It may take up to a few minutes
                                                        for them to appear here...
                                                    </p>
                                                ) : !selectedClaim.showNotAllAttachmentsVisibleMessage && (<p><span className={"small neutral700Text"}><i>No uploads have been provided</i></span></p>)}
                                            </p>
                                        }

                                        {(selectedClaim.showNotAllAttachmentsVisibleMessage) && (
                                          <p>
                                              <span className={"small neutral700Text"}>
                                                  <i>
                                                      Please note that not all attachments are visible.
                                                      For more information reach out to our team.
                                                  </i>
                                              </span>
                                          </p>
                                        )}

                                        {documentRequested &&
                                            <Grid columns={1} style={{marginTop: 20}}>
                                                <a href onClick={this.openModal} style={{cursor: "pointer"}}>
                                                    View types of documents
                                                </a>
                                            </Grid>
                                        }

                                        {(additionalDocuments.requested) &&
                                        <Grid columns={2} className={"ui form"} id={'additional-documents-grid'}>
                                            <Grid.Column width={13} className={"error field"} style={{paddingTop: 0}}>
                                                <label>Please upload at least (1) more document&mdash; *</label>
                                                <FileUpload onFilesLoad={this.onFilesLoadAdditionalDocuments}
                                                            disabled={false} small error>
                                                    <React.Fragment>
                                                        <p><Icon color={"grey"} name='download'/></p>
                                                        <p className={"small neutral700Text"}>Drag and drop or <strong>browse
                                                            your computer</strong></p>
                                                        <p className={"small neutral700Text"}>File types accepted are
                                                            JPGs, PNGs, and PDFs</p>
                                                        <p className={"small neutral700Text"}>Max file size {MAX_FILE_SIZE_MB}MB</p>

                                                    </React.Fragment>
                                                </FileUpload>

                                                <div
                                                    id={'additional-documents-upload-error'}
                                                    style={{
                                                    overflow: 'auto',
                                                    marginBottom: '3%',
                                                        maxHeight: '300px'
                                                }}>
                                                    {additionalDocuments.invalidFiles.map((file, index) => {
                                                        return (<p key={index} className={"small"}>
                                                            <Icon style={{color: '#D82000'}}
                                                                  name='exclamation circle'/>
                                                            {file.name} <span style={{color: '#D82000'}}> - {file.message}.</span>
                                                        </p>)
                                                    })}
                                                </div>

                                                <div
                                                    id={'additional-documents-upload-success'}
                                                    style={{
                                                    overflow: 'auto',
                                                    marginBottom: '3%',
                                                }}>
                                                {(!!additionalDocuments.validFiles && additionalDocuments.validFiles.length > 0) &&
                                                <List>
                                                    {additionalDocuments.validFiles.map((file, index) => {
                                                        return (<List.Item key={index}>
                                                            <Icon name='file'/>
                                                            {file.name}
                                                        </List.Item>)
                                                    })}
                                                </List>}
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        }

                                        {(documentRequest.requested) &&
                                        <Grid columns={2} className={"ui form"} id={'document-request-grid'}>
                                            <Grid.Column width={13} className={"error field"}>
                                                <label>Please upload at least (1) more
                                                    document&mdash; {documentRequest.claimAditionalInfo.claimDocumentType.name} * </label>
                                                <FileUpload onFilesLoad={this.onFilesLoadDocumentRequest}
                                                            disabled={false} small error>
                                                    <React.Fragment>
                                                        <p><Icon color={"grey"} name='download'/></p>
                                                        <p className={"small neutral700Text"}>Drag and drop or <strong>browse
                                                            your computer</strong></p>
                                                        <p className={"small neutral700Text"}>File types accepted are
                                                            JPGs, PNGs, and PDFs</p>
                                                        <p className={"small neutral700Text"}>Max file size {MAX_FILE_SIZE_MB}MB</p>
                                                    </React.Fragment>
                                                </FileUpload>

                                                {documentRequest.invalidFiles.map((file, index) => {
                                                    return (<p key={index} className={"small"}>
                                                        <Icon style={{color: '#D82000'}}
                                                              name='exclamation circle'/>
                                                        {file.name} <span style={{color: '#D82000'}}> - {file.message}.</span>
                                                    </p>)
                                                })}


                                                {(!!documentRequest.validFiles && documentRequest.validFiles.length > 0) &&
                                                <List>
                                                    {documentRequest.validFiles.map((file, index) => {
                                                        return (<List.Item key={index}>
                                                            <Icon name='file'/>
                                                            {file.name}
                                                        </List.Item>)
                                                    })}
                                                </List>}

                                            </Grid.Column>
                                        </Grid>
                                        }

                                        {this.showSubmitButton() &&
                                        <Grid columns={2}>
                                            <Grid.Column width={13}>
                                                <Button disabled={this.disableSubmit()} primary content="Submit"
                                                        onClick={this.submitClaimsAdditionalInfos}/>
                                            </Grid.Column>
                                        </Grid>
                                        }
                                    </React.Fragment> :
                                    <Message warning>No info</Message>
                                }
                            </CustomContainer>}
                    </Grid.Column>
                </Grid>
                <Footer showTopFooter={false} showCenterData={true}/>
                <DocumentExamplesModal onClose={this.closeModal} display={showModal} />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClaimDetails));
