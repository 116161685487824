import React, {Component} from "react"
import {Divider, Form, Grid, Icon, Loader, Modal, Popup, Radio} from "semantic-ui-react";
import {CustomContainer, S3Image, ClickableText} from '../custom-common';
import {Button, Header, Link} from "../base";
import {withRouter} from "react-router-dom";
import {getLocalization, interpolateString, languages, parseMultilineString, parseSingleUnderlinedWordInString} from "../../utils/localization";
import {connect} from "react-redux";
import {setPrevRoute} from "../../actions/navigationActions";
import {
    AOE_END_COVERAGE,
    AOE_HEALTH_PLAN_DETAILS_ROUTE,
    AOE_INCOME_ROUTE,
    AOE_REVIEW_FAMILY_ROUTE,
    checkIsOpenEnrollment,
    CHILD,
    DOMESTIC_PARTNER,
    findEmployerContribution,
    formatMoneyAmount,
    getNumberOfDeductions,
    handleSaveUserProgress,
    SPOUSE,
    stringTemplate,
    truncTwoDec
} from "../../utils/common";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {setCatastrophicCoverage, setCostPerOption, setCoverageOption, setFetchPlanRecommendation, setModerateCoverage, setPlanContribAndCost, setPlanCoverageSelectedAndRecommended, setSevereCoverage} from "../../actions/anualOpenEnrollmentActions";
import axios from "axios";
import {debounce} from "throttle-debounce";
import Footer from "../footer";
import moment from "moment";
import 'moment-timezone';
import {cancelAOEFlow} from "../../utils/utils";
import AOESteps from "./aOESteps";
import CancelLink from "./cancelLink";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {CustomModalHeader} from "../custom-common";
import IMAGE from "../../constants/images";

const mapStateToProps = (state, ownProps) => {
    const {user, initialData, anualOpenEnrollment} = state;
    const {certificate, riderLabels} = initialData;
    const {ratedClass, eligibility, contractInfo} = anualOpenEnrollment.generalInitialData;
    const {moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected} = anualOpenEnrollment;
    const moderateCS = !!moderateCoverageSelected ? moderateCoverageSelected : null;
    const severeCS = !!severeCoverageSelected ? severeCoverageSelected : null;
    const catastrophicCS = !!catastrophicCoverageSelected ? catastrophicCoverageSelected : null;
    const includesDomesticPartnerships = !!eligibility.domesticPartnership;

    let additionalChronic = 0;
    let additionalMental = 0;
    if(ratedClass && ratedClass.contractTerms && ratedClass.contractTerms.chronicBrokerRequested){
        additionalChronic = ratedClass.contractTerms.chronicCoverageLimit;
    }
    if(ratedClass && ratedClass.contractTerms && ratedClass.contractTerms.mentalBrokerRequested){
        additionalMental = ratedClass.contractTerms.mentalCoverageLimit;
    }

    const {enrollmentStartDate, enrollmentEndDate} = certificate;
    const start = moment(enrollmentStartDate);
    const end = moment(enrollmentEndDate);
    const isOpenEnrollment = checkIsOpenEnrollment(start, end, anualOpenEnrollment.generalInitialData.employer.timezone);

    return {
        ...ownProps,
        contractInfo: contractInfo,
        additionalChronic,
        additionalMental,
        isOpenEnrollment,
        includesDomesticPartnerships,
        openEnrollmentStarts: start.format("MMMM, Do YYYY"),
        moderateIncrements: (!!anualOpenEnrollment && !!anualOpenEnrollment.generalInitialData.increments && anualOpenEnrollment.generalInitialData.increments.moderate) || [],
        severeIncrements: (!!anualOpenEnrollment && !!anualOpenEnrollment.generalInitialData.increments && anualOpenEnrollment.generalInitialData.increments.severe) || [],
        catastrophicIncrements: (!!anualOpenEnrollment && !!anualOpenEnrollment.generalInitialData.increments && anualOpenEnrollment.generalInitialData.increments.catastrophic) || [],
        coverageOptions: anualOpenEnrollment.generalInitialData.coverageOptions,
        costPerOptions: anualOpenEnrollment.generalInitialData.costPerOptions,
        coverageOption: anualOpenEnrollment.coverageOption,
        costPerOption: anualOpenEnrollment.costPerOption,
        moderateCoverageSelected: moderateCS,
        severeCoverageSelected: severeCS,
        catastrophicCoverageSelected: catastrophicCS,
        moderateCoverageRecommended: anualOpenEnrollment.moderateCoverageRecommended,
        severeCoverageRecommended: anualOpenEnrollment.severeCoverageRecommended,
        catastrophicCoverageRecommended: anualOpenEnrollment.catastrophicCoverageRecommended,
        user: user,
        initialData,
        lang: user.lang,
        employerContribution: !!anualOpenEnrollment.employerContribution ? anualOpenEnrollment.employerContribution : "-",
        cost: (!!anualOpenEnrollment.cost || anualOpenEnrollment.cost === 0) ? anualOpenEnrollment.cost : "-",
        anualOpenEnrollment,
        skipRecommendation: anualOpenEnrollment.skipRecommendation,
        riderLabels,
    }
};

class PlanChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepProps: null,
            loading: false,
            loadingPersonalize: false,
            submitPlanLoading: false,
            skipRecommendation: false,
            displayInformationAboutCategoriesModal: false,
            displaySeeWhyWeChoseThisAmountsModal: false,
            displayCaptureEmailModal: false,
            capturedEmail: '',
            recommendationEmailScheduledSuccessfully: null,
            emailValidationError: null,
            displayDontWantCoverageModal: false,
            userModifiedData: false,
        };

        this.debouncedPersonalizePlan = debounce(500, () => this.personalizePlan());
    }

    async componentDidMount() {
        const {anualOpenEnrollment,coverageOption, coverageOptions, costPerOptions, skipRecommendation} = this.props;
        const {generalInitialData} = anualOpenEnrollment;
        if(anualOpenEnrollment.fetchPlanRecommendation) {
            const ec = findEmployerContribution(coverageOption, coverageOptions, anualOpenEnrollment.generalInitialData.employerContributions);
            this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), 0));

            if (skipRecommendation) {
                const {currentPlan, upcomingPlan} = anualOpenEnrollment.generalInitialData.planComparison;
                const currentFrecuency = currentPlan.payrollFrequency === generalInitialData.payrollFrequencyMonthly ? "Month" : "Paycheck";
                const costPerOption = costPerOptions.filter(cpo => cpo.value === currentFrecuency)[0].id
                const tier = generalInitialData.ratedClass.contractTerms.familyTier;
                this.props.dispatch(setCostPerOption(costPerOption));
                const deletes = this.alterDependentInfoBasedOnCoverage(tier);
                await this.props.dispatch(setCoverageOption(tier, deletes.deleteChildren, deletes.deleteSpouse));

                const m = upcomingPlan.benefitAmounts.moderate;
                const s = upcomingPlan.benefitAmounts.severe;
                const c = upcomingPlan.benefitAmounts.catastrophic;
                const translated = this.translateAmountToSliderIdx(m,s,c);
                this.props.dispatch(setPlanCoverageSelectedAndRecommended(
                    null, translated.moderateRecommendation,
                    null, translated.severeRecommendation,
                    null, translated.catastrophicRecommendation,
                ))
                await this.personalizePlan(tier, costPerOption, m, s, c).then();
                this.setState({
                    skipRecommendation: skipRecommendation,
                    loading: false,
                })
            } else {
                this.setState({skipRecommendation: false})
                await this.submitInitialPlanDetails();
                this.personalizePlan().then();
            }
        }

        let prevRoute = AOE_INCOME_ROUTE;
        if(skipRecommendation){
            prevRoute = AOE_HEALTH_PLAN_DETAILS_ROUTE;
        }
        this.props.dispatch(setPrevRoute(prevRoute));
    }

    alterDependentInfoBasedOnCoverage = (coverageOptionId) => {
        const {coverageOptions} = this.props;
        const option = coverageOptions.filter((c)=>c.value === coverageOptionId)[0];
        let deleteChildren;
        let deleteSpouse;
        switch(option.value){
            case 'employee':
                deleteChildren = true;
                deleteSpouse = true;
                break;
            case 'spouse':
                deleteChildren = true;
                deleteSpouse = false;
                break;
            case 'child':
                deleteChildren = false;
                deleteSpouse = true;
                break;
            default:
                deleteChildren = false;
                deleteSpouse = false;
        }
        return {deleteChildren, deleteSpouse};
    }

    translateSliderSelectionToAmount = () => {
        const {moderateIncrements, severeIncrements, catastrophicIncrements, moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected} = this.props;
        return {
            moderateCoverageSelected: moderateIncrements[moderateCoverageSelected-1],
            severeCoverageSelected: severeIncrements[severeCoverageSelected-1],
            catastrophicCoverageSelected: catastrophicIncrements[catastrophicCoverageSelected-1],
        }
    }

    translateAmountToSliderIdx = ( moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected ) => {
        const {moderateIncrements, severeIncrements, catastrophicIncrements, } = this.props;

        const getSliderIdx = (increments, amount) => {
            let sliderIdx = 1;
            increments.forEach((e, idx) => {
                if(e === amount){
                    sliderIdx = idx + 1;
                }
            })
            return sliderIdx;
        }

        return {
            moderateRecommendation: getSliderIdx(moderateIncrements, moderateCoverageSelected),
            severeRecommendation: getSliderIdx(severeIncrements, severeCoverageSelected),
            catastrophicRecommendation: getSliderIdx(catastrophicIncrements, catastrophicCoverageSelected),
        }
    }

    personalizePlan = async (forcedCoverageOption, forcedCostPerOpt, moderate, severe, catastrophic) => {
        this.setState({loadingPersonalize: true, recommendationEmailScheduledSuccessfully: null});
        try {
            const {anualOpenEnrollment, additionalChronic, additionalMental, coverageOption, costPerOption, costPerOptions, initialData} = this.props;
            const {userModifiedData} = this.state;
            let selectedCostPerOpt;
            if(!!forcedCostPerOpt){
                selectedCostPerOpt = forcedCostPerOpt;
            } else {
                selectedCostPerOpt = costPerOptions.filter(cpo => cpo.id === costPerOption)[0].value;
            }
            const payrollFreq =  selectedCostPerOpt === 'Month' ? anualOpenEnrollment.generalInitialData.payrollFrequencyMonthly : anualOpenEnrollment.generalInitialData.payrollFrequencySelected;
            let coverageOpt = coverageOption;
            if(!!forcedCoverageOption){
                coverageOpt = forcedCoverageOption;
            }
            const translatedAmounts = this.translateSliderSelectionToAmount();
            let moderateBenefit = !!translatedAmounts.moderateCoverageSelected? translatedAmounts.moderateCoverageSelected: 0;
            let severeBenefit = !!translatedAmounts.severeCoverageSelected? translatedAmounts.severeCoverageSelected: 0;
            let catastrophicBenefit = !!translatedAmounts.catastrophicCoverageSelected? translatedAmounts.catastrophicCoverageSelected: 0;
            if(!!moderate){
                moderateBenefit = moderate;
                severeBenefit = severe;
                catastrophicBenefit = catastrophic;
            }

            let result = await axios.post("/api/member/v2/personalizePlan", {
                familyTierValue: coverageOpt,
                moderateBenefit: moderateBenefit,
                severeBenefit: severeBenefit,
                catastrophicBenefit: catastrophicBenefit,
                quoteInfo: anualOpenEnrollment.generalInitialData.quoteInfo,
                mentalBenefit: additionalMental,
                chronicBenefit: additionalChronic,
                employer: anualOpenEnrollment.generalInitialData.employer,
                birthDate: initialData.user.birthDate,
                policyEffectiveDate: anualOpenEnrollment.generalInitialData.upcomingPolicyEffectiveDate,
                employerContributions: anualOpenEnrollment.generalInitialData.employerContributions,
                presets: anualOpenEnrollment.generalInitialData.presets,
                payrollFrequencySelected: payrollFreq,
                userModifiedData,
                planOffering: anualOpenEnrollment.generalInitialData.ratedClass.contractTerms.planOffering,
                ratedClassRates: anualOpenEnrollment.generalInitialData.ratedClass.ratedClassRates,
            }, {headers: {'Content-Type': 'application/json'}});
            if (!!result && !!result.data) {
                const data = result.data;
                const cost = !!data.costChoice ? data.costChoice : 0.0;
                const ec = data.contribChoice? data.contribChoice: 0.0;
                this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)))
            }
            this.setState({loadingPersonalize: false});
        } catch (e) {
            console.log(e);
            this.setState({loadingPersonalize: false})
        }
    }

    submitInitialPlanDetails = async () => {
        this.setState({loading: true});
        try {
            const {coverageOption, initialData, coverageOptions, costPerOptions, anualOpenEnrollment} = this.props;
            if(anualOpenEnrollment.highDeductibleInfo === null || anualOpenEnrollment.leftoverMoney === null){
                this.setState({loading: false});
                return;
            }
            let ageAtPolicyEffectiveDate = initialData.certificate.ageAtPolicyEffectiveDate;
            const totalDependents = anualOpenEnrollment.dependents.concat(anualOpenEnrollment.aOEDependents)

            let result = await axios.post("/api/member/v2/submitPlanDetails", {
                dependents: totalDependents,
                copayId: anualOpenEnrollment.typicallyPayInfo,
                highDeductibleInfoId: anualOpenEnrollment.highDeductibleInfo,
                leftoverMoneyId: anualOpenEnrollment.leftoverMoney,
                //contractInfo: contractInfo,
                ageAtPolicyEffectiveDate: ageAtPolicyEffectiveDate,
                payrollFrequencySelected: anualOpenEnrollment.generalInitialData.payrollFrequencySelected,
                planOffering: anualOpenEnrollment.generalInitialData.ratedClass.contractTerms.planOffering,
                quoteInfo: anualOpenEnrollment.generalInitialData.quoteInfo,
                presets: anualOpenEnrollment.generalInitialData.presets,
                ratedClassRates: anualOpenEnrollment.generalInitialData.ratedClass.ratedClassRates,
            }, {headers: {'Content-Type': 'application/json'}});
            if (!!result && !!result.data) {
                const data = result.data;
                const moderateRecommendation    = (!!data && !!data.choice && data.choice.moderateBenefit) || 0;
                const severeRecommendation      = (!!data && !!data.choice && data.choice.severeBenefit) || 0;
                const catastrophicRecommendation= (!!data && !!data.choice && data.choice.catastrophicBenefit) || 0;

                const translated = this.translateAmountToSliderIdx(moderateRecommendation, severeRecommendation, catastrophicRecommendation);
                const moderateCoverageSelected = translated.moderateRecommendation;
                const severeCoverageSelected = translated.severeRecommendation;
                const catastrophicCoverageSelected = translated.catastrophicRecommendation;
                this.props.dispatch(setPlanCoverageSelectedAndRecommended(
                    moderateRecommendation, moderateCoverageSelected,
                    severeRecommendation, severeCoverageSelected,
                    catastrophicRecommendation, catastrophicCoverageSelected,
                ))

                const isMonthly = anualOpenEnrollment.generalInitialData.payrollFrequencySelected === anualOpenEnrollment.generalInitialData.payrollFrequencyMonthly;
                let costPerOption = costPerOptions.filter(cpo => cpo.value === 'Paycheck')[0].id
                if(isMonthly){
                    costPerOption = costPerOptions.filter(cpo => cpo.value === 'Month')[0].id
                }
                this.props.dispatch(setCostPerOption(costPerOption));

                let recommendedOption = data.familyTierValue;
                const deletes = this.alterDependentInfoBasedOnCoverage(recommendedOption);
                this.props.dispatch(setCoverageOption(recommendedOption, deletes.deleteChildren, deletes.deleteSpouse));

                const cost = !!data && !!data.choice && !!data.choice.cost ? data.choice.cost : 0;
                const ec = findEmployerContribution(coverageOption, coverageOptions, anualOpenEnrollment.generalInitialData.employerContributions);
                this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)))
                this.setState({loading: false});
            } else {
                this.setState({loading: false});
            }
        } catch (e) {
            console.error(e);
            this.setState({loading: false})
        }
    }



    doRenderSlider = (printableOnly, stepsAmountsArr, value, recommendedValue, showWarning, onChange, onAfterChange) => {
        const localization = getLocalization(this.props.lang);
        const strings = localization.plan;
        const marks = {};
        const markStyle = {fontSize: '12px', color: '#9E9E9E', fontWeight: 'bold'};

        const sliderStepsArr = [];
        stepsAmountsArr.forEach((e, idx) => {
            sliderStepsArr[idx] = idx+1; // rc-slider doesn't allow selecting 0
        })

        sliderStepsArr.forEach((step) => {
            const stepAmountsArrIdx = step - 1 ;
            if(printableOnly){
                if(step === value){
                    marks[step] = {
                        style: markStyle,
                        label: `$${formatMoneyAmount(stepsAmountsArr[stepAmountsArrIdx])}`
                    }
                }
            } else {
                marks[step] = {style: markStyle}
                if(step === value){
                    marks[step].label = <div style={{fontSize: '12px', color: 'black', fontWeight: 'bold'}}>${formatMoneyAmount(stepsAmountsArr[stepAmountsArrIdx])}</div>;
                } else {
                    marks[step].label = `$${formatMoneyAmount(stepsAmountsArr[stepAmountsArrIdx])}`;
                }
            }
        });

        if (!!recommendedValue) {
            let recommendedValueIdx = 0;
            stepsAmountsArr.forEach((amount, idx)=>{
                if(amount === recommendedValue){
                    recommendedValueIdx = idx+1;
                }
            })
            let label = <div><div style={{fontSize: '12px', color: 'black', fontWeight: 'bold'}}>${formatMoneyAmount(recommendedValue)}</div>{strings.recommended}</div>
            if(value !== recommendedValueIdx) {
                label = <div><div>${formatMoneyAmount(recommendedValue)}</div>{strings.recommended}</div>
            }
            marks[recommendedValueIdx] = {
                style: markStyle,
                label: label,
            }
        }

        const min = stepsAmountsArr[0];
        value = value === null ? min : value;
        return <Slider

            onChange={onChange}
            onAfterChange={onAfterChange}
            min={1} max={stepsAmountsArr.length} step={1} dots={false} marks={marks}
            defaultValue={!!value ? value : min}
            value={value}
            activeDotStyle={{height: '0px', width: '0px', border: '0 none', backgroundColor: '#F00',}}
            dotStyle={{display: 'none'}}
            handleStyle={[{
                width: '14px',
                height: '14px',
                backgroundColor: '#FAFAFA',
                borderWidth: '1px',
                borderColor: '#9E9E9E',
            }]}
            railStyle={{
                backgroundColor: '#EEEEEE',
                height: '3px',
            }}
            trackStyle={{
                backgroundColor: showWarning ? '#F0A446' : STRATEGIC_PARTNER.PALETTE.SECONDARY,
                height: '3px',
            }}
        />
    }

    renderSlider = (stepsArr, value, recommendedValue, showWarning, onChange, onAfterChange) => {
        return this.doRenderSlider(false, stepsArr, value, recommendedValue, showWarning, onChange, onAfterChange);
    }

    renderCategoryExplanationModal = (strings) => {
        const {displayInformationAboutCategoriesModal} = this.state;
        const onModalClose = () => this.setState({displayInformationAboutCategoriesModal: false});
        return <Modal size={'tiny'} open={displayInformationAboutCategoriesModal} onClose={onModalClose}>
            <CustomModalHeader title={strings.categoryExp} onClose={onModalClose} className="customModalHeader" />
            <Modal.Content style={{maxHeight:'513px', overflowY: 'auto'}} className={"small"}>
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <S3Image {...IMAGE.CONDITION_MODERATE}/>
                        </Grid.Column>
                        <Grid.Column width={8} style={{paddingLeft: 0}}>
                            <div className="darkOrangeHeader">{strings.moderate}</div>
                            <div className="small">{strings.descrM}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <S3Image {...IMAGE.CONDITION_SEVERE}/>
                        </Grid.Column>
                        <Grid.Column width={8} style={{paddingLeft: 0}}>
                            <div className="darkOrangeHeader">{strings.severe}</div>
                            <div className="small">{strings.descrS}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2} verticalAlign="middle" style={{marginBottom: '30px'}}>
                        <Grid.Column width={4}>
                            <S3Image {...IMAGE.CONDITION_CATASTROPHIC}/>
                        </Grid.Column>
                        <Grid.Column width={8} style={{paddingLeft: 0}}>
                            <div className="darkOrangeHeader">{strings.catastrophic}</div>
                            <div className="small">{strings.descrC}</div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>
    }

    renderSeeWhyWeChoseThisAmountsModal = (strings) => {
        const {displaySeeWhyWeChoseThisAmountsModal} = this.state;
        const onModalClose = () => this.setState({displaySeeWhyWeChoseThisAmountsModal: false});
        return <Modal open={displaySeeWhyWeChoseThisAmountsModal} size={'tiny'} onClose={onModalClose}>
            <CustomModalHeader title={strings.seeWhyWeChoseTitle} onClose={onModalClose} className="customModalHeader" />
            <Modal.Content style={{maxHeight:'513px', overflowY: 'auto'}} className={"small"}>
                <p>{strings.modalP1}</p>
                <p><b>{strings.modalP2Title}</b><br/>
                    {strings.modalP2}
                </p>

                <p><b>{strings.modalP3Title}</b><br/>
                    {strings.modalP3}
                </p>

                <p><b>{strings.modalP4Title}</b><br/>
                    {strings.modalP4}
                </p>

                <p><b>{strings.modalP5Title}</b><br/>
                    {strings.modalP5}
                </p>
            </Modal.Content>
        </Modal>
    }

    getFilteredCoverOptions = () => {
        const {coverageOptions, includesDomesticPartnerships, anualOpenEnrollment} = this.props;
        const coverOpts = coverageOptions.map(c => {
            return {key: c.key, text: c.text, value: c.value}
        });

        const totalDependents = anualOpenEnrollment.dependents.concat(anualOpenEnrollment.aOEDependents)

        const isMarried = totalDependents.filter(dep => dep.relToEmployee === SPOUSE || (!!includesDomesticPartnerships && dep.relToEmployee === DOMESTIC_PARTNER)).length > 0;
        const hasChildren = totalDependents.filter(dep => dep.relToEmployee === CHILD).length > 0;

        let filteredCoverOptions;
        if (isMarried) {
            if (!hasChildren) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('child'))
            } else {
                filteredCoverOptions = coverOpts;
            }
        } else {
            if (hasChildren) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse'))
            } else {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse') && !opts.value.includes('child'))
            }
        }

        return filteredCoverOptions;
    }

    get sliderWarnings() {
        const {
            moderateIncrements,
            severeIncrements,
            catastrophicIncrements,
            moderateCoverageSelected,
            severeCoverageSelected,
            catastrophicCoverageSelected,
        } = this.props;


        const coverages = {
            moderate: moderateIncrements[moderateCoverageSelected - 1],
            severe: severeIncrements[severeCoverageSelected - 1],
            catastrophic: catastrophicIncrements[catastrophicCoverageSelected - 1]
        }

        return {
            moderate: coverages.severe < coverages.moderate,
            severe: (coverages.severe < coverages.moderate) || (coverages.catastrophic < coverages.severe),
            catastrophic: coverages.catastrophic < coverages.severe
        }
    }

    get hasWarnings() {
        return Object.values(this.sliderWarnings).some(e => e)
    }

    renderSliderWarnings() {
        if (!this.hasWarnings) return null

        const { lang } = this.props

        const localization = getLocalization(lang)
        const {
            sliderWarningLabel,
            sliderWarningDescription,
            moderate,
            severe,
            catastrophic
        } = localization.plan

        let vars;
        if (this.sliderWarnings.moderate && this.sliderWarnings.severe) {
            vars = {
                plan1: moderate,
                plan2: severe
            }
        } else {
            vars = {
                plan1: severe,
                plan2: catastrophic
            }
        }

        const description = stringTemplate(sliderWarningDescription, vars)

        return (
            <div style={{
                border: '1px solid #F0A446',
                background: '#FFF7EC',
                padding: '12px 16px',
                marginTop: '24px',
                color: '#212121',
                fontSize: '12px',
                lineHeight: '1.5',
            }}>
                <b>{sliderWarningLabel}</b> {description}
            </div>
        )
    }

    endCoverage = () => {
        this.props.history.push(AOE_END_COVERAGE)
    }

    render() {
        const {loading, loadingPersonalize, skipRecommendation} = this.state;

        const {lang, employerContribution, cost, additionalChronic, additionalMental,
            moderateIncrements, severeIncrements, catastrophicIncrements,
            costPerOption, costPerOptions, coverageOption, initialData,
            moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected,
            moderateCoverageRecommended, severeCoverageRecommended, catastrophicCoverageRecommended,
            includesDomesticPartnerships, anualOpenEnrollment, riderLabels,
        } = this.props;
        const {generalInitialData} = anualOpenEnrollment;
        const {benefitAmounts} = generalInitialData.planComparison.currentPlan;
        const localization = getLocalization(lang);
        const strings = localization.plan;
        const stringsAlsoIncludes = localization.yourBrellaPlanAlsoIncludes;

        const greenBlockStyle = {background: '#E3EEED', padding: '24px', width: '100%', borderRadius: '8px', marginTop: '1em'};
        const infoIcon = <Icon className={'nonprintable'} circular name={'info'}
                               style={{color: 'black', marginLeft: '1em', fontSize: '10px', cursor: 'pointer'}}
                               onClick={()=> this.setState({displayInformationAboutCategoriesModal: true})}/>;
        const paycheckOpt = !!costPerOptions && costPerOptions.filter((cpo) => cpo.value === "Paycheck")[0].id;
        const monthlyOpt = !!costPerOptions && costPerOptions.filter((cpo) => cpo.value === "Month")[0].id;

        let title = strings.title;
        let descriptionGreen = <ClickableText onClick={() => this.setState({displaySeeWhyWeChoseThisAmountsModal: true})}>
            <b>{strings.descriptionGreen}</b>
        </ClickableText>;
        let description = <React.Fragment>{strings.basedOn}<br/><br/><span className={"secondaryBlueText"}><b>{descriptionGreen}</b></span>{' '}{strings.getTips}</React.Fragment>

        if(skipRecommendation){
            title = strings.titleSkipped;
            descriptionGreen = <Link to={AOE_HEALTH_PLAN_DETAILS_ROUTE}><b>{strings.descriptionGreenSkipped}</b></Link>;
            description = <React.Fragment>{strings.descriptionSkipped}{' '}<span className={"secondaryBlueText"}>{descriptionGreen}{'\u2192'}</span></React.Fragment>
        }

        let displayAgeReduction = false;
        const {ageReduction, ageReductionAge, ageReductionPercentage} = anualOpenEnrollment.generalInitialData.ratedClass.contractTerms;
        if(ageReduction){
            const age = initialData.certificate.ageAtPolicyEffectiveDate;
            if(age >= ageReductionAge){
                displayAgeReduction = true;
            }
        }
        const filteredCoverOptions = this.getFilteredCoverOptions();

        const paycheckInfo = <Icon className={'nonprintable'} circular name={'info'}
                                   style={{color: 'black', marginLeft: '1em', fontSize: '10px', cursor: 'pointer'}}/>;
        const deductions = getNumberOfDeductions(anualOpenEnrollment.generalInitialData.payrollFrequencySelected);
        const paycheckTooltip = <Popup content={<span>{interpolateString(strings.paycheckInfo, [deductions])}</span>}
                                       style={{fontSize: '12px'}}
                                       trigger={paycheckInfo}/>

        const warningIcon = <Icon style={{ color: '#F0A446', float: 'right' }} name='warning sign' />

        const button = (<Button primary content={localization.next}
                                onClick={() => {
                                    handleSaveUserProgress(anualOpenEnrollment).then();
                                    this.props.history.push(this.props.nextRoute)}
                                }
                                disabled={!this.props.coverageOption || !this.props.costPerOption || this.hasWarnings}
                                loading={this.state.submitPlanLoading}
        />)

        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;

        if (loading) {
            return <React.Fragment>
                <div className="member-main-container" style={{background: 'white'}}>
                    <Grid container stackable columns={1} textAlign={'center'} style={{margin: '5em'}}>
                        <Grid.Row>
                            <Grid.Column>
                                <S3Image {...IMAGE.LEVERS}/>
                                <Header as={'h4'}>{strings.justAMoment}</Header>
                                <p>{strings.jamDescr}</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </React.Fragment>;
        } else {
            return (
                <React.Fragment>
                    {this.renderCategoryExplanationModal(strings)}
                    {this.renderSeeWhyWeChoseThisAmountsModal(strings)}

                    <div className="member-main-container" style={{background: 'white'}}>
                        <AOESteps activeStepIdx={1}/>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={10} className={"pageContent"}>
                                    <Grid stackable>
                                        <Grid.Row columns={1} centered>
                                            <Grid.Column textAlign={'left'} width={12}>
                                                <Header as='h2' className={'printableTitle'}>{title}</Header>
                                                <p className={'printableText'}>{description}</p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        {!!displayAgeReduction && <Grid.Row columns={1}>
                                            <Divider hidden/>
                                            <CustomContainer basic className={"bkgLinen small"}>
                                                <b>{strings.important}</b>: {interpolateString(strings.basedOnBenefit, [ageReductionPercentage])}
                                            </CustomContainer>
                                            <Divider hidden/>
                                        </Grid.Row>}

                                        <Grid.Row columns={1}>
                                            <Grid.Column textAlign={'left'} width={12}>
                                                <Form style={{ fontSize: '14px' }}>
                                                    <b>{strings.iWantToCover}:</b>
                                                    {filteredCoverOptions.map((opt, idx)=>{
                                                        const localizationEng = getLocalization(languages.ENGLISH);
                                                        const engStrings = localizationEng.plan;
                                                        let text = opt.text;
                                                        if(text === engStrings.coverOpt1){
                                                            text = strings.coverOpt1;
                                                        } else if(text === engStrings.coverOpt2){
                                                            if(!!includesDomesticPartnerships){
                                                                text = parseSingleUnderlinedWordInString(strings.coverOpt2Under);
                                                            } else {
                                                                text = strings.coverOpt2;
                                                            }
                                                        } else if(text === engStrings.coverOpt3){
                                                            text = parseSingleUnderlinedWordInString(strings.coverOpt3Under);
                                                        } else if(text === engStrings.coverOpt4){
                                                            text = strings.coverOpt4;
                                                        }

                                                        let content = <Form.Field key={idx} style={{ marginBottom: '.5em', display: 'inline-block', marginLeft: '1.5em' }}>
                                                            <Radio key={idx} label={<label>{text}</label>} name='radioGroup'
                                                                   checked={coverageOption === opt.value}
                                                                   value={opt.value}
                                                                   onClick={async (e, {value}) => {
                                                                       const deletes = this.alterDependentInfoBasedOnCoverage(value);
                                                                       await this.setState({userModifiedData: true});
                                                                       await this.props.dispatch(setCoverageOption(value, deletes.deleteChildren, deletes.deleteSpouse));
                                                                       this.props.dispatch(setFetchPlanRecommendation(false));
                                                                       this.personalizePlan().then();
                                                                   }}/>
                                                        </Form.Field>
                                                        if(opt.value === "child"){
                                                            content = <Popup key={idx} content={<span>{parseMultilineString(strings.childrenAreElegible)}</span>}
                                                                             style={{fontSize: '12px'}}
                                                                             trigger={content}/>
                                                        } else if(opt.value === "spouse" && !!includesDomesticPartnerships){
                                                            content = <Popup key={idx} content={<span>{strings.includesDomestic}</span>}
                                                                             style={{fontSize: '12px'}}
                                                                             trigger={content}/>
                                                        }
                                                        return content;
                                                    })}
                                                </Form>

                                                <div className="neutral700Text" style={{ marginTop: '1em', fontSize: '12px' }}>
                                                    {parseMultilineString(strings.dontSeeCoverageTier)}{' '}
                                                    <Link to={AOE_REVIEW_FAMILY_ROUTE}>{strings.goBackAndEdit}</Link>
                                                    {' '}{strings.yourFamilyDetails}
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={2} centered>
                                            <Grid.Column width={8}>
                                                <CustomContainer basic>
                                                <Grid stackable>
                                                    <Grid.Row>
                                                        <Grid.Column className="small sliderHeader">{strings.moderate}{infoIcon}{this.sliderWarnings.moderate && warningIcon} <span className={"neutral700Text"} style={{fontStyle: 'italic', paddingLeft:'10px'}}><span style={{fontWeight: "normal"}}>Previous payout</span> ${formatMoneyAmount(benefitAmounts.moderate)}</span></Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row className={'nonprintable'} style={{ padding: '0 2rem' }}>
                                                        <Grid.Column className='sliderRow'>
                                                            {this.renderSlider(moderateIncrements, moderateCoverageSelected, moderateCoverageRecommended, this.sliderWarnings.moderate, async (value) => {
                                                                await this.setState({userModifiedData: true});
                                                                await this.props.dispatch(setModerateCoverage(value));
                                                                this.props.dispatch(setFetchPlanRecommendation(false));
                                                            }, this.debouncedPersonalizePlan)}
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                    <Grid.Row>
                                                        <Grid.Column className="small sliderHeader">{strings.severe}{infoIcon}{this.sliderWarnings.severe && warningIcon} <span className={"neutral700Text"} style={{fontStyle: 'italic', paddingLeft:'10px'}}><span style={{fontWeight: "normal"}}>Previous payout</span> ${formatMoneyAmount(benefitAmounts.severe)}</span></Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row className={'nonprintable'} style={{ padding: '0 2rem' }}>
                                                        <Grid.Column className='sliderRow'>
                                                            {this.renderSlider(severeIncrements, severeCoverageSelected, severeCoverageRecommended, this.sliderWarnings.severe, async (value) => {
                                                                await this.setState({userModifiedData: true});
                                                                await this.props.dispatch(setSevereCoverage(value));
                                                                this.props.dispatch(setFetchPlanRecommendation(false));
                                                            }, this.debouncedPersonalizePlan)}
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                    <Grid.Row>
                                                        <Grid.Column className="small sliderHeader">{strings.catastrophic}{infoIcon}{this.sliderWarnings.catastrophic && warningIcon} <span className={"neutral700Text"} style={{fontStyle: 'italic', paddingLeft:'10px'}}><span style={{fontWeight: "normal"}}>Previous payout</span> ${formatMoneyAmount(benefitAmounts.catastrophic)}</span></Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row className={'nonprintable'} style={{ padding: '0 2rem' }}>
                                                        <Grid.Column className='sliderRow'>
                                                            {this.renderSlider(catastrophicIncrements, catastrophicCoverageSelected, catastrophicCoverageRecommended, this.sliderWarnings.catastrophic, async (value) => {
                                                                await this.setState({userModifiedData: true});
                                                                await this.props.dispatch(setCatastrophicCoverage(value));
                                                                this.props.dispatch(setFetchPlanRecommendation(false));
                                                            }, this.debouncedPersonalizePlan)}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                                </CustomContainer>
                                            </Grid.Column>
                                            <Grid.Column width={4} style={{ marginBottom: '2rem' }}>
                                                <Grid stackable>
                                                    <Grid.Column>
                                                        <div style={greenBlockStyle}>
                                                            <div style={{marginBottom: '2em'}} className="small neutral700Text">
                                                                <div><b>{strings.employeeContrib}</b></div>
                                                                <Header as="h3" style={{margin: '0'}}>
                                                                    {!!loadingPersonalize ? <Loader inline active size={'tiny'}/> :
                                                                        <React.Fragment>${cost}{anualOpenEnrollment.payrollFrequencySelected === anualOpenEnrollment.payrollFrequencyMonthly ? "/mo" : null}</React.Fragment>}
                                                                </Header>
                                                            </div>

                                                            {anualOpenEnrollment.generalInitialData.payrollFrequencySelected !== anualOpenEnrollment.generalInitialData.payrollFrequencyMonthly &&
                                                            <React.Fragment>
                                                                <Form.Checkbox
                                                                    radio
                                                                    className="small neutral900Text"
                                                                    checked={costPerOption === paycheckOpt}
                                                                    onClick={async () => {
                                                                        await this.setState({userModifiedData: true});
                                                                        await this.props.dispatch(setCostPerOption(paycheckOpt));
                                                                        this.props.dispatch(setFetchPlanRecommendation(false));
                                                                        this.personalizePlan().then();
                                                                    }}
                                                                    label={<label>{strings.perPaycheck}{paycheckTooltip}</label>}
                                                                />
                                                                <Form.Checkbox
                                                                    radio
                                                                    className="small neutral900Text"
                                                                    checked={costPerOption === monthlyOpt}
                                                                    onClick={async () => {
                                                                        await this.setState({userModifiedData: true});
                                                                        await this.props.dispatch(setCostPerOption(monthlyOpt));
                                                                        this.props.dispatch(setFetchPlanRecommendation(false));
                                                                        this.personalizePlan().then();
                                                                    }} label={strings.monthly}
                                                                />
                                                            </React.Fragment>
                                                            }

                                                            <Divider />

                                                            {!!employerContribution && employerContribution > 0 &&
                                                            <div style={{marginBottom: '1em'}} className="small neutral700Text">
                                                                <div className="smaller"><b>{strings.employerContrib}</b></div>
                                                                <b style={{margin: '0', color: STRATEGIC_PARTNER.PALETTE.PRIMARY}}>
                                                                    {!!loadingPersonalize ?
                                                                        <Loader inline active size={'tiny'}/> :
                                                                        <React.Fragment>${employerContribution}{costPerOption === paycheckOpt ? ` / ${strings.paycheck}` : ` / ${strings.month}`}</React.Fragment>}
                                                                </b>
                                                            </div>}
                                                        </div>

                                                        {this.renderSliderWarnings()}
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>

                                        {(!!additionalChronic || !!additionalMental) && <React.Fragment>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Header as={'h3'}>{stringsAlsoIncludes.title}</Header>
                                                    <p>{stringsAlsoIncludes.yourBrellaPlan}{stringsAlsoIncludes.payoutListed}</p>
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row style={{paddingTop: 0}} columns={!!additionalChronic && !!additionalMental ? 2 : 1} stretched>
                                                {!!additionalChronic &&
                                                <Grid.Column>
                                                    <div className={'bkgLightGray'} style={{padding: '2em'}}>
                                                        <div style={{marginBottom: '0.3em'}}>
                                                            <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                                <Header as={'h4'} style={{display: 'inline'}}>{riderLabels.chronic}{' '}</Header>
                                                            </span>
                                                            ${additionalChronic}
                                                        </div>
                                                        <p>{stringsAlsoIncludes.chronicDetails1}<b>4,100</b>{stringsAlsoIncludes.chronicDetails2}
                                                        </p>
                                                    </div>
                                                </Grid.Column>
                                                }
                                                {!!additionalMental &&
                                                <Grid.Column style={{margin: 0}}>
                                                    <div className={'bkgLightGray'} style={{padding: '2em'}}>
                                                        <div style={{marginBottom: '0.3em'}}>
                                                            <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                                <Header as={'h4'} style={{display: 'inline'}}>{riderLabels.mental}{' '}</Header>
                                                            </span>
                                                            ${additionalMental}
                                                        </div>
                                                        <p>{stringsAlsoIncludes.mentalDetails1}<b>600</b>{stringsAlsoIncludes.mentalDetails2}</p>
                                                    </div>
                                                </Grid.Column>}
                                            </Grid.Row>
                                        </React.Fragment>}
                                        <Divider hidden/>
                                        <Grid.Row columns={1} className={'planEndYourCoverage'}>
                                            <Grid.Column>
                                            <span className={'planEndYourCoverageLinkText'} onClick={this.endCoverage}>
                                                <Icon name={"close"}/><b>End your {STRATEGIC_PARTNER.LABEL} coverage</b>
                                            </span>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                            </Grid.Column>
                        </Grid>
                    </div>
                    <Footer link={cancel} showTopFooter showCenterData button={button}/>
                </React.Fragment>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps)(PlanChoice));
