import React from 'react';
import {Modal} from 'semantic-ui-react';
import CoverageComparisonTable from "./coverageComparisonTable";
import {CustomContainer, CustomModalHeader} from "../custom-common";

export default function CoverageComparisonModal({handleClose, show, upcomingCoverage}) {
        return (
        <Modal size={'large'} onClose={() => handleClose(false)} open={show}>
            <CustomModalHeader title={"Coverage comparison"} onClose={() => handleClose(false)} className="customModalHeader"/>
                <Modal.Content>
                <CustomContainer basic padded><CoverageComparisonTable showHeader showCurrent upcomingCoverage={upcomingCoverage}/></CustomContainer>
                </Modal.Content>
            </Modal>
        )
}
