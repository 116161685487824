import React from 'react';
import {Modal} from "semantic-ui-react";
import {Button} from "../base";
import {CustomModalHeader} from '../custom-common';

const RemovePaymentMethodModal = (props) => {
    const {show, close, remove, paymentMethod, autoPay, pmListLength} = props;
    const {isDefault} = paymentMethod;

    const handleSubmit = () => {
        remove(paymentMethod);
        close();
    };

    const modalHeaderTitle = !isDefault || (isDefault && !autoPay && pmListLength === 1) ? 'Delete payment method?' : 'Default payment method cannot be deleted';

    return (
        <Modal size={'tiny'} onClose={close} open={show} style={{maxWidth: '502px'}}>
            <CustomModalHeader title={modalHeaderTitle} onClose={close} className="customModalHeader" />
            <Modal.Content style={{overflowY: 'auto'}}>
                {isDefault ?
                    (autoPay ?
                        pmListLength > 1 ?
                            <>You cannot delete this payment method because it's set as your default and used for automatic payments. To delete this payment method, change your default payment method or turn off Autopay.</>
                            : <>You cannot delete this payment method because it's set as your default and used for automatic payments. To delete this payment method, add another payment method and set it to default or turn off Autopay.</>
                        :
                        pmListLength > 1 ?
                            <>You cannot delete this payment method because it's set as your default. To delete this payment method you need to change your default payment method.</>
                            : <>Are you sure you want to delete your last e-payment method? If you don't add another e-payment method, future payments will need to be mailed in by check.</>
                    ) : <>Are you sure you want to delete this payment method?</>
                }
                <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
                    {(!isDefault || (isDefault && !autoPay && pmListLength === 1)) && <>
                        <Button color='grey' basic onClick={close}>Cancel</Button>
                        <Button color="red" onClick={handleSubmit}>Delete</Button>
                    </>}
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default RemovePaymentMethodModal;
