import React from 'react';
import {Grid} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {CustomRow} from "./customRow";
import {getKeyOrValueAndKey, mergeStrings} from './utils/utils';


export const CustomGrid = ({ fitted, compact, className,...rest }) => {
    const fittedClass = getKeyOrValueAndKey(fitted, 'fitted')
    const compactClass = getKeyOrValueAndKey(compact, 'compact')
    const classes = mergeStrings(className, fittedClass, compactClass);
    return <Grid {...rest} className={classes}/>
};

CustomGrid.Row = CustomRow;
CustomGrid.Column = Grid.Column;

CustomGrid.propTypes = {
    fitted: PropTypes.oneOfType([
        PropTypes.bool, PropTypes.oneOf(['horizontally', 'vertically'])
    ]),
    compact: PropTypes.oneOfType([
        PropTypes.bool, PropTypes.oneOf(['horizontally', 'vertically'])
    ]),
};