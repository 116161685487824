import React, { useState } from "react";
import PropTypes from 'prop-types';
import {Form} from "semantic-ui-react";
import {isValidEmail} from "../../utils/utils";

export const CustomEmailInput = ({ value, name, onChange, onFocus, readOnly = false, required = false, label = "Email address", placeholder = "Enter your email", width, onBlur, customError, fluid, ...props }) => {
    const [inputError, setInputError] = useState(false);

    const handleFocus = (e) => {
      setInputError(false)
      if(onFocus){
        onFocus(e);
      }
    }

    const handleBlur = (e) => {
      if(!customError){
        validateEmail(e.target.value);
      }
      if(onBlur){
        onBlur(e);
      }
    };

    const validateEmail = (email) => {
        let error = null;
        if (!email) {
            error = required ? "Email is required" : null;;
        } else if (!isValidEmail(email)) {
            error = "Invalid email";
        }
        setInputError(error);
    };

    const handleChange = (e, { name, value }) => {
        setInputError(false)
        onChange(e, { name, value });
    };

    return (
      <Form.Field
        error={!!inputError || customError}
        width={width}
      >
        <Form.Input
          label={label}
          value={value}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          className={"customEmailInput"}
          readOnly={readOnly}
          required={required}
          {...props}
        />
        {customError ? (
          <span className="warningRedText smaller">{customError}</span>
        ) : (
          inputError && (
            <span className="warningRedText smaller">{inputError}</span>
          )
        )}
      </Form.Field>
    );
};

CustomEmailInput.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    onBlur: PropTypes.func,
    customError: PropTypes.string,
    fluid: PropTypes.bool,
    onFocus: PropTypes.func
};