import React from "react";
import {MFAButtonOutlined, MFAGrid, MFAHeading, MFAIcon, MFASettingItem, MFAText} from "../styled";

export const MfaMethodItem = ({
  title,
  description,
  createdTime,
  ableToBeReconfigured = true,
  onClickReconfigurable
}) => {

  if(ableToBeReconfigured && !onClickReconfigurable) {
    throw new Error("Missing onClickReconfigurable")
  }

  return (
    <MFASettingItem>
      <MFAGrid>
        <MFAGrid.Row columns={ableToBeReconfigured ? 2 : 1}>
          <MFAGrid.Column floated="left">
            <React.Fragment>
              <MFAHeading
                as="h5"
                textAlign="left"
                className={"dark"}
              >
                {title}
              </MFAHeading>
              <MFAText className={"settings"}>
                <span className={"bold"}>
                  {description}
                </span>{" "}
                is configured for your account.
              </MFAText>
            </React.Fragment>

            <MFAText className={"settings"}>
              <MFAIcon
                name="checkmark"
                size="small"
                className="verifiedIcon"
              />{" "}
              Date added {createdTime}
            </MFAText>
          </MFAGrid.Column>
          {ableToBeReconfigured && (
            <MFAGrid.Column
              floated="right"
              className={'flexEnd'}
            >
              <MFAButtonOutlined
                primary
                onClick={onClickReconfigurable}
                className={"settings"}
              >
                Re-configure{" "}
                <MFAIcon
                  name={"pencil"}
                  floated={"right"}
                  className={"settingsIcon"}
                />
              </MFAButtonOutlined>
            </MFAGrid.Column>
          )}
        </MFAGrid.Row>
      </MFAGrid>
    </MFASettingItem>
  )
}
