import React, {Component} from 'react';
import {Form, Message, Modal, TextArea,} from 'semantic-ui-react';
import {Button} from "./base";
import axios from 'axios';
import {connect} from 'react-redux';
import {MessagesContext} from '../contexts/messages'
import {readFileAsync} from '../utils/common'
import {toggle} from '../actions/getHelpActions'
import {show as showToast} from '../actions/toastActions'
import {UploadFile} from './messages/partials/uploadFile'
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import {CustomModalHeader} from './custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        user: state.initialData.user,
        show: state.getHelp.show
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (toastDetails) => dispatch(showToast(toastDetails)),
        toggle: (state) => dispatch(toggle(state)),
    }
};

const defaultState = {
    subject: '',
    message: '',
    email: '',
    loading: false,
    submitError: '',
    fullName: '',
    emailValidationError: '',
    files: []
}

class GetHelpModal extends Component {
    static contextType = MessagesContext

    constructor(props) {
        super(props);
        this.state = {...defaultState};
    }

    handleSubmit = async () => {
        const {refresh} = this.context
        const {user, showToast} = this.props;
        const {subject, message, files} = this.state;
        let fullName = `${user.firstName} ${user.lastName}`;

        this.setState({loading: true, error: null});
        try {
            const attachments = await this.processAttachments(files)

            const payload = {
                name: fullName,
                subject: subject,
                message: message,
                attachments
            };

            await axios.post('/api/member/v2/submitMessage', payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'authToken': this.props.authToken
                }
            });
            refresh()
            this.setState({...defaultState});
            showToast({type:'success', message:'Your message has been sent!'})
            this.handleClose();
        } catch (e) {
            this.setState({loading: false, submitError: 'Unable to submit your message'});
        }
    }

    handleClose = () => {
        const {show, toggle} = this.props;

        if (show) toggle(false);
    }

    handleFilesUpdate = (files) => {
        this.setState({files})
    }

    processAttachments = async (files) => {
        if (!files?.length) return

        return Promise.all(files.map(async file => {
            const content = await readFileAsync(file)
            const contentData = content.split(',')[1]

            return {
                fileName: file.name,
                contentType: file.type,
                contentData
            }
        }))
    }

    render() {
        const {show} = this.props;
        const {files, subject, message, submitError, loading} = this.state;

        const spanishHelp = "¿Necesitas ayuda en español? Llama al (888) 290-1153 para recibir ayuda."
        const modalHeaderTitle = `Message ${STRATEGIC_PARTNER.LABEL} Support`

        return (
            <Modal size={'tiny'} onClose={this.handleClose} open={show} className={'getHelpModal'}>
                <CustomModalHeader title={modalHeaderTitle} onClose={this.handleClose} className="customModalHeader" />
                <Modal.Content className={"small"}>
                    <p>Have a question? Send us a note and we’ll get back to you shortly—</p>
                    <Form autoComplete="false">

                        <Form.Input
                            disabled={loading}
                            label={"Subject"} required
                            value={subject}
                            onChange={(_, {value}) => this.setState({subject: value})}
                        />

                        <Form.Field required>
                            <label>{"Message"}</label>
                            <TextArea
                                disabled={loading}
                                value={message}
                                onChange={(_, {value}) => this.setState({message: value})}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>{"Attachments"}</label>
                            <UploadFile
                                disabled={loading}
                                files={files}
                                validFileTypes={[
                                    'image/jpeg',
                                    'image/jpg',
                                    'image/png',
                                    'application/pdf'
                                ]}
                                onFilesUpdate={files => this.handleFilesUpdate(files)}
                                style={{marginTop: 0}}
                            />
                        </Form.Field>

                        <div className='spanishHelpBox'>
                            {spanishHelp}
                        </div>

                        {!!submitError &&
                            <div><Message negative className='getHelpSubmitError'>{submitError}</Message></div>}

                        <div className='ui right aligned container'>
                            <Button primary basic onClick={this.handleClose} className="clHelpButton">{"Cancel"}</Button>
                            <Button
                                primary
                                loading={loading}
                                disabled={loading || subject.length === 0 || message.length === 0}
                                onClick={this.handleSubmit}
                            >{"Submit"}</Button>
                        </div>
                    </Form>
                </Modal.Content>
            </Modal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetHelpModal);

