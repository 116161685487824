import React from "react";
import {List} from "semantic-ui-react";
import {S3Image} from "../../custom-common";

const DocumentExampleCard = ({title, imageName, listItems}) => (
    <div className="documentExampleCard">
        <S3Image name={imageName}/>
        <p><b>{title}</b></p>
        <List bulleted className="small uploads">
            {listItems.map((listItem, idx) => <List.Item key={idx}>{listItem}</List.Item>)}
        </List>
    </div>
);

export default DocumentExampleCard;
