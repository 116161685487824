import React, {useState} from 'react';
import {
    Button,
    Divider,
    Form,
    Message,
    Input,
    Segment
} from "semantic-ui-react";
import NumberFormat from "react-number-format";
import {isValidPhoneNumber} from "../../utils/utils";

const formatPhoneNumber = "(###) ###-####";
const RAW_PHONE_NUMBER_LENGTH = 10;

const NumberFormatInput = ({fieldName, label, onInput, getError, cellNumber, confirmCellNumber, validateFields, onFocus}) => {
    return (
        <Form.Field required error={!!getError(fieldName)}>
            <label>{label}</label>
            <NumberFormat
                format={formatPhoneNumber}
                placeholder="(XXX) XXX-XXXX"
                required
                fluid
                customInput={Input}
                value={fieldName === 'cellNumber' ? cellNumber : confirmCellNumber || ""}
                name={fieldName}
                onInput={onInput}
                onBlur={(e) => validateFields(e)}
                onFocus={onFocus}
            />
            {!!getError(fieldName) && <small className={"warningRedText"}>{getError(fieldName)}</small>}
        </Form.Field>
    );
};

const RenderVenmoConfigForm = ({cellNumber = '', confirmCellNumber = '', loading, submitError, setError, getError, handleChange, configureVenmo, closeModal}) => {

    const [validPhoneNumber, setValidPhoneNumber] = useState(false);

    const onChange = (e) => {
        const {name, value: newValue = ''} = e.target;
        let sanitizedValue = newValue.replace(/\D/g, "");
        if (sanitizedValue.length > RAW_PHONE_NUMBER_LENGTH) {
            sanitizedValue = sanitizedValue.slice(0, RAW_PHONE_NUMBER_LENGTH);
        }
        setError(name, '');
        if (name === 'cellNumber' && sanitizedValue === confirmCellNumber) {
            setError('confirmCellNumber', '');
        }
        handleChange(e, {name, value: sanitizedValue});
    }

    const validateFields = ({target: {name, value}}) => {
        const validNumber = isValidPhoneNumber(value);
        setValidPhoneNumber(validNumber);
        if (name === 'cellNumber') {
            if (!validNumber) {
                setError(name, "Invalid phone number format");
            }
            const sanitizedValue = value.replace(/\D/g, "");
            if (confirmCellNumber && sanitizedValue !== confirmCellNumber) {
                setError("confirmCellNumber", "Your phone numbers do not match. Please try again.");
            }
        } else if (cellNumber !== confirmCellNumber) {
            setError("confirmCellNumber", "Your phone numbers do not match. Please try again.");
        }
    };

    const onFocus = ({target: {name}}) => {
        setError(name, '');
    }

    const disabled = !cellNumber || !confirmCellNumber || !validPhoneNumber || loading || cellNumber !== confirmCellNumber;

    return (
        <>
            <Form className="venmoConfig">
                <NumberFormatInput onFocus={onFocus} fieldName={"cellNumber"} label="Phone number" onInput={onChange} validateFields={validateFields} getError={getError}
                                   cellNumber={cellNumber} confirmCellNumber={confirmCellNumber}/>
                <NumberFormatInput onFocus={onFocus} fieldName={"confirmCellNumber"} label="Confirm phone number" onInput={onChange} validateFields={validateFields}
                                   getError={getError} cellNumber={cellNumber} confirmCellNumber={confirmCellNumber}/>
            </Form>
            <Divider hidden/>
            {!!submitError && <Message negative>{submitError}</Message>}
            <Segment basic vertical clearing style={{paddingBottom: 0}}>
                <Button
                    primary
                    floated={"right"}
                    loading={loading}
                    disabled={disabled}
                    onClick={configureVenmo}
                >
                    Submit
                </Button>
                <Button color={"grey"} basic floated={"right"} onClick={closeModal}>
                    Cancel
                </Button>
            </Segment>
        </>
    );
}

export default RenderVenmoConfigForm;
