import React, {Component} from "react"
import {Divider, Grid, Image} from "semantic-ui-react";
import {Header} from "../../../base";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,

    }
};

class ChangeAddressSuccess extends Component {

    render() {
        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                    <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Grid stackable container style={{marginTop: '3em'}} textAlign={'center'}>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Image centered style={{width: '70px'}} src="https://brella-platform-assets.s3.amazonaws.com/enrollment/images/success.svg"/>
                                        <Header as={"h2"}>Success!</Header>
                                        <p>Your address change was successful.</p>
                                        <Divider hidden/>
                                        <NavLink to={"/plan"}>Back to your account</NavLink>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <Footer
                        showTopFooter={false} showCenterData={true}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ChangeAddressSuccess));
