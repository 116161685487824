import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from "redux-devtools-extension"
import rootReducer from "./reducers/rootReducer"
import {persistState, loadState} from "./storePersistence";

const middleware = [];
const devEnhancer = composeWithDevTools(
    applyMiddleware(...middleware)
);

const persistedData = loadState();
export const store = createStore(rootReducer, persistedData, devEnhancer);
store.subscribe(() => {
    const newState = store.getState();
    const {newClaim, newMessages, toast, ...state} = newState;
    persistState({...state});
});

