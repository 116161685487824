import React, {Component} from 'react'
import {Menu} from 'semantic-ui-react'
import {BrandedLogo} from "./base";
import UserMenu from './userMenu'

class MainMenu extends Component {
    render() {
        const {center} = this.props;
        return (
            <React.Fragment>
                <Menu fixed='top' secondary pointing className="member-menuContainer">
                    <div className={'menuLogoColumn'}>
                        <BrandedLogo className={"member-headerLogo"}/>
                    </div>
                    <div className={'menuCenterColumn'}>
                         <b>{center}</b>
                    </div>
                    <UserMenu/>
                </Menu>
                <div className={"member-header-shadow"}/>
            </React.Fragment>
        )
    }
}

export default MainMenu;
