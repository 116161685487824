import React, {Component} from "react"
import {Divider, Grid, Radio, Table} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {Button, Header} from "../../../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import {setDependentsId, setMaritalStatus, setTotalDependents, setWantUpdatedRecommendations} from "../../../../actions/planChangeActions";
import NumberFormat from "react-number-format";
import {
    ADD_DEPENDENT_ROUTE,
    ADD_STEP_CHILDREN_ROUTE,
    CHILD,
    CONFIRM_ADDRESS_CHANGE_ROUTE,
    CONFIRM_CHILD_REMOVAL,
    convertServerDateFormatToDisplay,
    DOMESTIC_PARTNER,
    formatMoneyAmount,
    HAVE_STEP_CHILDREN_ROUTE,
    resolveBrellaPlanPath,
    SPOUSE
} from "../../../../utils/common";
import moment from "moment";
import {setPrevRoute} from "../../../../actions/navigationActions";
import {getPayrollFrecuencyLabel} from "../../../../utils/utils";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        wantUpdatedRecommendations: state.planChange.wantUpdatedRecommendations,
        benefits: state.initialData.certificate.assetCoverages,
        dependents: state.initialData.dependents,
        cost: state.initialData.cost,
        employerContribution: state.initialData.employerContribution,
        payrollFrequency: state.initialData.payrollFrequency,
        planType: state.initialData.contract.planOffering,
        certificate: state.initialData.certificate,
        planChangeDependents: state.planChange.planChangeDependents,
        qleReason: state.planChange.qleReason,
        prevRoute: state.navigation.prevRoute
    }
};

class UpdateCoverage extends Component {

    componentDidMount() {
        const {qleReason} = this.props;
        if (!!qleReason) {
            this.setFamilyInfo()
        }
        let qle = new Map();
        qle.set("Birth or Adoption of Child", ADD_DEPENDENT_ROUTE)
        qle.set("Gain of Child Custody", ADD_DEPENDENT_ROUTE)
        qle.set("Marriage", `${this.props.haveStepChildren ? ADD_STEP_CHILDREN_ROUTE : HAVE_STEP_CHILDREN_ROUTE}`)
        qle.set("New Domestic Partner", ADD_DEPENDENT_ROUTE)
        qle.set("Loss of Child Custody", CONFIRM_CHILD_REMOVAL)
        qle.set("Address Change", CONFIRM_ADDRESS_CHANGE_ROUTE)

        const prevRoute = qle.get(this.props.qleReason)
        this.props.dispatch(setPrevRoute(prevRoute))

        if (this.props.wantUpdatedRecommendations === null) {
            this.props.dispatch(setWantUpdatedRecommendations(true))
        }
    }

    setFamilyInfo = () => {
        const {planChangeDependents, dependents} = this.props;
        const totalDependents = dependents.concat(planChangeDependents);
        this.props.dispatch(setTotalDependents(totalDependents));
        const hasSpouse = totalDependents.filter(dependent => dependent.relToEmployee === SPOUSE || dependent.relToEmployee === DOMESTIC_PARTNER).length > 0;
        const maritalStatus = !!hasSpouse ? 1 : 2;
        this.props.dispatch(setMaritalStatus(maritalStatus))
        const children = totalDependents.filter(dependent => dependent.relToEmployee === CHILD);
        let hasChildUnder18 = false;
        if (!!children) {
            const mNow = moment(Date.now());
            const childUnder18 = children.filter(child => mNow.diff(moment(child.birthDate), "years") < 18)
            hasChildUnder18 = !!childUnder18 && childUnder18.length > 0
        }
        const dependentsId = !children ? 3 : !!hasChildUnder18 ? 1 : 2;
        this.props.dispatch(setDependentsId(dependentsId));
    }

    onNext = () => {
        const {wantUpdatedRecommendations, planType} = this.props;
        if (!!wantUpdatedRecommendations) {
            this.props.history.push("/change/update/healthPlan")
        } else {
            const plan = resolveBrellaPlanPath(planType)
            this.props.history.push(`${plan}?skipRecommendation`)
        }
    }

    onBackHandler = () => {
        this.props.history.push(this.props.prevRoute)
    }

    select = (value) => {
        this.props.dispatch(setWantUpdatedRecommendations(value))
    }

    render() {
        const {
            wantUpdatedRecommendations,
            benefits,
            dependents,
            payrollFrequency,
            cost,
            employerContribution
        } = this.props;
        const displayPayrollFrecuency = getPayrollFrecuencyLabel(payrollFrequency, true)
        const button = <Button disabled={wantUpdatedRecommendations === null} primary content="Next step"
                               onClick={this.onNext}/>;

        const spouse = dependents.filter((dependent) => dependent.relToEmployee === SPOUSE)[0];
        const domesticPartner = dependents.filter((dependent) => dependent.relToEmployee === DOMESTIC_PARTNER)[0];
        const children = dependents.filter((dependent) => dependent.relToEmployee === CHILD);
        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Header as={"h2"}>Let’s update your coverage</Header>
                            <p>Now it’s time to update your benefit selections. Take a short quiz to see updated
                                recommendations or skip to adjust your payouts— </p>
                            <Divider hidden/>
                            <Grid centered>
                                <Grid.Row verticalAlign={"middle"}>
                                    <Grid.Column width={1} style={{paddingRight: 0}} textAlign={"right"}>
                                        <Radio
                                            onChange={() => {
                                                this.select(true)
                                            }}
                                            checked={wantUpdatedRecommendations === true}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Table basic onClick={() => {
                                            this.select(true)
                                        }} className={"clickable"}>
                                            <Table.Body>
                                                <Table.Row verticalAlign={"middle"}>
                                                    <Table.Cell style={{height: '76px'}}
                                                                className={"neutral700Text"}><b>I’d like to get updated
                                                        recommendations</b></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                    <Grid.Column width={1}/>
                                </Grid.Row>
                                <Grid.Row verticalAlign={"middle"} style={{paddingTop: 0}}>
                                    <Grid.Column width={1} style={{paddingRight: 0}} textAlign={"right"}>
                                        <Radio
                                            onChange={() => {
                                                this.select(false)
                                            }}
                                            checked={wantUpdatedRecommendations === false}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Table basic onClick={() => {
                                            this.select(false)
                                        }} className={"clickable"}>
                                            <Table.Body>
                                                <Table.Row verticalAlign={"middle"}>
                                                    <Table.Cell style={{height: '76px'}}
                                                                className={"neutral700Text"}><b>I don’t need
                                                        recommendations,<br/> I’m ready to adjust my
                                                        benefits</b></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                    <Grid.Column width={1}/>
                                </Grid.Row>
                            </Grid>
                            <Divider hidden/>
                            <Divider hidden/>
                            <Header as={"h4"}>Current coverage</Header>
                            <Grid columns={2} className="small">
                                <Grid.Column>
                                    <span className={"neutral600Text"}><b>Benefit amounts</b></span>
                                    <Table basic={"very"} style={{marginTop: '.5em'}}>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <b>Moderate</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <NumberFormat value={benefits.moderate} displayType={'text'}
                                                                  thousandSeparator={true} prefix={'$'}/>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <b>Severe</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <NumberFormat value={benefits.severe} displayType={'text'}
                                                                  thousandSeparator={true} prefix={'$'}/>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <b>Catastrophic</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <NumberFormat value={benefits.catastrophic} displayType={'text'}
                                                                  thousandSeparator={true} prefix={'$'}/>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <Divider hidden/>

                                    {(!!benefits.chronic || !!benefits.mental) && <React.Fragment>
                                        <span className={"neutral600Text"}><b>Additional coverage</b></span>
                                        <Table basic={"very"} style={{marginTop: '.5em'}}>
                                            <Table.Body>
                                                {!!benefits.chronic && <Table.Row>
                                                    <Table.Cell>
                                                        <b>Chronic</b>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <NumberFormat value={benefits.chronic} displayType={'text'}
                                                                      thousandSeparator={true} prefix={'$'}/>
                                                    </Table.Cell>
                                                </Table.Row>}
                                                {!!benefits.mental && <Table.Row>
                                                    <Table.Cell>
                                                        <b>Mental</b>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <NumberFormat value={benefits.mental} displayType={'text'}
                                                                      thousandSeparator={true} prefix={'$'}/>
                                                    </Table.Cell>
                                                </Table.Row>}
                                            </Table.Body>
                                        </Table>
                                    </React.Fragment>}
                                </Grid.Column>
                                <Grid.Column>
                                    {(!!dependents && dependents.length > 0) && <React.Fragment>
                                        <span className={"neutral600Text"}><b>Dependents</b></span>
                                        <Table basic={"very"}>
                                            <Table.Body>
                                                {!!spouse && <Table.Row>
                                                    <Table.Cell className={'spaceNoWrap'}
                                                                verticalAlign={"top"}><b>Spouse</b></Table.Cell>
                                                    <Table.Cell>{`${spouse.firstName} ${spouse.lastName} (${convertServerDateFormatToDisplay(spouse.birthDate)})`}</Table.Cell>
                                                </Table.Row>}
                                                {!!domesticPartner && <Table.Row>
                                                    <Table.Cell className={'spaceNoWrap'} verticalAlign={"top"}><b>Domestic
                                                        partner</b></Table.Cell>
                                                    <Table.Cell>{`${domesticPartner.firstName} ${domesticPartner.lastName} (${convertServerDateFormatToDisplay(domesticPartner.birthDate)})`}</Table.Cell>
                                                </Table.Row>}
                                                {!!children && children.length > 0 && children.map((cd, idx) => {
                                                    return <Table.Row key={idx}>
                                                        <Table.Cell className={'spaceNoWrap'}
                                                                    verticalAlign={"top"}><b>Child</b></Table.Cell>
                                                        <Table.Cell>{`${cd.firstName} ${cd.lastName} (${convertServerDateFormatToDisplay(cd.birthDate)})`}</Table.Cell>
                                                    </Table.Row>
                                                })}
                                            </Table.Body>
                                        </Table>
                                        <Divider hidden/><Divider hidden/>
                                    </React.Fragment>}

                                    <CustomContainer basic className={"bkgFog"} compact>
                                        {!!employerContribution && <React.Fragment>
                                            <div className="smaller neutral600Text">
                                                Employer contribution
                                            </div>
                                            <div className={"vibranBlueText"}>
                                                <b>${formatMoneyAmount(employerContribution)}/{displayPayrollFrecuency}</b>
                                            </div>
                                            <br/>
                                        </React.Fragment>
                                        }
                                        <div className="smaller neutral600Text">Your cost</div>
                                        <Header as={"h4"} style={{margin: 0}}>${formatMoneyAmount(cost)}/{displayPayrollFrecuency}</Header>
                                    </CustomContainer>
                                </Grid.Column>
                            </Grid>

                        </Grid.Column>
                    </Grid>
                    <Footer
                        showTopFooter={true} showCenterData={true} button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(UpdateCoverage));
