import React from "react";

import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {Header} from "../../base";
import {Grid} from "semantic-ui-react";

import DOCUMENT_EXAMPLES from "./documentExamplesConstant";
import DocumentExampleCard from "./documentExampleCard";
import DocumentExamplesProTip from "./documentExamplesProTip";
import {CustomModalHeader, S3Image} from "../../custom-common";

const DocumentExamplesModal = ({onClose, display}) => (
    <Modal size={'large'} onClose={onClose} open={display} style={{maxWidth: 884}}>
        <CustomModalHeader title={"Types of documents"} onClose={onClose} className="customModalHeader" />
        <Modal.Content className={"small"}>
            <Header textAlign={"center"} as={"h2"}>Document examples</Header>
            <div className="centerAligned" style={{marginBottom: 40}}>
                <p className="uploadInstructionsModalDescription">
                    <S3Image name="cameraicon" centered verticalAlign='middle'/> Photos or scans of documents work just fine—</p>
            </div>
            <div style={{display: 'flex', flexDirection:'row', justifyContent: "space-around", marginBottom: '16px', marginTop: '16px'}}>
                { DOCUMENT_EXAMPLES.map(documentExample => <DocumentExampleCard {...documentExample} />) }
            </div>
            <Grid container stackable columns={1} centered padded={'vertically'} className={"proTip"}>
                <Grid.Column >
                <DocumentExamplesProTip/>
                </Grid.Column>
            </Grid>
        </Modal.Content>
    </Modal>
);

export default DocumentExamplesModal;
