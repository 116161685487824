import {Confirm, Grid, Icon, Label} from "semantic-ui-react";
import {Button, Header} from "../base";
import React, {useEffect, useState} from "react";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";

import PlaidMicroDeposits from "./plaidMicroDeposits";

const MicroDepositsBankAccount = ({strings, plaidEnviroment, setPlaidData}) => {
    const {
        microDepositsBankAccountPmData,
        removePm,
        bankAccountVerificationStatus,
        showRemovePendingConfirmationModal,
        setOpenRemovePendingConfirmationModal,
        removeMicroDepositsBankAccount,
    } = PaymentDetailsContext.useContainer()

    const {plaidLinkToken} = microDepositsBankAccountPmData
    const [verificationStatusLabelStyle, setVerificationStatusLabelStyle] = useState("")
    const [verificationStatusIconStyle, setVerificationIconStyle] = useState("")

    useEffect(()=>{
        switch (bankAccountVerificationStatus) {
            case "Unable to verify":
                setVerificationStatusLabelStyle("label-verificationStatus label-bkgWarning50")
                setVerificationIconStyle("icon-secondary-deep-purple  icon-small icon-vertical-allign")
                break
            case "Pending verification":
                setVerificationStatusLabelStyle("label-verificationStatus label-bkgSunshade50")
                setVerificationIconStyle("icon-primary-sunshade icon-small icon-vertical-allign")
                break
            default:
                setVerificationStatusLabelStyle("label-verificationStatus label-bkgWarning50")
                setVerificationIconStyle("icon-primary-sunshade icon-small icon-vertical-allign")
                break
        }
    },[bankAccountVerificationStatus])

    return (
        <>
            <div>
                <Header style={{fontSize: '16px', lineHeight: '21px', margin: '0px 0px'}}>
                    <strong>{strings.paymentRequired.bankAccount} <Icon color="red" name='warning circle'/> </strong>
                </Header>
                <p style={{fontSize: '14px', margin: '0px 10px', color: '#616161'}}>{`${microDepositsBankAccountPmData.name}`}</p>
                <Grid.Row >
                    <Grid.Column>
                        <Label className={`${verificationStatusLabelStyle} normal`} compact size={"mini"}>
                            <p style={{ fontSize: '14px', fontWeight:'400', color:'#000000', marginBottom:'2px'}} >
                                <Icon className={verificationStatusIconStyle} name="circle" />
                                {bankAccountVerificationStatus}
                            </p>
                        </Label>
                        <PlaidMicroDeposits
                            plaidEnviroment={plaidEnviroment}
                            plaidLinkToken={plaidLinkToken}
                            setPlaidData={setPlaidData}
                            loading={false}
                            removePm={removePm}
                        ></PlaidMicroDeposits>
                    </Grid.Column>
                </Grid.Row>
            </div>
            { bankAccountVerificationStatus === "Pending verification" &&
                <Confirm
                    open={showRemovePendingConfirmationModal}
                    style={{maxWidth: '517.89'}}
                    size='tiny'
                    header={strings.confirmRemovePendingVerification.header}
                    content={strings.confirmRemovePendingVerification.contentSwitching}
                    cancelButton={<Button basic primary content={strings.confirmRemovePendingVerification.cancel} onClick={() => {
                        setOpenRemovePendingConfirmationModal(false)
                    }}/>}
                    confirmButton={<Button content={strings.confirmRemovePendingVerification.confirm} onClick={() => {
                        removeMicroDepositsBankAccount()
                        removePm()
                        setOpenRemovePendingConfirmationModal(false)
                    }}/>}
                />
            }
        </>
    );
}

export default MicroDepositsBankAccount
