import React, {Component} from "react"
import {Divider, Grid, Message, Table} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {Button, Header} from "../../../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {convertDateToLongFormat, convertDisplayDateFormatToServer, formatMoneyAmount, getCoverageEffectiveDate, resolveErrorMessage} from "../../../../utils/common";
import NumberFormat from "react-number-format";
import axios from "axios";
import ChangeSteps from "../changeSteps";
import {getPayrollFrecuencyLabel} from "../../../../utils/utils";

const mapStateToProps = (state, ownProps) => {
    const {initialData} = state;
    return {
        ...ownProps,
        prevRoute: state.navigation.prevRoute,
        benefits: state.initialData.certificate.assetCoverages,
        employerContribution: state.initialData.employerContribution,
        cost: state.initialData.cost,
        payrollFrequency: state.initialData.payrollFrequency,
        dependents: state.initialData.dependents,
        updatedCost: state.planChange.cost,
        updatedEmployerContribution: state.planChange.employerContribution,
        totalDependents: state.planChange.totalDependents,
        moderateCoverageSelected: state.planChange.moderateCoverageSelected,
        severeCoverageSelected: state.planChange.severeCoverageSelected,
        catastrophicCoverageSelected: state.planChange.catastrophicCoverageSelected,
        costPerOption: state.planChange.costPerOption,
        costPerOptions: state.planChange.costPerOptions,
        qleReason: state.planChange.qleReason,
        qleDate: state.planChange.qleDate,
        coverageOption: state.planChange.coverageOption,
        newAddress: state.planChange.newAddress,
        user: state.initialData.user,
        contract: state.initialData.contract,
        moderateIncrements: (!!initialData && !!initialData.increments && initialData.increments.moderate) || [],
        severeIncrements: (!!initialData && !!initialData.increments && initialData.increments.severe) || [],
        catastrophicIncrements: (!!initialData && !!initialData.increments && initialData.increments.catastrophic) || [],
        certificate: initialData.certificate
    }
};

class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitError: null,
            submitting: false,
            moderateCoverage: this.props.moderateCoverageSelected || 0,
            severeCoverage: this.props.severeCoverageSelected || 0,
            catastrophicCoverage: this.props.catastrophicCoverageSelected || 0
        }
    }

    translateSliderSelectionToAmount = () => {
        const {
            moderateIncrements,
            severeIncrements,
            catastrophicIncrements,
            moderateCoverageSelected,
            severeCoverageSelected,
            catastrophicCoverageSelected
        } = this.props;
        return {
            moderateCoverageSelected: moderateIncrements[moderateCoverageSelected - 1],
            severeCoverageSelected: severeIncrements[severeCoverageSelected - 1],
            catastrophicCoverageSelected: catastrophicIncrements[catastrophicCoverageSelected - 1],
        }
    }

    onSubmit = async () => {
        const {
            qleReason,
            qleDate,
            totalDependents,
            coverageOption,
            newAddress,
            moderateCoverageSelected,
            severeCoverageSelected,
            catastrophicCoverageSelected
        } = this.props;
        try {
            this.setState({
                submitError: null,
                submitting: true
            })
            let payload = {
                qleReason: qleReason,
                qleDate: !!qleDate ? convertDisplayDateFormatToServer(qleDate) : null,
                benefitModerate: !!moderateCoverageSelected ? moderateCoverageSelected : 0,
                benefitSevere: !!severeCoverageSelected ? severeCoverageSelected : 0,
                benefitCatastrophic: !!catastrophicCoverageSelected ? catastrophicCoverageSelected : 0,
                newAddress: !!newAddress.street ? newAddress : null,
                dependents: totalDependents,
                familyTier: coverageOption
            }

            await axios.post("/api/member/v2/submitPlanChanges", payload);
            this.props.history.push("/change/update/planChangeSuccess")
        } catch (e) {
            this.setState({
                    submitError: resolveErrorMessage(e, "There has been a problem submitting your plan update."),
                    submitting: false
                }
            )
        }
    }

    onCancel = () => {
        this.props.history.push("/plan")
    }

    onBackHandler = () => {
        this.props.history.replace(this.props.prevRoute)
    }

    render() {
        const {
            qleReason,
            qleDate,
            coverageOption,
            benefits,
            employerContribution,
            cost,
            payrollFrequency,
            dependents,
            updatedCost,
            updatedEmployerContribution,
            totalDependents,
            moderateCoverageSelected,
            severeCoverageSelected,
            catastrophicCoverageSelected,
            costPerOption,
            costPerOptions,
            user
        } = this.props;
        const {submitError, submitting} = this.state;
        const button = <Button primary disabled={submitting} loading={submitting} content="Submit"
                               onClick={this.onSubmit}/>;
        const displayPayrollFrecuency = getPayrollFrecuencyLabel(payrollFrequency)
        const displayCostPerOption = costPerOptions.filter(option => option.id === costPerOption)[0].value.toLowerCase();
        const link = <span className={"linkText"} onClick={this.onCancel}>or Cancel</span>

        let updatedCoveredDependents = [...totalDependents];
        if (coverageOption === 'child') {
            updatedCoveredDependents = updatedCoveredDependents.filter(dep => dep.relToEmployee === "Child")
        } else if (coverageOption === 'spouse') {
            updatedCoveredDependents = updatedCoveredDependents.filter(dep => dep.relToEmployee === "Spouse")
        } else if (coverageOption === 'employee') {
            updatedCoveredDependents = []
        }

        const effectiveDate = getCoverageEffectiveDate(!!qleReason, qleReason, qleDate);

        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={3}/>
                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={10} className={"pageContent"}>
                            <Grid stackable container textAlign={'center'}>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Header as={"h2"}>Confirm your plan change</Header>
                                        <p>Here are your current plan details and the updated plan with your
                                            selections—</p>
                                        <Divider hidden/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <div style={{border: "1px solid #E0E0E0"}}>
                                            <div className={"bkgFog"}
                                                 style={{padding: '0.8em', textAlign: 'center', height: '56px'}}>
                                                <Header as={"h3"}>Current Brella coverage</Header>
                                            </div>
                                            <div style={{height: '37px'}}/>
                                            <CustomContainer basic className={"small"} textAlign={"left"}>
                                                <span className={"neutral600Text"}><b>Benefit amounts</b></span>
                                                <Table basic={"very"} style={{marginTop: '0'}}>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <b>Moderate</b>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={'right'}>
                                                                <NumberFormat value={benefits.moderate}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} prefix={'$'}/>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <b>Severe</b>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={'right'}>
                                                                <NumberFormat value={benefits.severe}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} prefix={'$'}/>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <b>Catastrophic</b>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={'right'}>
                                                                <NumberFormat value={benefits.catastrophic}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} prefix={'$'}/>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>

                                                <Divider hidden/>

                                                {!!employerContribution && <React.Fragment>
                                                    <span
                                                        className={"neutral600Text"}><b>Employer contribution</b></span>
                                                    <p><b>${formatMoneyAmount(employerContribution)}</b></p>
                                                    <Divider hidden/>
                                                </React.Fragment>
                                                }

                                                <span className={"neutral600Text"}><b>Who’s covered?</b></span>
                                                <p>
                                                    {!!user &&
                                                    <span><b>{`${user.firstName} ${user.lastName}`}</b></span>}
                                                    {(!!dependents && dependents.length > 0) && <React.Fragment>,{' '}
                                                        {dependents.map((dependent, index) => <span
                                                            key={index}><b>{(index ? ', ' : '') + `${dependent.firstName} ${dependent.lastName}`}</b></span>)}
                                                    </React.Fragment>
                                                    }
                                                </p>

                                                {(!!benefits && (!!benefits.chronic || !!benefits.mental)) &&
                                                <React.Fragment>
                                                    <Divider hidden/>
                                                    <span className={"neutral600Text"}><b>Additional coverage</b></span>
                                                    <Table basic={"very"} style={{marginTop: '0'}}>
                                                        <Table.Body>
                                                            {!!benefits.chronic && <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Chronic</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={'right'}>
                                                                    <NumberFormat value={benefits.chronic}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>}
                                                            {!!benefits.mental && <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Mental</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={'right'}>
                                                                    <NumberFormat value={benefits.mental}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>}
                                                        </Table.Body>
                                                    </Table>
                                                </React.Fragment>
                                                }
                                            </CustomContainer>
                                            <CustomContainer basic>
                                                <Header as={"h2"} style={{marginBottom: 0}}>${formatMoneyAmount(cost)}</Header>
                                                <p className={"small neutral700Text"}>Your cost
                                                    per {displayPayrollFrecuency}</p>
                                            </CustomContainer>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div style={{border: "1px solid #E0E0E0"}}>
                                            <div style={{
                                                background: "#43806C",
                                                padding: '0.8em',
                                                textAlign: 'center',
                                                height: '56px'
                                            }}>
                                                <Header as={"h3"} inverted>Your updated plan</Header>
                                            </div>
                                            <div className={"small bkgLinen"} style={{height: '37px'}}><p
                                                style={{lineHeight: '37px'}}>Changes will be
                                                effective <b>{convertDateToLongFormat(effectiveDate)}</b></p></div>

                                            <CustomContainer basic className={"small"} textAlign={"left"}>
                                                <span className={"neutral600Text"}><b>Benefit amounts</b></span>
                                                <Table basic={"very"} style={{marginTop: '0'}}>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <b>Moderate</b>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={'right'}>
                                                                <NumberFormat value={moderateCoverageSelected}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} prefix={'$'}/>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <b>Severe</b>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={'right'}>
                                                                <NumberFormat value={severeCoverageSelected}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} prefix={'$'}/>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <b>Catastrophic</b>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={'right'}>
                                                                <NumberFormat value={catastrophicCoverageSelected}
                                                                              displayType={'text'}
                                                                              thousandSeparator={true} prefix={'$'}/>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>

                                                <Divider hidden/>
                                                {!!employerContribution &&
                                                <React.Fragment>
                                                    <span
                                                        className={"neutral600Text"}><b>Employer contribution</b></span>
                                                    <p><b>${formatMoneyAmount(updatedEmployerContribution)}</b></p>
                                                    <Divider hidden/>
                                                </React.Fragment>
                                                }

                                                <span className={"neutral600Text"}><b>Who’s covered?</b></span>
                                                <p>
                                                    <span><b>{`${user.firstName} ${user.lastName}`}</b></span>,{' '}
                                                    {(!!updatedCoveredDependents && updatedCoveredDependents.length > 0) &&
                                                    <React.Fragment>
                                                        {updatedCoveredDependents.map((dependent, index) => <span
                                                            key={index}><b>{(index ? ', ' : '') + `${dependent.firstName} ${dependent.lastName}`}</b></span>)}
                                                    </React.Fragment>
                                                    }
                                                </p>

                                                {(!!benefits.chronic || !!benefits.mental) && <React.Fragment>
                                                    <Divider hidden/>
                                                    <span className={"neutral600Text"}><b>Additional coverage</b></span>
                                                    <Table basic={"very"} style={{marginTop: '0'}}>
                                                        <Table.Body>
                                                            {!!benefits.chronic && <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Chronic</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={'right'}>
                                                                    <NumberFormat value={benefits.chronic}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>}
                                                            {!!benefits.mental && <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Mental</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={'right'}>
                                                                    <NumberFormat value={benefits.mental}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>}
                                                        </Table.Body>
                                                    </Table>
                                                </React.Fragment>
                                                }
                                            </CustomContainer>
                                            <CustomContainer basic>
                                                <Header as={"h2"} style={{marginBottom: 0}}>${formatMoneyAmount(updatedCost)}</Header>
                                                <p className={"small neutral700Text"}>Your cost
                                                    per {displayCostPerOption}</p>
                                            </CustomContainer>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                {!!submitError && <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Message negative>{submitError}</Message>
                                    </Grid.Column>
                                </Grid.Row>}
                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <Footer
                        showTopFooter={true} showCenterData={true} button={button}
                        link={link}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Confirm));
