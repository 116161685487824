import React, {Component} from 'react';
import {Grid} from "semantic-ui-react";
import CustomStep from './customStep';

class Steps extends Component {
    render() {
        const {stepProps} = this.props;
        const stepsCount = stepProps.length;
        const steps = stepProps.map((s, idx) => {
            return <Grid.Column key={idx} style={{padding: 0}}>
                <CustomStep completed={!!s.completed}
                            stepNumber={idx + 1}
                            active={!!s.active}
                            disabled={!!s.disabled}
                            numberLabel={s.numberLabel}
                            lastStep={stepsCount}/>
            </Grid.Column>
        });

        const stepLabels = stepProps.map((s, idx) => {
            return <Grid.Column key={idx}>
                <div style={{width: '150px', textAlign: 'center'}} className={(s.disabled) ? 'disabledStep' : ""}>
                    <p className="smaller">{s.label}</p>
                </div>
            </Grid.Column>
        });

        let stepsStyle = {marginLeft: '-260px', maxWidth: '600px', textAlign: 'center', marginBottom: '4em', marginTop: '4em'};

        return (
            <div style={{marginLeft: '50%'}}>
                <div className='steps' style={stepsStyle}>
                    <Grid style={{marginLeft: '70px', marginRight: '0px'}} columns={steps.length}>
                        {steps}
                    </Grid>
                    <Grid style={{marginLeft: '0px', marginRight: '70px', marginTop: '0px'}} columns={steps.length}>
                        <Grid.Row>
                            {stepLabels}
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default Steps;