import React from "react";


function FailedPaymentAlert(props) {
    return <React.Fragment>
        <div className="billingAlert past-due">
                    <span className={"smaller"}>There was an issue with one or more of your payment methods. To review the issue, please go to your <span
                        onClick={props.switchToSettings} className={"clickable"}><b>billing settings.</b></span>
                    </span>
        </div>
    </React.Fragment>
}

export default FailedPaymentAlert;