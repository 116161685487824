import React, {Component} from 'react';
import axios from "axios";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {Container, Grid, Header, Search} from "semantic-ui-react";
import {throttle} from 'throttle-debounce';
import Map from "./map";
import MapMarker from "./mapMarker";
import MainMenu from "./menu";

class Places extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            results: [],
            map: null,
            maps: null,
            lat: 35.460669951495305,
            lng: 273.11328125,
            place: null,
        };
        this.throttledPlacesCall = throttle(1000, this.handlePlacesCall);
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(
            position => {
                this.setState({ lat: position.coords.latitude, lng: position.coords.longitude});
            },
            error => console.log(error)
        );
    }

    handlePlacesCall = async () => {
        const {search, lat, lng} = this.state;
        if (search.length === 0) {
            return [];
        }

        const payload = {search: search}
        if(!!lat && !!lng){
            payload.lat = lat;
            payload.lng = lng;
        }
        const response = await axios.post("/api/member/v2/listPlaces", payload);
        if (response.status === 200) {
            const result = response.data;
            const predictions = result.predictions;

            this.setState({
                results: predictions.map((r) => {
                    return {
                        title: r.structured_formatting.main_text,
                        description: r.description,
                        placeId: r.place_id,
                        id: r.id,
                    }
                })
            })
        } else {
            throw(new Error("Http status not OK " + response.statusText));
        }
    }

    handleOnChange = async (e, {value}) => {
        this.setState({loading: true, search: value});
        await this.throttledPlacesCall(value);
        this.setState({loading: false});
    }

    handleSelectPlace = async (e, {result}) => {
        const placeId = result.placeId;
        const title = result.title;
        const {search} = this.state;
        if (search.length === 0) {
            return [];
        }
        const response = await axios.post("/api/member/v2/findPlaceDetails", {placeId: placeId});
        if (response.status === 200) {
            const respResult = response.data.result;
            const geometry = respResult.geometry;

            this.setState({
                search: title,
                place: {
                    icon: respResult.icon,
                    lat: geometry.location.lat,
                    lng: geometry.location.lng,
                }
            })
        } else {
            throw(new Error("Http status not OK " + response.statusText));
        }
    }

    render() {
        const {loading, search, results, map, maps, place, lat, lng} = this.state;

        if (!!map && !!maps) {
            const mapBounds = new maps.LatLngBounds();
            if (!!place) {
                mapBounds.extend({lat: place.lat, lng: place.lng})
                mapBounds.extend({lat: place.lat + 0.005, lng: place.lng + 0.005})
                mapBounds.extend({lat: place.lat - 0.005, lng: place.lng - 0.005})
                map.fitBounds(mapBounds);
            } else if (!!lat && !!lng){
                mapBounds.extend({lat, lng})
                mapBounds.extend({lat: lat + 0.005, lng: lng + 0.005})
                mapBounds.extend({lat: lat - 0.005, lng: lng - 0.005})
                map.fitBounds(mapBounds);
            }
        }

        return (
            <React.Fragment>
                <MainMenu/>
                    <Container>
                        <Grid>
                            <Grid.Column width={16}>
                                <Form>
                                    <Header as='h3' textAlign='left'>Place</Header>
                                    <Search input={{fluid: true, iconPosition: 'left'}} loading={loading} value={search}
                                            results={results}
                                            onResultSelect={this.handleSelectPlace}
                                            onSearchChange={this.handleOnChange}/>
                                </Form>

                                <div style={{marginTop: '3em', height: '50vh', width: '100%'}}>
                                    <Map
                                        streetViewControl={false}
                                        defaultCenter={{lat: this.state.lat, lng: this.state.lng}}
                                        defaultZoom={7}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        onGoogleApiLoaded={({map, maps}) => this.setState({map: map, maps: maps})}
                                    >
                                        {!!place && <MapMarker icon={place.icon} lat={place.lat} lng={place.lng}/>}
                                    </Map>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Container>
            </React.Fragment>
        );
    }
}

export default Places;
