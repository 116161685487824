import {initialDataActionTypes} from "../actions/actionTypes";

const initialState = {
    dependents: null,
    employer: null,
    user: null,
    certificateHolder: null,
    disclaimers: [],
    limitations: [],
    doctorsOfficeOptions: [],
    urgentCareOptions: [],
    facilityOptions: [],
    documents: [],
    cost: null,
    employerContribution: null,
    payrollFrequency: null,
    certificate: null,
    userIsCertificateHolder: null,
    qleReasonOptions: [],
    contract: null,
    increments: null,
    eligibility: null,
    quoteInfo: null,
    employerContributions: null,
    presets: null,
    coverageHistory: [],
    futurePendingAsset: null,
    frauds: [],
    openEnrollment: null,
    hasRestrictedAccess: false,
    token: "",
    stopScreenInfo: null,
    isOpenEnrollment: false,
    isNotEligible: false,
    riderLabels: null,
    benefitLimits: null,
};

export const initialDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case initialDataActionTypes.SET_INITIAL_DATA:
            return {
                ...state,
                dependents: action.payload.dependents,
                employer: action.payload.employer,
                user: action.payload.user,
                certificateHolder: action.payload.certificateHolder,
                disclaimers: action.payload.disclaimers,
                limitations: action.payload.limitations,
                doctorsOfficeOptions: action.payload.doctorsOfficeOptions,
                urgentCareOptions: action.payload.urgentCareOptions,
                facilityOptions: action.payload.facilityOptions,
                documents: action.payload.documents,
                cost: action.payload.cost,
                employerContribution: action.payload.employerContribution,
                payrollFrequency: action.payload.payrollFrequency,
                certificate: action.payload.certificate,
                userIsCertificateHolder: action.payload.userIsCertificateHolder,
                qleReasonOptions: action.payload.qleReasonOptions,
                contract: action.payload.contract,
                increments: action.payload.increments,
                eligibility: action.payload.eligibility,
                quoteInfo: action.payload.quoteInfo,
                employerContributions: action.payload.employerContributions,
                presets: action.payload.presets,
                coverageHistory: action.payload.coverageHistory,
                futurePendingAsset: !!action.payload.futurePendingAsset,
                frauds: action.payload.frauds,
                openEnrollment: action.payload.openEnrollment,
                hasRestrictedAccess: action.payload.hasRestrictedAccess === true,
                paymentDetails: action.payload.paymentDetails,
                token: action.payload.token,
                isDirectBillingEnabled: action.payload.isDirectBillingEnabled,
                stopScreenInfo: action.payload.stopScreenInfo,
                isNotEligible: action.payload.isNotEligible,
                isOpenEnrollment: action.payload.isOpenEnrollment,
                riderLabels: action.payload.riderLabels,
                benefitLimits: action.payload.benefitLimits,
            }
        case initialDataActionTypes.CLEAR_INITIAL_DATA:
            return initialState
        case initialDataActionTypes.UPDATE_DEPENDENTS:
            return {
                ...state,
                dependents: action.payload,
            }
        case initialDataActionTypes.SET_CONSENT:
            let {eConsent, ...rest} = state.user;
            return {
                ...state,
                user: {
                    ...rest,
                    eConsent: action.payload
                }
            };
        default:
            return state
    }
};
