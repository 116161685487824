import React, {Component} from 'react';
import {Icon, Label} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import moment from 'moment';

class Event extends Component {
    render() {
        const {iconName, effective, title, isSelected, viewAction, colorClass, isCurrentCoverage} = this.props;
        return (
            <CustomContainer className={`lifeEvent ${isSelected ? 'isSelected' : ''}`}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Icon name={iconName} className={colorClass + ' lifeEventIcon'}/>
                    <div style={{padding: '0 1em', alignSelf: 'center'}}>
                        <p className={"small"}>
                            <b>{title}</b><br/>
                            <span className={"smaller"}>Effective: {moment(effective).format('MMM Do, YYYY')}</span>
                        </p>
                    </div>
                    {isCurrentCoverage &&
                    <div style={{alignSelf: 'center'}}><Label className={"QLECurrentLabel"} content={"Current coverage"}/></div>}
                    <div style={{flex: 1, alignSelf: 'center'}}>
                        <div className={"linkText smaller"} style={{textAlign: "right"}} onClick={viewAction}>
                            {isCurrentCoverage ? <span>View coverage plan summary</span> :
                                <span>View plan as of {moment(effective).format('MMM Do, YYYY')} <Icon
                                    name={"eye"}/></span>}
                        </div>
                    </div>
                </div>
            </CustomContainer>
        )
    }
}

export default Event;