import {toastActionTypes} from './actionTypes'

export function show({type, title, message, autodismissTime}) {
    return {
        type: toastActionTypes.SHOW,
        payload: {
            type,
            title,
            message,
            autodismissTime
        }
    }
}

export function hide(id) {
    return {
        type: toastActionTypes.HIDE,
        payload: {
            id
        }
    }
}

export function clear(id) {
    return {
        type: toastActionTypes.CLEAR,
        payload: {
            id
        }
    }
}

export function reset() {
    return {
        type: toastActionTypes.RESET,
    }
}
