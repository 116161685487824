import React, {Component} from 'react';
import {Label} from "semantic-ui-react";

class CustomStep extends Component {
    render() {
        const {stepNumber, completed, disabled, lastStep} = this.props;
        return (
            <div className='step'>
                <Label style={{marginTop: '8px', fontSize: '5px', marginRight: '0'}}
                       color={disabled ? 'grey' : 'blue'}
                       circular />

                {stepNumber !== lastStep ?
                    <div className='lineContainer' style={{paddingTop: '5px'}}>
                        <div className={completed ? 'colorLine' : 'grey'}/>
                    </div> : null
                }
            </div>
        )
    }
}

export default CustomStep;