import {useState, useEffect} from 'react';
import { createContainer } from "unstated-next";
import { useHistory } from 'react-router';

import axios from "axios";

function usePaymentDetails({setPaymentDetailsLoading, creditCardPaymentStatus, paymentMethods, microDepositsBankAccount,
                               initialAutoPay, assetId, enrollmentPaymentMethod, firstName, effectiveDate, billingAccountId}) {
    const history = useHistory();
    const [creditCardData, setCreditCardData] = useState(null);
    const [bankAccountData, setBankAccountData] = useState(null);
    const [last4, setLast4] = useState(null);

    const [makePaymentError, setMakePaymentError] = useState(null);
    const [addPaymentMethodError, setAddPaymentMethodError] = useState(null);
    const [makePaymentLoading, setMakePaymentLoading] = useState(false);

    const [autoPay, setAutoPayData] = useState(initialAutoPay);
    const [autoPayBtn, setAutoPayBtn] = useState(initialAutoPay);

    const [creditCardPmVisibility, setCreditCardPmVisibility] = useState(false);
    const [creditCardPmData, setCreditCardPmData] = useState(null);
    const [bankAccountPmData, setBankAccountPmData] = useState(null);
    const [microDepositsBankAccountPmData, setMicroDepositsBankAccountPmData] = useState(null);
    const [paperCheckInstructionsVisibility, setPaperCheckInstructionsVisibility] = useState(false);
    const [paperCheckConfirmationVisibility, setPaperCheckConfirmationVisibility] = useState(false);
    const [paymentDetailsCardLoading, setPaymentDetailsCardLoading] = useState(false);
    const [hasEPaymentFailed, setHasEPaymentFailed] = useState(false);
    const [hasVerificationFailed, setHasVerificationFailed] = useState(false);
    const [bankAccountVerificationStatus, setBankAccountVerificationStatus] = useState("");
    const [showRemovePendingConfirmationModal, setShowRemovePendingConfirmationModal] = useState(false);


    useEffect(() => {
        const isBankAccountManuallyVerifiedLocal = () => {
            if (!bankAccountPmData) {
                return false
            }
            const {metadata} = bankAccountPmData
            if(metadata && metadata.verificationStatus && metadata.verificationStatus === "manually_verified"){
                return true
            }
        }

        const setIsFailedBankAccountPayment = () => {
            if(isBankAccountManuallyVerifiedLocal()){
                setHasEPaymentFailed(false)
            }
        }

        if (paymentMethods) {
            const bankAccounts = paymentMethods.filter(paymentMethod => paymentMethod.type === "bankaccount");
            const creditCards = paymentMethods.filter(paymentMethod => paymentMethod.type === "card");
            if (bankAccounts && bankAccounts.length > 0) {
                const bankAccount = bankAccounts[0];
                bankAccount.bank_name = bankAccount.brand;
                setBankAccountPm(bankAccount);
                setIsFailedBankAccountPayment();
                setLast4(bankAccount.last4);
            } else if (creditCardPaymentStatus) {
                if (creditCards && creditCards.length > 0) {
                    const failedCreditCard = creditCards[0];
                    setCreditCardPm({card: {last4: failedCreditCard.last4}, id: failedCreditCard.stripeId})
                    setHasEPaymentFailed(true);
                    setMakePaymentError("There was an issue with the credit card entered below. Please double check the information provided and try again.");
                } else {
                    setMakePaymentError("There was an issue with the credit card entered before.");
                }
            }
        }
        if(microDepositsBankAccount){
            setMicroDepositsBankAccountPm(microDepositsBankAccount);
            setHasEPaymentFailed(true);
        }
    }, [creditCardPaymentStatus, microDepositsBankAccount, paymentMethods, bankAccountPmData])

    useEffect(()=>{
        if(microDepositsBankAccountPmData){
            switch (microDepositsBankAccountPmData.status) {
                case "verification_failed":
                    setBankAccountVerificationStatus("Unable to verify")
                    break
                case "pending_manual_verification":
                    setBankAccountVerificationStatus("Pending verification")
                    break
                case "manually_verified":
                    setBankAccountVerificationStatus("Verified")
                    break
                default:
                    setBankAccountVerificationStatus(microDepositsBankAccountPmData.status)
                    break
            }
        }
    },[microDepositsBankAccountPmData])

    const showCreditCardPm = () => {
        setCreditCardPmVisibility(true)
    }

    const hideCreditCardPm = () => {
        setCreditCardPmVisibility(false)
    }

    const setCreditCardPm = (data) => {
        setCreditCardPmData(data)
        setLast4(data.card.last4)
        setCreditCardData({paymentMethodId: data.id})
        setBankAccountPmData(null)
        setBankAccountData(null)
    }

    const setMicroDepositsBankAccountPm = (data) => {
        setMicroDepositsBankAccountPmData(data)
        setBankAccountPmData(null)
        setBankAccountData(null)
        setCreditCardData(null)
    }

    const setBankAccountPm = (data) => {
        data.paymentMethodId = data.id || data.stripeId
        setBankAccountPmData(data)
        setLast4(data.last4);
        setBankAccountData({paymentMethodId: data.paymentMethodId})
        setCreditCardPmData(null)
        setCreditCardData(null)
        setMicroDepositsBankAccountPmData(null)
    }

    const toggleAutoPay = () => {
        const toggled = !autoPayBtn
        setAutoPayBtn(toggled)
        setAutoPayData(toggled)
    }

    const isPmSelected = () => {
        return !!bankAccountPmData || !!creditCardPmData || !!microDepositsBankAccountPmData
    }

    const isCreditCardPmSelected = () => {
        return !!creditCardPmData
    }

    const isBankAccountPmSelected = () => {
        return !!bankAccountPmData
    }

    const isMicroDepositsBankAccountSelected = () => {
        return !!microDepositsBankAccountPmData &&
            bankAccountVerificationStatus !== "Verified" &&
            !bankAccountPmData
    }

    const removePm = () => {
        resetErrors()
        setCreditCardPmData(null)
        setBankAccountPmData(null)
        setHasEPaymentFailed(false)
        setMicroDepositsBankAccountPmData(null)
        setBankAccountVerificationStatus("")
    }

    const resetErrors = () => {
        setMakePaymentError(null)
        setAddPaymentMethodError(null)
    }

    const showPaperCheckInstrucions = () => {
        setPaperCheckInstructionsVisibility(true)
    }

    const hidePaperCheckInstructions = () => {
        setPaperCheckInstructionsVisibility(false)
    }

    const showPaperCheckConfirmation = () => {
        setPaperCheckConfirmationVisibility(true)
    }

    const hidePaperCheckConfirmartion = () => {
        setPaperCheckConfirmationVisibility(false)
    }

    const setPlaidData = (metadata) => {
        setBankAccountPm(metadata)
    }

    const setMicroDepositsPlaidData = (metadata) => {
        setMicroDepositsBankAccountPm(metadata)
    }

    const setFailedVerificationStatus = (value) => {
        setHasVerificationFailed(value)
    }

    const setOpenRemovePendingConfirmationModal = (value) => {
        setShowRemovePendingConfirmationModal(value)
    }

    const showPaymentDetailsCardLoading = (value) => {
        setPaymentDetailsLoading(value)
        setPaymentDetailsCardLoading(value)
    }

    const makePayment = (amount) => {
        setMakePaymentLoading(true)
        amount = amount.toString()
        let method = ''
        const data = {
            amount,
            autoPay,
            initialAutoPay,
            last4,
            effectiveDate,
            firstName,
            assetId,
            billingAccountId,
            initialEnrollmentPaymentMethod: enrollmentPaymentMethod
        }
        if (isBankAccountPmSelected()) {
            method = 'BankAccount'
            data.bankAccountData = bankAccountData;
        } else if (isCreditCardPmSelected()) {
            method = 'CreditCard'
            data.paymentMethodId = creditCardData.paymentMethodId
        }

        axios.post(`/api/member/v2/payInitialPaymentWith${method}`, data, {headers: {'Content-Type': 'application/json'}})
            .then(_ => history.go(0))
            .catch(({response}) => {
                let errorMessage = 'There was an error while trying to make the payment.'
                const {data} = response
                const {paymentErrorMsg} = data
                if (paymentErrorMsg) {
                    errorMessage = paymentErrorMsg
                }
                removePm();
                setMakePaymentError(errorMessage)
                setMakePaymentLoading(false)
            })
    }

    const removeMicroDepositsBankAccount = () => {
        const paylod = {accountId:  microDepositsBankAccountPmData.accountId, billingAccountId}
        showPaymentDetailsCardLoading(true)
        axios.post(`/api/member/v2/removeMicroDepositsBankAccount`, paylod, {headers: {'Content-Type': 'application/json'}})
            .then(() => {
                showPaymentDetailsCardLoading(false)
                removePm()
            })
            .catch(e => {
                showPaymentDetailsCardLoading(false)
                console.error(e)
            })
    }

    const isBankAccountManuallyVerified = () => {
        if (!bankAccountPmData) {
            return false
        }
        const {metadata} = bankAccountPmData
        if(metadata && metadata.verificationStatus && metadata.verificationStatus === "manually_verified"){
            return true
        }
    }

    return {
        creditCardPmVisibility,
        showCreditCardPm,
        hideCreditCardPm,
        setCreditCardPm,
        setBankAccountPm,
        removePm,
        creditCardPmData,
        bankAccountPmData,
        microDepositsBankAccountPmData,
        isPmSelected,
        isCreditCardPmSelected,
        isBankAccountPmSelected,
        isMicroDepositsBankAccountSelected,
        toggleAutoPay,
        autoPayBtn,
        paperCheckInstructionsVisibility,
        showPaperCheckInstrucions,
        hidePaperCheckInstructions,
        paperCheckConfirmationVisibility,
        showPaperCheckConfirmation,
        hidePaperCheckConfirmartion,
        setPlaidData,
        setMicroDepositsPlaidData,
        showPaymentDetailsCardLoading,
        paymentDetailsCardLoading,
        hasEPaymentFailed,
        makePayment,
        makePaymentError,
        setMakePaymentError,
        setAddPaymentMethodError,
        addPaymentMethodError,
        makePaymentLoading,
        removeMicroDepositsBankAccount,
        setFailedVerificationStatus,
        hasVerificationFailed,
        setBankAccountVerificationStatus,
        bankAccountVerificationStatus,
        setOpenRemovePendingConfirmationModal,
        showRemovePendingConfirmationModal,
        isBankAccountManuallyVerified,
    }
}

export const PaymentDetailsContext = createContainer(usePaymentDetails);