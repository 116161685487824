import React, {Component} from 'react';
import axios from "axios";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MainMenu from "./menu";
import {Container} from "semantic-ui-react";

class Transcribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filenames: [
                "sample1.wav",
                "sample2.wav",
                "BW900_sample_dictation.wav",
            ],
            audios: [],
            bucketUrl: "https://clara-claimsdata.s3.amazonaws.com/audio/",
            texts: [
                "sample1.wav",
                "sample2.wav",
                "BW900 sample dictation.wav",
            ],
            statuses: [
                "Transcribe",
                "Transcribe",
                "Transcribe",
            ],
            loading: [
                false,
                false,
                false,
            ],
        };
    }

    onClick = async (_, p) => {
        let {filenames, texts, statuses, loading} = this.state;
        const index = p.id;
        loading[index] = true
        this.setState({
            loading: loading
        });

        const payload = {
            fileName: filenames[index]
        };
        try {
            const result = await axios.post("/api/member/v2/transcribeVoice",
                payload, {headers: {'Content-Type': 'application/json'}});
            texts[index] = result.data.text;
            statuses[index] = result.data.status;
            loading[index] = false;
            this.setState({
                texts: texts,
                statuses: statuses,
                loading: loading,
            });
        } catch (e) {
            console.warn(e);
            texts[index] = "Error";
            statuses[index] = "Transcribe";
            loading[index] = false;
            this.setState({
                texts: texts,
                statuses: statuses,
                loading: loading,
            });
        }
    };

    render() {
        const {bucketUrl, filenames, texts, loading} = this.state;

        return (
            <React.Fragment>
                <MainMenu/>
                <Container>
                    <Form>
                        {filenames.map((filename, index) => {
                            return (
                                <Form.Group>
                                    <audio controls style={{minWidth: 300}} src={bucketUrl + filename}/>
                                    {!loading[index] ? (
                                        <Button id={index} primary padded='very' onClick={this.onClick}>Transcribe</Button>
                                    ) : (
                                        <Button basic loading/>
                                    )}
                                    {/*<h3>{statuses[index]}</h3>*/}
                                    <p>{texts[index]}</p>
                                </Form.Group>
                            )
                        })}
                    </Form>
                </Container>
            </React.Fragment>
        );
    }
}

export default Transcribe;
