import {claimsActionTypes} from "./actionTypes";

export function setClaimList(payload) {
    return {
        type: claimsActionTypes.SET_CLAIM_LIST,
        payload: payload
    }
}

export function clearClaims() {
    return {
        type: claimsActionTypes.CLEAR_CLAIMS,
        payload: null
    }
}

export function setSelectedClaim(payload) {
    return {
        type: claimsActionTypes.SET_SELECTED_CLAIM,
        payload: payload
    }
}

export function setNIGOClaims(payload) {
    return {
        type: claimsActionTypes.SET_NIGO_CLAIMS,
        payload: payload
    }
}

export function updateClaimList(claimList) {
    return {
        type: claimsActionTypes.UPDATE_CLAIM_LIST,
        payload: claimList
    }
}