import React, {Component} from "react"
import {Divider, Grid, Image, Message, Table} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {Button, Header} from "../../../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {convertDateToLongFormat, convertDisplayDateFormatToServer, formatMoneyAmount, resolveErrorMessage, truncTwoDec, WHEN_ROUTE} from "../../../../utils/common";
import NumberFormat from "react-number-format";
import axios from "axios";
import ChangeSteps from "../changeSteps";
import {
    setCatastrophicCoverage,
    setCostPerOption,
    setCoverageOption,
    setModerateCoverage,
    setPlanContribAndCost,
    setPlanCoverageSelectedAndRecommendedSelect,
    setSevereCoverage,
    setTotalDependents
} from "../../../../actions/planChangeActions";
import {getPayrollFrecuencyLabel} from "../../../../utils/utils";

const mapStateToProps = (state, ownProps) => {
    const {initialData} = state;
    let additionalChronic = 0;
    let additionalMental = 0;
    if (!!initialData.contract && !!initialData.contract.riderSelection && initialData.contract.riderSelection.length > 0) {
        initialData.contract.riderSelection.forEach((ci) => {
            if (ci.selected) {
                if (ci.name === "chronic") {
                    additionalChronic = ci.coverage;
                } else if (ci.name === "mental") {
                    additionalMental = ci.coverage;
                }
            }
        });
    }
    return {
        ...ownProps,
        prevRoute: state.navigation.prevRoute,
        benefits: state.initialData.certificate.assetCoverages,
        employerContribution: state.initialData.employerContribution,
        cost: state.initialData.cost,
        payrollFrequency: state.initialData.payrollFrequency,
        dependents: state.initialData.dependents,
        updatedCost: state.planChange.cost,
        updatedEmployerContribution: state.planChange.employerContribution,
        totalDependents: state.planChange.totalDependents,
        moderateCoverageSelected: state.planChange.moderateCoverageSelected,
        severeCoverageSelected: state.planChange.severeCoverageSelected,
        catastrophicCoverageSelected: state.planChange.catastrophicCoverageSelected,
        costPerOption: state.planChange.costPerOption,
        costPerOptions: state.planChange.costPerOptions,
        qleReason: state.planChange.qleReason,
        qleDate: state.planChange.qleDate,
        coverageOption: state.planChange.coverageOption,
        newAddress: state.planChange.newAddress,
        user: state.initialData.user,
        contract: state.initialData.contract,
        moderateIncrements: (!!initialData && !!initialData.increments && initialData.increments.moderate) || [],
        severeIncrements: (!!initialData && !!initialData.increments && initialData.increments.severe) || [],
        catastrophicIncrements: (!!initialData && !!initialData.increments && initialData.increments.catastrophic) || [],
        certificate: state.initialData.certificate,
        initialData: state.initialData,
        additionalMental,
        additionalChronic,
        familyTierOptions: state.planChange.familyTierOptions,
        presets: state.initialData.presets
    }
};

class ConfirmDeathDisolutionDivorce extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitError: null,
            loadingPersonalize: false,
            submitting: false
        }
    }

    async componentDidMount() {
        const {certificate, payrollFrequency, dependents} = this.props;
        const costPerOption = payrollFrequency === "Monthly" ? 3 : 1;
        this.props.dispatch(setCostPerOption(costPerOption));
        let newFamilyTier = certificate.familyTier;
        if (certificate.familyTier === "spouse") {
            newFamilyTier = "employee"
        } else if (certificate.familyTier === "family") {
            newFamilyTier = "child"
        }
        const totalDependents = dependents.filter(dep => !(dep.relToEmployee === "Spouse"));
        this.props.dispatch(setTotalDependents(totalDependents));
        const deletes = this.alterDependentInfoBasedOnCoverage(newFamilyTier);
        this.props.dispatch(setCoverageOption(newFamilyTier, deletes.deleteChildren, deletes.deleteSpouse));
        await this.personalizePlan(newFamilyTier, costPerOption).then();
    }

    alterDependentInfoBasedOnCoverage = (coverageOptionId) => {
        const {familyTierOptions} = this.props;
        const option = familyTierOptions.filter((c) => c.value === coverageOptionId)[0];
        let deleteChildren;
        let deleteSpouse;
        switch (option.value) {
            case 'employee':
                deleteChildren = true;
                deleteSpouse = true;
                break;
            case 'spouse':
                deleteChildren = true;
                deleteSpouse = false;
                break;
            case 'child':
                deleteChildren = false;
                deleteSpouse = true;
                break;
            default:
                deleteChildren = false;
                deleteSpouse = false;
        }
        return {deleteChildren, deleteSpouse};
    }

    personalizePlan = async (forcedCoverageOption) => {
        this.setState({loadingPersonalize: true, recommendationEmailScheduledSuccessfully: null});
        try {
            const {additionalChronic, additionalMental, coverageOption, initialData} = this.props;
            let coverageOpt = coverageOption;
            if (!!forcedCoverageOption) {
                coverageOpt = forcedCoverageOption;
            }
            const benefits = initialData.certificate.assetCoverages;
            let result = await axios.post("/api/member/v2/personalizePlan", {
                familyTierValue: coverageOpt,
                moderateBenefit: benefits.moderate,
                severeBenefit: benefits.severe,
                catastrophicBenefit: benefits.catastrophic,
                quoteInfo: initialData.quoteInfo,
                mentalBenefit: additionalMental,
                chronicBenefit: additionalChronic,
                employerContributions: initialData.employerContributions,
                presets: initialData.presets,
                payrollFrequencySelected: initialData.payrollFrequency,
                ageAtPolicyEffectiveDate: initialData.certificate.ageAtPolicyEffectiveDate,
                ratedClassRates: initialData.ratedClass.ratedClassRates,
            });
            if (!!result && !!result.data) {
                const data = result.data;
                this.props.dispatch(setPlanCoverageSelectedAndRecommendedSelect(data.costValue, data.costEnhanced, data.costPremier,
                    data.contribValue, data.contribEnhanced, data.contribPremier));

                let cost;
                let contrib;

                if (initialData.contract.planOffering === "Brella Choice") {
                    cost = data.costChoice;
                    contrib = data.contribChoice;
                } else if (initialData.contract.planOffering === "Brella Select") {
                    let plan = this.getCurrentPlan();
                    cost = data.costValue;
                    contrib = data.contribValue;

                    if (!!plan.enhanced) {
                        cost = data.costEnhanced;
                        contrib = data.contribEnhanced;
                    } else if (!!plan.premier) {
                        cost = data.costPremier;
                        contrib = data.contribPremier;
                    }
                } else if (initialData.contract.planOffering === "Brella Custom") {
                    cost = data.costCustom;
                    contrib = data.contribCustom;
                }


                const ec = contrib ? contrib : 0.0;
                const benefits = initialData.certificate.assetCoverages;
                this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)));
                this.props.dispatch(setModerateCoverage(benefits.moderate));
                this.props.dispatch(setCatastrophicCoverage(benefits.catastrophic));
                this.props.dispatch(setSevereCoverage(benefits.severe));
            }
            this.setState({loadingPersonalize: false});
        } catch (e) {
            console.log(e);
            this.setState({loadingPersonalize: false})
        }
    }

    getCurrentPlan = () => {
        const {benefits, presets} = this.props;
        const isValue = benefits.moderate === presets.value.moderate && benefits.severe === presets.value.severe && benefits.catastrophic === presets.value.catastrophic;
        const isEnhanced = benefits.moderate === presets.enhanced.moderate && benefits.severe === presets.enhanced.severe && benefits.catastrophic === presets.enhanced.catastrophic;
        const isPremier = benefits.moderate === presets.premier.moderate && benefits.severe === presets.premier.severe && benefits.catastrophic === presets.premier.catastrophic;
        return (
            {
                value: isValue,
                enhanced: isEnhanced,
                premier: isPremier
            }
        )
    }

    onSubmit = async () => {
        const {
            qleReason,
            qleDate,
            totalDependents,
            coverageOption,
            newAddress,
            moderateCoverageSelected,
            severeCoverageSelected,
            catastrophicCoverageSelected
        } = this.props;
        try {
            this.setState({
                submitError: null,
                submitting: true
            })
            await axios.post("/api/member/v2/submitPlanChanges", {
                qleReason: qleReason,
                qleDate: convertDisplayDateFormatToServer(qleDate),
                benefitModerate: !!moderateCoverageSelected ? moderateCoverageSelected : 0,
                benefitSevere: !!severeCoverageSelected ? severeCoverageSelected : 0,
                benefitCatastrophic: !!catastrophicCoverageSelected ? catastrophicCoverageSelected : 0,
                newAddress: !!newAddress.street ? newAddress : null,
                dependents: totalDependents,
                familyTier: coverageOption
            });
            this.props.history.push("/change/update/planChangeSuccess")
        } catch (e) {
            this.setState({
                    submitError: resolveErrorMessage(e, "There was a problem submitting your plan update."),
                    submitting: false
                }
            )
        }
    }

    onCancel = () => {
        this.props.history.push("/plan")
    }

    onBackHandler = () => {
        this.props.history.push(WHEN_ROUTE)
    }

    render() {
        const {
            benefits,
            employerContribution,
            cost,
            payrollFrequency,
            dependents,
            updatedCost,
            updatedEmployerContribution,
            totalDependents,
            moderateCoverageSelected,
            severeCoverageSelected,
            catastrophicCoverageSelected,
            costPerOption,
            costPerOptions,
            user,
            qleDate
        } = this.props;
        const button = <Button primary content="Submit" loading={this.state.submitting} onClick={this.onSubmit}/>;
        const displayPayrollFrecuency = getPayrollFrecuencyLabel(payrollFrequency)
        let displayCostPerOption = null;
        if (!!costPerOptions && !!costPerOption) {
            displayCostPerOption = costPerOptions.filter(option => option.id === costPerOption)[0].value.toLowerCase();
        }
        const link = <span className={"linkText"} onClick={this.onCancel}>or Cancel</span>
        const {submitError, loadingPersonalize} = this.state;
        const hasConstAndEC = employerContribution !== null && employerContribution !== undefined && cost !== null && cost !== undefined && updatedCost !== null && updatedCost !== undefined && updatedEmployerContribution !== null && updatedEmployerContribution !== undefined;

        if (loadingPersonalize || !hasConstAndEC) {
            return <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container"
                     style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                    <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                        <Grid.Column width={8} className={"pageContent"} textAlign={"center"}>
                            <Image centered style={{width: '150px'}}
                                   src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Loading.png"}/>
                            <Header as={'h4'}>Just a moment...</Header>
                            <p>We're personalizing your plan</p>
                        </Grid.Column>
                    </Grid>
                </div>
            </React.Fragment>;
        } else {
            return (
                <React.Fragment>
                    <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                    <div className="member-main-container" style={{background: 'white'}}>
                        <ChangeSteps activeStepIdx={3}/>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={10} className={"pageContent"}>
                                <Grid stackable container textAlign={'center'}>
                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <Header as={"h2"}>Confirm your plan change</Header>
                                            <p>Here are your current plan details and the updated plan with your
                                                selections—</p>
                                            <Divider hidden/>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <div style={{border: "1px solid #E0E0E0", height: '100%'}}>
                                                <div className={"bkgFog"}
                                                     style={{padding: '0.8em', textAlign: 'center', height: '56px'}}>
                                                    <Header as={"h3"}>Current Brella coverage</Header>
                                                </div>
                                                <div style={{height: '37px'}}/>
                                                <CustomContainer basic className={"small"} textAlign={"left"}>
                                                    <span className={"neutral600Text"}><b>Benefit amounts</b></span>
                                                    {!!benefits && <Table basic={"very"} style={{marginTop: '0'}}>
                                                        <Table.Body>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Moderate</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={"right"}>
                                                                    <NumberFormat value={benefits.moderate}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Severe</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={"right"}>
                                                                    <NumberFormat value={benefits.severe}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Catastrophic</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={"right"}>
                                                                    <NumberFormat value={benefits.catastrophic}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>}

                                                    <Divider hidden/>

                                                    {!!employerContribution && <React.Fragment>
                                                    <span
                                                        className={"neutral600Text"}><b>Employer contribution</b></span>
                                                        <p><b>${formatMoneyAmount(employerContribution)}</b></p>
                                                        <Divider hidden/>
                                                    </React.Fragment>
                                                    }

                                                    <span className={"neutral600Text"}><b>Who’s covered?</b></span>
                                                    <p>
                                                        {!!user &&
                                                        <span><b>{`${user.firstName} ${user.lastName}`}</b></span>}
                                                        {(!!dependents && dependents.length > 0) &&
                                                        <React.Fragment>,{' '}
                                                            {dependents.map((dependent, index) => <span
                                                                key={index}><b>{(index ? ', ' : '') + `${dependent.firstName} ${dependent.lastName}`}</b></span>)}
                                                        </React.Fragment>
                                                        }
                                                    </p>

                                                    {(!!benefits && (!!benefits.chronic || !!benefits.mental)) &&
                                                    <React.Fragment>
                                                        <Divider hidden/>
                                                        <span
                                                            className={"neutral600Text"}><b>Additional coverage</b></span>
                                                        <Table basic={"very"} style={{marginTop: '0'}}>
                                                            <Table.Body>
                                                                {!!benefits.chronic && <Table.Row>
                                                                    <Table.Cell>
                                                                        <b>Chronic</b>
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign={"right"}>
                                                                        <NumberFormat value={benefits.chronic}
                                                                                      displayType={'text'}
                                                                                      thousandSeparator={true}
                                                                                      prefix={'$'}/>
                                                                    </Table.Cell>
                                                                </Table.Row>}
                                                                {!!benefits.mental && <Table.Row>
                                                                    <Table.Cell>
                                                                        <b>Mental</b>
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign={"right"}>
                                                                        <NumberFormat value={benefits.mental}
                                                                                      displayType={'text'}
                                                                                      thousandSeparator={true}
                                                                                      prefix={'$'}/>
                                                                    </Table.Cell>
                                                                </Table.Row>}
                                                            </Table.Body>
                                                        </Table>
                                                    </React.Fragment>
                                                    }
                                                </CustomContainer>
                                                <CustomContainer basic>
                                                    <Header as={"h2"} style={{marginBottom: 0}}>${formatMoneyAmount(cost)}</Header>
                                                    <p className={"small neutral700Text"}>Your cost
                                                        per {displayPayrollFrecuency}</p>
                                                </CustomContainer>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div style={{border: "1px solid #E0E0E0", height: '100%'}}>
                                                <div style={{
                                                    background: "#43806C",
                                                    padding: '0.8em',
                                                    textAlign: 'center',
                                                    height: '56px'
                                                }}>
                                                    <Header as={"h3"} inverted>Your updated plan</Header>
                                                </div>
                                                <div className={"small bkgLinen"} style={{height: '37px'}}><p
                                                    style={{lineHeight: '37px'}}>Changes will be
                                                    effective <b>{convertDateToLongFormat(qleDate)}</b></p></div>
                                                <CustomContainer basic className={"small"} textAlign={"left"}>
                                                    <span className={"neutral600Text"}><b>Benefit amounts</b></span>
                                                    {!!benefits && <Table basic={"very"} style={{marginTop: '0'}}>
                                                        <Table.Body>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Moderate</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={"right"}>
                                                                    <NumberFormat value={moderateCoverageSelected}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Severe</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={"right"}>
                                                                    <NumberFormat value={severeCoverageSelected}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <b>Catastrophic</b>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign={"right"}>
                                                                    <NumberFormat value={catastrophicCoverageSelected}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}
                                                                                  prefix={'$'}/>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>}

                                                    <Divider hidden/>

                                                    {!!employerContribution && <React.Fragment>
                                                    <span
                                                        className={"neutral600Text"}><b>Employer contribution</b></span>
                                                        <p><b>${formatMoneyAmount(updatedEmployerContribution)}</b></p>
                                                        <Divider hidden/>
                                                    </React.Fragment>
                                                    }

                                                    <span className={"neutral600Text"}><b>Who’s covered?</b></span>
                                                    <p>
                                                        <span><b>{`${user.firstName} ${user.lastName}`}</b></span>,{' '}
                                                        {(!!totalDependents && totalDependents.length > 0) &&
                                                        <React.Fragment>
                                                            {totalDependents.map((dependent, index) => <span
                                                                key={index}><b>{(index ? ', ' : '') + `${dependent.firstName} ${dependent.lastName}`}</b></span>)}
                                                        </React.Fragment>
                                                        }
                                                    </p>

                                                    {(!!benefits.chronic || !!benefits.mental) && <React.Fragment>
                                                        <Divider hidden/>
                                                        <span
                                                            className={"neutral600Text"}><b>Additional coverage</b></span>
                                                        <Table basic={"very"} style={{marginTop: '0'}}>
                                                            <Table.Body>
                                                                {!!benefits.chronic && <Table.Row>
                                                                    <Table.Cell>
                                                                        <b>Chronic</b>
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign={"right"}>
                                                                        <NumberFormat value={benefits.chronic}
                                                                                      displayType={'text'}
                                                                                      thousandSeparator={true}
                                                                                      prefix={'$'}/>
                                                                    </Table.Cell>
                                                                </Table.Row>}
                                                                {!!benefits.mental && <Table.Row>
                                                                    <Table.Cell>
                                                                        <b>Mental</b>
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign={"right"}>
                                                                        <NumberFormat value={benefits.mental}
                                                                                      displayType={'text'}
                                                                                      thousandSeparator={true}
                                                                                      prefix={'$'}/>
                                                                    </Table.Cell>
                                                                </Table.Row>}
                                                            </Table.Body>
                                                        </Table>
                                                    </React.Fragment>
                                                    }
                                                </CustomContainer>
                                                <CustomContainer basic>
                                                    <Header as={"h2"} style={{marginBottom: 0}}>${formatMoneyAmount(updatedCost)}</Header>
                                                    <p className={"small neutral700Text"}>Your cost
                                                        per {displayCostPerOption}</p>
                                                </CustomContainer>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {!!submitError && <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <Message negative>{submitError}</Message>
                                        </Grid.Column>
                                    </Grid.Row>}
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        <Footer
                            showTopFooter={true} showCenterData={true} button={button}
                            link={link}
                        />
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps)(ConfirmDeathDisolutionDivorce));
