import React from 'react'
import moment from 'moment';
import {Grid, Icon, Image} from 'semantic-ui-react';
import {Header} from "../base";

export const claimPaymentMethodLabels = {
    bank: {
        label: 'Bank Account',
        icon: 'university',
    },
    stripe: {
        label: 'Bank Account',
        icon: 'university',
    },
    paypal: { label: 'Paypal' },
    venmo: { label: 'Venmo' },
    paycheck: {
        label: 'Paper check',
        icon: 'envelope outline'
    },
    check: {
        label: 'Paper check',
        icon: 'envelope outline'
    }
}

export const allowedPaymentStatuses = [
    'Pending',
    'Processing',
    'Succeeded',
    'Payment Failed'
]

export const paymentStatusLabel = (status, issuedDate) => {
    if (!allowedPaymentStatuses.includes(status)) return '--'

    if (!issuedDate) return status

    if (status === 'Processing' || status === 'Succeeded') {
        const date = moment(issuedDate).format("MMM Do, YYYY")
        return `Issued on ${date}`
    }

    return status
}

export const renderMethod = (method) => {
    switch (method) {
        case 'paypal':
            return (
                <Image
                    style={{width: '88px', display: 'inline-block'}}
                    src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Payment+methods/paypal%402x.png"}
                    alt="Paypal"
                />
            )
        case 'venmo':
            return (
                <Image
                    style={{width: '88px', display: 'inline-block'}}
                    src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Payment+methods/venmo%402x.png"}
                    alt="Venmo"
                />
            )
        default:
            if (!method) return '--'

            const { label, icon } = claimPaymentMethodLabels[method] || {}

            if (!label) return method

            return <>
                {icon && <Icon name={icon} />}
                <span>{label}</span>
            </>
    }

}

export const ClaimPayment = ({ method, status, amount, issuedDate }) => {
    const methodLabel = renderMethod(method)
    const showDepositMessage = (status === "Processing" || status === "Succeeded") && (method === "bank" || method === "stripe");
    const getAmount = () => {
        if (!amount) { return <span style={{ paddingLeft: '1em' }}>-</span> }

        if(amount !== 'Pending') {
            return (
                <Header style={{marginTop: '0.3em'}} as={"h3"}>
                    ${amount}
                </Header>
            )
        }
        return <span  className="small neutral600Text">{amount}</span>
    }
    return (
        <Grid columns={4}>
            <Grid.Row>
                <Grid.Column width={2}>
                    <Header as={"h4"}>
                        Payment<br/>details
                    </Header>
                </Grid.Column>
                <Grid.Column textAlign={"left"} width={3}>
                    <p className="small neutral600Text">
                        <b>Payment method</b><br/>
                        {methodLabel}
                    </p>
                </Grid.Column>
                <Grid.Column width={5}>
                    <p className="small neutral600Text">
                        <b>Payment Status</b><br />
                        {paymentStatusLabel(status, issuedDate)}
                        {showDepositMessage && (
                            <>
                                <br />
                                <i className={"smaller"}>
                                *Deposits take 5-10 business days to show in accounts.
                                </i>
                            </>
                        )}
                    </p>
                </Grid.Column>
                <Grid.Column textAlign={"center"} width={2}>
                    <p className="small neutral600Text">
                        <b>Amount</b><br />
                        {getAmount()}
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
