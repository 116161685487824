import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {setLeftNavigation} from "../../actions/navigationActions";
import {
    MFAIcon,
    MFASegment,
    MFAText
} from "../styled";

const MfaReminder = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const setUpMfa = () => {
        dispatch(setLeftNavigation(false));
        history.push({ pathname: '/mfa'});
    }

    return(
        <MFASegment className={"mfaReminder"}>
            <MFAText className={"mfaReminder-title"}>Reminder</MFAText>
            <MFAText>Two-factor authentication is not enabled yet. Add an additional layer of security to your account.</MFAText>
            <MFAText className={"linkText"} onClick={setUpMfa}>
                Set it up <MFAIcon name={'long arrow alternate right'} />
            </MFAText>
        </MFASegment>
    )
}

export default MfaReminder