import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {cleanupNewClaim} from "../../../actions/newClaimActions";
import {connect} from "react-redux";
import {memberName} from "../../../utils/common";
import {purgeState} from "../../../storePersistence";
import FullScreenPageMenu from '../../fullScreenPageMenu';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        claimant: state.newClaim.claimant
    }
};

const mapDispatchToProps = dispatch => {
    return {
        cleanupNewClaim: () => dispatch(cleanupNewClaim())
    }
};

class NewClaimMenu extends Component {
    onClose = () => {
        const {cleanupNewClaim, history} = this.props

        cleanupNewClaim()
        history.replace("/claims")
    }

    logout = () => {
        const {logout} = this.props

        purgeState()
        logout()
    };

    render() {
        const {onBack, claimant, user} = this.props
        return (
            <FullScreenPageMenu
                onBack={onBack}
                useMobileStyles
                center={`New claim for ${memberName(claimant)}`}
                user={user}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewClaimMenu));