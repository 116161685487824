import React from "react";
import {Dimmer, Grid, Image, Loader, Message} from "semantic-ui-react";
import {Button} from "../base";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";
import CreditCardPm from "./creditCardPm";
import PaymentMethodSelected from "./paymentMethodSelected";
import PaperCheckInstructionsModal from "./paperCheckInstructionsModal";
import YourCosts from "./yourCosts";
import Plaid from "./plaid";
import axios from "axios";

const PaymentDetailsCard = (props) => {
    const {
        creditCardPmVisibility,
        showCreditCardPm,
        isPmSelected,
        paperCheckInstructionsVisibility,
        showPaperCheckInstrucions,
        setPlaidData,
        setMicroDepositsPlaidData,
        showPaymentDetailsCardLoading,
        makePaymentError,
        setAddPaymentMethodError,
        addPaymentMethodError,
    } = PaymentDetailsContext.useContainer();

    const {
        plaidEnviroment,
        stripePublicKey,
        formattedCostPayment,
        loading,
        token,
        showPaperCheckIntructionsInfo,
        cost,
        employerContribution,
        strings,
        plaidLinkToken,
        billingAccountId
    } = props;

    const handleSetPlaidData = async (data) => {
        const isVerifiedBankAccount = !(data.account.verification_status === "pending_manual_verification") && !(data.account.verification_status === "verification_failed")

        const payload = {
            publicToken: data.public_token,
            accountId: data.account_id,
            metadata: data.metadata,
            billingAccountId,
        }
        if (isVerifiedBankAccount) {
            await addBankAccountWithAuth(payload)
        }
        else {
            await addMicroDepositsBankAccount(payload)
        }
    }

    const addBankAccountWithAuth = async (payload) => {
        const axiosPostconfig = {headers: {'Content-Type': 'application/json'}}
        try {
            showPaymentDetailsCardLoading(true)
            const response = await axios.post("/api/member/v2/addBankAccount", payload, axiosPostconfig);
            setPlaidData(response.data)
            showPaymentDetailsCardLoading(false)
        } catch (err) {
            console.log(err);
            setAddPaymentMethodError(strings.paymentRequired.addBankAccountError)
            showPaymentDetailsCardLoading(false)
        }
    }

    const addMicroDepositsBankAccount = async (payload) => {
        const axiosPostconfig = {headers: {'Content-Type': 'application/json'}}
        try {
            showPaymentDetailsCardLoading(true)
            const addMicroDepositsBankAccountResponse = await axios.post("/api/member/v2/addMicroDepositsBankAccount", payload, axiosPostconfig)
            setMicroDepositsPlaidData(addMicroDepositsBankAccountResponse.data.microDepositsBankAccount)
            showPaymentDetailsCardLoading(false)
        } catch (err) {
            setAddPaymentMethodError(strings.paymentRequired.addBankAccountError)
            showPaymentDetailsCardLoading(false)
        }
    }

    const renderErrorMessage  = () => {
        if (makePaymentError) {
            return <>{strings.paymentRequired.paymentIssue} {makePaymentError}</>
        } else if (addPaymentMethodError) {
            return (
                addPaymentMethodError
            )
        }
    }

    const removeBankAccount = async (id) => {
        const payload = {
            paymentMethodId: id,
            billingAccountId,
            token,
        }
        const axiosPostConfig = {headers: {'Content-Type': 'application/json'}}
        try {
            showPaymentDetailsCardLoading(true)
            await axios.post("/api/member/v2/removeBankAccount", payload, axiosPostConfig)
            showPaymentDetailsCardLoading(false)
        } catch (e) {
            showPaymentDetailsCardLoading(false)
        }
    }

    const noPaymentMethodSelected =
        <Grid>
            <Grid.Row columns={2} style={{alignItems: "center"}}>
                <Image src={process.env.PUBLIC_URL + "/images/payment_methods.svg"}/>
                <Grid.Column textAlign={"left"} width={10}>
                    <Grid.Row>
                        <p>{strings.paymentRequired.youHaveNoEPayment}</p>
                        <Plaid plaidEnviroment={plaidEnviroment}
                               plaidLinkToken={plaidLinkToken}
                               setPlaidData={handleSetPlaidData}
                               strings={strings}
                        />
                        <strong style={{margin: "0 10px"}}>{strings.paymentRequired.or}</strong>
                        <Button primary basic onClick={showCreditCardPm}>{strings.paymentRequired.addCreditCard}</Button>
                    </Grid.Row>
                </Grid.Column>
                { (makePaymentError || addPaymentMethodError) &&
                    <Message style={{width: "100%"}} negative>
                        {renderErrorMessage()}
                    </Message>
                }
            </Grid.Row>
            <Grid.Row style={{borderTop: "1px dashed lightgray", paddingBottom: 0 }}>
                <Grid.Column width={6} style={{padding: 0}}>
                    <YourCosts cost={cost}
                               formattedCostPayment={formattedCostPayment}
                               employerContribution={employerContribution}
                               strings={strings}
                    />
                </Grid.Column>
            </Grid.Row>
            { showPaperCheckIntructionsInfo &&
                <Grid.Row>
                    <p>{strings.paymentRequired.iDontWantToMake}</p>
                    <a href onClick={showPaperCheckInstrucions}>&nbsp;{strings.paymentRequired.seeInstructions}</a>
                </Grid.Row>
            }
        </Grid>;

    return (
        <>
            { loading && <Dimmer active page><Loader/></Dimmer> }
            { paperCheckInstructionsVisibility && <PaperCheckInstructionsModal strings={strings} /> }
            <Grid style={{margin: 0, marginBottom: '30px'}}>
                { !creditCardPmVisibility && !isPmSelected() && noPaymentMethodSelected }
                { creditCardPmVisibility && <CreditCardPm stripePublicKey={stripePublicKey} strings={strings} /> }
                { isPmSelected() &&
                    <PaymentMethodSelected
                        cost={cost}
                        formattedCostPayment={strings.paymentGeneral.mo}
                        employerContribution={employerContribution}
                        removeBankAccount={removeBankAccount}
                        strings={strings}
                        setPlaidData={handleSetPlaidData}
                        billingAccountId={billingAccountId}
                    /> }
            </Grid>
        </>
    )
}

export default PaymentDetailsCard;
